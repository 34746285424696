import { Buffer } from 'buffer'

export function decodePcmS24LE(pcmS24LEData: Buffer): Float32Array {
  if (!pcmS24LEData || !(pcmS24LEData instanceof Buffer)) {
    throw new Error('Invalid input: Expected a Buffer instance.')
  }

  // Each sample is 3 bytes, so the number of samples is the buffer length divided by 3
  const numSamples = pcmS24LEData.length / 3
  const float32Array = new Float32Array(numSamples)
  const normalizationFactor = 1 / 8388608 // To normalize to the range [-1, 1]

  for (let i = 0; i < numSamples; i++) {
    // Read three bytes and form a 24-bit signed integer, considering little endian format
    const index = i * 3
    let sample =
      pcmS24LEData[index] | (pcmS24LEData[index + 1] << 8) | (pcmS24LEData[index + 2] << 16)
    // If the sample is negative, extend the sign to 32 bits
    if (sample & 0x800000) {
      sample = (sample & 0x7fffff) - 0x800000
    }
    float32Array[i] = sample * normalizationFactor
  }
  return float32Array
}

export interface CreateCustomerSettingResponse {
  id: string
  type: CustomSettingsType
  userId: string
  workspaceId: string | null
  key: string
  value: any
}
export interface CreateCustomerSettingData {
  id?: string
  type: CustomSettingsType
  key: string
  value: any
}
export enum CustomSettingsType {
  workspace = 'ws',
  userWorkspace = 'uws',
  user = 'u'
}
export interface FindCustomSetting {
  key: string
  type: CustomSettingsType
}

import { Service } from '@/lib/api'
import type { SearchTrackDto, TrackEntity } from '@/lib/api/services/ai/tracker/tracker.itnerface'

export class TrackerService extends Service {
  public find(request: SearchTrackDto) {
    return this.post<Array<TrackEntity>>('/api/event-storage-ai/track', request)
  }

  public getTrack(cameraId: string, trackId: string) {
    return this.get<Array<TrackEntity>>(`/api/event-storage-ai/track/${cameraId}/${trackId}`)
  }
}

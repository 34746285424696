export enum MonitoringStatus {
  Recording = 'recording',
  Offline = 'offline',
  Disconnected = 'disconnected',
  Live = 'live'
}
export enum CameraStatus {
  offline = 'offline',
  live = 'live',
  tryAgain = 'tryAgain',
  connecting = 'connecting',
  error = 'error'
}
export enum MonitoringFilter {
  all = 'ALL',
  Live = 'LIVE',
  Recording = 'RECORD',
  Offline = 'ERROR'
}
export enum ClipsFilter {
  Downloading = 'Downloading',
  LiveURLs = 'LiveURLs',
  Archived = 'Archived',
  SortByNew = 'SortByNew'
}
export enum GridListEnum {
  clock = 'clock',
  aI = 'aI',
  gradiant = 'gradiant',
  box = 'box',
  list = 'list'
}

export interface CameraMonitoringItem {
  id: string
  title: string
  status: MonitoringStatus
  location?: string
  port?: string
  people: CameraMonitoringItemPeople[]
  manufactureCode: number | undefined
}

export interface CameraMonitoringItemPeople {
  id: string
  fullName?: string
  avatar?: string
}

export default {
  billing: {
    title: 'Billing',
    payment: {
      title: 'Payment Method',
      newPaymentTitle: 'New Payment Method',
      updatePaymentTitle: 'Update Payment Details',
      updatePaymentDescription:
        '{data}, your peace of mind is priceless!\n' +
        'Secure continuous protection for your spaces by updating your payment details.',
      selectCard: 'Select Card',
      labels: {
        fullName: 'Full Name',
        zipCode: 'Zip Code',
        cardNumber: 'Card Number'
      },
      actions: {
        addCard: 'Add Card',
        credit: 'Credit Card',
        google: 'Google Play',
        bank: 'Bank'
      },
      messages: {
        goodNews: 'Good News',
        footer: 'Commitment-free zone: bow out anytime without any fees.',
        drawer:
          "We require a valid payment method on file. {news} Your card won't be changed until {date} if you need to cancel at any time, you have that flexibility."
      }
    },
    ask: {
      title: 'Ask Us Anything ☕️',
      messages: {
        subtitle:
          'Need clarity on plans and billing? Our team is at your service and eager to assist!'
      }
    },
    methods: {
      title: 'Payment Method',
      actions: {
        add: 'Payment Method',
        update: 'Update',
        delete: 'Delete',
        replace: 'Replace'
      }
    },
    invoices: {
      title: 'Invoices',
      noData: 'Nada! Zilch! No invoices in sight.',
      labels: {
        invoice: 'Invoice',
        all: 'All'
      },
      actions: {
        download: 'Download'
      }
    },
    invoiceDetail: {
      invoiceStatus: {
        paid: 'Paid',
        overdue: 'Overdue',
        error: 'Failed',
        open: 'Open',
        freeTrial: '1 Month Free'
      },
      period: 'Subscription Period',
      monthly: 'Monthly',
      yearly: 'Yearly',
      sixtyDays: '60 Days',
      ninetyDays: '90 Days',
      days: 'Days',
      minutes: 'Minutes',
      hours: 'Hours',
      vat: 'Vat',
      subtotal: 'Subtotal',
      total: 'Total',
      download: 'Download',
      invoice: 'Invoice'
    },
    address: {
      title: 'Addresses',
      successUpdate: 'Updated!'
    }
  }
}

import { BookmarkType } from '@/player/interfaces'
import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'
import { TimelineHelpers } from '@/player/lib/playback-timeline/timeline-helpers'
import { EventCacheTampering } from '@/player/lib/events/event-cache-tampering'

export class TimelineTampering {
  public cache: EventCacheTampering
  public idSet: Set<string> = new Set()
  protected recordPauseImage: HTMLImageElement

  constructor(public readonly timelineCore: TimelineCore) {
    this.recordPauseImage = new Image()
    this.recordPauseImage.src = 'images/recordPause.svg'
    this.cache = new EventCacheTampering(this.timelineCore.core)
  }

  get activeBookmark() {
    return this.timelineCore.core.activeBookmark
  }

  push(value: Array<number[]>) {
    this.timelineCore.resetRenderCache()
  }

  getOverlappingPeriods(startTime: number, endTime: number): Array<number[]> {
    return this.cache.data.filter(([start, end]) => start <= endTime && end >= startTime)
  }

  filterForDrawing(points: Array<number[]>, minValue: number): Array<number[]> {
    const res = points.filter(([startAt, endAt]) => endAt - startAt > 2)
    if (res[0]) {
      res[0][0] = Math.max(res[0][0], this.timelineCore.findRelativeXForDate(minValue))
    }
    return res
  }

  render(records: number[][]) {
    for (const [start, end] of records) {
      this.timelineCore.drawer.drawLine(start, this.timelineCore.renderPositionsConstant.bookmark, {
        ...this.timelineCore.renderConstant.bookmark,
        width: end - start,
        color: {
          fill: TimelineHelpers.formatColor('#C7A82F', 0.3)
        }
      })
      const pattern = this.timelineCore.drawer.ctx.createPattern(this.recordPauseImage, 'repeat')
      this.timelineCore.drawer.drawLine(
        start,
        this.timelineCore.renderPositionsConstant.horizontalLinePlaceHolder,
        {
          ...this.timelineCore.renderConstant.horizontalLinePlaceHolder,
          color: {
            fill: pattern || '#C7A82F'
          },
          height: this.timelineCore.renderConstant.horizontalLinePlaceHolder.height * 2,
          width: end - start
        }
      )
    }
  }

  onHover(x: number, y: number, haveBookmark = false) {
    if (!haveBookmark) {
      const diffStart = (this.timelineCore.view.size * x) / this.timelineCore.renderWidth
      const date = this.timelineCore.view.start + diffStart
      const noRecordRaw = this.cache.data.find(([startAt, endAt]) => {
        // endAt = finished ? endAt : Date.now()
        return startAt <= date && endAt >= date
      })
      if (noRecordRaw) {
        const noRecord = {
          startAt: noRecordRaw[0],
          endAt: noRecordRaw[1],
          finished: true // todo
        }
        this.activeBookmark.enabled = true
        this.activeBookmark.x = this.timelineCore.ctx.canvas.offsetLeft + x
        this.activeBookmark.y = this.timelineCore.ctx.canvas.offsetTop
        this.activeBookmark.bookmark = {
          period: [noRecord.startAt, noRecord.finished ? noRecord.endAt : -1],
          color: '#C7A82F',
          description:
            'Warning: Field of view tampering detected; Vision obstructed by an unknown object.',
          submitBy: 'ArcadianAI System Health',
          id: '-1',
          submitById: 'systems'
        }
        this.activeBookmark.type = BookmarkType.RecordPaused
      } else {
        this.activeBookmark.enabled = false
      }
    }
    return this.activeBookmark.enabled
  }
}

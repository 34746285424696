import type { UserWorkSpaceData } from './workspace-user.interfaces'
import { Service } from '../../service'
export class WorkspaceUserService extends Service {
  public workspaces() {
    return this.get<UserWorkSpaceData[]>(`/api/workspace-manager/user/workspace`)
  }
  public switchWorkspace(id: string) {
    return this.post(`/api/workspace-manager/user/workspace/${id}/switch`, null)
  }

  public joinWorkspace(id: string) {
    return this.post(`/api/workspace-manager/user/workspace/${id}`)
  }
  public leaveWorkspace(id: string) {
    return this.delete(`/api/workspace-manager/user/workspace/${id}`)
  }
}

import { IntervalTree, type Tape } from './interval-tree'
import { debounce } from 'lodash'
import { CoverageChecker } from './coverage-checker'

export class EventCache {
  public readonly coverage = new CoverageChecker()
  protected dataTree = new IntervalTree()

  constructor(protected readonly loadData: (start: number, end: number) => Promise<Tape[]>) {}

  get(start: number, end: number): Tape[] {
    const intervals = this.dataTree.queryRange(start, end)

    if (intervals.length === 0) return []

    intervals[0] = [Math.max(intervals[0][0], start), intervals[0][1]]
    intervals[intervals.length - 1] = [
      intervals[intervals.length - 1][0],
      Math.min(intervals[intervals.length - 1][1], end)
    ]

    return intervals
  }

  request = debounce(async (start: number, end: number) => {
    end = Math.min(end, Date.now())
    const uncoveredIntervals = this.coverage.check(start, end)
    for (const [uncoveredStart, uncoveredEnd] of uncoveredIntervals) {
      const newData = await this.loadData(uncoveredStart, uncoveredEnd)

      newData.forEach((interval) => this.dataTree.insert(interval))
      this.coverage.mark(newData)
    }
  }, 500)

  addManually(start: number, end: number) {
    const interval: Tape = [start, end]
    this.dataTree.insert(interval)
    this.coverage.mark([interval])
  }

  markManually(start: number, end: number) {
    this.coverage.mark([[start, end]])
  }
}

import { Buffer } from 'buffer'

export function decodePcmS32LE(pcmS32LEData: Buffer): Float32Array {
  if (!pcmS32LEData || !(pcmS32LEData instanceof Buffer)) {
    throw new Error('Invalid input: Expected a Buffer instance.')
  }

  // Each sample is 4 bytes, so the number of samples is the buffer length divided by 4
  const numSamples = pcmS32LEData.length / 4
  const float32Array = new Float32Array(numSamples)
  const normalizationFactor = 1 / 2147483648 // To normalize to the range [-1, 1]

  for (let i = 0; i < numSamples; i++) {
    // Read four bytes and form a 32-bit signed integer, considering little endian format
    const sample = pcmS32LEData.readInt32LE(i * 4)
    float32Array[i] = sample * normalizationFactor
  }
  return float32Array
}

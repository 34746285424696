import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import { MetadataService } from '@/lib/api/services/metadata-manager/metadata'
import { MetadataResourceService } from '@/lib/api/services/metadata-manager/resource'
export class MetadataManagerService extends Service {
  public metadata: MetadataService
  public resource: MetadataResourceService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.metadata = new MetadataService(adaptor)
    this.resource = new MetadataResourceService(adaptor)
  }
}

export default {
  onboarding: {
    steps: {
      workspace: 'Workspace',
      cameras: 'Cameras',
      maps: 'Maps',
      pricing: 'Pricing',
      people: 'People',
      liveStream: 'Live Stream!'
    },
    inviteSuccess: 'Invites Sent!',
    alert: {
      title: 'Connection Interrupted',
      content: {
        header:
          'Your camera seems to have zoned out for a moment.' +
          "\n Let's reel it back in! Here are 4 immediate checks to bring it back online:",
        checks: {
          a: "1. Ensure the camera's power source is secure.",
          b: '\n 2. Double-check its connection to the router.',
          c: '\n 3. Turn off potential network barriers, like firewalls or VPNs.',
          d: "\n 4. Enter the camera's IP address in a web browser to verify its operational status."
        },
        footer: 'For deeper insights, consult our {link}.'
      },
      actions: {
        guide: 'troubleshoot guide',
        again: 'Again',
        support: 'Connect with an expert'
      }
    },
    tutorial: {
      description1: `Today, we embark on a transformative journey, unlocking the true potential of your existing physical security setup. Our guide will demystify essential tech concepts for you: firstly, the {staticIp}, the unique, unchanging digital home for your camera amidst the vast expanse of the internet. Next, we'll delve into {portForward}, a pivotal method letting external devices access your camera. We’ll guide you through the heart of your network, the {routerPanel}—a digital cockpit accessed via a web browser, where you hold the reins of all settings. Along the way, we’ll introduce you to {gettingPing}, a simple yet crucial test ensuring your camera’s seamless connectivity on the network. `,
      description2: `With the tools in hand, which include a reliable internet connection, a static IP address, and of course, your trusty security camera, by the culmination of this tutorial, you'll not only have your cameras streaming live on our platform but also fortified them with the best security practices in the realm. Ready to elevate your security game? Let’s dive right in!`,
      staticIp: 'Static IP Address',
      portForward: 'Port Forwarding',
      routerPanel: `Router's Admin Panel`,
      gettingPing: `Getting a Ping`
    }
  }
}

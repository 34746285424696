import { useServices } from '@/lib/services'
import { Bridge } from '@/modules/bridge/Bridge'
import type { activeData } from '@/lib/api'
import { ActiveBridgeStateType } from '@/modules/bridge/types'
import { type Ref, ref } from 'vue'
import type { CameraTemp } from '@/modules/camera-new/interface'
import { type DiscoveredCamera } from '@/lib/api'
import { CameraCore } from '@/modules/camera-new/CameraCore'
import { useOnboardingStore } from '@/stores/onboarding/useOnboardingStore'
import { useCameraStore } from '@/modules/camera-new/store/cameraStore'
import { useCreateCameraTempData } from '@/modules/camera-new/helpers/useCameraHelper'
import { storeToRefs } from 'pinia'
import { useTabStore } from '@/stores/tab/useTabStore'
import { useImpexStore } from '@/stores/import-export/useImpexStore'

export class BridgeStore {
  public bridges: Map<string, Bridge> = new Map()
  public activeBridgeState = ref(ActiveBridgeStateType.loading)
  public bridgesIdList: Ref<string[]> = ref([])

  async activate(activeData: activeData) {
    const bridge = await Bridge.initByActivate(activeData)
    this.bridges.set(bridge.id, bridge)
    this.bridgesIdList.value.push(bridge.id)
    return bridge.id
  }

  async loadBridges() {
    const bridgeList = await useServices().bridge.list()
    bridgeList.forEach((data) => {
      if (!this.bridgesIdList.value.some((id) => id === data.id))
        this.bridgesIdList.value.push(data.id)

      const bridgeObject = this.bridges.get(data.id)
      if (!bridgeObject) {
        const bridge = Bridge.initByBridge(data)
        this.bridges.set(bridge.id, bridge)
      } else {
        bridgeObject.updateBridge(data)
      }
    })
  }

  async loadBridge(id: string) {
    const bridgeObject = this.bridges.get(id)
    if (!bridgeObject) {
      const bridge = await Bridge.initById(id)
      if (bridge) {
        this.bridges.set(bridge.id, bridge)
        if (!this.bridgesIdList.value.some((id) => id === bridge.id))
          this.bridgesIdList.value.push(bridge.id)
      }
      return bridge
    } else return bridgeObject
  }

  async remove(id) {
    const bridge = this.bridges.get(id)
    if (bridge) {
      const { data } = storeToRefs(useOnboardingStore())
      await bridge.hardReset()
      useCameraStore().reset(true)
      this.removeFromList(id)

      data.value.camera.cameraList = data.value.camera.cameraList.filter(
        (camera) => camera.cameraData.bridgeId !== id
      )
      data.value.camera.selections = data.value.camera.selections.filter(
        (camera) => camera.cameraData.bridgeId !== id
      )
      const onboardingTab = useTabStore().tabs.find((item) => item.route === 'onboarding')
      const cameraConnectTab = useTabStore().tabs.find((item) => item.route === 'cameraConnect')
      if (onboardingTab) useImpexStore().updateTabState('onboarding')
      if (cameraConnectTab) useImpexStore().updateTabState('cameraConnect')
    }
  }

  async forceRemove(id) {
    const bridge = this.bridges.get(id)
    if (bridge) {
      const { data } = storeToRefs(useOnboardingStore())
      await bridge.forceReset()
      useCameraStore().reset(true)
      this.removeFromList(id)

      data.value.camera.cameraList = data.value.camera.cameraList.filter(
        (camera) => camera.cameraData.bridgeId !== id
      )
      data.value.camera.selections = data.value.camera.selections.filter(
        (camera) => camera.cameraData.bridgeId !== id
      )
      const onboardingTab = useTabStore().tabs.find((item) => item.route === 'onboarding')
      const cameraConnectTab = useTabStore().tabs.find((item) => item.route === 'cameraConnect')
      if (onboardingTab) useImpexStore().updateTabState('onboarding')
      if (cameraConnectTab) useImpexStore().updateTabState('cameraConnect')
    }
  }

  removeFromList(id: string) {
    const bridge = this.findBridge(id)
    if (bridge) {
      bridge.statusManager.unregisterFroUpdater()
      this.bridges.delete(bridge.id)
      this.bridgesIdList.value = this.bridgesIdList.value.filter((item) => item !== id)
    }
  }

  findBridge(id: string) {
    return this.bridges.get(id)
  }

  changeActivateState(state: ActiveBridgeStateType) {
    this.activeBridgeState.value = state
  }

  reset() {
    this.bridges.clear()
    this.bridgesIdList.value = []
  }

  async createCameraTemp(cameras: DiscoveredCamera[], bridgeId) {
    const groups = await useServices().cameraGroup.list()
    const { data } = useOnboardingStore()
    let defaultCameraGroup = ''
    if (groups.length > 0) {
      defaultCameraGroup = groups[0].id
    }
    cameras.forEach((camera) => {
      const cameraTemp = data.camera.cameraList.find(
        (item) => item.cameraData.config.url === camera.ip && item.cameraData.bridgeId === bridgeId
      )
      if (!cameraTemp) {
        const newCameraTempData: CameraTemp = useCreateCameraTempData(
          CameraCore.generateUniqueId(),
          camera.model,
          defaultCameraGroup,
          camera.ip,
          80,
          0,
          554,
          [],
          '',
          '',
          1,
          false,
          camera.manufacturer,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          [],
          '',
          {
            longitude: 0,
            latitude: 0,
            fullAddress: '',
            markerId: ''
          },
          bridgeId
        )
        data.camera.cameraList.push(newCameraTempData)
      }
    })
  }
}

import { useServices } from '@/lib/services'
import { BridgeService, type BridgeStatusData } from '@/lib/api'
import { BridgeStatusManager } from '@/modules/bridge/libs/status-manager/BridgeStatusManager'

export class BridgeStatusBackgroundService {
  protected service: BridgeService = useServices().bridge
  protected interval: ReturnType<typeof setInterval>
  protected instances: Set<BridgeStatusManager> = new Set()
  protected readonly INTERVAL_PERIOD = 30_000

  constructor() {
    this.interval = setInterval(this.update.bind(this), this.INTERVAL_PERIOD)
  }

  register(instance: BridgeStatusManager) {
    this.instances.add(instance)
  }

  unregister(instance: BridgeStatusManager) {
    this.instances.delete(instance)
  }

  protected async update() {
    if (this.service.getHeader('Authorization') && this.instances.size > 0) {
      const statusMap: Map<string, BridgeStatusData> = new Map()
      const status = await this.service.allStatus()
      status.forEach((s) => s && statusMap.set(s.id, s))
      for (const instance of this.instances) {
        const statusData = statusMap.get(instance.id)
        if (statusData) instance.setStatus(statusData.status)
      }
    }
  }

  restart() {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(this.update.bind(this), this.INTERVAL_PERIOD)
  }
}

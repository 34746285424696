export default {
  cameraDetail: {
    cameraOverview: 'Camera Overview',
    permissions: 'Permissions',
    dataPrivacy: {
      title: 'Data Privacy 🛡️',
      content:
        "Your video footage is sacrosanct, and we are unwavering in our commitment to safeguarding your privacy. Safeguarded within the highly secure {data}, our system employs an ironclad zero-trust model, rendering breaches a virtual impossibility. Your data isn't merely resilient; it's fortified to be impervious, ensuring you the unshakable peace of mind you rightfully demand.",
      aws: 'Amazon AWS infrastructure',
      digitalPrivacy: 'Digital Privacy'
    }
  }
}

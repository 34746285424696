import type { TempInterface } from './'
import { Service } from '../../service'

export class NotificationTemplateService extends Service {
  public create(data: any) {
    return this.post<TempInterface, TempInterface>(`/api/notifier/template`, data)
  }

  public find(id: string) {
    return this.get<TempInterface>(`/api/notifier/template/${id}`)
  }

  public update(id: string, data: any) {
    return this.patch<TempInterface, TempInterface>(`/api/notifier/template/${id}`, data)
  }

  public remove(id: string) {
    return this.delete<void>(`/api/notifier/template/${id}`)
  }
}

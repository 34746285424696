export default {
  pricePeople: {
    pricing: 'Pricing',
    people: 'People',
    cameras: 'Cameras',
    peopleHeader:
      'The power of collective vigilance! Bring your trusted contacts into your workspace and enhance monitoring.',
    peopleSlideQuestion: ' If I invite new members to my space, what will they have access to?',
    peopleSlideAnswer:
      "New members will initially have a {viewOnly} access to the monitoring dashboard. However, our platform boasts a versatile {peoplePermission} module enabling you to craft custom roles, ensuring you have full control over each member's access level within your workspace. ",
    viewOnly: 'view-only',
    peoplePermission: 'People and Permissions',
    goToMonitoring: 'All Set! Monitoring Dashboard',
    usersSeats: 'Seats for Users',
    usersSeatsPrefix: 'Up to',
    billingCycle: 'Billing Cycle',
    yearlySavings: 'Yearly Savings',
    totalAfterSaving: 'Total After Savings',
    off30: '30% OFF',
    yearlySavingsTooltip: 'Unlock a 30% discount by transitioning to an annual billing cycle.',
    freeTrial: 'Free Trial',
    yourPlan: 'Your Plan',
    cloudStorage: 'Cloud Memory',
    subscription: 'Subscription',
    subtotal: 'Subtotal',
    taxes: 'Taxes',
    total: 'Total',
    dueOn: 'Due on',
    dueNow: 'Due Now',
    discountApply: 'Discount Applied!',
    discountCode: 'Discount Code',
    days365: '365 Days',
    days60: '60 Days',
    days30: '30 Days',
    days90: '90 Days',
    day: '1 Day',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Yearly',
    secureMonitoring: 'Ensure uninterrupted monitoring by adding a payment method.',
    freeRide: 'And hey, enjoy the free ride until',
    uninterruptedVigilance: 'To keep your vigilance uninterrupted, add a payment method.',
    freeToCancel: 'Your terms, always: feel free to {cancelAnyTime} without any extra charges.',
    cancelAnyTime: 'cancel anytime',
    missingPayment: 'Missing Payment Method',
    edit: 'Edit',
    send: 'Send',
    pricingCalculate: 'Pricing Calculator 🍕',
    pricingCalculateLink: 'Visit our website',
    pricingCalculateContent:
      'Say hi to our pricing calculator! Simply enter a payment method to have your accurate totals calculated – all smiles, no hassle. Changed your mind? Cancel anytime.'
  }
}

import type { EachResource, ResourceFind } from './metadata-resource.interfaces'
import { Service } from '../../service'
export class MetadataResourceService extends Service {
  public getResource(id: string) {
    return this.get<EachResource>(`/api/metadata-manager/resource/${id}`)
  }
  public find(data: ResourceFind) {
    return this.post<EachResource, ResourceFind>(`/api/metadata-manager/resource/find`, data)
  }
}

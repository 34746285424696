import type {
  ChallengeState,
  ResponseChallengeInit
} from '../../services/authentication-manager/challenges'
import { SessionMode } from '../../../api/services/authentication-manager/authentication/authentication.interfaces'
import { ChallengeService, SessionState } from '../../services/authentication-manager/challenges'
import { translateError } from '@/utils/helpers'

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Iteration_protocols
 */
export class Authenticator implements AsyncIterable<ChallengeState> {
  protected waitForDataResolver!: (value: string) => void
  protected currentChallengeState!: ChallengeState
  public challengeVerifyState: boolean = false
  protected accessToken!: string
  protected refreshToken!: string

  constructor(
    protected readonly client: ChallengeService,
    protected readonly phoneNumber: string,
    protected readonly sessionState: SessionMode
  ) {}

  feed(value: string) {
    this.waitForDataResolver(value)
  }

  getChallengeState() {
    return this.currentChallengeState
  }

  result() {
    return {
      accessToken: this.accessToken,
      refreshToken: this.refreshToken
    }
  }

  isFinished() {
    return [SessionState.success, SessionState.register].includes(
      this.currentChallengeState as SessionState
    )
  }

  protected waitForData() {
    return new Promise<string>((resolve) => (this.waitForDataResolver = resolve))
  }

  async next(): Promise<IteratorResult<ChallengeState, ChallengeState>> {
    if (this.isFinished()) {
      return {
        done: true,
        value: this.currentChallengeState
      }
    }
    let response: ResponseChallengeInit<ChallengeState>
    try {
      if (!this.currentChallengeState) {
        response = await this.client.initChallenge({
          mode: this.sessionState,
          username: this.phoneNumber
        })
        this.challengeVerifyState = false
      } else {
        const data = await this.waitForData()
        response = await this.client.verifyChallenge({
          token: this.accessToken,
          value: data
        })
        this.challengeVerifyState = true
      }
      this.accessToken = response.accessToken || this.accessToken
      this.refreshToken = response.refreshToken || this.refreshToken
      this.currentChallengeState = response.status
      return {
        done: false,
        value: this.currentChallengeState
      }
    } catch (e: any) {
      if (e.message) {
        throw new Error(e.message)
      } else if (e && typeof e === 'object') {
        throw new Error(Object.values(e)[0] as string)
      } else {
        throw new Error(e)
      }
    }
  }

  [Symbol.asyncIterator](): AsyncIterator<ChallengeState> {
    return this
  }
}

import { AsyncCache } from '@/player/lib/helpers/async-cache'
import { HeatmapHelpers } from '@/player/lib/heatmap/heatmap-helpers'

export class ImageBufferCache extends AsyncCache {
  constructor() {
    super(50)
  }

  protected dataCleanup(key: string, value: string): void {
    setTimeout(() => {
      HeatmapHelpers.cleanupImage(value)
    })
  }

  protected generatePreloadValue(key: string): string {
    return ''
  }

  protected isPreloadValue(value: string): boolean {
    return value.length === 0
  }

  protected loadData(key: string): Promise<string> {
    return HeatmapHelpers.getImage(key)
  }
}

const heatmapImageCacheGlobal = new ImageBufferCache()

export function getHeatmapImageCache() {
  return heatmapImageCacheGlobal
}

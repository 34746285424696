export default {
  profile: {
    fullName: 'Full Name',
    timeZone: 'Timezone',
    email: 'Email',
    newEmail: 'New Email',
    enterOtp: 'Enter the 6-digit code generated by your active multi-factor authentication method:',
    activityLogs: 'Activity Log',
    messages: {
      fullNameUpdated: 'Saved!',
      fullNameUpdatedError: 'Oops! Something went wrong.',
      timezoneUpdated: 'Saved!',
      timezoneUpdatedError: 'Oops! Something went wrong.',
      emailVerified: 'Verified!',
      errorVerification: 'Oops! Something went wrong.',
      phoneVerified: 'Verified!'
    },
    newNumber: 'New Number',
    profile: 'Profile'
  },
  dataDeletion: {
    title: 'Data Deletion Protocol',
    main: 'At {site}, we deeply value the trust you place in us with your video surveillance data. Our entire infrastructure is built on the bedrock of preserving the sanctity of your captured moments. Every frame, every pixel, remains confidential and guarded under the watchful eyes of our top-tier security measures on {amazon}. If you decide to request data deletion, please know that we will handle your information with the utmost care and respect, ensuring its prompt removal within 72 hours, while carefully considering any legal or business retention necessities. Contact us directly; we’re here because we care. ',
    privacyPolicy: 'Privacy Policy',
    site: 'ArcadianAI',
    amazon: 'Amazon AWS cloud',
    sendEmail: 'Email CEO',
    DeleteAccount: 'Delete Workspace',
    deleteAlertText:
      'Before we can permanently delete your workspace, please ensure you have disconnected all your connected devices. We appreciate your cooperation.'
  },
  userDelete: {
    weAreSad: 'We’re sad to see you go ☹️',
    whatWentWrong:
      'We really appreciate the time you spent with us, can you let us know what went wrong?',
    troubleSetting: 'I had trouble setting up my cameras.',
    notEmployed: 'I am no longer employed by the company.',
    goCompetition: 'I am choosing to go with your competition. ',
    addedValue: "I don't see any added value in video security monitoring.",
    affordPricing: 'I can’t afford the pricing.',
    deleteNotice: 'Data Deletion Notice',
    deleteDescribe:
      'Please be advised, your invaluable data is slated for irreversible deletion within \n' +
      'the next 72 hours. This action is permanent and cannot be undone. To avoid this imminent loss, your immediate intervention is crucial. We strongly urge you to take the necessary steps to preserve your data. Failure to do so will result in the irrevocable loss of your information.',
    permanentlyDelete: 'Permanently Delete',
    emailCEO: 'Email CEO',
    RequestHelp: 'Request Help',
    disconnectedDevice:
      'Before we can permanently delete your account, \n' +
      'please ensure you have disconnected all your connected devices. We appreciate your cooperation.',
    backMonitoring: 'Back to Monitoring',
    help: 'Help',
    deleteAccount: 'Permanent Account \n' + 'Deletion ☠️',
    writeFullName: 'To confirm, type in your full name below.',
    Marie: 'Type “Marie Roohi”',
    MariePlaceHolder: 'Marie Roohi',
    selectedDeleted:
      "By selecting 'Delete', I acknowledge that I am choosing to discontinue Arcadian Orbit Inc.'s services. I understand that this will result in the immediate loss of all my valuable recorded video footage and personal data.",
    typeHere: 'Please type here.'
  },
  theme: {
    theme: 'Theme',
    dark: 'Dark',
    light: 'Light'
  },
  NewPhoneVerify: {
    phoneNumber: 'Phone Number'
  },
  setImgProfile: {
    header:
      "Your photo will be adjusted to fit a 256 x 256 px size. After this, simply hit 'Save'.",
    edit: 'Edit',
    memberSince: 'Member since ',
    hi: 'hi',
    successUpload: 'Updated!',
    errorUpload: 'Oops! Something went wrong. '
  }
}

import type { AxiosError, AxiosHeaderValue, AxiosInstance, AxiosResponse } from 'axios'
import { AxiosParser } from '../errors'
import { ApiClient } from '@/lib/api'

export class Service {
  protected errorParser: AxiosParser
  constructor(protected readonly adaptor: AxiosInstance) {
    this.errorParser = new AxiosParser()
  }

  protected processResult<T>(response: AxiosResponse): T {
    return response.data
  }

  protected processError(error: AxiosError): never {
    throw this.errorParser.parse(error)
  }

  setHeader(key: string, value: string): void {
    this.adaptor.defaults.headers.common[key] = value
  }

  deleteHeader(key: string): void {
    delete this.adaptor.defaults.headers.common[key]
  }

  getHeader(key: string): AxiosHeaderValue | undefined {
    return this.adaptor.defaults.headers.common[key]
  }

  get<HttpResponseType = unknown>(url: string): Promise<HttpResponseType> {
    return this.adaptor
      .get(url)
      .then((r) => this.processResult<HttpResponseType>(r))
      .catch<never>(this.processError.bind(this))
      .finally(() => {})
  }

  post<HttpResponseType = unknown, HttpRequestType = unknown>(
    url: string,
    data?: HttpRequestType
  ): Promise<HttpResponseType> {
    ApiClient.runningCount.value += 1
    return this.adaptor
      .post(url, data)
      .then((r) => this.processResult<HttpResponseType>(r))
      .catch<never>(this.processError.bind(this))
      .finally(() => {
        ApiClient.runningCount.value -= 1
      })
  }

  patch<HttpResponseType = unknown, HttpRequestType = unknown>(
    url: string,
    data?: HttpRequestType
  ): Promise<HttpResponseType> {
    ApiClient.runningCount.value += 1
    return this.adaptor
      .patch(url, data)
      .then((r) => this.processResult<HttpResponseType>(r))
      .catch<never>(this.processError.bind(this))
      .finally(() => {
        ApiClient.runningCount.value -= 1
      })
  }

  put<HttpResponseType = unknown, HttpRequestType = unknown>(
    url: string,
    data?: HttpRequestType
  ): Promise<HttpResponseType> {
    ApiClient.runningCount.value += 1
    return this.adaptor
      .put(url, data)
      .then((r) => this.processResult<HttpResponseType>(r))
      .catch<never>(this.processError.bind(this))
      .finally(() => {
        ApiClient.runningCount.value -= 1
      })
  }

  delete<HttpResponseType = unknown>(url: string): Promise<HttpResponseType> {
    ApiClient.runningCount.value += 1
    return this.adaptor
      .delete(url)
      .then((r) => this.processResult<HttpResponseType>(r))
      .catch<never>(this.processError.bind(this))
      .finally(() => {
        ApiClient.runningCount.value -= 1
      })
  }
  deleteData<HttpResponseType = unknown, HttpRequestType = unknown>(
    url: string,
    data?: HttpRequestType
  ): Promise<HttpResponseType> {
    ApiClient.runningCount.value += 1
    return this.adaptor
      .request({ url, method: 'delete', data })
      .then((r) => this.processResult<HttpResponseType>(r))
      .catch<never>(this.processError.bind(this))
      .finally(() => {
        ApiClient.runningCount.value -= 1
      })
  }
}

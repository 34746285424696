import { LocationManager } from '@/modules/location/lib/LocationManager'
import { CameraService, ResourceTypes } from '@/lib/api'
import { useServices } from '@/lib/services'

export class CameraLocation extends LocationManager {
  protected service: CameraService = useServices().camera
  constructor(public readonly id: string) {
    super(id, ResourceTypes.Camera)
  }

  public async loadData() {
    await this.load()
    await this.loadCameraMap()
  }

  public async loadCameraMap() {
    const data = await this.service.findCameraMarkers(this.id)
    this.setData(data.marker)
  }
}

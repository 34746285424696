import { PlayerCore } from '@/player/lib/player/player-core'
import { useServices } from '@/lib/services'
import { WindowsDate } from '@/player/lib/helpers/date-time'
import type {
  Camera,
  CreateBookmarkData,
  CreateExportDTO,
  CreateSnapshotRequest,
  updateBookmarkData
} from '@/lib/api'
import { AnalyticEventTypes, HeatMapPeriodType, RecordMode } from '@/lib/api'
import { usePermissionsStore } from '@/modules/Permissions'
import {
  type Analytic,
  CameraUpdateState,
  type CreateAnalyticDto
} from '@/lib/api/services/ai/analytics/analytic.interface'

const cameraService = useServices().camera
const bookmarkService = useServices().bookmark
const exportService = useServices().exportManager.export
const snapshotService = useServices().exportManager.snapshot
const heatmapService = useServices().ai.heatmap
const eventService = useServices().ai.event
const analyticService = useServices().ai.analytic

export class PlayerApis {
  constructor(protected readonly core: PlayerCore) {}

  async information(lazyData?: Camera) {
    if (lazyData) {
      Object.assign(this.core.information, lazyData)
    } else {
      const data = await cameraService.find(this.core.cameraId)
      Object.assign(this.core.information, data)
    }
    return this.core.information
  }

  static async information(cameraId: number | string) {
    return cameraService.find(cameraId.toString())
  }

  async detailedStatus() {
    const { status } = await cameraService.statusCameraAdvance(this.core.cameraId)
    this.core.detailedStatus.value = status
    return this.core.detailedStatus
  }

  connectionInformation() {
    return cameraService.connectionInfo(this.core.cameraId)
  }

  async supportPTZ() {
    this.core.supportPTZ.value = await cameraService.ptzSupport(this.core.cameraId)
    return this.core.supportPTZ.value
  }

  async archiveMap() {
    const { archiveMap } = await cameraService.archiveMap(this.core.cameraId)
    this.core.archiveMap.value = archiveMap.map((am) => [
      WindowsDate.toJSDate(am[1]).getTime(),
      WindowsDate.toJSDate(am[0]).getTime(),
      am[3]
    ])
    return this.core.archiveMap.value
  }

  async crossLine() {
    try {
      return await cameraService.getCrossLineDetail(this.core.cameraId)
    } catch (e) {
      return undefined
    }
  }

  async downtimeMap() {
    const maps = await cameraService.downtimeMap(this.core.cameraId)
    this.core.downMap.value = maps.map((dm) => ({
      id: dm.id,
      startAt: new Date(dm.startedAt).getTime(),
      endAt: new Date(dm.endedAt).getTime(),
      reason: dm.reason,
      finished: dm.finished,
      recordStopper: dm.recordStopper || undefined
    }))
    return this.core.downMap.value
  }

  async bookmarkList() {
    const bookmarks = await bookmarkService.findByDate(this.core.cameraId, {
      startTime: new Date(Date.now() - 365 * 24 * 3600 * 1000).toISOString(),
      endTime: new Date(Date.now() + 365 * 24 * 3600 * 1000).toISOString()
    })
    this.core.bookmarks.value = bookmarks.map((bookmark) => ({
      color: bookmark.color || '#FFFFFF',
      description: bookmark.description || bookmark.title,
      id: bookmark.id,
      submitById: bookmark.ownerId,
      submitBy: 'Loading...',
      period: [new Date(bookmark.startTime).getTime(), new Date(bookmark.endTime).getTime()]
    }))
    return this.core.bookmarks.value
  }

  async getUser(id: string) {
    const permissionsStore = usePermissionsStore()
    return permissionsStore.users.find((u) => u.remoteId === id)
  }

  async bookmarkCreate(dto: CreateBookmarkData) {
    return bookmarkService.create(this.core.cameraId, dto)
  }

  async bookmarkUpdate(bookmarkId: string, dto: updateBookmarkData) {
    return bookmarkService.update(bookmarkId.toString(), dto)
  }

  async bookmarkDelete(id: string | number) {
    return bookmarkService.remove(id.toString())
  }

  streamToken() {
    return cameraService.requestStream(this.core.cameraId)
  }

  createExport(request: Omit<CreateExportDTO, 'cameraId'>) {
    return exportService.create({
      cameraId: this.core.cameraId,
      ...request
    })
  }

  createSnapshot(request: Omit<CreateSnapshotRequest, 'cameraId'>) {
    return snapshotService.create({
      cameraId: this.core.cameraId,
      ...request
    })
  }

  async setRecordMode(mode: RecordMode) {
    await cameraService.updateRecordMode(this.core.cameraId, {
      recordMode: mode
    })
    return this.information()
  }

  getHeatmaps(type: HeatMapPeriodType) {
    const now = Date.now()
    return heatmapService.find({
      cameraId: this.core.cameraId,
      startTime: new Date(now - 8640000000).toISOString(), // -100 day
      endTime: new Date(now + 864000000).toISOString(), // +10 day
      period: type
    })
  }

  setMaskData(data: Array<Array<number>>) {
    return cameraService.setMask(this.core.cameraId, data)
  }

  removeMask() {
    return cameraService.removeMask(this.core.cameraId)
  }

  async getEvents(
    start: Date,
    end: Date,
    type: AnalyticEventTypes,
    index = '0'
  ): Promise<Array<Array<number>>> {
    return eventService
      .find({
        cameraId: this.core.cameraId,
        endTime: end.toISOString(),
        startTime: start.toISOString(),
        type,
        index: index.toString()
      })
      .then((response) => {
        if (response.length > 0) {
          const startTime = start.getTime()
          if (response[0][1] === 0) {
            response.unshift([0, 1])
          }
          if (response[response.length - 1][1] === 1) {
            response.push([Math.min(end.getTime(), Date.now()) - start.getTime(), 0])
          }
          return response.map((entity) => {
            entity[0] += startTime
            return entity
          })
        } else return []
      })
  }

  getAnalytics() {
    return analyticService.findAll(this.core.cameraId)
  }

  createAnalytics(option: CreateAnalyticDto): Promise<{
    status: CameraUpdateState
    item: Analytic
  }> {
    return analyticService.create(this.core.cameraId, option)
  }

  deleteAnalytics(id: string) {
    return analyticService.remove(this.core.cameraId, id)
  }

  updateAnalytics(
    id: string,
    option: CreateAnalyticDto
  ): Promise<{
    status: CameraUpdateState
    item: Analytic
  }> {
    return analyticService.update(this.core.cameraId, id, option)
  }

  static heatmapDownloadImage(id: string | number) {
    return heatmapService.download(id.toString())
  }

  static getCameraThumbnail(id: string, streamIndex = 0) {
    return cameraService.getCameraThumbnail(id, streamIndex)
  }

  static getCameraThumbnailArchive(id: string, date: Date) {
    return cameraService.getCameraThumbnailArchive(id, WindowsDate.toWindowsDate(date.getTime()))
  }
}

import { Service } from '../../service'
import type { StripeWorkspace } from '@/lib/api'

export class StripeWorkspaceService extends Service {
  public findCustomerWorkspace(id: string): Promise<StripeWorkspace> {
    return this.get(`/api/payment/workspace/customer/${id}`)
  }

  public findStripeWorkspace(id: string): Promise<StripeWorkspace> {
    return this.get(`/api/payment/workspace/${id}`)
  }

  public findStripeWorkspaceDetailed(id: string): Promise<StripeWorkspace> {
    return this.get(`/api/payment/workspace/${id}/detailed`)
  }

  public createWorkspacePaymentMethods(id: string, data: { token: string }): Promise<any> {
    return this.post(`/api/payment/workspace/${id}/payment-methods`, data)
  }

  public updateStripeWorkspace(id: string, data: any): Promise<StripeWorkspace> {
    return this.patch(`/api/payment/workspace/${id}`, data)
  }

  public getWorkspacePaymentMethods(id: string): Promise<any> {
    return this.get(`/api/payment/workspace/${id}/payment-methods`)
  }

  public removeWorkspacePaymentMethods(id: string, sourceId: string): Promise<any> {
    return this.delete(`/api/payment/workspace/${id}/payment-methods/${sourceId}`)
  }
}

import { EventTypes } from '@/player/types'
import { EventCacheTampering } from '@/player/lib/events/event-cache-tampering'
import { EventCache } from '@/player/lib/events/event-cache'

export class EventLiveFollower {
  protected startAt?: number
  protected lastAt?: number
  protected isActive = false
  constructor(
    public targetEvent: EventTypes,
    public cacheTarget: EventCacheTampering | EventCache
  ) {}

  restart(targetEvent: EventTypes) {
    this.startAt = undefined
    this.lastAt = undefined
    this.isActive = false
    this.targetEvent = targetEvent
  }

  protected start(at: number) {
    this.startAt = at
    this.lastAt = at
    this.isActive = true
    this.cacheTarget.data.push([this.startAt, this.startAt + 1])
  }

  protected update(at: number) {
    this.lastAt = at
    this.cacheTarget.data[this.cacheTarget.data.length - 1][1] = this.lastAt
  }

  protected end(at: number) {
    this.lastAt = at
    this.isActive = false
    this.cacheTarget.data[this.cacheTarget.data.length - 1][1] = this.lastAt
  }

  capture(events: Set<EventTypes>, date: number) {
    if (events.has(this.targetEvent)) {
      if (this.isActive) {
        this.update(date)
      } else {
        this.start(date)
      }
    } else {
      if (this.isActive) {
        this.end(date)
      }
    }
  }
}

import type {
  DrawConfigCenterLine,
  DrawConfigColor,
  DrawConfigLineOpen,
  DrawConfigShadow,
  DrawConfigText
} from '@/player/interfaces'

export class CanvasDrawBasedHelper {
  constructor(public readonly ctx: CanvasRenderingContext2D) {}

  setShadow(config?: DrawConfigShadow) {
    if (config) {
      this.ctx.shadowColor = config.color
      this.ctx.shadowBlur = config.blur
      this.ctx.shadowOffsetY = config.offsetY
      this.ctx.shadowOffsetX = config.offsetX
    } else {
      this.ctx.shadowColor = 'rgba(0,0,0,0)'
      this.ctx.shadowBlur = 0
      this.ctx.shadowOffsetY = 0
      this.ctx.shadowOffsetX = 0
    }
  }

  setTextStyle(config: DrawConfigText) {
    this.ctx.textAlign = 'start'
    this.ctx.textBaseline = 'top'
    this.ctx.direction = 'ltr'
    this.ctx.font = `${config.weight} ${config.size}px ${config.family}`
  }

  setColor(config: DrawConfigColor) {
    this.ctx.fillStyle = config.fill || '#000000'
    this.ctx.strokeStyle = config.stroke || '#000000'
  }

  drawLine(x: number, y: number, config: DrawConfigLineOpen): Path2D {
    this.setShadow(config.shadow)
    this.setColor(config.color)
    const path = new Path2D()
    path.roundRect(x, y - config.height, config.width, config.height, config.borderRadius)
    this.ctx.fill(path)
    return path
  }

  drawCenterLine(x: number, y: number, config: DrawConfigCenterLine) {
    this.setShadow(config.line.shadow)
    let line = new Path2D()
    line = this.drawLine(x - config.line.width / 2, y, config.line)
    this.ctx.fill(line)
    if (config.circle.radius > 0) {
      this.setShadow(config.circle.shadow)
      const circle = new Path2D()
      circle.arc(x, y, config.circle.radius, 0, 2 * Math.PI)
      this.ctx.fill(circle)
      return { circle, line }
    }
    return { circle: undefined, line }
  }

  putImage(img: ImageData, x: number, y: number) {
    this.ctx.putImageData(img, x, y)
  }

  drawText(x: number, y: number, value: string, config: DrawConfigText) {
    this.setTextStyle(config)
    this.setColor({
      fill: config.color
    })
    const metrics = this.ctx.measureText(value)
    const topPadding =
      config.lineHeight - metrics.fontBoundingBoxAscent - metrics.fontBoundingBoxDescent
    this.ctx.fillText(value, x, y - config.lineHeight + topPadding)
  }

  clear() {
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
  }
}

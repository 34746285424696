import type { PlayerCore } from '@/player/lib/player/player-core'
import type { ObjectPositionDto, TrackEntity } from '@/lib/api/services/ai/tracker'
import { ObjectType } from '@/lib/api/services/ai/tracker'
import { linearInterpolation } from '@/player/lib/helpers/math-helper'
import type { AnnotationObject, AnnotationRectangleObject } from '@/player/interfaces'
import { AnnotationObjectType } from '@/player/types'
import { watch } from 'vue'

export const TRACK_IMAGE_HEIGHT = 640
export const TRACK_IMAGE_WIDTH = 640

export const COLOR_MAP = {
  [ObjectType.cat]: '#FF7A67',
  [ObjectType.dog]: '#FF7A67',
  [ObjectType.person]: '#BA1A1A'
}

export const END_OF_TRACK = Symbol()

export class TrackManager {
  protected tracks: Array<TrackEntity> = []
  protected endAt = 0
  protected currentStartPosition: ObjectPositionDto | undefined = undefined
  protected currentEndPosition: ObjectPositionDto | undefined = undefined
  protected currentPosition: ObjectPositionDto | undefined = undefined

  constructor(protected readonly core: PlayerCore) {
    watch(() => this.core.playback.lastFrameDate, this.onDateChange.bind(this))
  }

  push(tracks: TrackEntity[]) {
    tracks.forEach((t) => {
      this.tracks.push(t)
    })
    this.tracks.sort((t1, t2) => (t1.startTime - t2.endTime > 0 ? 1 : -1))
    for (const track of this.tracks) {
      if (this.endAt < track.endTime) {
        this.endAt = track.endTime
      }
    }
  }

  reset() {
    this.tracks = []
    this.endAt = 0
    if (this.core.annotator) {
      this.core.annotator.clear()
    }
  }

  calculateLinearInterpolation(
    dt: number,
    startPosition: ObjectPositionDto,
    endPosition: ObjectPositionDto
  ): ObjectPositionDto {
    if (dt !== this.currentPosition?.dt) {
      const x1 = linearInterpolation(
        startPosition.x1,
        startPosition.dt,
        endPosition.x1,
        endPosition.dt,
        dt
      )
      const x2 = linearInterpolation(
        startPosition.x2,
        startPosition.dt,
        endPosition.x2,
        endPosition.dt,
        dt
      )
      const y1 = linearInterpolation(
        startPosition.y1,
        startPosition.dt,
        endPosition.y1,
        endPosition.dt,
        dt
      )
      const y2 = linearInterpolation(
        startPosition.y2,
        startPosition.dt,
        endPosition.y2,
        endPosition.dt,
        dt
      )
      this.currentPosition = {
        dt,
        x1,
        y1,
        x2,
        y2
      }
    }
    return this.currentPosition
  }

  findObjectPosition(
    dt: number,
    positions: ObjectPositionDto[]
  ): { startPosition: ObjectPositionDto; endPosition: ObjectPositionDto } {
    if (!(dt >= (this.currentStartPosition?.dt || 0) && dt <= (this.currentEndPosition?.dt || 0))) {
      const { startPosition, endPosition } = TrackManager.findObjectPositionStatic(dt, positions)
      this.currentStartPosition = startPosition
      this.currentEndPosition = endPosition
    }
    if (this.currentStartPosition && this.currentEndPosition) {
      return { startPosition: this.currentStartPosition, endPosition: this.currentEndPosition }
    } else throw new Error()
  }

  static findObjectPositionStatic(dt: number, positions: ObjectPositionDto[]) {
    let startPosition = positions.findLast((position) => position.dt < dt)
    const endPosition = positions.find((position) => position.dt > dt)
    if (!endPosition) {
      throw new Error('no position found!')
    }
    if (!startPosition) {
      startPosition = endPosition
    }
    return { startPosition, endPosition }
  }

  getTracks(date: number): Array<AnnotationObject> | typeof END_OF_TRACK {
    if (this.tracks.length === 0) return []
    if (date > this.endAt) return END_OF_TRACK
    const activeTracks = this.tracks.filter(
      (track) => track.startTime <= date && track.endTime >= date
    )
    const result: Array<AnnotationRectangleObject> = []
    for (const track of activeTracks) {
      const dt = date - track.startTime
      const { startPosition, endPosition } = this.findObjectPosition(dt, track.objectPosition)
      const { x1, x2, y1, y2 } = this.calculateLinearInterpolation(dt, startPosition, endPosition)
      // console.log(startPosition.dt, endPosition.dt, { x1, x2, y1, y2, dt })
      const color = COLOR_MAP[track.objectType]
      result.push({
        type: AnnotationObjectType.rectangle,
        annotation: {
          x1: x1 / TRACK_IMAGE_WIDTH,
          x2: x2 / TRACK_IMAGE_WIDTH,
          y1: y1 / TRACK_IMAGE_HEIGHT,
          y2: y2 / TRACK_IMAGE_HEIGHT,
          borderRadius: 0,
          color: { stroke: color || '#01D55F' },
          shadow: undefined,
          lineWidth: 3
        }
      })
    }
    return result
  }

  onDateChange() {
    const frames = this.getTracks(this.core.playback.lastFrameDate)
    if (frames === END_OF_TRACK) {
      this.core.annotator.clear()
      if (!this.core.playback.isLive) {
        this.core.capture.speed(0)
      }
    } else {
      if (this.core.annotator) {
        this.core.annotator.clear()
        this.core.annotator.draw(frames)
      }
    }
  }
}

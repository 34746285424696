import { Service } from '../service'
import type { AxiosInstance } from 'axios'

import { TagResourceService } from '../../../../../src/lib/api/services/tag-manager/resource'
import { TagService } from '../../../../../src/lib/api/services/tag-manager/tag'
import { TagRelationService } from '../../../../../src/lib/api/services/tag-manager/tag-relation'

export class TagManagerService extends Service {
  public resource: TagResourceService
  public tag: TagService
  public tagRelation: TagRelationService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.resource = new TagResourceService(adaptor)
    this.tag = new TagService(adaptor)
    this.tagRelation = new TagRelationService(adaptor)
  }
}

export function setItems(state, entities: Array<any> | any) {
  if (Array.isArray(entities)) {
    const mergedArr = state?.concat(entities)
    return [...new Map(mergedArr!.map((item) => [item.id, item])).values()]
  } else {
    if (!state!.includes(entities)) state!.push(entities)
  }
}

export function setItem(state, entity: any) {
  if (entity.id !== -1) {
    state = entity
  }
}

export function addItem(state, entity: any) {
  state?.unshift(entity)
}

export function updatedItem(state, entity: any) {
  const index = state?.findIndex((e) => e.id === entity.id)
  if (index !== -1) {
    state![index!] = entity
  }
}

export function removedItem(state, entity: any) {
  const index = state?.findIndex((e) => e.id === entity.id)
  if (index !== -1) {
    state?.splice(index!, 1)
  }
}

import type { UserData } from '../authentication/authentication.interfaces'
import type {
  PasswordStrong,
  UpdateEmailData,
  UpdatePasswordData,
  UserUpdateData
} from './user.interface'
import { Service } from '../../service'

// import type {AvatarData} from "@/lib/api";

export class UserService extends Service {
  // ***** USER APIS  *****

  public getUser(id: string) {
    return this.get<UserData>(`/api/u-crm/user/${id}`)
  }

  public updateUser(id: string, data: UserUpdateData) {
    return this.patch<UserData, UserUpdateData>(`/api/u-crm/user/${id}`, data)
  }

  public updateUserPassword(id: string, data: UpdatePasswordData) {
    return this.patch<UserData, UpdatePasswordData>(`/api/u-crm/user/${id}/password`, data)
  }
  public passwordStrengthCheckDto(password: string) {
    return this.post<PasswordStrong>(`/api/u-crm/auth/password-strength`, { password: password })
  }

  public updateUserEmail(data: UpdateEmailData) {
    return this.post(`/api/u-crm/challenge/update-email`, data)
  }

  // *************Avatar******
  public avatarUpdate(data: FormData) {
    return this.patch(`/api/u-crm/avatar`, data)
  }

  public avatarRemove() {
    return this.delete(`/api/u-crm/avatar`)
  }

  public avatarGet(userId: string) {
    return this.adaptor.get(`/api/u-crm/avatar/${userId}`, {
      responseType: 'blob'
    })
  }

  public currentAvatar() {
    return this.adaptor.get(`/api/u-crm/avatar/current`, {
      responseType: 'blob'
    })
  }
}

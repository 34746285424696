import type {
  Box,
  CreateLayoutData,
  Layout,
  UpdateBoxData,
  UpdateLayoutData
} from './layout.interfaces'
import { Service } from '../../service'
export class LayoutService extends Service {
  // ***** LAYOUT APIS  *****
  public create(data: CreateLayoutData) {
    return this.post<Layout, CreateLayoutData>(`/api/layout-manager/layout`, data)
  }
  public list() {
    return this.get<Layout[]>(`/api/layout-manager/layout`)
  }
  public findPrivet() {
    return this.get<Layout | Layout[]>(`/api/layout-manager/layout/`)
  }
  public update(id: string, data: UpdateLayoutData) {
    return this.patch<Layout, UpdateLayoutData>(`/api/layout-manager/layout/${id}`, data)
  }
  public remove(id: string) {
    return this.delete<void>(`/api/layout-manager/layout/${id}`)
  }

  public findPublic() {
    return this.get<Layout | Layout[]>(`/api/layout-manager/layout/public`)
  }

  public updateBox(layoutId: string, index: string, data: UpdateBoxData) {
    return this.patch<Box>(`/api/layout-manager/layout/${layoutId}/box/${index}`, data)
  }
}

import { useServices } from '@/lib/services'
import { type Camera as CameraObject, type UpdateCameraData, CameraService } from '@/lib/api'
import { SyncAbleObject } from '@/modules/camera-new/SyncAbleObject'
import type { CameraBaseObject } from '@/modules/camera-new/interface'

export class CameraBaseInfo extends SyncAbleObject<CameraBaseObject> {
  protected service: CameraService = useServices().camera

  constructor(public readonly id: string, cameraObject: CameraObject) {
    super(CameraBaseInfo.parseCameraObject(cameraObject))
  }

  protected static parseCameraObject(camera: CameraObject): CameraBaseObject {
    return {
      id: camera.id,
      workspaceId: camera.workspaceId,
      enable: camera.enable,
      configured: camera.configured,
      name: camera.name,
      description: camera.description ? camera.description : '',
      timezone: camera.timezone ? camera.timezone : '',
      maskData: camera.maskData || undefined,
      updatedAt: new Date(camera.updatedAt),
      createdAt: new Date(camera.createdAt)
    }
  }

  public async update(data: UpdateCameraData) {
    await this.updateCamera(data)
    super.sync()
    this.updateDependencies()
  }

  protected async updateCamera(data: UpdateCameraData): Promise<void> {
    const cameraUpdateData = await this.service.update(this.id, data)
    this.initialCameraData(cameraUpdateData)
  }

  public initialCameraData(cameraObject: CameraObject) {
    Object.assign(this.data, CameraBaseInfo.parseCameraObject(cameraObject))
  }

  protected updateDependencies() {
    //todo
  }

  public async reload() {
    const data = await useServices().camera.find(this.id)
    this.initialCameraData(data)
  }
}

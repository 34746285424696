import type {
  ChallengeRetryData,
  ChallengeInitData,
  ResponseChallengeInit,
  ChallengeType,
  ChallengeVerifyData,
  SessionState,
  ChallengeRegisterResponse,
  ChallengeOrderData,
  ChallengeVerifyUpdatePhoneData,
  UpdatePhoneData,
  ResponseUpdatePhone,
  ChallengeOrderEntity
} from './challenge.interface'
import type { RegisterData, UserData } from '../authentication/authentication.interfaces'
import { Service } from '../../../services/service'

export class ChallengeService extends Service {
  public initChallenge(data: ChallengeInitData) {
    return this.post<ResponseChallengeInit<ChallengeType>, ChallengeInitData>(
      '/api/u-crm/challenge/init',
      data
    )
  }

  public async verifyChallenge(
    data: ChallengeVerifyData
  ): Promise<ResponseChallengeInit<SessionState>> {
    return this.post<ResponseChallengeInit<SessionState>, ChallengeVerifyData>(
      '/api/u-crm/challenge/verify',
      data
    )
  }

  public registerChallenge(data: RegisterData, withEasyLogin = false) {
    return this.post<ChallengeRegisterResponse, RegisterData>(
      `/api/u-crm/challenge/register?version=${withEasyLogin ? 'v2' : 'v1'}`,
      data
    )
  }

  public retryChallenge(data: ChallengeRetryData) {
    return this.post<boolean, ChallengeRetryData>('/api/u-crm/challenge/retry', data)
  }

  public changeOrderChallenge(data: ChallengeOrderData) {
    return this.post<ChallengeOrderEntity[], ChallengeOrderData>(
      '/api/u-crm/challenge/change-order',
      data
    )
  }

  public findAll() {
    return this.get<ChallengeOrderEntity[]>('/api/u-crm/challenge')
  }

  public updatePhone(data: UpdatePhoneData) {
    return this.post<ResponseUpdatePhone, UpdatePhoneData>(
      '/api/u-crm/challenge/update-phone',
      data
    )
  }

  public verifyUpdatePhone(data: ChallengeVerifyUpdatePhoneData) {
    return this.post<boolean | UserData, ChallengeVerifyUpdatePhoneData>(
      '/api/u-crm/challenge/verify-phone',
      data
    )
  }

  public activateEasyLogin() {
    return this.patch<ChallengeOrderEntity[]>('/api/u-crm/challenge/easy-login/activate')
  }

  public inactivateEasyLogin() {
    return this.patch<ChallengeOrderEntity[]>('/api/u-crm/challenge/easy-login/inactivate')
  }
}

import { Buffer } from 'buffer'

export function decodePcmU8(pcmU8Data: Buffer): Float32Array {
  if (!pcmU8Data || !(pcmU8Data instanceof Buffer)) {
    throw new Error('Invalid input: Expected a Buffer instance.')
  }

  const float32Array = new Float32Array(pcmU8Data.length)
  const normalizationFactor = 1 / 128 // To scale and center the data

  for (let i = 0; i < pcmU8Data.length; i++) {
    // Convert each sample, centering around 0 by subtracting 128 and normalize
    float32Array[i] = (pcmU8Data[i] - 128) * normalizationFactor
  }
  return float32Array
}

import { PeriodType } from '@/player/types'

const BaseDate = new Date('Sun, 30 Dec 1899 00:00:00 GMT').getTime()

export class WindowsDate {
  static toJSDate(windowsDate: number): Date {
    const totalMilliseconds = windowsDate * 86400000
    const dateSinceBase = new Date(BaseDate + totalMilliseconds)
    return new Date(dateSinceBase.toISOString())
  }

  static toWindowsDate(jsDate: number): number {
    const tmp = jsDate - BaseDate
    return tmp / 86400000
  }

  static addDate(current: number, size: PeriodType): number {
    if (size <= PeriodType.daily) {
      return current + size
    } else {
      const now = new Date(current)
      switch (size) {
        case PeriodType.monthly:
          now.setMonth(now.getMonth() + 1)
          return now.getTime()
        case PeriodType.threeMonth:
          now.setMonth(now.getMonth() + 3)
          return now.getTime()
      }
    }
    return 0
  }
}

export class TimeZoneHelper {
  static getBrowserLocalTimezone(): string {
    return TimeZoneHelper.getBrowserLocalOptions().timeZone
  }

  static getBrowserLocalOptions() {
    return Intl.DateTimeFormat().resolvedOptions()
  }

  static getLocalTime(
    date = new Date(),
    timezone: string = TimeZoneHelper.getBrowserLocalTimezone(),
    locales: string | string[] = TimeZoneHelper.getBrowserLocalOptions().locale
  ) {
    return TimeZoneHelper.getTimeString(date, timezone, locales)
  }

  static getDiffTimezone(
    now = new Date(),
    targetTimezone: string,
    currentTimezone = TimeZoneHelper.getBrowserLocalTimezone()
  ) {
    const nowOffset = TimeZoneHelper.getTimeOffset(now, currentTimezone)
    const cameraOffset = TimeZoneHelper.getTimeOffset(now, targetTimezone)
    return cameraOffset - nowOffset
  }

  protected static getTimeString(date: Date, timeZone: string, locales: string | string[]) {
    return (
      date.toLocaleDateString(locales, {
        timeZone: timeZone,
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }) +
      ' ' +
      date.toLocaleTimeString(locales, {
        timeZone: timeZone,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    )
  }

  protected static getTimeOffset(now: Date, timeZone: string) {
    const nowDay = Number(
      now.toLocaleDateString('en-US', {
        timeZone,
        day: '2-digit'
      })
    )
    let nowHours = Number(
      now.toLocaleTimeString('en-US', {
        timeZone,
        hour: '2-digit',
        hour12: false
      })
    )
    nowHours = nowHours === 24 ? 0 : nowHours
    const nowMinute = Number(
      now.toLocaleTimeString('en-US', {
        timeZone,
        minute: '2-digit',
        hour12: false
      })
    )
    return nowDay * 24 * 60 + nowHours * 60 + nowMinute
  }
}

import { Service } from '@/lib/api'
import type {
  HeatMapEntity,
  HeatmapFindRequestDTO,
  HeatmapFindResponseDTO,
  HeatmapGetNearestRequestDTO,
  HeatmapGetNearestResponseDTO
} from '@/lib/api/services/ai/heatmap/heatmap.interface'

export class HeatmapService extends Service {
  public nearest(request: HeatmapGetNearestRequestDTO) {
    return this.post<HeatmapGetNearestResponseDTO>('/api/event-storage-ai/heatmap/nearest', request)
  }

  public find(request: HeatmapFindRequestDTO) {
    return this.post<HeatmapFindResponseDTO>('/api/event-storage-ai/heatmap', request)
  }

  public findOne(id: string) {
    return this.get<HeatMapEntity>(`/api/event-storage-ai/heatmap/${id}`)
  }

  public download(id: string) {
    return this.adaptor
      .get(`/api/event-storage-ai/heatmap/${id}/download`, {
        responseType: 'blob'
      })
      .then((r) => this.processResult<Blob>(r))
      .catch<never>(this.processError.bind(this))
  }
}

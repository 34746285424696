export default {
  activityLogs: {
    entity: {
      b: 'Bookmark',
      br: 'Bridge',
      brc: 'Bridge Camera',
      c: 'Camera',
      cg: 'Camera Group',
      cl: 'Clips',
      m: 'Map',
      md: 'Metadata',
      p: 'Payment Details',
      t: 'Team',
      u: 'People',
      w: 'Workspace'
    },
    actions: {
      bookmarkCreate: 'Successfully annotated {date}.',
      bookmarkCreateFailed: 'Unsuccessful attempt to save a moment on {date}.',
      bookmarkUpdate: 'Successfully edited the moment on {date}.',
      bookmarkUpdateFailed: 'Unsuccessful attempt to edit the moment on {date}.',
      bookmarkDelete: 'Deleted a saved moment on {date}',
      bookmarkDeleteFailed: 'Unsuccessful attempt to delete the moment on {date}.',

      bridgeCreate: 'Successfully connected {data}.',
      bridgeCreateFailed: 'Unsuccessful attempt to set up {data}.',
      bridgeUpdate: "Updated {data}'s settings.",
      bridgeUpdateFailed: "Unsuccessful attempt to update {data}'s configurations.",
      bridgeDelete: 'Disconnected {data}.',
      bridgeDeleteFailed: 'Unsuccessful attempt to delete {data} device.',

      cameraGroupCreate: 'Successfully created {cameraGroup}, the camera group.',
      cameraGroupCreateFailed: 'Unsuccessful attempt to create {cameraGroup}, the camera group.',
      cameraGroupUpdate: 'Updated {cameraGroup},the camera group, details.',
      cameraGroupUpdateFailed: 'Unsuccessful attempt to update {cameraGroup}, the camera group.',
      cameraGroupDelete: 'Deleted {cameraGroup}, the camera group and all its dependencies.',
      cameraGroupDeleteFailed: 'Unsuccessful attempt to delete {cameraGroup}, the camera group.',

      // Camera type
      cameraConnect: 'Successfully connected {camera} camera.',
      cameraConnectFailed: 'Unsuccessful attempt to set up {camera} camera.',
      cameraUpdate: "Updated {camera}'s settings.",
      cameraUpdateFailed: "Unsuccessful attempt to update {camera}'s configurations.",
      cameraDelete: 'Deleted {camera} camera and all its stored footage.',
      cameraDeleteFailed: 'Unsuccessful attempt to delete {camera} camera.',
      cameraUpdateConnectionInfo: "Updated {camera}'s connection settings.",
      cameraUpdateConnectionInfoFailed:
        "Unsuccessful attempt to update {camera}'s connection settings.",
      cameraUpdateSchedule: "Updated {camera}'s settings.",
      cameraUpdateScheduleFailed: "Unsuccessful attempt to update {camera}'s settings.",
      cameraUpdateRecordMode: "Updated {camera}'s cloud archiving mode.",
      cameraUpdateRecordModeFailed:
        "Unsuccessful attempt to change {camera}'s cloud archiving mode.",
      cameraAvatarUpdate: "Updated {camera}'s profile avatar.",
      cameraAvatarUpdateFailed: "Unsuccessful attempt to update {camera}'s profile avatar.",
      cameraAvatarDelete: "Deleted {camera}'s profile avatar.",
      cameraAvatarDeleteFailed: "Unable to delete {camera}'s profile avatar.",
      CameraRecordingStartedStatusHealthy:
        'Cloud archiving for {camera} camera is reinstated in a healthy state.',
      CameraRecordingStartedStatusHealthyFailed:
        "Unsuccessful attempt to reinstate {camera}'s cloud archiving to a healthy state.",
      CameraRecordingPausedStatusUnhealthy:
        'Cloud archiving paused as {camera} hardware became unreachable.',
      CameraRecordingPausedStatusUnhealthyFailed: 'Unsuccessful attempt to pause record mode.',
      CameraDisabledPaymentIssue:
        'Unable to record footage for {camera} camera due to payment failure.',
      CameraDisabledPaymentIssueFailed: 'Unsuccessful attempt to pause record mode.',
      CameraEnabledPaymentIssue:
        'Cloud archiving for {camera} camera restored after payment success.',
      CameraEnabledPaymentIssueFailed:
        'Unsuccessful attempt to reinstate cloud archiving to a healthy state.',
      CameraRecordingPausedPaymentIssue: 'Stopped recording due to payment failure.',
      CameraRecordingPausedPaymentIssueFailed: 'Unsuccessful attempt to pause record mode.',
      CameraRecordingStartedPaymentIssue:
        'Cloud archiving for {camera} restored after payment success.',
      CameraRecordingStartedPaymentIssueFailed:
        'Unsuccessful attempt to reinstate cloud archiving to a healthy state.',
      // Clip type
      exportCreate: 'Successfully created a clip from {date}.',
      exportCreateFailed: 'Unsuccessful attempt to create a clip from {date}.',
      exportUpdate: 'Updated a clip from {date}.',
      exportUpdateFailed: 'Unsuccessful attempt to update a clip from {date}.',
      exportDelete: 'Deleted {name} from {date}.',
      exportDeleteFailed: 'Unsuccessful attempt to delete {name} from {date}.',
      exportDownload: 'Downloaded {name} from {date}.',
      exportDownloadFailed: 'Unsuccessful attempt to download {name} from {date}.',
      ExportVideoFailed: 'Export Video was Failed',

      snapshotCreate: 'Captured a snapshot from {date}',
      snapshotCreateFailed: 'Unsuccessful attempt to capture a snapshot from {date}.',
      snapshotUpdate: 'Updated a snapshot from {date}.',
      snapshotUpdateFailed: 'Unsuccessful attempt to update a snapshot from {date}.',
      snapshotDelete: 'Deleted a snapshot from {date}.',
      snapshotDeleteFailed: 'Unsuccessful attempt to delete a snapshot from {date}.',

      stripCreateCustomer: 'Welcomed {user} as a billing customer.',
      stripeCreateCustomerFailed: 'Unable to create {user} as a billing customer.',
      stripUpdateCustomer: "Updated {user}'s customer profile.",
      stripUpdateCustomerFailed: "Unable to update {user}'s customer profile.",
      stripRemoveCustomer: 'Deleted {user} as a customer',
      stripRemoveCustomerFailed: 'Unsuccessful attempt to delete {user} as a customer.',
      stripCreateSubscription: 'Created a {duration} cloud storage subscription, paid {recurring}.',
      stripCreateSubscriptionFailed: 'Unsuccessful attempt to create a subscription.',
      stripUpdateSubscription: 'Updated the {recurring} subscription to {duration} cloud storage.',
      stripUpdateSubscriptionFailed: 'Unsuccessful attempt to update the subscription.',
      stripFreezeSubscription: 'Paused all upcoming payments for the workspace.',
      stripFreezeSubscriptionFailed: 'Unsuccessful attempt to pause all upcoming payments.',
      stripUpdateRenewalSubscription:
        'Renewed subscription to {duration} cloud storage, paid {recurring}',
      stripUpdateRenewalSubscriptionFailed:
        'Unsuccessful attempt to renew the workspace subscription.',
      stripReactivateSubscription:
        'Re-activated workspace subscription of {duration} cloud storage, paid {recurring}.',
      stripReactivateSubscriptionFailed: 'Unsuccessful attempt to re-activate the subscription.',
      stripCreatePaymentMethods: 'Uploaded a payment method ending in ****{card}.',
      stripCreatePaymentMethodsFailed: 'Unsuccessful attempt to upload a payment method.',
      stripDeletePaymentMethods: 'Deleted a payment method ending in ****{card}.',
      stripeDeletePaymentMethods:
        'Unsuccessful attempt to delete a payment method ending in ****{card}.',

      stripUpdateStripeWorkspace: 'stripUpdateStripeWorkspace: Delete this log.', //delete log

      stripeUpdateStripeWorkspaceFailed: 'Unsuccessful attempt to update workspace subscription.',

      stripeTrialEnded: 'Free trial ended on {date}.',

      stripeTrialEndedFailed: 'Unsuccessful attempt to end free trial.',

      SubscriptionCreated: 'New Subscription was created',
      SubscriptionDeleted: 'The Subscription was Deleted',
      SubscriptionPaused: 'The Subscription was Paused',
      SubscriptionResumed: 'The Subscription was Resumed',
      SubscriptionTrialWillEnd: 'The Subscription Trial will End',
      SubscriptionUpdated: 'The Subscription was Updated',

      PaymentIntentCreated: 'PaymentIntentCreated: Delete this log.', //Delete this

      PaymentIntentCreatedFailed:
        'Unsuccessful attempt to schedule a payment collection for invoice #{number}.',
      PaymentMethodDeleted: 'Deleted a payment method ending in ****{card}.',
      PaymentMethodDeletionFailed:
        'Unsuccessful attempt to delete a payment method ending in ****{card}.',
      PaymentMethodAttached: 'Added a new payment method ending in ****{card}.',
      PaymentMethodAttachedFailed:
        'Unsuccessful attempt to add a new payment method ending in ****{card}.',
      PaymentMethodExpiring: 'Expired a payment method ending in ****{card}.',

      // Invoices
      InvoiceCreated: 'Drafted an upcoming invoice #{number} for ${amount} due on {startDate}.',
      InvoiceCreatedFailed:
        'Unsuccessful attempt to draft an invoice for ${amount} due on {startDate}.',
      InvoiceDeleted: 'Deleted invoice #{number} for ${amount} due on {date}.',
      InvoiceDeletedFailed:
        'Unsuccessful attempt to delete invoice #{number} for ${amount} due on {date}.',
      InvoiceFinalized: 'Processed payment for invoice #{number} for ${amount} due on {date}.',
      InvoiceFinalizationFailed:
        'Unsuccessful attempt to process invoice #{number} for ${amount} due on {date}.',
      InvoiceMarkedUncollectible:
        'Failed to process invoice #{number} for ${amount} due on {date}.',
      InvoicePaid: 'Paid invoice #{number} for ${amount} due on {date}.',
      InvoicePaymentActionRequired: '',
      InvoicePaymentFailed:
        'Unsuccessful attempt to pay invoice #{number}for ${amount} due on {date}.',
      InvoicePaymentSucceeded: 'Collected payment for invoice #{number} for ${amount}.',
      InvoiceUpcoming: 'Reminder for an upcoming invoice #{number} for ${amount} due on {endDate}.',

      // User type
      userUpdate: "Updated {user}'s user profile.",
      userUpdateFailed: "Unsuccessful attempt to update {user}'s user profile.",
      userUpdatePassword: "Updated {user}'s password credentials.",
      userUpdatePasswordFailed: "Unsuccessful attempt to update {user}'s password credentials.",
      userUpdatePhone: "Updated {user}'s phone number.",
      userUpdatePhoneFailed: "Unsuccessful attempt to update {user}'s phone number.",
      userUpdateEmail: "Updated {user}'s email.",
      userUpdateEmailFailed: "Unsuccessful attempt to update {user}'s email.",
      userUpdateAvatar: 'Updated profile avatar.',
      userUpdateAvatarFailed: 'Unsuccessful attempt to update profile avatar.',
      userDeleteAvatar: 'Removed user profile avatar.',
      userDeleteAvatarFailed: 'Unsuccessful attempt to delete profile avatar.',
      WorkspaceSecurityDeviceSwitch: 'Logged in via {os}/{browser} - {ip}.',
      WorkspaceSecurityDeviceSwitchFailed:
        'Unsuccessful attempt to log in via {os}/{browser} - {ip}.',

      // Workspace type
      workspaceCreate: '{user} created a new workspace called {workspace}.',
      workspaceCreateFailed: 'Unsuccessful attempt to create a new workspace.',
      workspaceDelete: '{user} deleted the {workspace} workspace.',
      workspaceDeleteFailed: 'Unsuccessful attempt to delete the workspace.',
      workspaceUpdate: "Updated workspace's data.",
      workspaceUpdateFailed: "Unsuccessful attempt to update workspace's data.",
      workspaceInvite: 'Invited {phone} to the {workspace}.',
      workspaceInviteFailed: 'Unsuccessful attempt to invite {email} to the {workspace}.',
      workspaceDeleteUser: 'Deleted {otherUser} from the {workspace}.',
      workspaceDeleteUserFailed: 'Unsuccessful attempt to delete {otherUser} from the workspace.',
      workspaceSuspendUser: 'Suspended {otherUser} from the {workspace}.',
      workspaceSuspendUserFailed: 'Unsuccessful attempt to suspend {user} the {workspace}.',
      workspaceResendUser: 'Resent an invite to {phone} to join the {workspace}.',
      workspaceResendUserFailed: 'Unsuccessful attempt to re-invite {phone} to the {workspace}.',
      workspaceAvatarUpdate: "Updated workspace's profile avatar.",
      workspaceAvatarUpdateFailed: "Unsuccessful attempt to update {workspace}'s profile avatar.",
      workspaceAvatarRemove: "Removed workspace's profile avatar.",
      workspaceAvatarRemoveFailed: "Unsuccessful attempt to delete {workspace}'s profile avatar.",
      userJoinWorkspace: 'Hooray! {user} joined the {workspace}.',
      userJoinWorkspaceFailed: 'Unsuccessful attempt to join the {workspace}.',
      userLeaveWorkspace: '{user} left the {workspace}.',
      userLeaveWorkspaceFailed: 'Unsuccessful attempt to leave the {workspace}.'
    },
    title: '{data}: {name} ',
    bookmarkTitle: 'Annotated Moments: {camera}',
    bridgeTitle: 'Bridge: {bridge}',
    clipsTitle: 'Clip Log',
    workspaceTitle: 'Workspace Log',
    billingTitle: 'Billing & Invoicing',
    eventTitle: 'System Events',
    arcadian: 'ARCADIAN.AI',
    deviceSwitch: 'Device Switch: {os}/{browser} - {ip}',
    filter: {
      workspace: 'All',
      all: 'All',
      me: 'Profile',
      cameras: 'Cameras',
      payment: 'Billing',
      exports: 'Exports',
      snapShots: 'SnapShots',
      bookmarks: 'Bookmarks'
    }
  }
}

import type { TabsResponse, CreateTabData } from './tabs.interfaces'
import { Service } from '../../service'

export class TabsService extends Service {
  public create(data: CreateTabData) {
    return this.post<TabsResponse>(`/api/custom-settings/tab`, data)
  }
  public list() {
    return this.get<TabsResponse[]>(`/api/custom-settings/tab`)
  }
  public update(id: string, data: CreateTabData) {
    return this.patch<TabsResponse[]>(`/api/custom-settings/tab/${id}`, data)
  }
  public remove(id: string) {
    return this.delete<void>(`/api/custom-settings/tab/${id}`)
  }
}

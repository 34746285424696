import type { PlayerCore } from '@/player/lib/player/player-core'
import { ref } from 'vue'
export class MaskHelper {
  constructor(protected readonly core: PlayerCore) {}

  public maskPath = ref('')

  get config() {
    return this.core.maskConfig
  }

  async enable() {
    if (this.isAvailable() && !this.config.enabled) {
      this.calculateMaskPath()
      const value = JSON.parse(JSON.stringify(this.core.information.maskData))
      await this.core.worker.setMaskData(value)
      this.config.enabled = true
    }
  }

  protected calculateMaskPath() {
    const points = this.core.information.maskData
    if (points && points.length > 2) {
      this.maskPath.value = 'polygon('
      for (let i = 0; i < points.length; i++) {
        this.maskPath.value += `${(points[i][0] * 100).toFixed(2)}% ${(points[i][1] * 100).toFixed(
          2
        )}%`
        if (i < points.length - 1) {
          this.maskPath.value += ', '
        }
      }
      this.maskPath.value += ')'
    }
  }

  async onMaskUpdate() {
    await this.disable()
    await this.enable()
  }

  isAvailable() {
    return !!this.core.information.maskData
  }

  async disable() {
    if (this.config.enabled) {
      await this.core.worker.clearMask()
      this.config.enabled = false
    }
  }
}

export function getSubscriptionList(prices: Array<any>) {
  const subscriptionList: {
    label: string
    action: string
  }[] = []
  getRecurringActionList(prices).forEach((recurring) => {
    const label: string = recurring.charAt(0).toUpperCase() + recurring.slice(1)
    subscriptionList.push({
      label: `${label}ly`,
      action: recurring
    })
  })
  subscriptionList.sort((recurringA, recurringB) => {
    const labelA = recurringA.label.toUpperCase() // ignore upper and lowercase
    const labelB = recurringB.label.toUpperCase() // ignore upper and lowercase
    if (labelA < labelB) {
      return -1
    }
    if (labelA > labelB) {
      return 1
    }
    return 0
  })
  return subscriptionList
}

export function getRecurringActionList(prices: Array<any>): Array<any> {
  const recurrings: string[] = []
  prices.forEach((price) => {
    if (recurrings.indexOf(price.data.recurring.interval) == -1) {
      recurrings.push(price.data.recurring.interval)
    }
  })
  return recurrings
}

export enum HeatMapPeriodType {
  shortTerm,
  midTerm,
  longTerm
}

export interface HeatMapEntity {
  id: string
  cameraId: string
  period: HeatMapPeriodType
  image: string
  periodStart: number
  periodEnd: number
}

export interface HeatmapGetNearestRequestDTO {
  cameraId: number
  period: HeatMapPeriodType
  time: string
}

export type HeatmapGetNearestResponseDTO = HeatMapEntity

export interface HeatmapFindRequestDTO {
  cameraId: string
  period: HeatMapPeriodType
  startTime: string
  endTime: string
}

export type HeatmapFindResponseDTO = Array<HeatMapEntity>

import { FunctionMode, HeatMapPeriodType } from '@/player/types'
import { PlayerCore } from '@/player/lib/player/player-core'
import type { DrawConfigHeatmapTimeline, HeatMapRecordInfo } from '@/player/interfaces'
import { HeatMapRenderFactory } from '@/player/lib/heatmap/heatmap-render-factory'
import { HeatmapTimelineImageManager } from '@/player/lib/heatmap-timeline/heatmap-timeline-record-manager'
import { HeatmapTimelineDrawerHelpers } from '@/player/lib/heatmap-timeline/heatmap-timeline-drawer-helpers'
import { HeatmapTimelineHelpers } from '@/player/lib/heatmap-timeline/heatmap-timeline-helpers'
import { getHeatmapCache } from '@/player/lib/heatmap/heatmap-cache'
import { heatMapRenderConst } from '@/player/lib/heatmap-timeline/heatmap-timeline-consts'
import { TimelineBasic } from '@/player/lib/helpers/timeline-basic'
import { computed } from 'vue'

export const PLAYBACK_HEATMAP_TIMELINE_HEIGHT = 116

const HEATMAP_TIMELINE_OPACITY = 0.7
const IMAGE_DIMENSIONS = { width: 64, height: 64 }
const TEXT_Y_POSITION = 44
const IMAGE_Y_POSITION = 45

export class HeatmapTimeline extends TimelineBasic {
  public thumbnailRenderFactory = new HeatMapRenderFactory().opacity(HEATMAP_TIMELINE_OPACITY)

  public images = new HeatmapTimelineImageManager()
  public drawer: HeatmapTimelineDrawerHelpers

  public seekMode = computed(() => this.playbackConfig.speed === 0)

  constructor(
    core: PlayerCore,
    ctx: CanvasRenderingContext2D,
    public readonly imageCache = getHeatmapCache()
  ) {
    super(core, ctx)
    this.drawer = new HeatmapTimelineDrawerHelpers(this.ctx)
  }

  get playbackConfig() {
    return this.core.playback
  }
  get config() {
    return this.core.heatmapConfig
  }

  public setCanvasRenderSize(w: number, h: number = PLAYBACK_HEATMAP_TIMELINE_HEIGHT) {
    super.setCanvasRenderSize(w, h)
  }

  public generateCacheValue(): string {
    if (this.dragHelper.context === '') {
      return `${this.core.playback.lastFrameDate}:${this.config.timelineSize}:${this.renderWidth}`
    } else return Math.random().toString()
  }

  protected onDrag(dx: number) {
    const diffX = (this.view.size * dx) / this.renderWidth
    this.seek(this.view.now - diffX)
    return 'timeline'
  }

  seek(value: number) {
    if (this.playbackConfig.isLive) {
      this.core.capture.archive(value)
      this.core.decoderQueue.disable()
    }
    this.core.capture.softSeek(value)
    this.core.mode.value = FunctionMode.aiHeatmapMode
    this.playbackConfig.isLive = false
    super.seek(value)
  }

  protected get renderConstant(): DrawConfigHeatmapTimeline {
    return heatMapRenderConst[this.config.timelineSize]
  }

  protected calculateWindowSize() {
    this.view.size = Math.round(
      (this.renderWidth / this.renderConstant.periodSize) * this.config.timelineSize
    )
  }

  protected renderPeriod(
    startDate: number,
    endDate: number,
    type: HeatMapPeriodType,
    periods: HeatMapRecordInfo[]
  ) {
    for (let xDate = startDate; xDate <= endDate; xDate += type) {
      const text = HeatmapTimelineHelpers.getTextOfDate(xDate, type)
      const x = this.findRelativeXForDate(xDate)

      const image = periods.find((r) => r.period[0] <= xDate && r.period[1] > xDate)
      if (this.view.now >= xDate && this.view.now <= xDate + type) {
        if (image) {
          this.config.activeImage = image
        } else {
          this.config.activeImage = undefined
        }
      }
      if (image) {
        const data = this.imageCache.get(image.image)
        if (data) {
          const heatMapRender = this.thumbnailRenderFactory.create(data)
          const imageData = heatMapRender.getImage()
          this.drawer.putImage(imageData, x, IMAGE_Y_POSITION)
        }
      }
      this.drawer.drawText(x, TEXT_Y_POSITION, text.mini, this.renderConstant.font)
    }
  }

  public render() {
    if (this.needRender()) {
      try {
        if (this.dragHelper.context === '' && !this.seekMode.value) {
          this.setNow(this.core.playback.lastFrameDate)
        }
        const type = this.config.timelineSize
        const startDate = HeatmapTimelineHelpers.findPreviousRoundDate(this.view.start, type)
        const endDate = HeatmapTimelineHelpers.findPreviousRoundDate(this.view.end, type)
        const periods = this.images.getOverlappingPeriods(type, this.view.start, this.view.end)

        this.drawer.clear()
        this.renderPeriod(startDate, endDate, type, periods)

        this.drawer.drawLine(0, 112.5, {
          ...this.renderConstant.horizontalLine,
          width: this.renderWidth
        })

        this.drawer.drawCenterLine(this.renderWidth / 2, 110, this.renderConstant.centerLine)
      } catch (e) {
        console.error(e)
      }
    }
    if (this._enabled) {
      requestAnimationFrame(this.render.bind(this))
    }
  }
}

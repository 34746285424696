import { HeatmapHelpers } from '@/player/lib/heatmap/heatmap-helpers'
import { AsyncCache } from '@/player/lib/helpers/async-cache'
import { getHeatmapImageCache } from '@/player/lib/heatmap/heatmap-image-cache'

export class HeatmapCache extends AsyncCache<string, ImageData | undefined> {
  constructor(public readonly imageCache = getHeatmapImageCache()) {
    super(25)
  }

  protected dataCleanup(key: string, value: ImageData | undefined): void {
    return
  }

  protected generatePreloadValue(key: string): ImageData | undefined {
    return undefined
  }

  protected isPreloadValue(value: ImageData | undefined): boolean {
    return value === undefined
  }

  protected async loadData(key: string): Promise<ImageData | undefined> {
    const image = await this.imageCache.request(key)
    return HeatmapHelpers.parseImage(image)
  }
}

const heatMapCacheGlobal = new HeatmapCache()

export function getHeatmapCache() {
  return heatMapCacheGlobal
}

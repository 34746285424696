import type { CameraService, CameraStatusObject } from '@/lib/api'
import type { CameraStatus } from '@/modules/camera-new/libs/status/CameraStatus'
import { useServices } from '@/lib/services'

export class CameraStatusBackgroundService {
  protected service: CameraService = useServices().camera
  protected interval: ReturnType<typeof setInterval>
  protected instances: Set<CameraStatus> = new Set()
  protected readonly INTERVAL_PERIOD = 10_000

  constructor() {
    this.interval = setInterval(this.update.bind(this), this.INTERVAL_PERIOD)
  }

  register(instance: CameraStatus) {
    this.instances.add(instance)
  }

  unregister(instance: CameraStatus) {
    this.instances.delete(instance)
  }

  protected async update() {
    if (this.service.getHeader('Authorization') && this.instances.size > 0) {
      const statusMap: Map<string, CameraStatusObject> = new Map()
      const status = await this.service.statusAllCamera()
      status.forEach((s) => statusMap.set(s.cameraId, s.status))
      for (const instance of this.instances) {
        const statusData = statusMap.get(instance.id)
        if (statusData) instance.setStatus(statusData)
      }
    }
  }

  restart() {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(this.update.bind(this), this.INTERVAL_PERIOD)
  }
}

const LocalesConfig = {
  en: {
    locale: 'en-US',
    num: {
      notation: 'standard'
    },
    currency: {
      style: 'currency',
      currency: 'USD'
    },
    date: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour12: false
    }
  }
}

export default LocalesConfig

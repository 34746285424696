import type {
  ResponseSetupTOtpStepOne,
  ResponseSetupTOtpStepTwo,
  ResponseTotpVerify,
  SetupTotpStepTwoData,
  TOtpRecoveryData
} from '@/lib/api'
import { Service } from '../../service'

export class TotpService extends Service {
  // ***** TOTP APIS  *****

  public setupStepOneTotp() {
    return this.post<ResponseSetupTOtpStepOne>('/api/u-crm/otp/time-based/setup/step/1')
  }

  public setupStepTwoTotp(data: SetupTotpStepTwoData) {
    return this.post<ResponseSetupTOtpStepTwo>('/api/u-crm/otp/time-based/setup/step/2', data)
  }

  public recoverTotp(data: TOtpRecoveryData) {
    return this.post<void, TOtpRecoveryData>('/api/u-crm/otp/time-based/recover', data)
  }

  public removeTotp() {
    return this.delete<void>('/api/u-crm/otp/time-based/remove')
  }

  public verifyTotp(data: SetupTotpStepTwoData) {
    return this.post<ResponseTotpVerify, SetupTotpStepTwoData>(
      '/api/u-crm/otp/time-based/verify',
      data
    )
  }
}

import { Service } from '../../service'
import type { StripeCustomer } from '@/lib/api/services/stripe-payment/customer/stripe-customer.interfaces'

export class StripeCustomerService extends Service {
  public createCustomer(data: { description?: string }): Promise<StripeCustomer> {
    return this.post(`/api/payment/customer`, data)
  }

  public currentCustomer(): Promise<StripeCustomer> {
    return this.get(`/api/payment/customer/current`)
  }

  public findCustomer(id: string): Promise<StripeCustomer> {
    return this.get(`/api/payment/customer/${id}`)
  }

  public updateCustomer(id: string, data: { description: string }): Promise<StripeCustomer> {
    return this.patch(`/api/payment/customer/${id}`, data)
  }

  public removeCustomer(id: string): Promise<void> {
    return this.delete(`/api/payment/customer/${id}`)
  }
}

import type { Permission, Team, TeamCreate, TeamUpdate } from './team.interfaces'
import { Service } from '../../service'

export class TeamService extends Service {
  // ***** TEAM APIS  *****
  public create(data: TeamCreate): Promise<Team> {
    return this.post(`/api/p-crm/team`, data)
  }
  public list(): Promise<Team[]> {
    return this.get(`/api/p-crm/team`)
  }
  public find(id: string): Promise<Team> {
    return this.get(`/api/p-crm/team/${id}`)
  }
  public update(id: string, data: TeamUpdate): Promise<Team> {
    return this.patch(`/api/p-crm/team/${id}`, data)
  }
  public remove(id: string) {
    return this.delete(`/api/p-crm/team/${id}`)
  }
  public dryRun(id: string) {
    return this.delete(`/api/p-crm/team/${id}/dry-run`)
  }
  public suspend(id: string) {
    return this.delete(`/api/p-crm/team/${id}/suspend`)
  }
  public reinstate(id: string) {
    return this.post(`/api/p-crm/team/${id}/reinstate`)
  }

  public permissions(id: string): Promise<Permission[]> {
    return this.get(`/api/p-crm/team/${id}/permission`)
  }
}

import type { AxiosInstance, AxiosHeaderValue } from 'axios'
import axios from 'axios'
import { AuthenticationManagerService } from './services/authentication-manager/authentication-manager.service'
import {
  ActivityLogService,
  AIManagerService,
  BookmarkService,
  BridgeProxyService,
  BridgeService,
  CameraGroupService,
  CameraService,
  CustomSettingService,
  EventStorageService,
  ExportManagerService,
  LayoutManagerService,
  MapManagerService,
  MetadataManagerService,
  NotificationService,
  NotificationTemplateService,
  NotificationUserService,
  permissionManagerService,
  StripePaymentService,
  TabsService,
  TagManagerService,
  UserEventBus,
  WorkspaceManagerService
} from '@/lib/api/services'
import { computed, ref, watch } from 'vue'

const beforeUnloadHandler = (event: Event) => {
  event.preventDefault()

  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true
  return "Wait a sec—saving your changes! Stick around by hitting 'Cancel'"
}

export class ApiClient {
  public static runningCount = ref(0)

  public static readonly isRunning = computed(() => this.runningCount.value > 0)

  public static exitCheck() {
    if (ApiClient.isRunning.value) {
      return confirm("Wait a sec—saving your changes! Stick around by hitting 'Cancel'")
    } else return true
  }

  public static closeWatch = watch(
    ApiClient.isRunning,
    () => {
      if (ApiClient.isRunning.value) {
        window.addEventListener('beforeunload', beforeUnloadHandler)
      } else {
        window.removeEventListener('beforeunload', beforeUnloadHandler)
      }
    },
    { immediate: true }
  )

  protected adaptor: AxiosInstance

  public ai: AIManagerService
  public activityLogService: ActivityLogService
  public authenticationManager: AuthenticationManagerService
  public bookmark: BookmarkService
  public bridge: BridgeService
  public bridgeProxy: BridgeProxyService
  public camera: CameraService
  public cameraGroup: CameraGroupService
  public customSetting: CustomSettingService
  public eventStorage: EventStorageService
  public layoutManager: LayoutManagerService
  public mapManager: MapManagerService
  public metaDataManager: MetadataManagerService
  public notification: NotificationService
  public notificationTemplate: NotificationTemplateService
  public notificationUserService: NotificationUserService
  public permissionManager: permissionManagerService
  public tagManager: TagManagerService
  public workspaceManager: WorkspaceManagerService
  public userEventBus: UserEventBus
  public tabs: TabsService
  public stripePayment: StripePaymentService
  public exportManager: ExportManagerService

  constructor(protected readonly baseURL: string) {
    this.adaptor = axios.create({
      baseURL: this.baseURL
    })

    this.activityLogService = new ActivityLogService(this.adaptor)
    this.ai = new AIManagerService(this.adaptor)
    this.authenticationManager = new AuthenticationManagerService(this.adaptor)
    this.bookmark = new BookmarkService(this.adaptor)
    this.bridge = new BridgeService(this.adaptor)
    this.bridgeProxy = new BridgeProxyService(this.adaptor)
    this.camera = new CameraService(this.adaptor)
    this.cameraGroup = new CameraGroupService(this.adaptor)
    this.customSetting = new CustomSettingService(this.adaptor)
    this.tabs = new TabsService(this.adaptor)
    this.eventStorage = new EventStorageService(this.adaptor)
    this.layoutManager = new LayoutManagerService(this.adaptor)
    this.mapManager = new MapManagerService(this.adaptor)
    this.metaDataManager = new MetadataManagerService(this.adaptor)
    this.notification = new NotificationService(this.adaptor)
    this.notificationTemplate = new NotificationTemplateService(this.adaptor)
    this.notificationUserService = new NotificationUserService(this.adaptor)
    this.permissionManager = new permissionManagerService(this.adaptor)
    this.tagManager = new TagManagerService(this.adaptor)
    this.workspaceManager = new WorkspaceManagerService(this.adaptor)
    this.userEventBus = new UserEventBus(this.adaptor)
    this.stripePayment = new StripePaymentService(this.adaptor)
    this.exportManager = new ExportManagerService(this.adaptor)
  }

  public setHeader(key: string, value: string): void {
    this.adaptor.defaults.headers.common[key] = value
  }

  public getHeader(key: string): AxiosHeaderValue | undefined {
    return this.adaptor.defaults.headers.common[key]
  }

  public removeHeader(key: string): void {
    delete this.adaptor.defaults.headers.common[key]
  }
}

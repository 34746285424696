import { HeatMapPeriodType } from '@/player/types'
import type { HeatMapRecordInfo } from '@/player/interfaces'

export class HeatmapTimelineImageManager {
  public recordInformation: Map<HeatMapPeriodType, HeatMapRecordInfo[]> = new Map()

  constructor() {
    this.recordInformation.set(HeatMapPeriodType.short, [])
    this.recordInformation.set(HeatMapPeriodType.medium, [])
    this.recordInformation.set(HeatMapPeriodType.long, [])
  }
  push(type: HeatMapPeriodType, value: HeatMapRecordInfo[]) {
    value = value.filter((r) => r.type === type)
    const tmp = this.recordInformation.get(type)
    if (tmp) {
      const merged = [...tmp, ...value]
      merged.sort((a, b) => a.period[0] - b.period[0])
      this.recordInformation.set(type, merged)
    }
  }

  resetRecordInfo(type: HeatMapPeriodType) {
    this.recordInformation.set(type, [])
  }

  getOverlappingPeriods(
    type: HeatMapPeriodType,
    startTime: number,
    endTime: number
  ): HeatMapRecordInfo[] {
    const tmp = this.recordInformation.get(type)
    if (tmp) {
      return tmp.filter(({ period }) => {
        const [start, end] = period
        return start <= endTime && end >= startTime
      })
    } else return []
  }
}

import { CameraCore } from '@/modules/camera-new/CameraCore'
import { useServices } from '@/lib/services'
import { storeToRefs } from 'pinia'
import { useTabStore } from '@/stores/tab/useTabStore'
import type { CameraTemp } from '@/modules/camera-new/interface'
import { convertFromBlob } from '@/utils/helpers/general'
import { useEventBus } from '@/utils/event-bus/EventBus'
import type { Ref } from 'vue'
import { ref } from 'vue'

export class CameraStore {
  public cameras: Map<string, CameraCore> = new Map()
  public cameraIdList: Ref<string[]> = ref([])

  async loadCameras() {
    const cameras = await useServices().camera.findAll()
    const reqs: Promise<CameraCore>[] = []
    cameras.forEach((camera) => {
      const cameraObject = this.cameras.get(camera.id)
      if (!cameraObject) reqs.push(CameraCore.initByCamera(camera))
      else cameraObject.updateCameraData(camera)
    })
    const data = await Promise.all(reqs)
    data.forEach((camera) => {
      this.cameras.set(camera.id, camera)
    })
    cameras.forEach((camera) => {
      if (!this.cameraIdList.value.some((id) => id === camera.id))
        this.cameraIdList.value.push(camera.id)
    })
    return this.cameras
  }

  async loadCamera(id: string) {
    const camera = await CameraCore.initById(id)
    if (camera) {
      this.cameras.set(camera.id, camera)
      if (!this.cameraIdList.value.find((c) => c === camera.id)) {
        this.cameraIdList.value.push(camera.id)
      }
    }
    return camera
  }

  async loadBasicCameras(force = false) {
    const data = await useServices().camera.findAll()
    data.forEach((camera) => {
      if (!this.cameraIdList.value.some((id) => id === camera.id))
        this.cameraIdList.value.push(camera.id)
    })
    return data
  }

  async createCamera(cameraTemp: CameraTemp) {
    const camera = await CameraCore.initTemp(cameraTemp)
    await camera.getCameraData()
    if (cameraTemp.tags.length > 0) await camera.tagManager.assign(cameraTemp.tags)
    if (cameraTemp.avatar) {
      await camera.avatar.create(await convertFromBlob(cameraTemp.avatar))
    }
    if (cameraTemp.location)
      await camera.locationManager.create(
        { longitude: cameraTemp.location.longitude, latitude: cameraTemp.location.latitude },
        cameraTemp.location.fullAddress
      )
    this.cameras.set(camera.id, camera)
    this.cameraIdList.value.push(camera.id)
    useEventBus().emit('cameras:update', true)
    return camera
  }

  async deleteCamera(id: string, actualDelete = true) {
    const camera = this.getCameraById(id)
    if (camera) {
      if (actualDelete) {
        await camera.delete()
      }
      this.removeFromList(id)
      this.cameraIdList.value = this.cameraIdList.value.filter((item) => item !== id)
      useEventBus().emit('cameras:update', true)
      const { tabs } = storeToRefs(useTabStore())
      const removeTabItem = (tabs.value as any).find(
        (tab) => tab.route === 'cameraDetail' && tab.settings && tab.settings.value.cameraId == id
      )
      if (removeTabItem) {
        await useTabStore().removeTab(removeTabItem.id, removeTabItem)
      }
    }
  }

  removeFromList(id: string) {
    const camera = this.getCameraById(id)
    if (camera) {
      this.cameras.delete(camera.id)
      this.cameraIdList.value = this.cameraIdList.value.filter((cameraId) => cameraId !== id)
      camera.status.unregisterFroUpdater()
    }
  }

  getCameraById(id: string) {
    return this.cameras.get(id)
  }

  reset(flag = false) {
    this.cameras.clear()
    this.cameraIdList.value = []
    useEventBus().emit('cameras:update', flag)
  }
}

let cameraStore: null | CameraStore = null

export function useCameraStore() {
  if (!cameraStore) {
    cameraStore = new CameraStore()
    return cameraStore
  } else return cameraStore
}

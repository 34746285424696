import { Service } from '../../service'
import type { CreateSnapshotRequest, ExportData } from '@/lib/api'

export class SnapshotService extends Service {
  protected dataURLtoBlob(dataurl) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  public create(req: CreateSnapshotRequest) {
    const data = new FormData()
    data.set('file', this.dataURLtoBlob(req.file))
    data.set('cameraId', req.cameraId.toString())
    data.set('time', req.time.toISOString())
    if (req.exportId) {
      data.set('exportId', req.exportId.toString())
    }
    return this.adaptor.post('/api/export-manager/legacy/snapshot', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  public findAll(cameraId: string) {
    return this.get<ExportData[]>(`/api/export-manager/legacy/snapshot?cameraId=${cameraId}`)
  }

  public findOne(id: string) {
    return this.get<ExportData>(`/api/export-manager/legacy/snapshot/${id}`)
  }

  public update(id: string, data: any) {
    return this.patch<any, ExportData>(`/api/export-manager/legacy/snapshot/${id}`, data)
  }

  public remove(id: string) {
    return this.delete<void>(`/api/export-manager/legacy/snapshot/${id}`)
  }

  public download(id: string, callback?) {
    return this.adaptor.get(`/api/export-manager/legacy/snapshot/${id}/media`, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (callback) {
          callback(percentCompleted)
        }
      }
    })
  }
}

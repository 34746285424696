import type {
  CreateRecordBulkData,
  CreateRecordData,
  RecordEntity,
  UpdateRecordData
} from './record.interfaces'
import { Service } from '../../service'
export class RecordService extends Service {
  // ***** TOUR APIS  *****
  public create(cameraId: string, data: CreateRecordData) {
    return this.post<RecordEntity, CreateRecordData>(`/api/camera/${cameraId}/records`, data)
  }
  public createBulk(cameraId: string, data: CreateRecordBulkData) {
    return this.post<RecordEntity[], CreateRecordBulkData>(
      `/api/camera/${cameraId}/records/bulk`,
      data
    )
  }
  public find(cameraId: string) {
    return this.get<RecordEntity>(`/api/camera/${cameraId}/records`)
  }
  public update(cameraId: string, id: string, data: UpdateRecordData) {
    return this.put<RecordEntity, UpdateRecordData>(`/api/camera/${cameraId}/records/${id}`, data)
  }
  public remove(cameraId: string, id: string) {
    return this.delete<void>(`/api/camera/${cameraId}/records/${id}`)
  }
}

import { computed, reactive, ref, type Ref, watch, type WatchStopHandle } from 'vue'
import { NEVER_SYNCED, SyncState } from '@/modules/camera-new/types'

export class SyncAbleObject<DataType extends object> {
  public data: DataType
  private readonly syncWatcher: WatchStopHandle
  private readonly syncState: Ref<SyncState> = ref(SyncState.notSync)
  public isSyncCompute: Ref<boolean>
  protected _lastSyncAt: Date | typeof NEVER_SYNCED

  protected constructor(data: DataType, isSync = true) {
    this.data = reactive<DataType>(data) as DataType
    this.syncState.value = isSync ? SyncState.synced : SyncState.notSync
    this.isSyncCompute = computed(() => this.syncState.value === SyncState.synced)
    this._lastSyncAt = isSync ? new Date() : NEVER_SYNCED
    this.syncWatcher = watch(this.data, this.onDataUpdate.bind(this), { deep: true })
  }

  protected onDataUpdate() {
    if (this.syncState.value === SyncState.justSync) {
      this.syncState.value = SyncState.synced
    } else {
      this.syncState.value = SyncState.notSync
    }
  }

  protected sync() {
    this.syncState.value = SyncState.justSync
    this._lastSyncAt = new Date()
  }

  protected destroy() {
    this.syncWatcher()
  }

  public get isSync() {
    return this.isSyncCompute.value
  }

  public get lastSyncAt() {
    return this._lastSyncAt !== NEVER_SYNCED ? new Date(this._lastSyncAt) : NEVER_SYNCED
  }
}

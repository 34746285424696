import { Actions } from '../interface'
import type { Authorities, MatrixAuthorities } from '../interface'
import { Access, VirtualPermissions } from '../interface'
import type { VirtualAuthorities } from './interface'

type AuthorityLevel = {
  authorities: Actions[]
  access: Access
}

type AuthorityInput = Actions[] | AuthorityLevel[]

export class VirtualAuthoritiesHandler {
  teamAuthorities: Array<Authorities | MatrixAuthorities> = []
  virtualAuthority: VirtualAuthorities[] = []

  constructor() {}
  cleanup() {
    this.teamAuthorities = []
    this.virtualAuthority = []
  }

  setTeamAuthority(teamAuthority: Array<Authorities | MatrixAuthorities>): void {
    this.teamAuthorities = teamAuthority
  }

  parser(): VirtualAuthorities[] {
    const permissionsToCheck = [
      {
        permissionKey: VirtualPermissions.WorkspaceOverview,
        authorities: [
          {
            authorities: [Actions.workspaceUpdateInformation, Actions.readActionLog],
            access: Access.FullControl
          },
          {
            authorities: [Actions.readActionLog],
            access: Access.ViewOnly
          }
        ]
      },
      {
        permissionKey: VirtualPermissions.PeopleAndPermissions,
        authorities: [
          {
            authorities: [
              Actions.workspaceManageUsers,
              Actions.workspaceManagePermissions,
              Actions.workspaceViewPermissions,
              Actions.workspaceViewUsers
            ],
            access: Access.FullControl
          },
          {
            authorities: [Actions.workspaceViewUsersSummery, Actions.workspaceViewPermissions],
            access: Access.ViewOnly
          }
        ]
      },
      {
        permissionKey: VirtualPermissions.BillingAndInvoicing,
        authorities: [
          {
            authorities: [Actions.workspaceManagePayment],
            access: Access.FullControl
          },
          {
            authorities: [Actions.workspaceViewPayment],
            access: Access.ViewOnly
          }
        ]
      },
      {
        permissionKey: VirtualPermissions.LiveStream,
        authorities: [
          {
            authorities: [
              Actions.cameraGroupRead,
              Actions.cameraView,
              Actions.cameraStreamLive,
              Actions.workspaceReadPublicLayout,
              Actions.workspaceUpdatePublicLayout,
              Actions.mapRead,
              Actions.mapUpdate,
              Actions.mapCreate,
              Actions.readMetadata,
              Actions.updateMetadata,
              Actions.createTag,
              Actions.updateTag,
              Actions.createTagRelation,
              Actions.updateTagRelation,
              Actions.assignTag,
              Actions.dropTag
            ],
            access: Access.FullControl
          },
          {
            authorities: [
              Actions.cameraView,
              Actions.workspaceReadPublicLayout,
              Actions.mapRead,
              Actions.cameraPTZAccess,
              Actions.cameraStreamLive
            ],
            access: Access.ViewOnly
          }
        ]
      },
      {
        permissionKey: VirtualPermissions.DeviceSetup,
        authorities: [
          {
            authorities: [
              Actions.cameraGroupRead,
              Actions.cameraGroupCreate,
              Actions.cameraGroupUpdate,
              Actions.cameraGroupRemove,
              Actions.cameraRemove,
              Actions.cameraUpdate,
              Actions.cameraCreate,
              Actions.cameraViewSettings,
              Actions.cameraUpdateSettings,
              Actions.bridgeActivate,
              Actions.bridgeFind,
              Actions.bridgeUpdate,
              Actions.bridgeRemove,
              Actions.bridgeDiscoverCamera,
              Actions.bridgeForget,
              Actions.bridgeHeartbeat,
              Actions.bridgeReset,
              Actions.bridgeGetHandlers,
              Actions.bridgeSync,
              Actions.bridgeViewDiscoveryResponse
            ],
            access: Access.FullControl
          },
          {
            authorities: [Actions.cameraViewSettings, Actions.cameraGroupRead, Actions.bridgeFind],
            access: Access.ViewOnly
          }
        ]
      },
      {
        permissionKey: VirtualPermissions.ClipsHistoricalFootage,
        authorities: [
          {
            authorities: [
              Actions.cameraUpdateBookmark,
              Actions.cameraReadBookmark,
              Actions.cameraRemoveBookmark,
              Actions.cameraCreateBookmark,
              Actions.cameraViewBookmark,
              Actions.cameraViewSettings,
              Actions.cameraStreamArchive,
              Actions.cameraPTZAccess,
              Actions.aiTrackFind,
              Actions.aiTrackStatisticsFind,
              Actions.cameraCreateSnapshot,
              Actions.cameraViewSnapshot,
              Actions.cameraDownloadSnapshot,
              Actions.cameraRemoveSnapshot,
              Actions.cameraViewExport,
              Actions.cameraDownloadExport,
              Actions.cameraCreateExport,
              Actions.cameraRemoveExport
            ],
            access: Access.FullControl
          },
          {
            authorities: [
              Actions.cameraViewSnapshot,
              Actions.cameraViewExport,
              Actions.cameraStreamArchive,
              Actions.cameraReadBookmark,
              Actions.cameraViewBookmark
            ],
            access: Access.ViewOnly
          }
        ]
      },
      {
        permissionKey: VirtualPermissions.CustomerAnalyticsAndAIHeatmaps,
        authorities: [
          {
            authorities: [Actions.readAlertSchedule, Actions.writeAlertSchedule],
            access: Access.FullControl
          },
          {
            authorities: [Actions.readAlertSchedule],
            access: Access.ViewOnly
          }
        ]
      }
    ]

    permissionsToCheck.forEach(({ permissionKey, authorities }) => {
      this.virtualAuthority.push({
        title: permissionKey,
        access: this.determineAccess(authorities)
      })
    })

    return this.virtualAuthority
  }

  determineAccess(authoritiesList: AuthorityInput): Access {
    if (isAuthorityLevelArray(authoritiesList)) {
      // Handle complex access levels (full control vs view only)
      for (const level of authoritiesList) {
        if (this.hasAllActions(level.authorities)) {
          return level.access
        }
      }
      return Access.Hidden
    } else {
      // Handle single level of access
      return this.hasAllActions(authoritiesList) ? Access.FullControl : Access.Hidden
    }
  }

  private hasAllActions(actions: Actions[]): boolean {
    return actions.every((action) => {
      return this.teamAuthorities.some((authority) => authority.key === action)
    })
  }

  getVirtualAuthority(): VirtualAuthorities[] {
    return this.virtualAuthority
  }
}

// Helper function to check if input is of type AuthorityLevel[]
function isAuthorityLevelArray(input: AuthorityInput): input is AuthorityLevel[] {
  return (
    Array.isArray(input) &&
    input.length > 0 &&
    typeof input[0] === 'object' &&
    'authorities' in input[0]
  )
}

export function decodeAdPcmG726_32(adpcmData) {
  // Initialize the state of the decoder
  let index = 0 // Index in the step size table
  let predictedSample = 0 // Predicted PCM value
  const stepSizes: number[] = [
    /* Array of step sizes for G.726 */
  ]
  const indexAdjust: number[] = [
    /* Index adjustments based on ADPCM samples */
  ]

  // Initialize the stepSizes and indexAdjust arrays with values based on the G.726 specification for 32 kbps
  stepSizes[0] = 7 // Replace with correct G.726 32 kbps values
  for (let i = 1; i < 49; i++) {
    stepSizes[i] = stepSizes[i - 1] + 8 // Replace with correct G.726 32 kbps values
  }
  indexAdjust[0] = -1 // Replace with correct G.726 32 kbps values
  indexAdjust[1] = 2
  // Continue filling indexAdjust based on G.726 specifications for 32 kbps

  const pcmSamples: number[] = []

  // Process each byte of ADPCM data (4 bits per sample for 32 kbps)
  for (let i = 0; i < adpcmData.length; i++) {
    const byte = adpcmData[i]
    for (let nibblePos = 0; nibblePos < 8; nibblePos += 4) {
      // Extract 4-bit samples from the current byte
      const adpcmSample = (byte >> nibblePos) & 0x0f

      // Decode ADPCM sample to PCM sample
      const step = stepSizes[index]
      let difference = 0

      // Determine the difference using the lower bits of the sample
      if (adpcmSample & 8) difference += step
      if (adpcmSample & 4) difference += step >> 1
      if (adpcmSample & 2) difference += step >> 2
      if (adpcmSample & 1) difference += step >> 3
      difference += step >> 4

      // Adjust the sample based on the sign bit
      if (adpcmSample & 8) {
        predictedSample -= difference
      } else {
        predictedSample += difference
      }

      // Clamp the predicted sample within 16-bit PCM range
      if (predictedSample > 32767) predictedSample = 32767
      else if (predictedSample < -32768) predictedSample = -32768

      // Adjust the index for the next step size
      index += indexAdjust[adpcmSample]
      if (index < 0) index = 0
      else if (index > stepSizes.length - 1) index = stepSizes.length - 1

      // Save the decoded sample
      pcmSamples.push(predictedSample)
    }
  }

  // Convert to Float32 for audio processing
  const float32Samples = new Float32Array(pcmSamples.length)
  for (let i = 0; i < pcmSamples.length; i++) {
    float32Samples[i] = pcmSamples[i] / 32768
  }
  return float32Samples
}

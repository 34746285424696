import { useServices } from '@/lib/services'
import { convertFromBlob } from '@/utils/helpers/general'
import { CameraService } from '@/lib/api'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { CameraCoreComposite } from '@/modules/camera-new/interface'
import { CameraCore } from '@/modules/camera-new/CameraCore'

/**
 * Class to manage the avatar for a camera. It includes functionalities to load, create, and remove the avatar.
 */
export class CameraAvatar implements CameraCoreComposite {
  public avatar: Ref<string> = ref('')
  protected service: CameraService = useServices().camera
  public readonly id: string
  /**
   * Constructor to initialize the CameraAvatar class with a CameraManager instance.
   * @param {CameraCore} core - An instance of CameraManager to manage camera operations.
   */
  constructor(public readonly core: CameraCore) {
    this.id = this.core.id
  }

  /**
   * Asynchronously loads the avatar image for a camera.
   * Fetches the avatar from the camera service and updates the `avatar` property if successful.
   */
  public async load(): Promise<void> {
    const imageData = await this.service.avatarGet(this.id)
    if (imageData && imageData.data.size > 0) {
      this.avatar.value = await this.readFileAsDataURL(imageData.data)
    }
  }

  /**
   * Asynchronously removes the avatar for a camera.
   * Calls the camera service to remove the avatar and clears the `avatar` property.
   */
  public async remove(): Promise<void> {
    await this.service.avatarRemove(this.id)
    this.avatar.value = ''
  }

  /**
   * Asynchronously creates or updates the avatar for a camera using a provided file.
   * @param {File} file - The file to be used as the new avatar.
   */
  public async create(file: File): Promise<void> {
    const data = new FormData()
    data.append('file', file)
    await this.service.avatarUpdate(this.id, data)
    await this.load()
  }

  /**
   * Reads a Blob object as a data URL string.
   * @param {Blob} blob - The Blob to be converted to a data URL.
   * @returns {Promise<string>} A promise that resolves with the data URL string.
   */
  protected readFileAsDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(blob)
    })
  }
}

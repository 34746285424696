import type { CreatMeta, UpdateMeta, EachResource, ResourceMetaData } from '@/lib/api'
import { useServices } from '@/lib/services'
import { MetadataManagerService, ResourceTypes } from '@/lib/api'
import { type Ref, ref } from 'vue'

export class MetadataManager {
  public readonly metaData: Ref<ResourceMetaData[]> = ref([])
  protected resource!: EachResource
  protected readonly service: MetadataManagerService = useServices().metaDataManager

  constructor(public readonly remoteId: string, public readonly type: ResourceTypes) {}

  public async load() {
    this.resource = await this.service.resource.find({
      remoteId: this.remoteId,
      type: this.type
    })
    this.metaData.value.splice(0, this.metaData.value.length)
    if (this.resource.metadata) this.metaData.value.push(...this.resource.metadata)
  }

  public async add(data: CreatMeta) {
    const newMetaData = await this.service.metadata.create(data)
    this.metaData.value.push(newMetaData)
  }

  public async remove(id: string) {
    await this.service.metadata.remove(id)
    const index = this.metaData.value.findIndex((e) => e.id === id)
    if (index !== -1) {
      this.metaData.value.splice(index!, 1)
    }
  }

  public async update(id: string, data: UpdateMeta) {
    const newMetaData = await this.service.metadata.update(id, data)
    const index = this.metaData.value.findIndex((e) => e.id === newMetaData.id)
    if (index !== -1) {
      this.metaData.value![index!] = newMetaData
    }
  }
}

// version 0.0.1

import type { AuthoritiesFinderOptions } from './interface'
import type { MatrixResource, ResourceTypes } from '@/lib/api'
import { Actions } from './interface'
import type { MatrixAuthorities } from './interface'

export class UserPermissions {
  permissions: MatrixAuthorities[] = []

  transformPermissions(permissionsMatrix: MatrixResource[]): void {
    this.permissions = permissionsMatrix.map((permission) => ({
      resourceId: permission.remoteId,
      type: permission.type as ResourceTypes,
      key: permission.key
    }))
  }

  checkPermission(requiredPermission: AuthoritiesFinderOptions): boolean {
    const requiredAction =
      typeof requiredPermission.action === 'number'
        ? requiredPermission.action
        : Actions[requiredPermission.action]
    return this.permissions.some(
      (permission) =>
        (requiredPermission.resourceId
          ? permission.resourceId === requiredPermission.resourceId
          : true) && requiredAction === permission.key
    )
  }

  checkAllPermissions(requiredPermissions: AuthoritiesFinderOptions[]): boolean {
    return requiredPermissions.every((requiredPermission) => {
      const requiredAction =
        typeof requiredPermission.action === 'number'
          ? requiredPermission.action
          : Actions[requiredPermission.action]
      return this.permissions.some(
        (permission) =>
          (requiredPermission.resourceId
            ? permission.resourceId === requiredPermission.resourceId
            : true) && permission.key === requiredAction
      )
    })
  }

  clear() {
    this.permissions = []
  }
}

import type {
  AssignTagData,
  AssignTagResponse,
  TagResourceData,
  TagResourceResponse
} from './tag-resource.interfaces'
import { Service } from '../../service'

export class TagResourceService extends Service {
  public listResource() {
    return this.get<TagResourceResponse[]>(`/api/tag-manager/resource`)
  }
  public findResource(data: TagResourceData) {
    return this.post<TagResourceResponse[]>(`/api/tag-manager/resource/find`, data)
  }
  public getByIds() {
    return this.get<TagResourceResponse[]>(`/api/tag-manager/resource/get-by-ids`)
  }
  public assignResource(data: AssignTagData) {
    return this.post<AssignTagResponse, AssignTagData>(`/api/tag-manager/resource/assign-tag`, data)
  }
  public dropResource(data: AssignTagData) {
    return this.adaptor.request<void>({
      method: 'delete',
      url: `/api/tag-manager/resource/drop-tag`,
      data: data
    })
  }
}

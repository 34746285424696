// version: 1.1.0
const permissionsEn = {
  permissions: {
    title: 'People',
    rolePermissions: 'Roles & Permissions ',
    newRole: 'New Role',
    spaceOverview: 'Space Overview',
    peopleRoles: 'People & Roles',
    invitePeople: 'Invite People',
    invitePeopleTooltip:
      "This permission entrusts your chosen teammates with the ability to expand your workspace circle. Handle with care - each new member can influence your workspace's security!",
    edit: 'Edit',
    clone: 'Duplicate',
    suspend: 'Suspend',
    delete: 'Delete',
    hidden: 'Hidden',
    viewOnly: 'View only',
    fullControl: 'Full control',
    newRoleDesc:
      "Role is the digital key in the workspace, unlocking doors to various adventures. It sets a member's access level and controls what they can explore or modify.",
    roleName: 'Role name',
    createMode: '🖌️ creator mode',
    reviewMode: '🧐 review mode',
    editMode: '✏️  edit mode',
    editModeSimple: 'edit mode',
    workspacePermissions: 'Workspace Permissions',
    workspaceOverview: 'Workspace Overview',
    descriptionTooltip:
      'Seen as tooltips, this field acts as your quick guide to identifying roles and permissions within your workspace.',
    workspaceOverviewTooltip:
      'This screen is your one-stop hub for all things workspace. It includes a snapshot of your members and connected devices, lets you keep tabs on activity logs, and helps you stay ahead of the game with a glimpse of upcoming payments.',
    peoplePermissions: 'People & Permissions',
    peoplePermissionsTooltip:
      "This is where your workspace comes alive! Here, you'll find your team members, their roles, and their varying levels of access.",
    billingInvoicing: 'Billing & Invoicing',
    billingInvoicingTooltip:
      'Your financial control panel, where all invoice and payment method matters are handled. This is your one-stop shop for all monetary transactions.',
    Subscription: 'Subscription',
    SubscriptionTooltip:
      " This permission item empowers your selected teammates to alter your subscription plan, either upgrading or downgrading, using the active payment method on file. It's a powerful tool, so handle with care!",
    monitoringPermissions: 'Monitoring Permissions',
    liveStream: 'Live Stream',
    liveStreamTooltip:
      "Think of this as your digital surveillance hub, presenting live video feeds at your command. Watch-only members can sit back and enjoy the show, while those with full-control can play around with the dashboard setup. Navigate wisely - your workspace's security depends on it!",
    deviceSetup: 'Device Setup',
    deviceSetupTooltip:
      'This permission allows your handpicked teammates to connect, troubleshoot, disconnect, and fiddle with all your devices configurations. Keep in mind, each alteration could impact your live stream monitoring. Tread carefully!',
    clipsHistoricalFootage: 'Clips & Historical Footage',
    clipsHistoricalFootageTooltip:
      "This permission allows your chosen team members to dive into the playback modes, download clips, and export videos. It's akin to possessing a time machine for your feed!",
    mapsDeviceLocations: 'Maps & Device Locations',
    mapsDeviceLocationsTooltip:
      "Nestled in your monitoring dashboard, this feature uncovers the geographical locations of your devices and cameras, giving your selected members a bird's eye view of your security setup.",
    timestampsEvents: 'Timestamps & Events',
    suspended: 'Suspended',
    RestoreAccess: 'Restore Access',
    EditRole: 'Edit Role',
    NoticeSuspendHeader: 'Access Interruption Notice',
    NoticeSuspendDesc:
      "Heads up! Suspension isn't a light matter. It means complete cutoff - the suspended users will lose all access to your workspace. Proceed with caution, and know that the power of restoration is always in your hands. They can rejoin the game at any moment, all you have to do is click on ",
    NoticeEditedHeader: 'Game-Changing Permissions',
    NoticeEditeDesc:
      "Changing permissions in a role will ripple through all members of the authority group. Handle with care, it's game-changing!",
    RemoveTeamDesc: 'Ready to remove team \n' + 'in the workspace?',
    RemoveTeam: 'Yes, Remove Team',
    RemoveUser: 'Ready to part ways with {user} \n' + 'in the {workspace}?',
    teamStatusChange: 'team status was changed',
    teamWasDelete: 'team was deleted!',
    userDeleted: 'user was deleted!',
    deleteRole: 'Delete Role',
    suspendRole: 'Suspend {role} Role',
    suspendUser: 'Suspend {user}',
    confirmText: 'To confirm, type in the role name below.',
    guideMessageDelete:
      'By clicking {delete}, I acknowledge that I will irreversibly remove all workspace users linked to this role. Proceed with caution.',
    successRole: 'new role was created!',
    successUpdateRole: ' role was updated!',
    sidebarInvitedPrefix: 'hey! Join {workspaceName}',
    sidebarLongNamePrefix: 'hey! Join {workspaceName}',
    table: {
      header: {
        fullName: 'Full name',
        status: 'Status',
        onlineStatus: 'Status',
        email: 'Email',
        phone: 'Phone Number',
        lastSwitchedAt: 'Last Active',
        role: 'Role',
        inviteTooltip: 'Quickly add team members'
      },
      statuses: {
        title: 'Member Status',
        tooltip:
          "Member Status: Represents a user's condition in the platform — 'Active', 'Invited'.",
        pending: 'Invited',
        joined: 'Active',
        termsRequired: 'Terms Required',
        withdrawn: 'With Drawn',
        notFound: 'Not Found'
      },
      rules: {
        title: 'Role',
        tooltip:
          'A designation for workspace members, setting their access levels and dictating what they can view or modify in the platform.',
        coPilot: 'Co-pilot',
        captain: 'Captain',
        member: 'Member',
        transfer: 'Transfer'
      },
      actions: {
        profile: 'Profile',
        remove: 'Remove',
        removeAccess: 'Restore access',
        suspend: 'Suspend',
        reSend: 'Re-send Invite',
        transfer: 'Transfer Ownership',
        confirmRemove: 'Yes, Remove User'
      }
    },
    profile: {
      header: {
        contact: 'Contact',
        security: 'Security'
      },
      labels: {
        littleTitle: 'Little Nudge',
        accessNotice: 'Access Interruption Notice',
        memberSince: 'Member since'
      },
      actions: {
        review: 'Review Permission',
        restore: 'Restore Access',
        forceEmail: 'Force Verification',
        resetAuthentication: 'Reset Authentication',
        forceMultiFactor: 'Force Multi-Factor Authentication'
      }
    },
    messages: {
      successSuspendUser: 'user suspended successfully!',
      successReInviteUser: 'user re-invite successfully!',
      returnAccessUser: 'user access is return',
      changeRole: 'user role changed successfully',
      inviteMessage:
        'Your invitee is playing a bit hard to get? Try sending them another invitation and get them on board! {link}',
      accessNotice:
        "Heads up! Suspension isn't a light matter. It means complete cutoff - the suspended users will lose all access to your workspace. Proceed with caution, and know that the power of restoration is always in your hands. They can rejoin the game at any moment, all you have to do is click on {link}",
      tooltips: {
        coPilot:
          "Think of them as your ace managers or power-users, keeping an eye on live streams and juggling day-to-day tasks. Just one thing - they're kept away from the finance stuff.",
        member:
          "Also known as Viewers, these are invited folks who have just enough access to see what's going on. They're more like guests in the workspace - they can look, but can't touch much!"
      },
      resendInviteSuccess: 'Sent!',
      bulkInviteSuccess: 'Sent!',
      removeUserSuccess: 'Removed. The user no longer has entry to your workspace.',
      changeUserTeamSuccess: 'Saved!'
    },
    errorMessages: {
      isPhoneNumber:
        '{phoneNumber} Invalid. Make sure you entered a valid US or CA cellphone number. ',
      phoneNotFound: 'oops! The phone number was not found',
      QuotaIsLocked:
        "hey, we've noticed a subscription issue. Please add a valid payment method. If the problem persists, contact us at +1 (800) 288-9192 or sales{'@'}arcadian.ai. We're here to help!",
      SubscriptionProblem:
        "hey, we've noticed a subscription issue. Please add a valid payment method. If the problem persists, contact us at +1 (800) 288-9192 or sales{'@'}arcadian.ai. We're here to help!",
      limitationProblem:
        "Uh-mazing! Popular workspace alert — you've reached the limit of {n} users per space. To unlock new invites at no cost, just email us at sales{'@'}arcadian.ai.",
      phoneExist: 'Whoops! {phone} is already part of the workspace.'
    },
    tooltips: {
      captain: {
        name: 'Captain',
        description:
          'Captains are exclusive business owner of the workspace, blessed with unrestricted access to all its bells and whistles.'
      },
      'co-pilot': {
        name: 'Co-Pilot',
        description:
          'Co-Pilots serve as your elite operational managers or advanced users, vigilantly monitoring live streams while adeptly handling daily responsibilities. Their access spans all areas except billing and finance.'
      },
      member: {
        name: 'Member',
        description:
          'Members, also known as Viewers, have minimal interaction capabilities; they are limited to viewing your streams only.'
      },
      guest: {
        name: 'Guest',
        description:
          'Guests—think of them as temporary “ghosts” or demo explorers—can roam and interact with everything, but their changes vanish into thin air and won’t be saved!'
      },
      usersOverview: {
        pending: '{phone} has a pending invitation to join your workspace.',
        joined: '{fullName}' // todo check with mari
      }
    },
    modals: {
      remove: {
        title: 'Ready to remove {fullName} in {workspaceName}?',
        cancel: 'Cancel',
        ok: 'Yes, Remove User'
      }
    },
    permissionsList: {
      WorkspaceOverview: 'Space Overview & Logs',
      PeopleAndPermissions: 'People & Permissions',
      BillingAndInvoicing: 'Billing & Subscriptions',
      LiveStream: 'Live Stream',
      DeviceSetup: 'Device Setups & Configs',
      ClipsHistoricalFootage: 'Historical Footage & Clips',
      CustomerAnalyticsAndAIHeatmaps: 'Proactive AI Alerts, Video Intelligence Suite'
    },
    dropDownDescriptions: {
      captain:
        'Exclusive business owner of the workspace, blessed with unrestricted access to all its bells and whistles.',
      'co-pilot':
        'Operational managers or power users, vigilantly monitoring live streams while adeptly handling daily responsibilities. Their access spans all areas except billing and finance',
      member:
        'Members, also known as Viewers, have minimal interaction capabilities; they are limited to viewing your streams only.',
      guest:
        'Guests—think of them as temporary “ghosts” or demo explorers—can roam and interact with everything, but their changes vanish into thin air and won’t be saved!'
    }
  }
}
export { permissionsEn }

import type {
  CameraGroup,
  CreateCameraGroupData,
  UpdateCameraGroupData
} from './camera-group.interfaces'
import { Service } from '../../service'
export class CameraGroupService extends Service {
  public create(data: CreateCameraGroupData) {
    return this.post<CameraGroup, CreateCameraGroupData>(`/api/camera-manager/camera-group`, data)
  }
  public list() {
    return this.get<CameraGroup[]>(`/api/camera-manager/camera-group`)
  }
  public find(id: string) {
    return this.get<CameraGroup>(`/api/camera-manager/camera-group/${id}`)
  }
  public update(id: string, data: UpdateCameraGroupData) {
    return this.patch<CameraGroup, UpdateCameraGroupData>(
      `/api/camera-manager/camera-group/${id}`,
      data
    )
  }
  public remove(id: string) {
    return this.delete<void>(`/api/camera-manager/camera-group/${id}`)
  }
  public findAdvance() {
    return this.get<CameraGroup[]>(`/api/camera-manager/camera-group/tree`)
  }
}

import { Service } from '../../service'

export class StripeProductService extends Service {
  public findAllProduct(): Promise<any> {
    return this.get(`/api/payment/product`)
  }

  public findAllProductDetails(): Promise<any> {
    return this.get(`/api/payment/product/detailed`)
  }

  public findOneProduct(id: string): Promise<any> {
    return this.get(`/api/payment/product/${id}`)
  }

  public findOneProductDetails(id: string): Promise<any> {
    return this.get(`/api/payment/product/${id}/detailed`)
  }
}

import type { AdvanceSessionRequest, UserSessionEntity } from './session.interfaces'
import { Service } from '../../service'

export class SessionService extends Service {
  // ***** BOOKMARK APIS  *****
  public list() {
    return this.get<UserSessionEntity[]>(`/api/u-crm/sessions`)
  }

  public advance(limit: number, offset: number, data: AdvanceSessionRequest) {
    return this.post<UserSessionEntity[], AdvanceSessionRequest>(
      `/api/u-crm/sessions/advance?limit=${limit}&offset=${offset}`,
      data
    )
  }

  public find(id: string) {
    return this.get<UserSessionEntity>(`/api/u-crm/sessions/${id}`)
  }

  public remove(id: string) {
    return this.delete<void>(`/api/u-crm/sessions/${id}`)
  }
}

import { GridListEnum } from '@/components/monitoring/interfaces'
import { defineStore } from 'pinia'

export interface MonitoringState {
  viewMode: GridListEnum
}

export const useMonitoringStore = defineStore('monitoringStore', {
  state: (): MonitoringState => ({
    viewMode: GridListEnum.box
  }),

  getters: {},

  actions: {
    getMonitorigSettings() {},
    changeViewMode(mode: GridListEnum) {
      this.viewMode = mode
    }
  }
})

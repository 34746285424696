import type { Buffer } from 'buffer'

export function decodeAdPcmG726_40(adpcmData: Buffer): Float32Array {
  // Initialize the state of the decoder
  let index = 0 // Index in the step size table
  let predictedSample = 0 // Predicted PCM value
  const stepSizes: number[] = [] // Array of step sizes for G.726
  const indexAdjust: number[] = [] // Index adjustments based on ADPCM samples

  // Initialize the stepSizes and indexAdjust arrays with values based on the G.726 specification for 40 kbps
  stepSizes[0] = 7 // Placeholder value; replace with correct G.726 40 kbps values
  for (let i = 1; i < 49; i++) {
    stepSizes[i] = stepSizes[i - 1] + 8 // Placeholder logic; replace with correct G.726 40 kbps values
  }
  indexAdjust[0] = -1 // Placeholder value; replace with correct G.726 40 kbps values
  indexAdjust[1] = 2 // Placeholder value; replace with correct G.726 40 kbps values
  // Fill in all indexAdjust values as per G.726 specifications for 40 kbps

  const pcmSamples: number[] = []

  // Process each byte of ADPCM data (5 bits per sample for 40 kbps)
  for (let i = 0; i < adpcmData.length; i++) {
    const byte = adpcmData[i]
    for (let bitPos = 0; bitPos < 8; bitPos += 5) {
      // Extract 5-bit samples from the current byte and the next if needed
      const bitsRemaining = 8 - bitPos
      let adpcmSample = (byte >> bitPos) & ((1 << bitsRemaining) - 1)
      if (bitsRemaining < 5 && i + 1 < adpcmData.length) {
        // Get the remaining bits from the next byte
        const nextByte = adpcmData[i + 1]
        const additionalBits = 5 - bitsRemaining
        const extraBits = nextByte & ((1 << additionalBits) - 1)
        adpcmSample |= extraBits << bitsRemaining
        i++ // Increment to account for taking bits from the next byte
      }

      // Decode ADPCM sample to PCM sample
      const step = stepSizes[index]
      let difference = 0

      // Determine the difference using the lower bits of the sample
      if (adpcmSample & 16) difference += step
      if (adpcmSample & 8) difference += step >> 1
      if (adpcmSample & 4) difference += step >> 2
      if (adpcmSample & 2) difference += step >> 3
      if (adpcmSample & 1) difference += step >> 4
      difference += step >> 5

      // Adjust the sample based on the sign bit
      if (adpcmSample & 16) {
        predictedSample -= difference
      } else {
        predictedSample += difference
      }

      // Clamp the predicted sample within 16-bit PCM range
      if (predictedSample > 32767) predictedSample = 32767
      else if (predictedSample < -32768) predictedSample = -32768

      // Adjust the index for the next step size
      index += indexAdjust[adpcmSample]
      if (index < 0) index = 0
      else if (index > stepSizes.length - 1) index = stepSizes.length - 1

      // Save the decoded sample
      pcmSamples.push(predictedSample)
    }
  }

  // Convert to Float32 for audio processing
  const float32Samples = new Float32Array(pcmSamples.length)
  for (let i = 0; i < pcmSamples.length; i++) {
    float32Samples[i] = pcmSamples[i] / 32768
  }
  return float32Samples
}

import type { Access } from '../PermissionManager/interface'

export enum VirtualPermissions {
  WorkspaceOverview,
  PeopleAndPermissions,
  BillingAndInvoicing,
  LiveStream,
  DeviceSetup,
  ClipsHistoricalFootage,
  CustomerAnalyticsAndAIHeatmaps
}

export enum PermissionGroups {
  workspacePermissions,
  monitoringPermissions
}

export interface VirtualAuthorities {
  title: VirtualPermissions
  access: Access
}

export interface VirtualGroupedAuthorities {
  title: string
  authorities: VirtualAuthorities[]
}

export interface VirtualTeam {
  name: string
  description: string
  authorities: VirtualGroupedAuthorities[]
}

import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'

export class TimelineAnalytic {
  constructor(public readonly timelineCore: TimelineCore) {}

  push() {
    this.timelineCore.resetRenderCache()
  }

  getOverlappingPeriods(startTime: number, endTime: number): Array<[number, number]> {
    const analytic = this.timelineCore.core.analyticManager2.getActiveAnalytic()
    if (analytic) {
      return analytic.get(startTime, endTime)
    }
    return []
  }

  /*optimizeLineForDrawing(
    points: Array<[number, number]>,
    minValue: number
  ): Array<[number, number]> {
    if (points.length <= 1) return points
    const mergedLines: Array<[number, number]> = []
    let [currentStart, currentEnd] = points[0]
    for (let i = 1; i < points.length; i++) {
      const point = points[i]
      const [pointStart, pointEnd] = point

      if (pointStart - currentEnd <= 1) {
        currentEnd = pointEnd
      } else {
        mergedLines.push([currentStart, currentEnd])
        ;[currentStart, currentEnd] = point
      }
    }
    mergedLines.push([currentStart, currentEnd])
    if (mergedLines[0]) {
      const x = this.timelineCore.findRelativeXForDate(minValue)
      mergedLines[0][1] = Math.max(mergedLines[0][1], x)
    }
    return mergedLines
  }*/

  render(records: Array<[number, number, number, number]>, now = 0) {
    if (this.timelineCore.core.analyticConfig.enabled) {
      let isThereAnyActiveEvent = false
      for (const [start, end, dateStart, dateEnd] of records) {
        const isActive = start < now && now < end
        if (isActive) {
          this.timelineCore.core.analyticHelper.activePeriod.value = [dateStart, dateEnd]
          isThereAnyActiveEvent = true
        }
        this.timelineCore.drawer.drawLine(
          start,
          this.timelineCore.renderPositionsConstant.horizontalLine + 20,
          {
            ...this.timelineCore.renderConstant.horizontalLine,
            color: {
              fill: isActive ? '#FFFFFF' : 'rgba(255,255,255,0.45)'
            },
            height: 18,
            width: end - start
          }
        )
      }
      if (!isThereAnyActiveEvent) {
        this.timelineCore.core.analyticHelper.activePeriod.value = undefined
      }
    }
  }

  onHover(x: number, y: number, haveBookmark = false) {}
}

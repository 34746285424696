import { TooManyRequests } from '../http-errors/too-many-requests'

export class TooManyLoginAttempts extends TooManyRequests {
  constructor(filed: string, value: string, retrySecs: number) {
    super(retrySecs)
    this.payload = {
      message: {
        [filed]: {
          value,
          retrySecs
        }
      }
    }
  }
}

export default {
  security: {
    mySessions: 'My Sessions',
    labels: {
      forgetDevice: 'Forget Device',
      reportProblem: 'Help'
    },
    rules: {
      title: 'Log In Rules',
      password: 'Password',
      sms: 'SMS Verification',
      multiFactor: 'Authenticator App',
      otp: 'SMS Verification',
      tOtp: 'Authenticator App'
    },
    sessions: {
      noSessions: 'No old sessions found.'
    },
    password: {
      serverError: 'Oops! Something went wrong.'
    },
    security: 'Security',
    errors: {
      atLeastOne:
        'hey! You need at least one authentication method enabled to log in. Set up a new one before turning off the old!'
    }
  },
  twoFA: {
    fAAuth: 'Two-Factor Authentication ',
    byApp: 'By Authenticator App',
    byText: 'By Text Message',
    byAppDesc: 'Install any Authenticator App on your mobile device. We recommend: ',
    byTextDesc: 'Receive a one-time code via SMS to ensure your security. ',
    authy: 'Authy',
    authyDes: 'from Authy inc.',
    google: 'Google',
    googleDesc: 'from Google inc.',
    duo: 'Duo Mobile',
    duoDesc: 'from Duo Security ',
    microsoft: 'Microsoft ',
    microsoftDesc: 'from Microsoft Corporation',
    QR: 'Scan the QR Code',
    enterDigit: 'Enter the 6-digit code *',
    sendToPhone: 'Your phone is buzzing with a new SMS from us – go see what the fuss is about!',
    messages: {
      wrongToken: 'Please enter a valid code.'
    }
  }
}

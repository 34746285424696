import type { FindResourceData, MapResource } from './map-resource.interfaces'
import { Service } from '../../service'
export class MapResourceService extends Service {
  public getRecource(id: string) {
    return this.get<MapResource>(`/api/map-manager/resource/${id}`)
  }
  public find(data: FindResourceData) {
    return this.post<MapResource, FindResourceData>(`/api/map-manager/resource/find`, data)
  }
}

import type { CameraCoreComposite } from '@/modules/camera-new/interface'
import { CameraCore } from '@/modules/camera-new/CameraCore'
import type { Camera as CameraObject } from '@/lib/api'

export class CameraGroup implements CameraCoreComposite {
  public readonly groupId!: string
  public readonly id!: string
  constructor(public readonly core: CameraCore, cameraObject: CameraObject) {
    this.id = this.core.id
    this.groupId = cameraObject.groupId
  }
}

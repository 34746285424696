import type {
  ResetPasswordRequestData,
  ResetPasswordVerifyOtp,
  ResetPasswordData,
  ResponseResetPasswordRequest
} from './reset-password.interface'
import { Service } from '../../service'
export class ResetPasswordService extends Service {
  // ***** RESET PASSWORD APIS  *****

  public resetPasswordRequest(data: ResetPasswordRequestData) {
    return this.post<ResponseResetPasswordRequest, ResetPasswordRequestData>(
      '/api/u-crm/reset-password/request',
      data
    )
  }
  public resetPasswordVerify(data: ResetPasswordVerifyOtp) {
    return this.post<void, ResetPasswordVerifyOtp>('/api/u-crm/reset-password/verify', data)
  }

  public resetPassword(data: ResetPasswordData) {
    return this.post<void, ResetPasswordData>('/api/u-crm/reset-password/verify-final', data)
  }
}

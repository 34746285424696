export class MaskLocal {
  protected ctx?: CanvasRenderingContext2D
  public height = 0
  public width = 0
  protected maskData?: Array<Array<number>>
  protected convertedPath?: Path2D

  protected makeConvertedPath() {
    if (this.maskData && this.maskData.length > 2) {
      const points = this.maskData
      const path = new Path2D()
      path.moveTo(points[0][0] * this.width, points[0][1] * this.height)
      for (let i = 1; i < points.length; i++) {
        path.lineTo(points[i][0] * this.width, points[i][1] * this.height)
      }
      path.closePath()
      this.convertedPath = path
    }
  }

  setCtx(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx
  }

  setMaskData(data: Array<Array<number>>) {
    this.maskData = data
    this.makeConvertedPath()
  }

  removeMask() {
    this.maskData = undefined
    this.convertedPath = undefined
  }

  setRenderSize(width: number, height: number) {
    if (this.width !== width || this.height !== height) {
      this.width = width
      this.height = height
      this.makeConvertedPath()
    }
  }

  draw() {
    if (this.convertedPath && this.ctx) {
      this.ctx.fillStyle = 'black'
      this.ctx.fill(this.convertedPath)
    }
  }
}

import * as Comlink from 'comlink'
import type {
  HeaderObject,
  PlayerWorkerCore,
  PlayerWorkerCoreConstructor
} from '@/player/interfaces'
import type { YUVBuffer } from 'yuv-buffer'
import { worker_url } from '@/player/types'

interface JobWrapper {
  resolve: (image: YUVBuffer[]) => unknown
  reject: (e: any) => unknown
  frame: HeaderObject
}

export class DecoderQueue {
  protected workerHandler = new Worker(worker_url)
  protected worker!: Comlink.Remote<PlayerWorkerCore>
  protected queue: Array<JobWrapper> = []

  protected async loop() {
    let job = this.queue.shift()
    while (job) {
      try {
        const images = await this.worker.directDecode(job.frame)
        job.resolve([images])
      } catch (e) {
        job.reject(e)
      }
      job = this.queue.shift()
    }
  }

  async init() {
    const proxy = Comlink.wrap<PlayerWorkerCoreConstructor>(this.workerHandler)
    this.worker = await new proxy()
    this.worker.setRenderSize(-1, -1)
  }

  decode(frame: HeaderObject): Promise<YUVBuffer[]> {
    return new Promise((resolve, reject) => {
      this.queue.push({ frame, resolve, reject })
      if (this.queue.length === 1) {
        this.loop()
      }
    })
  }

  destroy() {
    this.workerHandler.terminate()
  }
}

const StaticDecoder = new DecoderQueue()
StaticDecoder.init()

export function getDirectDecoder() {
  return StaticDecoder
}

import { CameraStatus, FunctionMode, StreamQuality } from '@/player/types'
import { PlayerCore } from '@/player/lib/player/player-core'
import { SizeHelper } from '@/player/lib/helpers/size-helper'
import { InitializeHelper } from '@/player/lib/helpers/initialize-helper'
import { STREAM_ADDRESS } from '@/lib/host'
import { HeatMapPeriodType } from '@/lib/api'
import { HeatMapPeriodType as HeatMapPeriodTypeNumber } from '@/player/types'
import { KeyboardShortcutsHelper } from '@/player/lib/helpers/keyboard-shortcuts-helper'

export const STREAM_PROXY_ADDRESS = STREAM_ADDRESS

export class PlayerHelpers {
  public sizeHelper: SizeHelper
  public initializer: InitializeHelper
  public keyboardShortcuts: KeyboardShortcutsHelper

  constructor(protected readonly core: PlayerCore) {
    this.sizeHelper = new SizeHelper(core)
    this.initializer = new InitializeHelper(core)
    this.keyboardShortcuts = new KeyboardShortcutsHelper(core)
  }

  public calculateStatus() {
    const status = this.core.detailedStatus.value
    if (status) {
      let result = CameraStatus.unknown
      if (!status.enabled) {
        result = CameraStatus.offline
      } else if (status.recording) {
        result = CameraStatus.healthyRecording
      } else if (status.connected) {
        result = CameraStatus.healthyLive
      } else if (status.connecting) {
        result = CameraStatus.connecting
      } else if (
        status.analyticStreamError ||
        status.recordStreamError ||
        status.liveHighResStreamError ||
        status.livePoorStreamError ||
        status.liveLowResStreamError ||
        status.recordFailed
      ) {
        result = CameraStatus.error
      } else if (status.standBy) {
        result = CameraStatus.unknown
      }
      this.core.status.value = result
    }
  }

  public setAspectRatio(imageWidth: number, imageHeight: number) {
    return this.sizeHelper.setAspectRatio(imageWidth, imageHeight)
  }

  public setRenderSizes() {
    return this.sizeHelper.setRenderSizes()
  }

  setArchiveMap() {
    if (this.core.archiveMap.value && this.core.timeline) {
      this.core.timeline.archiveMaps.push(this.core.archiveMap.value)
    }
    if (this.core.downMap.value && this.core.timeline) {
      this.core.timeline.noRecordMaps.clear()
      this.core.timeline.noRecordMaps.push(this.core.downMap.value)
    }
  }

  async setHeatMap() {
    if (this.core.archiveMap.value && this.core.heatmapTimeline) {
      const data = await Promise.all([
        this.core.apis.getHeatmaps(HeatMapPeriodType.shortTerm),
        this.core.apis.getHeatmaps(HeatMapPeriodType.midTerm),
        this.core.apis.getHeatmaps(HeatMapPeriodType.longTerm)
      ])
      this.core.heatmapTimeline.images.push(
        HeatMapPeriodTypeNumber.short,
        data[0].map((r) => ({
          type: HeatMapPeriodTypeNumber.short,
          period: [Number(r.periodStart), Number(r.periodEnd)],
          image: r.id
        }))
      )
      this.core.heatmapTimeline.images.push(
        HeatMapPeriodTypeNumber.medium,
        data[1].map((r) => ({
          type: HeatMapPeriodTypeNumber.medium,
          period: [Number(r.periodStart), Number(r.periodEnd)],
          image: r.id
        }))
      )
      this.core.heatmapTimeline.images.push(
        HeatMapPeriodTypeNumber.long,
        data[2].map((r) => ({
          type: HeatMapPeriodTypeNumber.long,
          period: [Number(r.periodStart), Number(r.periodEnd)],
          image: r.id
        }))
      )
    }
  }

  setBookmark() {
    if (this.core.bookmarks.value && this.core.timeline) {
      this.core.timeline.bookmarks.clear()
      this.core.timeline.bookmarks.push(this.core.bookmarks.value)
    }
  }

  goLive() {
    switch (this.core.streams.quality) {
      case StreamQuality.low:
        this.core.capture.live(this.core.information.streamInformation.poorIndex)
        break
      case StreamQuality.medium:
        this.core.capture.live(this.core.information.streamInformation.lowResIndex)
        break
      case StreamQuality.highDefinition:
        this.core.capture.live(this.core.information.streamInformation.highResIndex)
        break
    }
    this.core.mode.value = FunctionMode.liveMode
    this.core.playback.isLive = true
    this.core.decoderQueue.enable()
  }
}

import type {
  Camera,
  CameraArchiveMapData,
  CameraConnectionInformationData,
  CameraMarker,
  CameraRecordModeData,
  CameraReturnType,
  CameraStatusData,
  CameraUser,
  CreateCameraData,
  CrossLineConfig,
  DowntimeMapEntity,
  Manufactures,
  Schedule,
  UpdateCameraData,
  UpdateScheduleData,
  VideoConfig
} from './camera-interfaces'
import { Service } from '../../service'

export class CameraService extends Service {
  // ***** LAYOUT APIS  *****
  public create(data: CreateCameraData) {
    data.config.port = Number(data.config.port)
    data.config.streamPort = Number(data.config.streamPort)
    data.config.securePort = Number(data.config.securePort)
    data.config.extraPorts = data.config.extraPorts?.map((p) => Number(p))
    return this.post<Camera, CreateCameraData>(`/api/camera-manager/camera`, data)
  }

  public updateConnectionInfo(id: string, data: CameraConnectionInformationData) {
    return this.patch<CameraReturnType, CameraConnectionInformationData>(
      `/api/camera-manager/camera/${id}/connection`,
      data
    )
  }

  public connectionInfo(id: string) {
    return this.get<CameraConnectionInformationData>(`/api/camera-manager/camera/${id}/connection`)
  }

  public ptzSupport(id: string) {
    return this.get<string>(`/api/camera-manager/camera/${id}/ptz-enabled`).then(
      (r) => r === 'true'
    )
  }

  public updateSchedule(id: string, data: UpdateScheduleData) {
    return this.patch<Schedule, UpdateScheduleData>(
      `/api/camera-manager/camera/${id}/schedule`,
      data
    )
  }

  public schedule(id: string) {
    return this.get<Schedule>(`/api/camera-manager/camera/${id}/schedule`)
  }

  public updateRecordMode(id: string, data: CameraRecordModeData) {
    return this.patch<CameraReturnType, CameraRecordModeData>(
      `/api/camera-manager/camera/${id}/record`,
      data
    )
  }

  public archiveMap(id: string) {
    return this.get<CameraArchiveMapData>(`/api/camera-manager/camera/${id}/archive-map`)
  }

  public findAll() {
    return this.get<Camera[]>(`/api/camera-manager/camera/details`)
  }

  public find(id: string) {
    return this.get<Camera>(`/api/camera-manager/camera/${id}/details`)
  }

  public findAllCameraUsers() {
    return this.get<CameraUser[]>(`/api/camera-manager/camera/details/users`)
  }
  public findCameraUsers(id: string) {
    return this.get<CameraUser>(`/api/camera-manager/camera/${id}/details/users`)
  }

  public findAllCameraMarkers() {
    return this.get<CameraMarker[]>(`/api/camera-manager/camera/details/markers`)
  }

  public findCameraMarkers(id: string) {
    return this.get<CameraMarker>(`/api/camera-manager/camera/${id}/details/markers`)
  }

  public cameraStatus(id: string) {
    return this.get<CameraStatusData>(`/api/camera-manager/camera/${id}/status`)
  }

  public statusAllCamera() {
    return this.get<CameraStatusData[]>(`/api/camera-manager/camera/status`)
  }

  public statusCameraAdvance(id: string) {
    return this.get<CameraStatusData>(`/api/camera-manager/camera/${id}/status/advance`)
  }

  public update(id: string, data: UpdateCameraData) {
    return this.patch<Camera, UpdateCameraData>(`/api/camera-manager/camera/${id}`, data)
  }

  public remove(id: string) {
    return this.delete<void>(`/api/camera-manager/camera/${id}`)
  }

  public getManufactures() {
    return this.get<Manufactures[]>(`/api/camera-manager/camera/manufacturers`)
  }

  public getManufacturesDetail() {
    return this.get<Manufactures[]>(`/api/camera-manager/camera/manufactures/detailed`)
  }

  public requestStream(id: string) {
    return this.post<string>(`/api/camera-manager/camera/${id}/stream`)
  }

  public getCrossLineDetail(id: string) {
    return this.get<CrossLineConfig>(`/api/camera-manager/camera/${id}/analytic/cross-line`)
  }

  public getCameraThumbnail(id: string, streamIndex = 0) {
    return this.adaptor
      .get(`/api/camera-manager/camera/${id}/stream/thumbnail?streamId=${streamIndex}`, {
        responseType: 'arraybuffer'
      })
      .then((r) => ({
        data: this.processResult<ArrayBuffer>(r),
        type: r.headers['content-type']
      }))
      .catch<never>(this.processError.bind(this))
  }

  public getCameraThumbnailArchive(id: string, date: number) {
    return this.adaptor
      .get(`/api/camera-manager/camera/${id}/archive/thumbnail?dateTime=${date}`, {
        responseType: 'arraybuffer'
      })
      .then((r) => ({
        data: this.processResult<ArrayBuffer>(r),
        type: r.headers['content-type'],
        actualDate: r.headers['Date-Time']
      }))
      .catch<never>(this.processError.bind(this))
  }

  public getVideoConfig(id: string, streamId: string) {
    return this.get<VideoConfig>(`/api/camera-manager/camera/${id}/video-config/${streamId}`)
  }

  public updateVideoConfig(id: string, streamId: string, data: VideoConfig) {
    return this.patch<VideoConfig>(
      `/api/camera-manager/camera/${id}/video-config/${streamId}`,
      data
    )
  }

  public retry(id: string) {
    return this.post<Camera>(`/api/camera-manager/camera/${id}/retry`)
  }

  // *************Avatar******
  public avatarUpdate(cameraId: string, data: FormData) {
    return this.patch(`/api/camera-manager/avatar/${cameraId}`, data)
  }

  public avatarRemove(cameraId: string) {
    return this.delete(`/api/camera-manager/avatar/${cameraId}`)
  }

  public avatarGet(cameraId: string) {
    return this.adaptor.get(`/api/camera-manager/avatar/${cameraId}`, {
      responseType: 'blob'
    })
  }

  public downtimeMap(id: string) {
    return this.get<DowntimeMapEntity[]>(`/api/camera-manager/camera/${id}/down-map`)
  }

  setMask(id: string, data: Array<Array<number>>) {
    return this.patch<Camera>(`/api/camera-manager/camera/${id}/mask`, {
      data
    })
  }

  removeMask(id: string) {
    return this.delete<Camera>(`/api/camera-manager/camera/${id}/mask`)
  }
}

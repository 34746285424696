import type { Camera as CameraObject, CameraConnectionInformationData } from '@/lib/api'
import { CameraService } from '@/lib/api'
import { CameraCore } from '@/modules/camera-new/CameraCore'
import type {
  CameraConnectionInformationObject,
  CameraCoreComposite
} from '@/modules/camera-new/interface'
import { SyncAbleObject } from '@/modules/camera-new/SyncAbleObject'
import { CameraConnectionInformationUpdateHelper } from '@/modules/camera-new/libs/connection-information/CameraConnectionInformationUpdateHelper'
import { useServices } from '@/lib/services'
import { CameraStatusType } from '@/modules/camera-new/types'

export class CameraConnectionInformation
  extends SyncAbleObject<CameraConnectionInformationObject>
  implements CameraCoreComposite
{
  public readonly id: string
  public readonly updater: CameraConnectionInformationUpdateHelper
  protected service: CameraService = useServices().camera

  constructor(public readonly core: CameraCore, camera: CameraObject) {
    super(CameraConnectionInformation.parseCameraObject(camera))
    this.id = camera.id
    this.updater = new CameraConnectionInformationUpdateHelper(this)
  }

  public static parseCameraObject(camera: CameraObject): CameraConnectionInformationObject {
    return {
      url: camera.connectionInformation.url,
      port: camera.connectionInformation.port || CameraConnectionInformation.getDefaultPort(),
      securePort: camera.connectionInformation.securePort,
      streamPort:
        camera.connectionInformation.streamPort || CameraConnectionInformation.getDefaultPort(),
      extraPorts: camera.connectionInformation.extraPorts,
      username: camera.connectionInformation.username || '',
      password: camera.connectionInformation.password || '',
      channel: camera.connectionInformation.channel,
      useSSL: camera.connectionInformation.useSSL,
      manufacturerCode: camera.connectionInformation.manufacturerCode,
      configured: camera.configured,
      configAttempts: camera.configAttempts
    }
  }

  // helpers methods
  public static getDefaultPort() {
    return 80
  }

  public static getDefaultSecurePort() {
    return 443
  }

  public static getDefaultStreamPort() {
    return 554
  }

  public static validateUrl(value: string): boolean {
    const ipv4Regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
    let invalidURL: boolean = false
    try {
      new URL(value)
    } catch {
      invalidURL = true
    }
    return !value || !invalidURL || ipv4Regex.test(value)
  }

  public async update(data: CameraConnectionInformationData) {
    return this.updater.update(data)
  }
  public initialCameraData(camera: CameraObject) {
    Object.assign(this.data, CameraConnectionInformation.parseCameraObject(camera))
  }
  public async retry() {
    this.core.status.cameraStatus.value = CameraStatusType.connecting
    await this.service.retry(this.id)
    await this.core.status.update(true)
  }
}

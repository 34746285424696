import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import { StripeCustomerService } from '@/lib/api/services/stripe-payment/customer'
import { StripeInvoiceService } from '@/lib/api/services/stripe-payment/invoice'
import { StripePriceService } from '@/lib/api/services/stripe-payment/price'
import { StripeProductService } from '@/lib/api/services/stripe-payment/product'
import { StripeSubscriptionService } from '@/lib/api/services/stripe-payment/subscription'
import { StripeWorkspaceService } from '@/lib/api/services/stripe-payment/workspace'
import { StripeQuotaService } from '@/lib/api/services/stripe-payment/quota'

export class StripePaymentService extends Service {
  public customer: StripeCustomerService
  public invoice: StripeInvoiceService
  public price: StripePriceService
  public product: StripeProductService
  public subscription: StripeSubscriptionService
  public workspace: StripeWorkspaceService
  public quota: StripeQuotaService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.customer = new StripeCustomerService(adaptor)
    this.invoice = new StripeInvoiceService(adaptor)
    this.price = new StripePriceService(adaptor)
    this.product = new StripeProductService(adaptor)
    this.subscription = new StripeSubscriptionService(adaptor)
    this.workspace = new StripeWorkspaceService(adaptor)
    this.quota = new StripeQuotaService(adaptor)
  }
}

export abstract class AsyncCache<KeyType = string, ValueType = string> {
  protected abstract dataCleanup(key: KeyType, value: ValueType): void

  protected abstract generatePreloadValue(key: KeyType): ValueType

  protected abstract loadData(key: KeyType): Promise<ValueType>

  protected abstract isPreloadValue(value: ValueType): boolean

  protected constructor(public readonly maxSize: number) {}

  protected data: Map<KeyType, ValueType> = new Map()
  protected callbacks: Map<KeyType, Promise<ValueType>> = new Map()
  protected stack: Array<KeyType> = []

  protected async cleanup() {
    const key = this.stack.pop()
    if (key) {
      const callback = this.callbacks.get(key)
      if (callback) {
        await callback
      }
      setTimeout(() => {
        const value = this.data.get(key)
        if (value) {
          this.dataCleanup(key, value)
          this.data.delete(key)
        }
      })
    }
  }

  async request(key: KeyType) {
    const tmp = this.data.get(key)
    if (!tmp) {
      if (this.stack.length === this.maxSize) {
        this.cleanup()
      }
      this.stack.push(key)
      this.data.set(key, this.generatePreloadValue(key))
      const promise = this.loadData(key)
      this.callbacks.set(key, promise)
      const data = await promise
      this.data.set(key, data)
      this.callbacks.delete(key)
      return data
    } else {
      if (this.isPreloadValue(tmp)) {
        const promise = this.callbacks.get(key)
        if (promise) {
          return promise
        } else throw new Error()
      } else {
        return tmp
      }
    }
  }

  get(key: KeyType) {
    const value = this.data.get(key)
    if (value) {
      if (!this.isPreloadValue(value)) {
        return value
      } else return undefined
    } else {
      this.request(key)
      return undefined
    }
  }
}

import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import { LayoutService, TourService } from '@/lib/api'
export class LayoutManagerService extends Service {
  public layout: LayoutService
  public tour: TourService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.layout = new LayoutService(adaptor)
    this.tour = new TourService(adaptor)
  }
}

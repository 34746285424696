import { BadRequestException } from '../http-errors'

export class UniquenessViolation extends BadRequestException {
  constructor(...keys: string[]) {
    const payload: Record<string, string> = {}
    keys.forEach((r) => {
      payload[r] = 'UniquenessViolation'
    })
    super(payload)
  }
}

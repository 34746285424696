import { BridgeStore } from '@/modules/bridge/store/useBridgeStore'

let bridgeStore: null | BridgeStore = null

export function useBridgeStore() {
  if (!bridgeStore) {
    bridgeStore = new BridgeStore()
    return bridgeStore
  } else return bridgeStore
}

import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import { SnapshotService } from '@/lib/api/services/export-manager/snapshot/snapshot.service'
import { ExportService } from '@/lib/api/services/export-manager/export/export.service'

export class ExportManagerService extends Service {
  public export: ExportService
  public snapshot: SnapshotService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.export = new ExportService(adaptor)
    this.snapshot = new SnapshotService(adaptor)
  }
}

import { ApiClient, ApiHelpers } from '@/lib/api'

// if you want to use MOCK DATA set 'localhost' for BASE_URL
//   otherwise set your end point for BASE_URL

const BASE_URL = import.meta.env.VITE_API_ADDRESS

interface GlobalServiceInterface {
  service: ApiClient
  helpers: ApiHelpers
}

const globalServices: Partial<GlobalServiceInterface> = {
  service: undefined,
  helpers: undefined
}

export function useGlobal(): GlobalServiceInterface {
  if (!globalServices.service) {
    // if you want to use MOCK DATA create object from 'ApiMockClient'
    // otherwise create object from 'ApiClient'
    globalServices.service = new ApiClient(BASE_URL)
  }
  if (!globalServices.helpers) {
    globalServices.helpers = new ApiHelpers(globalServices.service)
  }
  return globalServices as GlobalServiceInterface
}

export function useHelpers() {
  return useGlobal().helpers
}

export function useServices() {
  return useGlobal().service
}

import {
  VirtualPermissions,
  type VirtualAuthorities,
  type VirtualGroupedAuthorities
} from './interface'

export class AuthorityGroupHandler {
  authoritiesList: Array<VirtualAuthorities> = []
  groupedAthorities: VirtualGroupedAuthorities[] = []

  setAuthorities(authorities: Array<VirtualAuthorities>): void {
    this.authoritiesList = authorities
  }

  group(): void {
    const permissionsToCheck = [
      {
        title: 'workspacePermissions',
        authorityKeys: [
          VirtualPermissions.WorkspaceOverview,
          VirtualPermissions.PeopleAndPermissions,
          VirtualPermissions.BillingAndInvoicing
        ]
      },
      {
        title: 'monitoringPermissions',
        authorityKeys: [
          VirtualPermissions.LiveStream,
          VirtualPermissions.DeviceSetup,
          VirtualPermissions.ClipsHistoricalFootage,
          VirtualPermissions.CustomerAnalyticsAndAIHeatmaps
        ]
      }
    ]
    const virtualGroupedAuthorities: VirtualGroupedAuthorities[] = []
    permissionsToCheck.forEach(({ title, authorityKeys }) => {
      virtualGroupedAuthorities.push({
        title: title,
        authorities: this.authoritiesList.filter(({ title: authorityTitle }) => {
          return authorityKeys.includes(authorityTitle)
        })
      })
    })
    this.groupedAthorities = virtualGroupedAuthorities
  }

  getGroupedAuthorities(): VirtualGroupedAuthorities[] {
    return this.groupedAthorities
  }
}

import type { CreateMapData, MapEntity, UpdateMapData } from './map.interfaces'
import { Service } from '../../service'
export class MapService extends Service {
  public create(cameraId: string, data: CreateMapData) {
    return this.post<MapEntity, CreateMapData>(`/api/map-manager/map`, data)
  }
  public findAll() {
    return this.get<MapEntity[]>(`/api/map-manager/map`)
  }
  public find(mapId: string) {
    return this.get<MapEntity>(`/api/map-manager/map/${mapId}`)
  }
  public update(mapId: string, data: UpdateMapData) {
    return this.patch<MapEntity, UpdateMapData>(`/api/map-manager/map/${mapId}`, data)
  }
  public remove(mapId: string) {
    return this.delete<void>(`/api/map-manager/map/${mapId}`)
  }
}

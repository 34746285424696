import { Buffer } from 'buffer'
// Precompute the full conversion table for A-law bytes to linear samples
const fullConversionTable = new Int16Array(256)
const SIGN_BIT = 0x80
const QUANT_MASK = 0x0f
const SEG_SHIFT = 4
const SEG_MASK = 0x70

for (let byte = 0; byte < 256; byte++) {
  const alaw = ~byte
  const sign = alaw & SIGN_BIT
  const segment = (alaw & SEG_MASK) >> SEG_SHIFT
  const mantissa = (alaw & QUANT_MASK) + 16

  let sample = (mantissa << segment) - 1
  if (segment >= 1) sample += 1 << (segment - 1)
  if (sign !== 0) sample = -sample

  fullConversionTable[byte] = sample
}
export function decodePcmALaw(g711AData: Buffer): Float32Array {
  if (!g711AData) {
    throw new Error('Invalid input: Expected a Buffer instance.')
  }

  // Convert A-law data to linear samples using the precomputed table
  const samples = new Int16Array(g711AData.length)
  for (let i = 0; i < g711AData.length; i++) {
    samples[i] = fullConversionTable[g711AData[i]]
  }

  // Normalize the samples to Float32Array
  const float32Array = new Float32Array(samples.length)
  const normalizationFactor = 1 / 32768
  for (let i = 0; i < samples.length; i++) {
    float32Array[i] = samples[i] * normalizationFactor
  }
  return float32Array
}

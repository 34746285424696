//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
import NumberFormat = Intl.NumberFormat
import type { Options } from '@/utils/normalize/numbers/interface'

const defaultOptions: Options = {
  notation: 'standard'
}
const defaultLocale = 'en-US'
export default class NormalizeNumber {
  static format(number: number, locale = defaultLocale, options = defaultOptions): string | number {
    if (options.currency) {
      if (locale === 'fa-IR') {
        const [amount, curren] = NumberFormat(locale, options)
          .format(number)
          .split(/(\s+)/)
          .filter(function (str) {
            return /\S/.test(str)
          })
        return curren.split('.')[0] + ' ' + amount
      } else {
        const [curren] = NumberFormat(locale, options)
          .format(number)
          .split(/(\s+)/)
          .filter(function (str) {
            return /\S/.test(str)
          })
        return curren
      }
    }
    return NumberFormat(locale, options).format(number)
  }
}

import { TimelineBasic } from '@/player/lib/helpers/timeline-basic'
import { PlayerCore } from '@/player/lib/player/player-core'
import type {
  ArchiveMap,
  BookmarkWithX,
  DrawConfigTimeline,
  DrawConfigTimelinePositions
} from '@/player/interfaces'
import { TimelineDrawerHelpers } from '@/player/lib/playback-timeline/timeline-drawer-helpers'
import { TimelineRecordInfoManager } from '@/player/lib/playback-timeline/timeline-record-info-manager'
import { TimelineHelpers } from '@/player/lib/playback-timeline/timeline-helpers'
import { TimelineBookmarkManager } from '@/player/lib/playback-timeline/timeline-bookmark-manager'
import { TimelinePeriodSelectorManager } from '@/player/lib/playback-timeline/timeline-period-selector-manager'
import {
  timelinePositionConst,
  timelineRenderConst
} from '@/player/lib/playback-timeline/timeline-consts'
import { FunctionMode, PeriodType } from '@/player/types'
import { computed } from 'vue'
import { TimelineNoRecordInfoManager } from '@/player/lib/playback-timeline/timeline-no-record-info-manager'
import { WindowsDate } from '@/player/lib/helpers/date-time'
import { TimelineAnalytic } from '@/player/lib/playback-timeline/timeline-analytic'
import type { DowntimeMapPLayerEntity } from '@/player/interfaces'
import { TimelineTampering } from '@/player/lib/playback-timeline/timeline-tampering'

export class TimelineCore extends TimelineBasic {
  public drawer!: TimelineDrawerHelpers

  public archiveMaps: TimelineRecordInfoManager
  public analytics: TimelineAnalytic
  public noRecordMaps: TimelineNoRecordInfoManager
  public bookmarks: TimelineBookmarkManager
  public selector: TimelinePeriodSelectorManager
  public tampering: TimelineTampering

  public seekMode = computed(() => this.config.speed === 0)

  protected minSeekValue = 0
  protected minSeekValueUpdater!: ReturnType<typeof setInterval>

  get minSeek() {
    return this.minSeekValue
  }

  constructor(core: PlayerCore, ctx?: CanvasRenderingContext2D) {
    super(core, ctx)
    if (ctx) {
      this.drawer = new TimelineDrawerHelpers(ctx)
    }
    this.selector = new TimelinePeriodSelectorManager(this)
    this.bookmarks = new TimelineBookmarkManager(this)
    this.archiveMaps = new TimelineRecordInfoManager(this)
    this.noRecordMaps = new TimelineNoRecordInfoManager(this)
    this.analytics = new TimelineAnalytic(this)
    this.tampering = new TimelineTampering(this)
  }

  public setCtx(ctx: CanvasRenderingContext2D) {
    this.drawer = new TimelineDrawerHelpers(ctx)
    super.setCtx(ctx)
  }

  public get config() {
    return this.core.playback
  }

  public get renderConstant(): DrawConfigTimeline {
    return timelineRenderConst[this.core.size.type][this.config.timelineSize]
  }

  public get renderPositionsConstant(): DrawConfigTimelinePositions {
    return timelinePositionConst[this.core.size.type]
  }

  protected calculateWindowSize() {
    this.view.size = Math.round(
      (this.renderWidth / this.renderConstant.periodSize) * this.config.timelineSize
    )
  }

  public generateCacheValue(): string {
    if (this.dragHelper.context === '') {
      return `${this.core.playback.lastFrameDate}:${this.config.timelineSize}:${this.renderWidth}`
    } else return Math.random().toString()
  }

  protected getRecords(start: number, end: number) {
    const records = this.archiveMaps.getOverlappingPeriods(start, end)
    if (records[0] && this.minSeekValue) {
      if (Math.abs(records[0][0] - this.minSeekValue) < 6 * 60 * 1000) {
        records[0][0] = this.minSeekValue
      }
    }
    const recordsX: ArchiveMap = records.map(([start, end, isLive]) => {
      end = isLive ? Date.now() : end
      return [this.findRelativeXForDate(start), this.findRelativeXForDate(end), isLive]
    })
    return this.archiveMaps.optimizeLineForDrawing(recordsX, start)
  }

  protected getEvents(start: number, end: number) {
    const records = this.analytics.getOverlappingPeriods(start, end)
    const recordsX: Array<[number, number, number, number]> = records.map(([start, end]) => [
      this.findRelativeXForDate(start),
      this.findRelativeXForDate(end),
      start,
      end
    ])
    return recordsX
  }

  protected getTamperingEvents(start: number, end: number) {
    const records = this.tampering.getOverlappingPeriods(start, end)
    const recordsX: Array<[number, number]> = records.map(([start, end]) => [
      this.findRelativeXForDate(start),
      this.findRelativeXForDate(end)
    ])
    return recordsX
  }

  protected getNoRecords(start: number, end: number) {
    const records = this.noRecordMaps.getOverlappingPeriods(start, end)
    const recordsX: DowntimeMapPLayerEntity[] = records.map((entity) => ({
      ...entity,
      startAt: this.findRelativeXForDate(entity.startAt),
      endAt: this.findRelativeXForDate(entity.finished ? entity.endAt : Date.now())
    }))
    return this.noRecordMaps.filterForDrawing(recordsX, start)
  }

  protected getBookmarks(start: number, end: number) {
    const bookmarks = this.bookmarks.getOverlappingPeriods(start, end)
    const bookmarksX: BookmarkWithX[] = bookmarks.map((bookmark) => ({
      ...bookmark,
      periodX: [
        this.findRelativeXForDate(bookmark.period[0]),
        this.findRelativeXForDate(bookmark.period[1])
      ]
    }))
    return this.bookmarks.filterForDrawing(bookmarksX, start)
  }

  protected renderStartAt(start: number, end: number) {
    if (start <= this.minSeekValue && this.minSeekValue <= end) {
      const x = this.findRelativeXForDate(this.minSeekValue)
      this.drawer.drawLine(x, this.renderPositionsConstant.periodLine, {
        ...this.renderConstant.periodLine,
        height: this.renderConstant.periodLine.height * 1.5,
        color: this.renderConstant.horizontalLine.color
      })
    }
  }

  protected renderHintLines(startDate: number, endDate: number) {
    const diff = this.config.timelineSize
    const simiDiff = diff / this.renderConstant.simiPeriodCount
    for (let xDate = startDate; xDate <= endDate; xDate = WindowsDate.addDate(xDate, diff)) {
      const x = this.findRelativeXForDate(xDate)
      this.drawer.drawDateLabel(
        xDate,
        x,
        this.renderPositionsConstant.label +
          this.renderConstant.dateLabel.font.lineHeight +
          this.renderConstant.dateLabel.paddingY * 2,
        this.renderConstant.dateLabel,
        this.config.timelineSize
      )
      this.drawer.drawText(
        x,
        this.renderPositionsConstant.font,
        TimelineHelpers.getTextOfDate(xDate, this.config.timelineSize),
        this.renderConstant.font
      )
      this.drawer.drawLine(
        x,
        this.renderPositionsConstant.periodLine,
        this.renderConstant.periodLine
      )
      for (let x2Date = xDate; x2Date < xDate + diff; x2Date += simiDiff) {
        const x2 = this.findRelativeXForDate(x2Date)
        this.drawer.drawLine(
          x2,
          this.renderPositionsConstant.simiPeriodLine,
          this.renderConstant.simiPeriodLine
        )
      }
    }
  }

  protected onDrag(dx: number, dy: number, x: number, y: number, context: string): string {
    const ref = context as 'selector-start' | 'selector-end' | 'timeline'
    if (ref === 'timeline') {
      const newRef = this.selector.onDrag(dx, dy, x, y)
      if (newRef === '') {
        const diffX = (this.view.size * dx) / this.renderWidth
        this.seek(this.view.now - diffX)
        return 'timeline'
      } else return newRef
    } else {
      this.selector.doDrag(dx, ref)
      return ref
    }
  }

  protected onHover(x: number, y: number) {
    const isBookmarkVisible = this.bookmarks.onHover(x, y)
    const isTamperingVisible = this.tampering.onHover(x, y, isBookmarkVisible)
    this.selector.onHover(x, y)
    this.archiveMaps.onHover(x, y, isBookmarkVisible || isTamperingVisible)
    this.noRecordMaps.onHover(x, y, isBookmarkVisible || isTamperingVisible)
    this.analytics.onHover(x, y, isBookmarkVisible || isTamperingVisible)
  }

  protected onClick(x: number, y: number) {
    const diffStart = (this.view.size * x) / this.renderWidth
    const date = this.view.start + diffStart
    if (this.config.isLive) {
      this.core.capture.archive(this.view.now)
      this.core.decoderQueue.disable()
    }
    setTimeout(() => {
      this.seek(date)
      setTimeout(() => {
        this.core.capture.speed(1)
      }, 50)
    }, 10)
  }

  protected onMouseOut() {
    this.core.previewConfig.enabled = false
  }

  protected render() {
    if (this.needRender()) {
      try {
        if (this.dragHelper.context === '' && !this.seekMode.value) {
          this.setNow(this.core.playback.lastFrameDate)
        }
        const startDate = TimelineHelpers.findPreviousRoundDate(
          this.view.start,
          this.config.timelineSize
        )
        const endDate = TimelineHelpers.findNextRoundDate(this.view.end, this.config.timelineSize)
        const dataStartAt = Math.max(startDate, this.minSeekValue)
        const records = this.getRecords(dataStartAt, endDate)
        const noRecords = this.getNoRecords(dataStartAt, endDate)
        const bookmarks = this.getBookmarks(dataStartAt, endDate)
        const events = this.getEvents(dataStartAt, endDate)
        const tampering = this.getTamperingEvents(dataStartAt, endDate)

        this.drawer.clear()
        this.bookmarks.render(bookmarks)
        this.renderHintLines(startDate, endDate)
        this.renderStartAt(startDate, endDate)
        this.noRecordMaps.render(noRecords)
        this.archiveMaps.render(records)
        this.analytics.render(events, this.findRelativeXForDate(this.view.now))
        this.tampering.render(tampering)
        this.drawer.drawCenterLine(
          this.renderWidth / 2,
          this.renderHeight - this.renderPositionsConstant.centerLine /*18*/,
          this.renderConstant.centerLine
        )
        this.selector.render()
      } catch (e) {
        console.error(e)
      }
    }
    if (this._enabled) {
      requestAnimationFrame(this.render.bind(this))
    }
  }

  setPeriodType(type: PeriodType) {
    this.config.timelineSize = type
    this.calculateWindowSize()
    this.setNow(this.view.now)
  }

  seek(value: number, emitSeek = true) {
    if (this.minSeekValue <= value && value <= Date.now()) {
      if (this.config.isLive) {
        this.core.capture.archive(value)
        this.core.decoderQueue.disable()
      }
      this.core.capture.softSeek(value)
      this.core.mode.value = FunctionMode.historyMode
      this.config.isLive = false
      super.seek(value, emitSeek)
    }
  }

  protected computeMinSeekValue() {
    if (this.core.information && this.core.information.recordInformation) {
      const retention = Date.now() - this.core.information.recordInformation.archiveAge * 86400000
      this.minSeekValue = Math.max(new Date(this.core.information.createdAt).getTime(), retention)
      this.tampering.cache.setMinDate(this.minSeekValue)
    }
  }

  enable() {
    super.enable()
    this.minSeekValueUpdater = setInterval(this.computeMinSeekValue.bind(this), 1000)
  }

  disable() {
    super.disable()
    clearInterval(this.minSeekValueUpdater)
  }
}

import { computed, reactive, type Ref, ref, watch } from 'vue'
import { ImagePreview } from '@/player/lib/preview/image-preview'
import type { CameraCoreComposite } from '@/modules/camera-new/interface'
import { CameraCore } from '@/modules/camera-new/CameraCore'

/**
 * Manages the loading and retrying mechanism for camera thumbnails.
 */
export class CameraThumbnail implements CameraCoreComposite {
  public thumbnail: Ref<string> = ref('')
  private imagePreview!: ImagePreview
  private attemptLoadCount = 0
  public cameraVisibility = reactive({
    cameraConnect: false,
    cameraDrawer: false
  })
  public readonly id: string

  public readonly isCameraVisible = computed(
    () => this.cameraVisibility.cameraConnect || this.cameraVisibility.cameraDrawer
  )
  protected visibilityWatcher = watch(
    this.isCameraVisible,
    this.onCameraVisibilityChanged.bind(this)
  )

  protected loadThumbnailPromise: Promise<void> | undefined

  protected onCameraVisibilityChanged() {
    if (this.isCameraVisible.value && this.thumbnail.value === '' && !this.loadThumbnailPromise) {
      this.loadThumbnailPromise = this.loadThumbnailInternal().then(
        () => (this.loadThumbnailPromise = undefined)
      )
    }
  }

  /**
   * Constructor to initialize the ThumbnailManager class with a CameraManager instance.
   * @param {CameraCore} core - An instance of CameraManager to manage camera operations.
   */
  constructor(public readonly core: CameraCore) {
    this.id = this.core.id
    this.imagePreview = new ImagePreview(this.id)
  }

  public loadThumbnail(): Promise<void> {
    if (!this.loadThumbnailPromise) {
      this.loadThumbnailPromise = this.loadThumbnailInternal().then(
        () => (this.loadThumbnailPromise = undefined)
      )
      return this.loadThumbnailPromise
    } else {
      return this.loadThumbnailPromise
    }
  }

  /**
   * Asynchronously loads the thumbnail for a camera, with retry logic for failed attempts.
   */
  protected async loadThumbnailInternal(): Promise<void> {
    if (this.isCameraVisible.value) {
      try {
        this.thumbnail.value = await this.attemptLoadThumbnail()
      } catch (e: any) {
        await this.retryLoadThumbnail()
      }
    }
  }

  /**
   * Resets the thumbnail property and load attempt counter.
   */
  public reset() {
    this.thumbnail.value = ''
    this.attemptLoadCount = 0
  }

  /**
   * Protected method to attempt loading the live thumbnail.
   * @returns {Promise<string>} A promise that resolves with the thumbnail image URL.
   */
  protected async attemptLoadThumbnail(): Promise<string> {
    this.imagePreview.maskData = this.core.base.data.maskData
    return this.imagePreview.live('image/png', this.core.streams.data.lowResIndex || 0)
  }

  /**
   * Loads a thumbnail from the archive for a specific date.
   * @param {Date} date - The date for which to load the archived thumbnail.
   * @returns {Promise<string>} A promise that resolves with the archived thumbnail URL.
   */
  public async loadArchiveThumbnail(date: Date): Promise<string> {
    return this.imagePreview.archive(date)
  }

  /**
   * Retries loading the thumbnail with exponential backoff strategy.
   */
  protected async retryLoadThumbnail(): Promise<void> {
    // Limit the retry attempts to 3.
    if (this.attemptLoadCount < 2) {
      await this.delay(5000)
      this.attemptLoadCount++
      await this.loadThumbnailInternal()
    }
  }

  /**
   * Creates a delay.
   * @param {number} ms - The delay in milliseconds.
   * @returns {Promise<void>} A promise that resolves after the specified delay.
   */
  protected delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}

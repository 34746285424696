import * as comlink from 'comlink'
import type { HeatMapFactoryConstructor } from '@/player/interfaces'
import { HeatMapRenderFactory } from '@/player/lib/heatmap/heatmap-render-factory'

export class HeatmapWorker {
  protected worker = new Worker('/workers/heatmap_render.js')
  protected FactoryProxy: ReturnType<typeof comlink.wrap<HeatMapFactoryConstructor>>
  factory: comlink.Remote<HeatMapRenderFactory> | undefined = undefined
  protected canvas = document.createElement('canvas').transferControlToOffscreen()

  constructor() {
    this.FactoryProxy = comlink.wrap<HeatMapFactoryConstructor>(this.worker)
    this.init()
  }

  protected async init() {
    this.factory = await new this.FactoryProxy()
    this.factory?.setRenderCanvas(comlink.transfer(this.canvas, [this.canvas]))
  }
}

const worker = new HeatmapWorker()

export function getHeatMapWorker() {
  return worker
}

import { ComponentPermissionsManager } from './ComponentPermissionsManager'

let componentPermissionsManager: null | ComponentPermissionsManager = null

export function useComponentPermissionsManager() {
  if (!componentPermissionsManager) {
    componentPermissionsManager = new ComponentPermissionsManager()
    return componentPermissionsManager
  } else return componentPermissionsManager
}

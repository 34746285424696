import {
  ActionTypeMismatch,
  AgentNotAccessible,
  AgentRejection,
  BadRequestException,
  CantAssignRole,
  CustomerExists,
  DriverNotFound,
  FileMaxSizeExceeded,
  IncompatiblePayloadOrReceptor,
  InvalidCoupon,
  InvalidFileExtension,
  InvoiceHasError,
  LiveRequestPending,
  LockTeamBadResource,
  LockTeamNoUser,
  LockTeamUpdate,
  MockingPayloadError,
  PasswordNotStrongEnough,
  PayloadInitializationError,
  PayloadValidationError,
  PlanNotSelectable,
  ReceptorNotInitialized,
  ReceptorValidationError,
  RequiredFieldNotFound,
  ResourceDoesNotExist,
  SubscriptionProblem,
  ThreadAssignationError,
  TimeNotSync,
  TypeNotSupported,
  UniquenessViolation,
  UserIsNotCustomer,
  UsernameOrPasswordNotMatch
} from '../../service-errors'
interface ErrorMap {
  [message: string]: new (...args: any[]) => any
}
import type { ServerErrorPayload } from '../universal.parser'
export class BadRequestParser {
  private errorMap: ErrorMap = {
    'resource does not exist': ResourceDoesNotExist,
    'Driver not found!': DriverNotFound,
    'incompatible payload or receptor': IncompatiblePayloadOrReceptor,
    'error in mocking payload': MockingPayloadError,
    'payload not initialized': PayloadInitializationError,
    'payload validation:': PayloadValidationError,
    'invoice has error': InvoiceHasError,
    'receptor not initialized': ReceptorNotInitialized,
    'error in email receptor validation': ReceptorValidationError,
    'type not supported': TypeNotSupported,
    'customer already exists': CustomerExists,
    'invalid coupon:': InvalidCoupon,
    'subscription problem': SubscriptionProblem,
    'plan not selectable': PlanNotSelectable,
    'file max size exceeded': FileMaxSizeExceeded,
    'invalid file extension': InvalidFileExtension,
    'live request pending': LiveRequestPending,
    "can't assign thread": ThreadAssignationError,
    "can't assign role": CantAssignRole,
    'action type mismatch': ActionTypeMismatch,
    'agent not accessible': AgentNotAccessible,
    'rejection from bridge agent': AgentRejection,
    LockTeamBadResource: LockTeamBadResource,
    LockTeamNoUser: LockTeamNoUser,
    LockTeamAuthorityUpdate: LockTeamUpdate
  }

  parseBadRequest(payload: ServerErrorPayload) {
    if (typeof payload === 'string') {
      for (const [message, ErrorClass] of Object.entries(this.errorMap)) {
        if (payload.indexOf(message) !== -1) {
          return new ErrorClass()
        }
      }
    }
    if (typeof payload === 'object') {
      const payloadData = payload.message
      const errors = new Set(Object.values(payloadData))
      if (errors.has('UniquenessViolation')) {
        return new UniquenessViolation(...Object.keys(payloadData))
      }
      if (errors.has('IsDefined')) {
        return new RequiredFieldNotFound(...Object.keys(payloadData))
      }
      if (errors.has('PasswordNotStrongEnough')) {
        return new PasswordNotStrongEnough()
      }
      if (errors.has('UserIsNotCustomer')) {
        return new UserIsNotCustomer()
      }
      if (errors.has('UsernameOrPasswordNotMatch')) {
        return new UsernameOrPasswordNotMatch()
      }
      if (errors.has('time not sync')) {
        // Is payload object is usefully for timeNotSync?
        return new TimeNotSync(200)
      }

      return new BadRequestException(payloadData)
    }
  }
}

import type { CreatMeta, EachMeta, ResponseMeta, UpdateMeta } from './metadata.interfaces'
import { Service } from '../../service'
export class MetadataService extends Service {
  public create(data: CreatMeta) {
    return this.post<ResponseMeta>(`/api/metadata-manager/metadata`, data)
  }
  public search(q: string, page: number): Promise<EachMeta[]> {
    return this.adaptor
      .post<EachMeta[]>(`/api/metadata-manager/metadata/search?page=${page}`, {
        query: q
      })
      .then((r) => r.data)
  }
  public find(id: string) {
    return this.get<ResponseMeta>(`/api/metadata-manager/metadata/${id}`)
  }
  public update(id: string, data: UpdateMeta) {
    return this.patch<ResponseMeta>(`/api/metadata-manager/metadata/${id}`, data)
  }
  public remove(id: string) {
    return this.delete<void>(`/api/metadata-manager/metadata/${id}`)
  }
}

interface Layer {
  canvas: CanvasImageSource
  opacity: number
}

export class CanvasMerger {
  protected layers: Array<false | Layer> = []
  protected _width = 0
  protected _height = 0

  get height() {
    return this._height
  }

  get width() {
    return this._width
  }

  constructor() {}

  removeLayer(index: number) {
    this.layers[index] = false
  }

  putLayer(index: number, layer: Layer) {
    this.layers[index] = layer
  }

  getLayer(index: number) {
    return this.layers[index]
  }

  addCanvasLayer(canvas: HTMLCanvasElement, opacity = 1) {
    const index = this.layers.push({ canvas, opacity: Math.min(1, opacity) })
    if (canvas.width > this._width) {
      this._width = canvas.width
    }
    if (canvas.height > this._height) {
      this._height = canvas.height
    }
    return index
  }

  addImageDataLayer(image: ImageData, opacity = 1) {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error()
    ctx.putImageData(image, 0, 0)
    const index = this.layers.push({ canvas, opacity: Math.min(1, opacity) })
    if (image.width > this._width) {
      this._width = image.width
    }
    if (image.height > this._height) {
      this._height = image.height
    }
    return index
  }

  async addImagLayer(src: string, opacity = 1) {
    const image = document.createElement('img')
    image.src = src
    await image.decode()
    const index = this.layers.push({ canvas: image, opacity: Math.min(1, opacity) })
    if (image.width > this._width) {
      this._width = image.width
    }
    if (image.height > this._height) {
      this._height = image.height
    }
    return index
  }

  addCtxLayer(ctx: CanvasRenderingContext2D, opacity = 1) {
    return this.addCanvasLayer(ctx.canvas, opacity)
  }

  protected makeImage() {
    const render = document.createElement('canvas')
    render.width = this._width
    render.height = this._height
    const ctx = render.getContext('2d')
    if (!ctx) throw new Error('can`t generate context 2d')
    for (const layer of this.layers) {
      if (layer) {
        const { canvas, opacity } = layer
        ctx.globalAlpha = opacity
        ctx.drawImage(canvas, 0, 0, this._width, this._height)
      }
    }
    return render
  }

  getImage(type = 'image/jpg') {
    return this.makeImage().toDataURL(type)
  }

  static merge(...canvases: HTMLCanvasElement[]) {
    const merger = new CanvasMerger()
    for (const canvas of canvases) {
      merger.addCanvasLayer(canvas)
    }
    return merger.getImage()
  }
}

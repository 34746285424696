import type {
  Bookmark,
  CreateBookmarkData,
  findBookmarkByDateData,
  updateBookmarkData
} from './bookmark.interfaces'
import { Service } from '../service'

export class BookmarkService extends Service {
  // ***** BOOKMARK APIS  *****
  public create(cameraId: string, data: CreateBookmarkData) {
    return this.post<Bookmark, CreateBookmarkData>(
      `/api/bookmark-manager/bookmark/${cameraId}`,
      data
    )
  }

  public find(cameraId: string) {
    return this.get<Bookmark>(`/api/bookmark-manager/bookmark/${cameraId}`)
  }

  public update(cameraId: string, data: updateBookmarkData) {
    return this.patch<Bookmark>(`/api/bookmark-manager/bookmark/${cameraId}`, data)
  }

  public remove(cameraId: string) {
    return this.delete<void>(`/api/bookmark-manager/bookmark/${cameraId}`)
  }

  public findByDate(cameraId: string, data: findBookmarkByDateData) {
    return this.post<Bookmark[]>(`/api/bookmark-manager/bookmark/camera/${cameraId}`, data)
  }
}

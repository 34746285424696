import type { RGBColor } from '@/player/interfaces'

export enum CameraStatus {
  unknown,
  offline,
  error,
  connecting,
  healthyLive,
  healthyRecording
}

export enum FunctionMode {
  liveMode,
  historyMode,
  snapshotMode,
  aiHeatmapMode,
  aiDetectionMode
}

export enum SizeMode {
  desiredWidth,
  desiredHeight,
  contain,
  scale
}

export enum PeriodType {
  singleMinute = 60 * 1000,
  minimal = 5 * 60 * 1000,
  quarter = 15 * 60 * 1000,
  hourly = 60 * 60 * 1000,
  daily = 24 * 60 * 60 * 1000,
  monthly = 30 * 24 * 60 * 60 * 1000,
  threeMonth = 3 * 30 * 24 * 60 * 60 * 1000
}

export enum HeatMapPeriodType {
  short = 4 * 60 * 60 * 1000, // 4 hours
  medium = 24 * 60 * 60 * 1000, // 24 hours
  long = 7 * 24 * 24 * 24 * 60 * 1000 // 1 week
}

export enum StreamQuality {
  highDefinition,
  medium,
  low
}

export enum FrameTypes {
  VideoHeader,
  AudioHeader,
  SubtitleHeader,
  DataHeader,
  AttachmentHeader,
  VideoFrame,
  AudioFrame,
  SubtitleFrame,
  EventDetails
}

export enum Codec {
  unknown = -1,
  h264,
  h265,
  mPEG4,
  mJPEG,
  pcmMULAW, // JS Code
  pcmALAW, // JS code
  pcmU8, // JS Code
  pcmS16LE, // JS Code
  pcmS24LE, // JS Code
  pcmS32LE, // JS Code
  pcmF32LE, // JS Code
  adPcmG726_16,
  adPcmG726_24,
  adPcmG726_32,
  adPcmG726_40,
  g729,
  aac // AudioDecode
}

export enum EventTypes {
  Unknown,
  NoFrame,
  ArchiveMapChanged,
  Bookmark,
  Connected,
  Disconnected,
  HWMotionDetected,
  HWBorderCrossed,
  HWFaceDetected,
  HWAudioDetected,
  HWTamperDetected,
  HWSensor,
  MotionDetected,
  BorderCrossed,
  LeftMissed,
  RecordFailed,
  SmokeDetected,
  FireDetected,
  FaceDetected,
  AudioDetected,
  LPRDetected
}

export enum CaptureRoutes {
  init = 'init',
  live = 'live',
  archive = 'archive',
  seek = 'seek',
  speed = 'speed',
  ptz = 'ptz'
}

export const CAPTURE_MESSAGE_SEPARATOR = '@'

export enum CaptureStatus {
  unknown,
  connecting,
  activation,
  ready,
  error,
  closed
}

export enum PathActionType {
  move,
  line
}

export enum AnnotationObjectType {
  rectangle,
  circle,
  path
}

export const HEATMAP_DEFAULT_COLORS: Array<RGBColor> = [
  [0, 255, 255, 255],
  [255, 255, 0, 255],
  [255, 0, 0, 255]
]

export enum InfoMode {
  message,
  backward,
  forward,
  jumpBack,
  jumpForward,
  expand
}

export enum ResizeMode {
  upLeft,
  up,
  upRight,
  left,
  right,
  bottomLeft,
  bottom,
  bottomRight
}

export const EXPORT_BOX_HEIGHT = 599

export const worker_url = 'workers/player-391f1cc6ee0e8f474fd236aa60227faf.js'

export default {
  shortcuts: {
    title: 'Keyboard Shortcuts',
    list: {
      'alt-c': { action: 'Open camera profile', keys: 'ALT + C' },
      'alt-ctrl-e': { action: 'Export clips', keys: 'ALT + CTRL + E' },
      'alt-s': { action: 'Take snapshots', keys: 'ALT + S' },
      'ctrl-b': { action: 'Drop new bookmarks', keys: 'CTRL + B' },
      'ctrl-m': { action: 'Show/hide privacy masks', keys: 'CTRL + M' },
      'alt-+': { action: 'Zoom in video', keys: 'ALT + "+"' },
      'alt--': { action: 'Zoom out video', keys: 'ALT + "-"' },
      esc: { action: 'Exit any action on video streaming', keys: 'ESC' },
      space: { action: 'Pause/Unpause video', keys: 'Space' },
      'up-arrow': { action: 'Longer timeline intervals on video streaming', keys: '' },
      'down-arrow': { action: 'Shorter timeline intervals on video streaming', keys: '' },
      'right-arrow': { action: 'Go forward 15 seconds in historical footage', keys: '' },
      'left-arrow': { action: 'Go back 15 seconds in historical footage', keys: '' }
    }
  }
}

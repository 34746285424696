export default {
  created(el, binding, vnode, prevVnode) {
    console.log('created')
    console.log(el)
    console.log(binding)
    console.log(vnode)
    console.log(prevVnode)
  },

  beforeMount(el, binding, vnode, prevVnode) {},

  mounted(el, binding, vnode, prevVnode) {},

  beforeUpdate(el, binding, vnode, prevVnode) {},

  updated(el, binding, vnode, prevVnode) {},

  beforeUnmount(el, binding, vnode, prevVnode) {},

  unmounted(el, binding, vnode, prevVnode) {}
}

export const NEVER_SYNCED = Symbol()
export enum SyncState {
  notSync = 'notSync',
  justSync = 'justSync',
  synced = 'synced'
}
export enum CameraStatusType {
  offline = 'offline',
  live = 'live',
  recording = 'recording',
  troubleshoot = 'troubleshoot',
  connecting = 'connecting',
  error = 'error'
}

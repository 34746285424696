export class MapHelper {
  // example   await MapHelper.getUserLocation(); I don't know is it good to pass window or not
  public getUserLocation() {
    return new Promise((resolve, reject) => {
      if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        reject(ErrorLocationHelper.errorEnvironment())
        return
      }
      if (!navigator.geolocation) {
        reject(ErrorLocationHelper.errorGeolocation())
        return
      }
      navigator.geolocation?.getCurrentPosition(
        (position) => {
          const userLocation = {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude
          }
          resolve(userLocation)
        },
        (error) => {
          reject(error)
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      )
    })
  }
}

export class ErrorLocationHelper {
  public static errorEnvironment() {
    return new Error('Geolocation is not available in this environment.')
  }

  public static errorGeolocation() {
    return new Error('Geolocation is not available in this environment.')
  }
}

import { useEventBus } from '@/utils/event-bus/EventBus'

export function useLayoutUpdateToaster() {
  const eventBus = useEventBus()
  let disabled = false

  function activateLoading() {
    if (!disabled) {
      eventBus.emit('toast.add', {
        severity: 'info',
        summary: 'Saving...'
      })
    }
  }

  function deactivateLoading() {
    eventBus.emit('toast.removeAllGroups')
  }

  function showSaved() {
    deactivateLoading()
    if (!disabled) {
      eventBus.emit('toast.add', {
        severity: 'success',
        summary: 'Saved!',
        life: 2000
      })
    }
  }

  function showError() {
    if (!disabled) {
      eventBus.emit('toast.add', {
        severity: 'error',
        summary: `hey, we've noticed a connection issue. If the problem persists, contact us at +1 (800) 288-9192 or sales{'@'}arcadian.ai. We're here to help!`,
        life: 5000
      })
    }
  }

  function active() {
    disabled = false
  }

  function deactivate() {
    disabled = true
  }

  return {
    activateLoading,
    deactivateLoading,
    showSaved,
    showError,
    active,
    deactivate
  }
}

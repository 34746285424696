import type { Permission } from '../team/team.interfaces'
import type { MatrixResource } from '../resource/permission-resource.interfaces'
import { Service } from '../../service'
export class PermissionUserService extends Service {
  public permissions(id: string): Promise<Permission[]> {
    return this.get(`/api/p-crm/user/${id}/permissions`)
  }
  public permissionMatrix(id: string): Promise<MatrixResource[]> {
    return this.get(`/api/p-crm/user/${id}/permissions/matrix`)
  }
}

import type { ArchiveMap, ArchiveMapEntity } from '@/player/interfaces'
import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'

export class TimelineRecordInfoManager {
  public records: ArchiveMap = []

  constructor(public readonly timelineCore: TimelineCore) {}

  push(value: ArchiveMap, reset = true) {
    if (value && value.length > 0) {
      if (reset) {
        this.records = []
      }
      const merged = [...this.records, ...value]
      merged.sort((a, b) => a[0] - b[0])

      const result: ArchiveMap = []
      let currentPeriod: ArchiveMapEntity = merged[0]

      for (let i = 1; i < merged.length; i++) {
        if (currentPeriod[1] >= merged[i][0]) {
          currentPeriod = [
            currentPeriod[0],
            Math.max(currentPeriod[1], merged[i][1]),
            currentPeriod[2] || merged[i][2]
          ]
        } else {
          result.push(currentPeriod)
          currentPeriod = merged[i]
        }
      }
      result.push(currentPeriod)
      this.records = result
      this.timelineCore.resetRenderCache()
    }
  }

  getOverlappingPeriods(startTime: number, endTime: number): ArchiveMap {
    return this.records.filter(([start, end]) => start <= endTime && end >= startTime)
  }

  optimizeLineForDrawing(points: ArchiveMap, minValue: number): ArchiveMap {
    if (points.length <= 1) return points
    const mergedLines: ArchiveMap = []
    let [currentStart, currentEnd, currentIsLive] = points[0]
    for (let i = 1; i < points.length; i++) {
      const point = points[i]
      const [pointStart, pointEnd, pointIsLive] = point

      if (pointStart - currentEnd <= 1) {
        currentEnd = pointEnd
      } else {
        mergedLines.push([currentStart, currentEnd, currentIsLive || pointIsLive])
        ;[currentStart, currentEnd, currentIsLive] = point
      }
    }
    mergedLines.push([currentStart, currentEnd, currentIsLive])
    if (mergedLines[0]) {
      const x = this.timelineCore.findRelativeXForDate(minValue)
      mergedLines[0][1] = Math.max(mergedLines[0][1], x)
    }
    return mergedLines
  }

  render(records: ArchiveMap) {
    for (const [start, end] of records) {
      this.timelineCore.drawer.drawLine(
        start,
        this.timelineCore.renderPositionsConstant.horizontalLine,
        {
          ...this.timelineCore.renderConstant.horizontalLine,
          width: end - start
        }
      )
    }
  }

  onHover(x: number, y: number, haveBookmark = false) {
    const diffStart = (this.timelineCore.view.size * x) / this.timelineCore.renderWidth
    const date = this.timelineCore.view.start + diffStart

    const recordIndex = this.records.findIndex(([start, end, isRecording]) => {
      end = isRecording ? Date.now() : end
      return start <= date && end >= date
    })
    if (recordIndex >= 0) {
      this.timelineCore.core.previewConfig.x = haveBookmark ? x - 140 : x
      this.timelineCore.core.previewConfig.y =
        this.timelineCore.ctx.canvas.getBoundingClientRect().top
      this.timelineCore.core.previewConfig.date = date
      this.timelineCore.core.previewConfig.enabled = true
    } else {
      this.timelineCore.core.previewConfig.enabled = false
    }
  }
}

import type { CreateResourceTag, SearchResourceTag, TagEachResource } from './tag.interfaces'
import { Service } from '../../service'

export class TagService extends Service {
  // ***** TAG APIS  *****
  public async create(data: CreateResourceTag) {
    return this.post<TagEachResource>(`/api/tag-manager/tag`, data)
  }

  public async list() {
    return this.get<TagEachResource[]>(`/api/tag-manager/tag`)
  }

  public async find(id: string) {
    return this.get<TagEachResource>(`/api/tag-manager/tag/${id}`)
  }

  public async update(id: string, data: CreateResourceTag) {
    return this.patch<TagEachResource>(`/api/tag-manager/tag/${id}`, data)
  }

  public async remove(id: string) {
    return this.delete(`/api/tag-manager/tag/${id}`)
  }

  public async search(data: SearchResourceTag) {
    return this.post<TagEachResource[]>(`/api/tag-manager/search`, data)
  }
}

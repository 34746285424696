import { AnnotatorDrawHelpers } from '@/player/lib/annotator/annotator-draw-helpers'
import type { AnnotationObject } from '@/player/interfaces'
import { AnnotationObjectType } from '@/player/types'
import type { PlayerCore } from '@/player/lib/player/player-core'

export class Annotator {
  public drawer!: AnnotatorDrawHelpers
  protected currentObjects: Array<AnnotationObject> = []
  protected enabled = true

  constructor(public readonly core: PlayerCore, public canvas: HTMLCanvasElement) {
    this.setCanvas(canvas)
  }

  setCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('cant generate 2d context')
    this.drawer = new AnnotatorDrawHelpers(ctx)
  }

  get renderHeight() {
    return this.drawer.renderHeight
  }

  get renderWidth() {
    return this.drawer.renderWidth
  }

  set renderHeight(value: number) {
    this.setRenderSize(this.drawer.renderWidth, value)
  }

  set renderWidth(value: number) {
    this.setRenderSize(value, this.drawer.renderHeight)
  }

  setRenderSize(width: number, height: number) {
    this.canvas.width = this.drawer.renderWidth = width
    this.canvas.height = this.drawer.renderHeight = height
    this.draw(this.currentObjects)
  }

  clear() {
    return this.drawer.clear()
  }

  enable() {
    if (!this.enabled) {
      this.enabled = true
      if (this.currentObjects) {
        this.draw(this.currentObjects)
      }
    }
  }

  disable() {
    if (this.enabled) {
      this.enabled = false
      this.clear()
    }
  }

  draw(annotations: Array<AnnotationObject>) {
    // this.drawer.drawRectangle({
    //   color: {
    //     fill: '#FFF'
    //   },
    //   y1: 0,
    //   x1: 0,
    //   y2: 1,
    //   x2: 1,
    // })
    if (this.enabled) {
      this.currentObjects = annotations
      for (const obj of annotations) {
        if (obj.type === AnnotationObjectType.rectangle) {
          this.drawer.drawRectangle(obj.annotation)
          if (obj.label) {
            this.drawer.drawLabelOnRectangle(obj.label, obj.annotation)
          }
        }
        if (obj.type === AnnotationObjectType.circle) {
          this.drawer.drawCircle(obj.annotation)
        }
        if (obj.type === AnnotationObjectType.path) {
          this.drawer.drawPath(obj.annotation)
        }
      }
    }
  }
}

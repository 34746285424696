import { EventTypes } from '@/player/types'
import { FrameFollower } from '@/player/lib/analytic/frame-follower'
import { EventCache } from '@/player/lib/analytic/event-cache'
import type { PlayerCore } from '@/player/lib/player/player-core'
import type { Tape } from '@/player/lib/analytic/interval-tree'
import { type Analytic } from '@/lib/api/services/ai/analytics/analytic.interface'
import { AnalyticEventTypes } from '@/lib/api'

const MARGIN_FACTOR = 2
export interface EventIdentity {
  type: AnalyticEventTypes
  index: string
  ref?: Analytic
}

function convertEventType(analyticType: AnalyticEventTypes): EventTypes | undefined {
  switch (analyticType) {
    case AnalyticEventTypes.HwTampering:
      return EventTypes.HWTamperDetected
    case AnalyticEventTypes.HwMotion:
      return EventTypes.HWMotionDetected
    case AnalyticEventTypes.HwAudio:
      return EventTypes.HWAudioDetected
    case AnalyticEventTypes.HwBorderCrossing:
      return EventTypes.HWBorderCrossed
    default:
      return undefined
  }
}

export class EventManager {
  public cache: EventCache
  public frameFollower?: FrameFollower

  constructor(public readonly core: PlayerCore, public readonly identity: EventIdentity) {
    this.cache = new EventCache(this.getEvents.bind(this))
    const lowLevelEvent = convertEventType(this.identity.type)
    if (lowLevelEvent) {
      this.frameFollower = new FrameFollower(lowLevelEvent, this.cache)
    }
  }

  isSameIdentity(identity: EventIdentity) {
    return this.identity.type === identity.type && this.identity.index === identity.index
  }

  protected getEvents(start: number, end: number) {
    return this.core.apis
      .getEvents(new Date(start), new Date(end), this.identity.type, this.identity.index)
      .then((data) => {
        const tapes: Tape[] = []
        for (let i = 0; i < data.length - 1; i++) {
          const [timestamp, status] = data[i]
          if (status === 1) {
            const [endTimestamp, endStatus] = data[i + 1]
            if (endStatus === 0) {
              tapes.push([timestamp, endTimestamp])
              i++
            }
          }
        }
        return tapes
      })
  }

  updateForCurrentView() {
    let start = this.core.timeline.view.start
    let end = this.core.timeline.view.end
    const margin = (end - start) / MARGIN_FACTOR
    start -= margin
    end += margin
    return this.cache.request(start, end)
  }

  get(start: number, end: number) {
    return this.cache.get(start, end)
  }

  processLiveFrames(set: Set<EventTypes>, date: number) {
    if (this.frameFollower) {
      return this.frameFollower.process(set, date)
    }
  }
}

import { reactive, ref, type Ref } from 'vue'
import { type BridgeData, BridgeService, type bridgeUpdateCheckData } from '@/lib/api'
import { useServices } from '@/lib/services'

export class BridgeVersionManager {
  public version!: Ref<string>
  public latestVersionData: bridgeUpdateCheckData
  protected service: BridgeService = useServices().bridge
  constructor(public readonly id: string, bridge: BridgeData) {
    this.version = ref<string>(bridge.version)
    const data = {
      latest: '',
      available: false,
      compatible: false,
      current: ''
    }
    this.latestVersionData = reactive<bridgeUpdateCheckData>(data) as bridgeUpdateCheckData
  }

  public async isNeedUpdate() {
    const updateData = await this.service.updateCheck(this.id)
    Object.assign(this.latestVersionData, updateData)
    this.version.value = updateData.current
    return updateData.available
  }

  public async update() {
    await this.service.softwareUpdate(this.id)
    return this.isNeedUpdate()
  }
}

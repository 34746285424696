import type { CreateTourData, Tour, UpdateTourData } from './tour.interfaces'
import { Service } from '../../service'
export class TourService extends Service {
  // ***** TOUR APIS  *****
  public create(data: CreateTourData) {
    return this.post<Tour, CreateTourData>(`/api/layout-manager/tour`, data)
  }
  public findPrivet() {
    return this.get<Tour[]>(`/api/layout-manager/tour`)
  }
  public findOne(id: string) {
    return this.get<Tour>(`/api/layout-manager/tour/${id}`)
  }
  public update(id: string, data: UpdateTourData) {
    return this.patch<Tour, UpdateTourData>(`/api/layout-manager/tour/${id}`)
  }
  public remove(id: string) {
    return this.delete<Tour>(`/api/layout-manager/tour/${id}`)
  }

  public findPublic() {
    return this.get<Tour[]>(`/api/layout-manager/tour/public`)
  }
}

import { Service } from '../../service'

export class StripePriceService extends Service {
  public findAllPrice(): Promise<any> {
    return this.get(`/api/payment/price`)
  }

  public priceDetails(id: string): Promise<any> {
    return this.get(`/api/payment/price/${id}/detailed`)
  }

  public allPriceDetails(): Promise<any> {
    return this.get(`/api/payment/price/detailed`)
  }

  public priceRelations(id: string): Promise<any> {
    return this.get(`/api/payment/price/${id}/relations`)
  }

  public allPriceRelations(): Promise<any> {
    return this.get(`/api/payment/price/relations`)
  }

  public allPriceRelationsDetails(type: string = ''): Promise<any> {
    return this.get(`/api/payment/price/relations/type?type=${type}`)
  }
}

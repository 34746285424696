import { defineStore } from 'pinia'
import type {
  operationHours,
  ScheduleEntity,
  ScheduleFilterDto,
  ScheduleRoutineDto
} from '@/lib/api'
import { useServices } from '@/lib/services'
import { usePermissionsStore } from '@/modules/Permissions'
import {
  convertOperationHoursToRoutines,
  makeDefaultCriticalScheduleOperationHours,
  makeDefaultMainScheduleOperationHours
} from '@/stores/alert/helpers'
import { AlertTypes, AnalyticEventTypes, FrequencyTypes, ScheduleMode } from '@/lib/api'
import { computed, ref } from 'vue'

export const useAlertStore = defineStore('alertStore', () => {
  const AlertService = useServices().ai.alert
  const PermissionStore = usePermissionsStore()
  const defaultMainScheduleOperationHours = makeDefaultMainScheduleOperationHours()
  const defaultCriticalScheduleOperationHours = makeDefaultCriticalScheduleOperationHours()
  const defaultFilters: ScheduleFilterDto[] = [
    {
      eventType: AnalyticEventTypes.HwMotion,
      indexes: ['0', '-1']
    },
    {
      eventType: AnalyticEventTypes.HwAudio,
      indexes: ['0', '-1']
    },
    {
      eventType: AnalyticEventTypes.HwBorderCrossing,
      indexes: ['0', '-1']
    },
    {
      eventType: AnalyticEventTypes.HwAppearance,
      indexes: ['0', '-1']
    },
    {
      eventType: AnalyticEventTypes.HwDisappearance,
      indexes: ['0', '-1']
    },
    {
      eventType: AnalyticEventTypes.HwCapacity,
      indexes: ['0', '-1']
    },
    {
      eventType: AnalyticEventTypes.HwSensor,
      indexes: ['0', '-1']
    },
    {
      eventType: AnalyticEventTypes.SwMotion,
      indexes: ['*']
    },
    {
      eventType: AnalyticEventTypes.SwFence,
      indexes: ['*']
    },
    {
      eventType: AnalyticEventTypes.SwLeftMissed,
      indexes: ['*']
    }
  ]
  const defaultCriticalFilters: ScheduleFilterDto[] = [
    {
      eventType: AnalyticEventTypes.HwTampering,
      indexes: ['0', '-1']
    }
  ]

  const criticalSchedule = ref<ScheduleEntity>()
  const mainSchedule = ref<ScheduleEntity>()
  const lastUpdate = ref(0)
  const loading = ref(false)
  const lastError = ref<Error | false>(false)
  let activeTask: Promise<unknown> | false = false

  const mainScheduleMode = computed(() => {
    return mainSchedule.value?.mode || ScheduleMode.afterHours
  })

  const mainScheduleRoutines = computed(() => {
    if (mainSchedule.value) {
      return mainSchedule.value?.routines
    } else return defaultMainScheduleOperationHours
  })

  const hasCriticalSchedule = computed(() => {
    return isSchedulesLoaded.value && !!criticalSchedule.value
  })

  const hasMainSchedule = computed(() => {
    return isSchedulesLoaded.value && !!mainSchedule.value
  })

  const isSchedulesLoaded = computed(() => {
    return lastUpdate.value > 0
  })

  const isLoading = computed(() => {
    return loading.value
  })

  async function waitForActiveJob() {
    try {
      await activeTask
    } catch (e) {
      // safe to ignore
    }
  }

  async function serviceCallWrapper<T>(job: () => Promise<T>): Promise<T> {
    await waitForActiveJob()
    lastError.value = false
    loading.value = true
    try {
      const res: Promise<T> = (activeTask = job())
      return await res
    } catch (e) {
      lastError.value = e as Error
      throw e
    } finally {
      loading.value = false
      activeTask = false
    }
  }

  async function reset() {
    await waitForActiveJob()
    setTimeout(() => {
      criticalSchedule.value = undefined
      mainSchedule.value = undefined
      lastUpdate.value = 0
      loading.value = false
      lastError.value = false
      activeTask = false
    })
  }

  function init() {
    return loadSchedules()
  }

  async function loadSchedules() {
    return serviceCallWrapper(async () => {
      const schedules = await AlertService.getCurrentWorkspaceSchedules()
      criticalSchedule.value = schedules.find(
        (schedule) =>
          schedule.filters.some((filter) => filter.eventType === AnalyticEventTypes.HwTampering) &&
          schedule.filters.length === 1
      )
      mainSchedule.value = schedules.find((schedule) =>
        schedule.filters.some((filter) => filter.eventType === AnalyticEventTypes.HwMotion)
      )
      if (!criticalSchedule.value) {
        criticalSchedule.value = await createSchedule(
          convertOperationHoursToRoutines(defaultCriticalScheduleOperationHours),
          defaultCriticalFilters,
          ScheduleMode.all
        )
      }
      if (!mainSchedule.value) {
        mainSchedule.value = await createSchedule(
          convertOperationHoursToRoutines(defaultMainScheduleOperationHours),
          defaultFilters,
          ScheduleMode.afterHours
        )
      }
      lastUpdate.value = Date.now()
    })
  }

  async function getTeamsId() {
    await PermissionStore.getTeams(false)
    return PermissionStore.teams.map((t) => t.id)
  }

  async function createSchedule(
    routines: ScheduleRoutineDto[],
    filters: ScheduleFilterDto[],
    mode: ScheduleMode
  ): Promise<ScheduleEntity> {
    return AlertService.create({
      filters,
      routines,
      mode,
      teamIds: await getTeamsId(),
      notificationTypes: [AlertTypes.firebase, AlertTypes.email, AlertTypes.inApp],
      frequency: FrequencyTypes.weekly
    })
  }

  async function updateSchedule(
    scheduleId: string,
    mode: ScheduleMode,
    routines?: ScheduleRoutineDto[]
  ): Promise<ScheduleEntity> {
    return AlertService.update(scheduleId, {
      routines: routines,
      mode: mode
    })
  }

  async function createMainSchedule(
    routines: ScheduleRoutineDto[],
    mode: ScheduleMode
  ): Promise<ScheduleEntity> {
    if (isSchedulesLoaded.value) {
      if (!mainSchedule.value) {
        return await serviceCallWrapper(
          async () => (mainSchedule.value = await createSchedule(routines, defaultFilters, mode))
        )
      } else return mainSchedule.value
    } else {
      await loadSchedules()
      return createMainSchedule(routines, mode)
    }
  }

  async function updateMainScheduleMode(mode: ScheduleMode) {
    if (isSchedulesLoaded.value) {
      if (hasMainSchedule.value) {
        await serviceCallWrapper(async () => {
          if (mainSchedule.value) {
            mainSchedule.value.mode = mode
            mainSchedule.value = await updateSchedule(
              mainSchedule.value.id,
              mainSchedule.value.mode
            )
          } else throw new Error('mainSchedule not found!')
        })
      } else
        return createMainSchedule(
          convertOperationHoursToRoutines(defaultMainScheduleOperationHours),
          mode
        )
    } else {
      await loadSchedules()
      return updateMainScheduleMode(mode)
    }
  }

  async function updateMainScheduleWorkingHours(value: operationHours[]) {
    if (isSchedulesLoaded.value) {
      if (hasMainSchedule.value) {
        await serviceCallWrapper(async () => {
          if (mainSchedule.value) {
            mainSchedule.value = await updateSchedule(
              mainSchedule.value.id,
              mainSchedule.value.mode,
              convertOperationHoursToRoutines(value)
            )
          } else throw new Error('mainSchedule not found!')
        })
      } else
        return createMainSchedule(convertOperationHoursToRoutines(value), ScheduleMode.afterHours)
    } else {
      await loadSchedules()
      return updateMainScheduleWorkingHours(value)
    }
  }

  async function handleNewWorkspace() {
    return serviceCallWrapper(async () => {
      const criticalScheduleRequest = createSchedule(
        convertOperationHoursToRoutines(defaultCriticalScheduleOperationHours),
        defaultCriticalFilters,
        ScheduleMode.all
      )
      const mainScheduleRequest = createSchedule(
        convertOperationHoursToRoutines(defaultMainScheduleOperationHours),
        defaultFilters,
        ScheduleMode.afterHours
      )
      const [criticalScheduleTemp, mainScheduleTemp] = await Promise.all([
        criticalScheduleRequest,
        mainScheduleRequest
      ])
      criticalSchedule.value = criticalScheduleTemp
      mainSchedule.value = mainScheduleTemp
      lastUpdate.value = Date.now()
    })
  }

  return {
    defaultMainScheduleOperationHours,
    defaultCriticalScheduleOperationHours,
    mainScheduleMode,
    mainScheduleRoutines,
    hasCriticalSchedule,
    hasMainSchedule,
    isSchedulesLoaded,
    isLoading,
    loadSchedules,
    updateMainScheduleMode,
    updateMainScheduleWorkingHours,
    handleNewWorkspace,
    reset,
    init
  }
})

import type { Schedule, ScheduleWeekDetailData } from '@/lib/api'
import { AbstractHelper } from './abstract-helper'
import { ApiClient, CameraService } from '@/lib/api'

type TimeValue = {
  hours: number
  minutes: number
  ampm: 'am' | 'pm'
}

export class ScheduleHelper extends AbstractHelper {
  private positionPeriods: ScheduleWeekDetailData = {
    saturday: [],
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: []
  }
  protected camera: CameraService

  constructor(apiClient: ApiClient) {
    super(apiClient)
    this.camera = apiClient.camera
    this.initiateSchedule()
  }

  // example   await scheduleHelper.setSchedule('friday', '2:07 pm', '4:29 pm');
  public setSchedule(day: keyof ScheduleWeekDetailData, from, to) {
    const startDate = this.parseTimeValue(from)
    const endDate = this.parseTimeValue(to)
    this.positionPeriods[day as keyof ScheduleWeekDetailData] = this.markTimeRange(
      startDate.hours,
      startDate.minutes,
      endDate.hours,
      endDate.minutes,
      this.positionPeriods[day as keyof ScheduleWeekDetailData]
    )
  }

  public getPositionPeriods(): ScheduleWeekDetailData {
    return this.positionPeriods
  }

  //response from click on row
  public selectRow(i: number, day: keyof ScheduleWeekDetailData): void {
    this.positionPeriods[day][i] = !this.positionPeriods[day][i]
  }

  public async updateScheduleWithResponse(id: string) {
    const data = await this.getScheduleRequest(id)
    this.setValue(data.week)
  }

  public reset(data: ScheduleWeekDetailData) {
    for (const dayName in this.positionPeriods) {
      for (let i = 0; i < 24 * 4; ++i) {
        this.positionPeriods[dayName as keyof ScheduleWeekDetailData][i] =
          data[dayName as keyof ScheduleWeekDetailData][i] === true
      }
    }
  }

  setValue(data: ScheduleWeekDetailData) {
    Object.keys(this.positionPeriods).forEach((e) => {
      this.positionPeriods[e] = data[e]
    })
  }

  getScheduleRequest(id: string): Promise<Schedule> {
    return this.camera.schedule(id)
  }

  initiateSchedule() {
    for (const dayName in this.positionPeriods) {
      for (let i = 0; i < 24 * 4; ++i) {
        this.positionPeriods[dayName as keyof ScheduleWeekDetailData][i] = false
      }
    }
  }

  parseTimeValue(timeString: string): TimeValue {
    // const regex = /^(\d{1,2}):(\d{2}) ([ap]m)$/i;
    const regex = /^(\d{1,2}):?(\d{2})? ([ap]m)$/i
    const matches = timeString.match(regex)
    if (!matches) {
      return {
        hours: 0,
        minutes: 0,
        ampm: 'am'
      }
    }
    const ampm = matches[3].toLowerCase() as 'am' | 'pm'
    const hours = ampm === 'pm' ? parseInt(matches[1]) + 12 : parseInt(matches[1])
    const minutes = matches[2] ? parseInt(matches[2]) : 0

    return { hours, minutes, ampm }
  }

  markTimeRange(
    startHour: number,
    startMinute: number,
    endHour: number,
    endMinute: number,
    dayArray: boolean[]
  ) {
    const startIndex = Math.floor(startHour * 4 + startMinute / 15)
    const endIndex = Math.floor(endHour * 4 + endMinute / 15)
    for (let i = startIndex; i <= endIndex; i++) {
      dayArray[i] = true
    }
    return dayArray
  }
}

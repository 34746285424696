// version: 0.7.2

import { defineStore } from 'pinia'
import { useServices } from '@/lib/services'
import type { User, PermissionsState, Team, Permission } from './interfaces'
import type { ShortTeam, UserInvite } from '@/modules/Permissions/components/interfaces'
import type {
  inviteUserWorkspace,
  Permission as APIPermission,
  PendingUsersAdvance,
  WorkSpaceUserAdvance
} from '@/lib/api'
import { usePermissionManager } from '..'

export const usePermissionsStore = defineStore('PermissionsStore', {
  state: (): PermissionsState => ({
    teams: [],
    joinedUsers: [],
    pendingUsers: [],
    permissions: [],
    usersAvatars: {},
    newUsersList: [],
    tableLoading: true,
    isInviteOpen: false
  }),
  getters: {
    user(state) {
      return (id: string): User | undefined => {
        const pending = state.pendingUsers.find((user) => user.remoteId === id)
        const joined = state.pendingUsers.find((user) => user.remoteId === id)
        return joined ? joined : pending
      }
    },
    sortedTeams(state) {
      return state.teams.sort((a: Team, b: Team) => {
        // Convert booleans to 1 or 0 and subtract to get -1, 0, or 1
        // Anyone what is lock must to get in the end of the list
        return (b.lock ? 1 : 0) - (a.lock ? 1 : 0)
      })
    },
    team(state) {
      return (id: string) => {
        return state.teams.find((team) => {
          return team.id == id
        })
      }
    },
    users(state): Array<User> {
      return [...state.joinedUsers, ...state.pendingUsers]
    },
    joinedUsersList(state): Array<User> {
      return state.joinedUsers
    },
    userRemoteId(state) {
      return (userId: string): string => {
        return (
          state.joinedUsers.find(({ id }) => {
            return userId === id
          })?.remoteId || ''
        )
      }
    },
    currentUserIsCaptain(state) {
      return (userId: string): boolean => {
        const user = state.joinedUsers.some(({ remoteId, team }) => {
          return userId === remoteId && team?.name === 'Captain'
        })
        return user
      }
    },
    isOwnerLoggedin() {
      return (userId: string, ownerId: string): boolean => {
        return this.userRemoteId(ownerId) === userId
      }
    },
    dropDownTeams(state) {
      const isOwnerLoggedIn = this.isOwnerLoggedin
      return (ownerId: string, currentUserId: string) => {
        return state.teams.filter(({ name }) => {
          // some where in the application one component send owner id as remote id from
          return (
            ownerId === currentUserId ||
            isOwnerLoggedIn(currentUserId, ownerId) ||
            name !== 'Captain'
          )
        })
      }
    }
  },
  actions: {
    async getSingleActiveUser(userId: string) {
      this.tableLoading = true
      let activeUser: User | undefined
      try {
        activeUser = (await useServices().workspaceManager.workspace.advanceUser(
          userId
        )) as unknown as User
      } catch (e) {
        console.error(e)
      }
      if (activeUser) {
        if (this.pendingUsers.some((user) => user.phone === activeUser?.phone)) {
          this.pendingUsers = this.pendingUsers.filter((user) => user.phone !== activeUser?.phone)
          this.joinedUsers.push(activeUser)
        } else if (this.joinedUsers.some((user) => user.id === activeUser?.id)) {
          this.joinedUsers = this.joinedUsers.map((user) => {
            if (activeUser && user.id === activeUser.id) {
              return this.userSerializer(activeUser)
            } else {
              return user
            }
          })
        } else {
          if (activeUser.status === 'joined') {
            this.joinedUsers.push(activeUser)
          } else {
            this.pendingUsers.push(activeUser)
          }
        }
      }
      this.tableLoading = false
    },

    openInviteDrawer(): void {
      this.isInviteOpen = true
    },
    closeInviteDrawer(): void {
      this.isInviteOpen = false
    },

    async getTeams(force: boolean = false) {
      if (this.teams.length === 0 || force) {
        const teams = await useServices().permissionManager.team.list()
        this.teams = teams.map((team) => {
          return {
            id: team.id,
            name: team.name,
            lock: team.lock,
            description: team.description || '',
            authorities:
              team.authorities?.map((authority) => {
                return {
                  ...authority,
                  isGeneral: authority.isGeneral ? true : false,
                  description: authority.description ? authority.description : ''
                }
              }) || []
          }
        })
      }
    },

    async getUsers(workspaceId: string, force: boolean = false) {
      this.tableLoading = true
      await this.getPendingUsers(workspaceId, force) // todo check this
      await this.getActiveUsers(workspaceId, force)
      for (const user of this.users) {
        if (user.remoteId) await this.getUsersAvatar(user.remoteId)
      }
      this.tableLoading = false
    },

    async getPendingUsers(workspaceId: string, force: boolean = false) {
      if (
        (this.pendingUsers.length === 0 || force) &&
        usePermissionManager().hasPermission([
          { action: 'workspaceViewUsers' },
          { action: 'workspaceViewPermissions' }
        ])
      ) {
        this.pendingUsers = []
        const pendingUsers: User[] = (
          await useServices().workspaceManager.workspace.advancePendingUsers(workspaceId)
        ).map((user) => this.userSerializer(user)) as unknown as User[]
        this.pendingUsers = pendingUsers
      }
    },
    async getActiveUsers(workspaceId: string, force: boolean = false) {
      if (
        (this.joinedUsers.length === 0 || force) &&
        usePermissionManager().hasPermission([{ action: 'workspaceViewPermissions' }]) &&
        (usePermissionManager().hasPermission({ action: 'workspaceViewUsers' }) ||
          usePermissionManager().hasPermission({ action: 'workspaceViewUsersSummery' }))
      ) {
        this.joinedUsers = []
        const activeUsers: User[] = (
          await useServices().workspaceManager.workspace.advanceUsers(workspaceId)
        ).map((user) => this.userSerializer(user)) as unknown as User[]
        this.joinedUsers = activeUsers
      }
    },

    async getSingleUser(userId: string) {
      this.tableLoading = true
      let receivedUser: User | undefined
      try {
        receivedUser = (await useServices().workspaceManager.workspace.advanceUser(
          userId
        )) as unknown as User
      } catch (e) {
        console.error(e)
      }
      if (receivedUser) {
        if (this.pendingUsers.some((user) => user.phone === receivedUser?.phone)) {
          this.pendingUsers = this.pendingUsers.filter((user) => user.phone !== receivedUser?.phone)
          this.joinedUsers.push(receivedUser)
        } else if (this.joinedUsers.some((user) => user.id === receivedUser?.id)) {
          this.joinedUsers = this.joinedUsers.map((user) => {
            if (receivedUser && user.id === receivedUser.id) {
              return this.userSerializer(receivedUser)
            } else {
              return user
            }
          })
        } else {
          if (receivedUser.status === 'joined') {
            this.joinedUsers.push(receivedUser)
          } else {
            this.pendingUsers.push(receivedUser)
          }
        }
      }
      this.tableLoading = false
    },

    async getUsersAvatar(userId: string, loading: boolean = false) {
      const permission = usePermissionManager()
      if (loading) this.tableLoading = true
      if (
        permission.hasPermission([{ action: 'workspaceViewPermissions' }]) &&
        (permission.hasPermission({ action: 'workspaceViewUsers' }) ||
          permission.hasPermission({ action: 'workspaceViewUsersSummery' }))
      ) {
        const image = await useServices().authenticationManager.user.avatarGet(userId)
        if (image.data.size > 0) {
          const reader = new FileReader()
          reader.addEventListener(
            'load',
            () => {
              this.usersAvatars[userId] = reader.result as string
            },
            false
          )
          reader.readAsDataURL(image.data)
        }
      }
      if (loading) this.tableLoading = false
    },

    userSerializer(user: PendingUsersAdvance | WorkSpaceUserAdvance): User {
      let formattedUser = {
        ...user,
        team: user.team ? user.team : { name: '', id: '' }
      } as unknown as User
      formattedUser = {
        ...formattedUser,
        lastSwitchedAt: formattedUser.lastSwitchedAt ? formattedUser.lastSwitchedAt : '0'
      }
      return formattedUser
    },

    async getPermissions(teamId: string) {
      if (
        usePermissionManager().hasPermission([
          { action: 'workspaceViewUsers' },
          { action: 'workspaceViewPermissions' }
        ])
      ) {
        this.tableLoading = true
        this.permissions.push(
          ...(await useServices().permissionManager.team.permissions(teamId)).map((permission) =>
            this.permissionSerializer(permission)
          )
        )
        this.tableLoading = false
      }
    },

    permissionSerializer(permission: APIPermission): Permission {
      return {
        resourceId: permission.resourceId,
        id: permission.id,
        teamId: permission.teamId,
        userId: permission.user?.remoteId,
        workspaceId: permission.workspaceId
      }
    },

    async getAllPermissions(force: boolean = false) {
      if (
        this.teams.length > 0 &&
        (this.permissions.length == 0 || force) &&
        usePermissionManager().hasPermission([
          { action: 'workspaceViewUsers' },
          { action: 'workspaceViewPermissions' }
        ])
      ) {
        this.tableLoading = true
        this.permissions = []
        for (const team of this.teams) {
          await this.getPermissions(team.id)
        }
        this.tableLoading = false
      }
    },

    async inviteUser(user: UserInvite, workspaceId: string) {
      const serUser: inviteUserWorkspace = {
        phone: user.phone,
        type: user.type,
        teamId: user.teamId
      }
      if (
        usePermissionManager().hasPermission([
          { action: 'workspaceManageUsers' },
          { action: 'workspaceManagePermissions' }
        ]) ||
        usePermissionManager().hasPermission([{ action: 'workspaceInviteUser' }])
      ) {
        await useServices().workspaceManager.workspace.invite(workspaceId, serUser)
      }
    },

    async userInviteBulk(workspaceId: string) {
      if (
        usePermissionManager().hasPermission([
          { action: 'workspaceManageUsers' },
          { action: 'workspaceManagePermissions' }
        ]) ||
        usePermissionManager().hasPermission([{ action: 'workspaceInviteUser' }])
      ) {
        const errors: Array<any> = []
        const newUsersList = [...this.newUsersList]
        for (const user of newUsersList) {
          try {
            await this.inviteUser(user, workspaceId)
            this.newUsersList = this.newUsersList.filter(
              (listUser) =>
                listUser.phone === user.phone ||
                (listUser.email ? listUser.email === user.email : false)
            )
          } catch (err: any) {
            if (err.phone) errors.push({ ...err, user })
            else errors.push(err)
          }
        }
        await this.getPendingUsers(workspaceId, true)
        this.clearNewUsersList()
        if (errors.length > 0) throw errors
      }
    },

    async changeTeam(userId: string, teamId: string): Promise<void> {
      if (
        usePermissionManager().hasPermission([
          { action: 'workspaceManageUsers' },
          { action: 'workspaceManagePermissions' }
        ])
      ) {
        this.tableLoading = true
        const currentPermission = this.findPermissionByUserId(userId)
        if (currentPermission?.id && currentPermission?.resourceId) {
          const newPermission: Permission = await useServices().permissionManager.permission.update(
            currentPermission.id,
            { teamId }
          )
          this.permissions = this.permissions.filter((permission) => {
            return permission.id != currentPermission.id
          })
          this.addPermission(newPermission)
        } else {
          throw 'Permission not found'
        }
        this.tableLoading = false
      }
    },

    addPermission(newPermission: Permission): void {
      this.permissions.push(this.permissionSerializer(newPermission))
    },

    findPermissionByUserId(userId: string): Permission | undefined {
      return this.permissions.find((permission) => permission.userId === userId)
    },

    updateUserTeam(userId: string, teamId: string): void {
      const team: Team | ShortTeam | undefined = this.team(teamId)
      if (team?.id && team.name) {
        this.joinedUsers = this.joinedUsers.map((user) => {
          return {
            ...user,
            team:
              user.remoteId === userId
                ? {
                    id: team.id,
                    name: team.name
                  }
                : user.team
          }
        })
      }
    },

    async removeUser(workspaceId: string, userId: string) {
      if (
        usePermissionManager().hasPermission([
          { action: 'workspaceManageUsers' },
          { action: 'workspaceManagePermissions' }
        ])
      ) {
        await useServices().workspaceManager.workspace.deleteUser(workspaceId, userId)
        this.removeUserFromList(userId)
        this.permissions = this.permissions.filter((permission) => {
          return permission.userId != userId
        })
      }
    },

    removeUserFromList(userId: string): void {
      this.joinedUsers = this.joinedUsers.filter((user) => {
        return user.remoteId != userId
      })
      this.pendingUsers = this.pendingUsers.filter((user) => {
        return user.remoteId != userId
      })
    },

    async removePendingUser(workspaceId: string, phone: string) {
      if (
        usePermissionManager().hasPermission([
          { action: 'workspaceManageUsers' },
          { action: 'workspaceManagePermissions' }
        ])
      ) {
        await useServices().workspaceManager.workspace.removePendingUser(workspaceId, { phone })
        this.pendingUsers = this.pendingUsers.filter((pendingUser) => {
          return pendingUser.phone != phone
        })
      }
    },
    async resendInvite(workspaceId: string, phone: string) {
      if (
        usePermissionManager().hasPermission([
          { action: 'workspaceManageUsers' },
          { action: 'workspaceManagePermissions' }
        ])
      ) {
        await useServices().workspaceManager.workspace.resendUser(workspaceId, { phone })
      }
    },

    pushNewUser(newUser: UserInvite) {
      if (!this.newUsersList.some(({ phone }) => phone === newUser.phone))
        this.newUsersList.push(newUser)
    },

    clearNewUsersList() {
      this.newUsersList = []
    },

    selectTeam(team: ShortTeam, index: number) {
      this.newUsersList[index].teamName = team.name
      this.newUsersList[index].teamId = team.id
    },
    removeNewUserFromList(index) {
      this.newUsersList.splice(index, 1)
    },
    checkDuplicateUserAuth(phone: string) {
      if (
        phone &&
        phone.length > 0 &&
        (this.newUsersList.some((listPhone) => listPhone.phone === phone) ||
          this.users.some((user) => user.phone === phone))
      ) {
        return false
      }
      return true
    },
    userDeleteMessage(id: string) {
      this.removeUserFromList(id)
    },

    updateUserAvatar(userId: string, avatar: string) {
      this.usersAvatars[userId] = avatar
    }
  }
})

import { ApiClient } from './api.client'
import { AuthenticationHelper } from '@/lib/api/helpers/authentication'
import {
  MapHelper,
  ScheduleHelper,
  UserHelper,
  permissionHelper,
  WorkspaceHelper
} from '@/lib/api/helpers'
import CameraHelper from '@/lib/api/helpers/camera-helper'

export class ApiHelpers {
  public authentication: AuthenticationHelper
  public readonly mapHelper: MapHelper
  public readonly userHelper: UserHelper
  public readonly workspaceHelper: WorkspaceHelper
  public readonly scheduleHelper: ScheduleHelper
  public readonly camera: CameraHelper
  public readonly permission: permissionHelper
  constructor(public readonly services: ApiClient) {
    this.authentication = new AuthenticationHelper(services)
    this.mapHelper = new MapHelper()
    this.userHelper = new UserHelper(services)
    this.workspaceHelper = new WorkspaceHelper(services)
    this.scheduleHelper = new ScheduleHelper(services)
    this.camera = new CameraHelper(services)
    this.permission = new permissionHelper(services)
  }
}

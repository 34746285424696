import { AbstractHelper } from './abstract-helper'
import { ApiClient, BridgeService, CameraService } from '@/lib/api'
import type { BridgeData, Camera, CreateCameraData } from '@/lib/api'

export default class CameraHelper extends AbstractHelper {
  protected camera: CameraService
  protected bridge: BridgeService

  constructor(apiClient: ApiClient) {
    super(apiClient)
    this.camera = apiClient.camera
    this.bridge = apiClient.bridge
  }

  createManually(config: Omit<CreateCameraData, 'bridgeId'>): Promise<Camera> {
    return this.camera.create(config)
  }

  createByBridge(
    config: Omit<CreateCameraData, 'bridgeId'>,
    bridge: string | BridgeData
  ): Promise<Camera> {
    const bridgeId = typeof bridge === 'string' ? bridge : bridge.id
    return this.camera.create({
      ...config,
      bridgeId
    })
  }
}

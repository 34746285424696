import type { HeatMapOptions, RGBColor } from '@/player/interfaces'

export class HeatmapRender {
  public ctx!: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  protected input!: ImageData
  protected arrayInput: Array<number> = []
  protected colors: Array<RGBColor> = []
  protected colorMap: RGBColor[] = []
  protected colorSplitter: number = 0
  protected opacity: number = 1
  protected userMin?: number
  protected userMax?: number

  constructor(options: HeatMapOptions) {
    this.setColorPalette(options.colors)
    this.setOpacity(options.opacity)
    this.setCTX()
    this.input = this.cloneImage(options.actualInput)
    this.userMin = options.userMin || 0
    this.userMax = options.userMax || this.findBestMaxMax()
  }

  protected cloneImage(src: ImageData) {
    const dst = new ImageData(src.width, src.height)
    dst.data.set(src.data)
    return dst
  }

  protected setCTX() {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('Failed to get 2D context')
    this.ctx = ctx
  }

  protected remapInput() {
    const min = this.userMin || 0
    const max = this.userMax || 255
    const diff = max - min
    for (let i = 0; i < this.input.data.length; i++) {
      if (i % 4 === 3) {
        // channel alpha
        this.input.data[i] = 255
      } else {
        this.input.data[i] = Math.max(Math.min((255 * (this.input.data[i] - min)) / diff, 255), 0)
      }
    }
  }

  setColorPalette(colors: Array<RGBColor>) {
    this.colors = colors
    this.colorSplitter = this.colors.length - 1
    for (let i = 0; i < 256; i++) {
      this.colorMap[i] = this.colorConvertor(i / 256)
    }
  }

  setOpacity(value: number) {
    this.opacity = value
  }

  setUserScaleOption(min: number, max: number) {
    this.userMin = min
    this.userMax = max
  }

  protected colorConvertor(x: number): RGBColor {
    const part = x * this.colorSplitter
    const colorPart = Math.min(Math.floor(part), this.colorSplitter - 1)
    const colorState = (x - colorPart * (1 / this.colorSplitter)) * this.colorSplitter
    const startColor = this.colors[colorPart]
    const endColor = this.colors[colorPart + 1]
    return [
      colorState * (endColor[0] - startColor[0]) + startColor[0],
      colorState * (endColor[1] - startColor[1]) + startColor[1],
      colorState * (endColor[2] - startColor[2]) + startColor[2],
      colorState * (endColor[3] - startColor[3]) + startColor[3]
    ]
  }

  protected findBestMaxMax(): number {
    const heatmap = this.input.data
    const count = heatmap.length
    let sum = 0
    for (let i = 0; i < count; i += 4) {
      sum += heatmap[i]
    }
    const mean = sum / count / 4

    let variance_sum = 0
    for (let i = 0; i < count; i += 4) {
      const value = heatmap[i]
      variance_sum += (value - mean) * (value - mean)
    }
    const standard_deviation = Math.sqrt(variance_sum / count / 4)

    const max_val = mean + 2.5 * standard_deviation
    return Math.max(Math.min(max_val, 255), 0)
  }

  protected gaussianBlur5x() {
    const src = this.arrayInput
    const dst: Array<number> = []
    const width = this.input.width
    const height = this.input.height
    const count = width * height
    for (let n = 0; n < count; ++n) {
      if (n > 2 * width && n < count - 2 * width && n % width > 2 && n % width < width - 2) {
        const first_row = n - 2 * width
        const sec_row = n - width
        const fourth_row = n + width
        const fifth_row = n + 2 * width
        dst[n] =
          (src[first_row - 2] +
            src[first_row - 1] * 4 +
            src[first_row] * 6 +
            src[first_row + 1] * 4 +
            src[first_row + 2] +
            src[sec_row - 2] * 4 +
            src[sec_row - 1] * 16 +
            src[sec_row] * 24 +
            src[sec_row + 1] * 16 +
            src[sec_row + 2] * 4 +
            src[n - 2] * 6 +
            src[n - 1] * 24 +
            src[n] * 36 +
            src[n + 1] * 24 +
            src[n + 2] * 6 +
            src[fourth_row - 2] * 4 +
            src[fourth_row - 1] * 16 +
            src[fourth_row] * 24 +
            src[fourth_row + 1] * 16 +
            src[fourth_row + 2] * 4 +
            src[fifth_row - 2] +
            src[fifth_row - 1] * 4 +
            src[fifth_row] * 6 +
            src[fifth_row + 1] * 4 +
            src[fifth_row + 2]) /
          256
      } else {
        dst[n] = 0
      }
    }
    this.arrayInput = dst
  }

  protected preProcessInput(width: number, height: number) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('Failed to get 2D context')
    canvas.width = width
    canvas.height = height
    ctx.putImageData(this.input, 0, 0)
    ctx.drawImage(canvas, 0, 0, this.input.width, this.input.height, 0, 0, width, height)
    ctx.filter = `blur(${Math.round(width / 50)}px)`
    ctx.drawImage(canvas, 0, 0)
    this.input = ctx.getImageData(0, 0, width, height)
  }

  getImage(width: number = this.input.width, height: number = this.input.height) {
    this.remapInput()
    this.preProcessInput(width, height)
    const image = this.ctx.createImageData(width, height)
    for (let y = 0; y < this.input.height; y++) {
      for (let x = 0; x < this.input.width; x++) {
        const j = (x + y * this.input.width) * 4
        const color = this.colorMap[Math.floor(this.input.data[j])]
        image.data[j] = Math.floor(color[0])
        image.data[j + 1] = Math.floor(color[1])
        image.data[j + 2] = Math.floor(color[2])
        image.data[j + 3] = Math.floor(color[3] * this.opacity)
      }
    }
    return image
  }
}

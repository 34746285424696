import { HeatMapPeriodType } from '@/player/types'
import type { DrawConfigHeatmapTimeline } from '@/player/interfaces'

const defaultConfig: DrawConfigHeatmapTimeline = {
  periodSize: 64,
  horizontalLine: {
    height: 2,
    width: 1024,
    borderRadius: 0,
    color: {
      fill: '#BA1A1A'
    }
  },
  centerLine: {
    line: {
      height: 58,
      width: 4,
      borderRadius: 4,
      color: {
        fill: '#FFFFFF'
      },
      shadow: {
        offsetX: 0,
        offsetY: 0,
        blur: 9,
        color: 'rgba(0, 0, 0, 0.45)'
      }
    },
    circle: {
      radius: 7,
      color: {
        fill: '#FFFFFF'
      },
      shadow: {
        offsetX: 0,
        offsetY: 0,
        blur: 9,
        color: 'rgba(0, 0, 0, 0.45)'
      }
    }
  },
  font: {
    family: 'Montserrat',
    size: 12,
    weight: 500,
    lineHeight: 18,
    color: '#FFF'
  }
}
export const heatMapRenderConst: Record<HeatMapPeriodType, DrawConfigHeatmapTimeline> = {
  [HeatMapPeriodType.short]: defaultConfig,
  [HeatMapPeriodType.medium]: defaultConfig,
  [HeatMapPeriodType.long]: defaultConfig
}

import type { activeData, BridgeData } from '@/lib/api'
import { useServices } from '@/lib/services'
import { BridgeService, ResourceTypes } from '@/lib/api'
import { TagManager } from '@/modules/tag/lib/TagManager'
import { LocationManager } from '@/modules/location/lib/LocationManager'
import { MetadataManager } from '@/modules/metadata/lib/MetadataManager'
import { BridgeBaseInfo } from '@/modules/bridge/libs/base-info/BridgeBaseInfo'
import { BridgeStatusManager } from '@/modules/bridge/libs/status-manager/BridgeStatusManager'
import { BridgeVersionManager } from '@/modules/bridge/libs/version-manager/BridgeVersionManager'

export class Bridge {
  public readonly id!: string
  public readonly base: BridgeBaseInfo
  public readonly statusManager: BridgeStatusManager
  public readonly versionManager: BridgeVersionManager
  public readonly locationManager: LocationManager
  public readonly metadataManager: MetadataManager
  public readonly tagManager: TagManager
  protected service: BridgeService = useServices().bridge

  protected constructor(bridge: BridgeData) {
    this.id = bridge.id
    this.base = new BridgeBaseInfo(this, bridge)
    this.statusManager = new BridgeStatusManager(this.id, bridge)
    this.versionManager = new BridgeVersionManager(this.id, bridge)
    this.locationManager = new LocationManager(this.id, ResourceTypes.Bridge)
    this.metadataManager = new MetadataManager(this.id, ResourceTypes.Bridge)
    this.tagManager = new TagManager(this.id, ResourceTypes.Bridge)
  }

  public static async initByActivate(activeData: activeData) {
    const data = await useServices().bridge.active(activeData)
    return Bridge.initByBridge(data)
  }

  public static async initById(id: string) {
    const data = await useServices().bridge.find(id)
    return Bridge.initByBridge(data)
  }

  public static initByBridge(data: BridgeData) {
    return new Bridge(data)
  }

  async getBridgeData() {
    await Promise.all([
      this.base.find(),
      this.tagManager.load(),
      this.locationManager.load(),
      this.metadataManager.load()
    ])
  }

  updateBridge(data: BridgeData) {
    this.base.updateData(data)
  }

  public async fastDiscovery() {
    const { key } = await this.service.fastDiscovery(this.id)
    return await this.getDiscoveryResponse(key)
  }

  public async fullDiscovery() {
    const { key } = await this.service.fullDiscovery(this.id)
    return await this.getDiscoveryResponse(key)
  }

  protected async getDiscoveryResponse(key: string, maxAttempts = 5, interval = 5_000) {
    let attempts = 0
    while (attempts < maxAttempts) {
      try {
        await new Promise((resolve) => setTimeout(resolve, interval))
        const data = await this.service.discoveryResponse(key)
        if (data) {
          return data
        }
        attempts++
      } catch (error) {
        console.error('Error checking bridge status:', error)
        break
      }
    }
    throw new Error('Maximum attempts reached without active status')
  }

  public async heartBeat() {
    const { time } = await this.service.bridgeHeartbeat(this.id)
    return time
  }

  public async softRest() {
    await this.service.softReset(this.id)
    this.base.cameras.value = []
  }

  public async hardReset() {
    await this.service.hardReset(this.id)
  }

  public async forceReset() {
    await this.service.forceReset(this.id)
  }

  public async installUpdate() {
    await this.service.softwareUpdate(this.id)
  }
}

import type { CameraUsersType } from '@/modules/camera-new/interface'
import { ref, type Ref } from 'vue'
import { CameraService, type UserData } from '@/lib/api'
import { useServices } from '@/lib/services'

export class CameraUsers {
  public readonly users: Ref<CameraUsersType[]> = ref([])
  protected service: CameraService = useServices().camera
  constructor(public readonly id: string) {
    this.users.value = []
  }

  public async load() {
    const { users } = await this.service.findCameraUsers(this.id)
    this.initData(users)
  }

  public initData(users: UserData[]) {
    this.users.value = users.map((user) => {
      return {
        id: user.id,
        fullName: user.fullName
      }
    })
  }
}

import { useLayoutStoreWrapper } from '@/modules/Monitoring/helpers/layoutStoreWrapper'
import { useTabStore } from '@/stores/tab/useTabStore'
import { useRoute, useRouter } from 'vue-router'
import { useCameraStoreWrapper } from '@/modules/Monitoring/helpers/cameraStoreWrapper'
import { type LayoutBoxConfig } from '@/player/interfaces'
import { CustomSettingsType, WorkspaceHelper } from '@/lib/api'

let staticNewTabPromise: undefined | Promise<unknown> = undefined
export function useMonitoringTabsWrapper() {
  const layoutManager = useLayoutStoreWrapper()
  const cameraStore = useCameraStoreWrapper()
  const tabStore = useTabStore()
  const router = useRouter()
  const route = useRoute()
  function createMonitoringTab() {
    const id = layoutManager.createId()
    staticNewTabPromise = layoutManager
      .create(id, [])
      .then(() => addBasicCameras(id))
      .finally((staticNewTabPromise = undefined))
    return {
      name: 'streaming',
      params: {
        tabId: id
      }
    }
  }

  async function addBasicCameras(id: string) {
    await cameraStore.loadCameras()
    const cameras = [...cameraStore.camerasBasic.value]
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .slice(0, 8)
      .map((c) => ({ id: c.id }))
    return layoutManager.update(id, cameras)
  }

  function goOrCreateMonitoring() {
    const monitoringTab = tabStore.tabs.find((t) => t.route === 'streaming')
    if (monitoringTab) {
      tabStore.changeActiveTab(monitoringTab)
    } else {
      return router.push(createMonitoringTab())
    }
  }

  function goToListView() {
    return router.push({
      name: 'streamingListView'
    })
  }

  async function duplicateConfigView() {
    let configs: LayoutBoxConfig[] = []
    if (route.name === 'streaming' && route.params.tabId) {
      configs = await layoutManager.load(route.params.tabId.toString())
    } else {
      await cameraStore.loadCameras()
      configs = cameraStore.camerasBasic.value.map((c) => ({ id: c.id }))
    }
    const tabId = layoutManager.createId()
    await layoutManager.create(tabId, configs)
    staticNewTabPromise = layoutManager
      .create(tabId, configs)
      .finally((staticNewTabPromise = undefined))
    return tabId
  }

  async function createSyncPlayback(date: Date) {
    const tabId = await duplicateConfigView()
    await router.push({
      name: 'streamingPlayback',
      params: {
        tabId
      },
      query: {
        date: date.getTime()
      }
    })
  }

  async function createHeatMap(date: Date) {
    const tabId = await duplicateConfigView()
    await router.push({
      name: 'streamingHeatmap',
      params: {
        tabId
      },
      query: {
        date: date.getTime()
      }
    })
  }

  async function duplicateMonitoring(id: string) {
    const configs = await layoutManager.load(id)
    const tabId = await duplicateConfigView()
    await layoutManager.create(tabId, configs)
    staticNewTabPromise = layoutManager
      .create(tabId, configs)
      .finally((staticNewTabPromise = undefined))
    await router.push({
      name: 'streaming',
      params: {
        tabId
      }
    })
  }

  function splitArray<T>(array: T[], chunkSize: number): T[][] {
    if (chunkSize <= 0) {
      throw new Error('Chunk size must be greater than 0')
    }

    const result: T[][] = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }

    return result
  }

  async function createMonitoringForAllCameras() {
    await cameraStore.loadCameras()
    const camerasChunks = splitArray(cameraStore.camerasBasic.value, 8)
    for (const cameras of camerasChunks) {
      const key = layoutManager.createId()
      await layoutManager.create(key, cameras)
      const tabCount = tabStore.tabs.filter((t) => t.route === 'streaming').length
      const name = 'Monitoring ' + WorkspaceHelper.integerToRoman(tabCount) // todo use lang file
      await tabStore.createTab({
        name: name,
        route: 'streaming',
        settings: {
          type: CustomSettingsType.userWorkspace,
          key: 'streaming',
          value: {
            tabId: key
          }
        }
      })
    }
  }

  function getNewTabPromise() {
    return staticNewTabPromise
  }

  async function addNewCameraToTabs(id: string) {
    const requests: Promise<unknown>[] = []
    for (const tab of tabStore.arrayTabs) {
      if (tab.route === 'streaming') {
        requests.push(
          (async () => {
            const tabId = tab.settings.value.tabId
            if (tabId) {
              const settings = await layoutManager.load(tabId)
              if (settings.length < 8) {
                settings.push({
                  id
                })
                await layoutManager.update(tabId, settings)
              }
            }
          })()
        )
      }
    }
    await Promise.all(requests)
  }

  return {
    createMonitoringTab,
    goOrCreateMonitoring,
    goToListView,
    createSyncPlayback,
    createHeatMap,
    duplicateMonitoring,
    createMonitoringForAllCameras,
    getNewTabPromise,
    addNewCameraToTabs
  }
}

import { computed } from 'vue'
import { CameraStatus, PeriodType } from '@/player/types'
import { PlayerCore } from '@/player/lib/player/player-core'
import { TimeZoneHelper } from '@/player/lib/helpers/date-time'

export class PlayerGetters {
  constructor(protected readonly core: PlayerCore) {}

  public statusHasError = computed(() =>
    [CameraStatus.connecting, CameraStatus.offline, CameraStatus.error].includes(
      this.core.status.value
    )
  )

  public statusIsOffline = computed(() =>
    [CameraStatus.offline, CameraStatus.error].includes(this.core.status.value)
  )

  public cameraTimeZone = computed(
    () => this.core.information.timezone || TimeZoneHelper.getBrowserLocalTimezone()
  )
  public cameraTimeZoneDifference = computed(() => {
    const different = TimeZoneHelper.getDiffTimezone(
      new Date(this.core.playback.lastFrameDate),
      this.cameraTimeZone.value
    )
    return `${different > 0 ? '+' : ''}${Math.floor(different / 60)}:${Math.abs(different % 60)}`
  })
  public cameraCurrentFrameDateString = computed(() => {
    const dateString = TimeZoneHelper.getLocalTime(
      new Date(this.core.playback.lastFrameDate),
      this.cameraTimeZone.value
    )
    if (this.cameraTimeZone.value === TimeZoneHelper.getBrowserLocalTimezone()) {
      return dateString
    } else {
      return `${dateString} ${this.cameraTimeZoneDifference.value}HRS`
    }
  })

  public cameraPreviewFrameDateString = computed(() => {
    if (this.core.previewConfig.date > 0) {
      const dateString = TimeZoneHelper.getLocalTime(
        new Date(this.core.previewConfig.date),
        this.cameraTimeZone.value
      )
      if (this.cameraTimeZone.value === TimeZoneHelper.getBrowserLocalTimezone()) {
        return dateString
      } else {
        return `${dateString} ${this.cameraTimeZoneDifference.value}HRS`
      }
    } else return ''
  })

  public isLocalTimezone = computed(
    () => this.cameraTimeZone.value === TimeZoneHelper.getBrowserLocalTimezone()
  )

  public timelineSizeText = computed(() => {
    switch (this.core.playback.timelineSize) {
      case PeriodType.minimal:
        return '5-Mins'
      case PeriodType.quarter:
        return '15-Mins'
      case PeriodType.hourly:
        return '1-Hour '
      case PeriodType.daily:
        return '1-Day'
      case PeriodType.monthly:
        return '1-Mon'
      case PeriodType.threeMonth:
        return '3-Mon'
      default:
        return 'unknown'
    }
  })
}

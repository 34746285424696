import { defineStore } from 'pinia'
import { useServices } from '@/lib/services'
import { ResourceTypes, type ChallengeOrderEntity, type CurrentUser, ApiClient } from '@/lib/api'
import { useApplicationStore } from '@/stores/useApplicationStore'
import { ChallengeType } from '@/lib/api'
import { SESSION_SOCKET_URL } from '@/lib/host'
import router from '@/router'
import { useOnboardingStore } from '@/stores/onboarding/useOnboardingStore'
import { useImpexStore } from '@/stores/import-export/useImpexStore'
import { useCameraStore } from '@/modules/camera-new/store/cameraStore'
import { useCustomSettingStore } from '@/stores/custom-setting/useCustomSettingStore'
import { useNotificationStore } from '@/modules/Notifications/store/useNotificationStore'
import { usePaymentStore } from '@/stores/payment/usePaymentStore'
import { usePermissionsStore } from '@/modules/Permissions/'
import { SecuritySessionsStore } from '@/stores/security/Sessions'
import { useTabStore } from '@/stores/tab/useTabStore'
import { useWorkspaceStore } from '@/stores/WorkspaceSetingStore/UseWorkspaceStore'
import { useThemeStore } from '@/stores/Theme/useThemeStore'
import { SessionClient, SocketVersion } from '@/lib/socket/SessionClient'
import { useBridgeStore } from '@/modules/bridge/store'
import { PlayerRepository } from '@/player/lib/player/player-repository'
import { usePermissionManager } from '@/modules/Permissions'
import * as Sentry from '@sentry/vue'
import { useMonitoringStore } from '@/stores/tab/monitoring'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { useTagStore } from '@/modules/tag/store/TagStore'
import { NotificationDisplayTypes } from '@/modules/Notifications/store'
import { useAlertStore } from '@/stores/alert/useAlertStore'
import { useCameraStoreWrapper } from '@/modules/Monitoring/helpers/cameraStoreWrapper'

const socketVersion = SocketVersion.v1

interface AuthToken {
  accessToken: string | null
  refreshToken: string | null
}

enum UpdatePaymentListenerMethods {
  Method = 'Method',
  WS = 'WS',
  Sub = 'Sub'
}

interface RuleItem {
  id: number
  type: ChallengeType
  status: boolean
}

export enum AuthenticationWorkflowState {
  password = 'password',
  otp = 'otp',
  tOtp = 'tOtp',
  register = 'register',
  success = 'success',
  phone = 'phone'
}

interface AuthenticationState {
  phone: string
  resetPasswordToken: string
  currentUserAvatar: string
  currentUser: CurrentUser | null
  rules: Array<ChallengeOrderEntity>
  disableRules: Array<RuleItem>
  isAdmin: boolean
  activeTabProfile: number
  sessionSocket: SessionClient | null
  rulesLoading: boolean
  socketCloseMessage: any
  isSafeMode: boolean
  loginState: AuthenticationWorkflowState
}

export const useAuthenticationStore = defineStore('Authentication', {
  state: (): AuthenticationState => ({
    phone: '',
    resetPasswordToken: '',
    socketCloseMessage: '',
    currentUserAvatar: '',
    currentUser: null,
    rules: [],
    disableRules: [],
    isAdmin: false,
    activeTabProfile: 0,
    sessionSocket: null,
    rulesLoading: false,
    isSafeMode: false,
    loginState: AuthenticationWorkflowState.phone
  }),

  getters: {
    storage() {
      if (this.isSafeMode) return localStorage
      else return sessionStorage
    }
  },

  actions: {
    async initializeTokens() {
      try {
        this.isSafeMode = !!localStorage.getItem('mode') && localStorage.getItem('mode') === 'safe'
        const accessToken = this.storage.getItem('token')
        const refreshToken = this.isSafeMode ? this.storage.getItem('token') : null
        const expirationDate = this.storage.getItem('expirationDate')
        if (
          accessToken &&
          expirationDate &&
          Number(expirationDate) > Date.now() &&
          ((this.isSafeMode && refreshToken) || !this.isSafeMode)
        )
          this.setAuthorizationToken({
            accessToken,
            refreshToken
          })
        else throw new Error()
      } catch (e) {
        await this.logout()
      }
    },

    async getCurrentUser() {
      this.currentUser = await useServices().authenticationManager.authentication.currentUser()
      Sentry.setUser({
        id: this.currentUser?.user?.id,
        email: this.currentUser?.user?.email,
        username: this.currentUser?.user?.phone
      })
      return this.currentUser
    },

    async loadCurrentUserAvatar() {
      const image = await useServices().authenticationManager.user.currentAvatar()
      if (image.data.size > 0) {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            this.currentUserAvatar = reader.result as string
          },
          false
        )
        reader.readAsDataURL(image.data)
      }
    },

    async updateUserAvatar(image: FormData) {
      await useServices().authenticationManager.user.avatarUpdate(image)
      this.loadCurrentUserAvatar()
    },
    async removeUserAvatar() {
      await useServices().authenticationManager.user.avatarRemove()
      this.currentUserAvatar = ''
    },

    async checkUpdatingAction(attempt = 0) {
      await new Promise((r) => setInterval(r, 2000))
      if (ApiClient.isRunning.value) {
        attempt++
        if (attempt === 1) {
          const eventBus = useEventBus()
          eventBus.emit('waitForDoActions')
        }
        await this.checkUpdatingAction(attempt)
      }
    },

    async logout() {
      if (ApiClient.exitCheck()) {
        try {
          useApplicationStore().startLoading('logout')
          await this.checkUpdatingAction()
          this.storage.removeItem('expirationDate')
          this.storage.removeItem('mode')
          this.storage.removeItem('token')
          this.storage.removeItem('refresh')
          this.storage.removeItem('workspaceId')
          this.storage.removeItem('activeTab')
          PlayerRepository.stopStatusWatch()
          usePermissionManager().clearPermissions()
          if (this.currentUser && this.currentUser.session)
            await useServices().authenticationManager.session.remove(this.currentUser.session.id)
          this.resetAllStore(true)
          useServices().removeHeader('Authorization')
        } finally {
          await router.push({ name: 'authentication' })
          useApplicationStore().stopLoading('logout')
        }
      }
    },
    setAuthorizationToken(tokens: AuthToken) {
      const service = useServices()
      if (tokens['refreshToken']) this.setSafeToken(tokens)
      else this.setNormalToken(tokens)

      service.setHeader('Authorization', 'Bearer ' + tokens['accessToken'])
    },
    setSafeToken(tokens: AuthToken): void {
      if (tokens['accessToken']) {
        this.isSafeMode = true
        localStorage.setItem('expirationDate', String(this.calculateExpirationDate()))
        localStorage.setItem('mode', 'safe')
        localStorage.setItem('token', tokens['accessToken'])
        localStorage.setItem('refresh', tokens['refreshToken'] ? tokens['refreshToken'] : '')
      }
    },
    setNormalToken(tokens: AuthToken): void {
      if (tokens['accessToken']) {
        this.isSafeMode = false
        sessionStorage.setItem('expirationDate', String(this.calculateExpirationDate()))
        sessionStorage.setItem('mode', 'normal')
        sessionStorage.setItem('token', tokens['accessToken'])
      }
    },
    calculateExpirationDate(): number {
      return Date.now() + 24 * 3550 * 1000
    },

    async openSessionSocket() {
      this.sessionSocket = new SessionClient(SESSION_SOCKET_URL, socketVersion)
      await this.sessionSocket.start()
      this.takeOverListener()
      this.updatePermissionsListener()
      this.updateUsersListener()
      this.updateWorkspaceUsersListener()
      this.ditachUserListener()
      this.lockedWorkspaceListener()
      this.updatePaymentListener()
      this.updateNotificationListener()
      this.updateResourceListener()
      this.updateMaskListener()
    },

    takeOverListener(): void {
      if (socketVersion === SocketVersion.v1) {
        this.sessionSocket?.on('SessionTakeOver', async (data) => {
          this.socketCloseMessage = {
            os: data.metadata.uaOs,
            browser: data.metadata.uaBrowser,
            ip: data.metadata.ip
          }
          await router.push({ name: 'loginAwayError' })
        })
      } else {
        this.sessionSocket?.on('takeover', async (data) => {
          this.socketCloseMessage = data
          await router.push({ name: 'loginAwayError' })
        })
      }
    },

    async updateResourceListener() {
      this.sessionSocket?.on('ResourceEvent', async (data: any) => {
        const type = data.metadata.type as ResourceTypes
        const remoteId: string = data.metadata.remoteId
        const currentWorkspace = useWorkspaceStore().currentWorkspace

        switch (type) {
          case ResourceTypes.Camera: {
            try {
              await useCameraStore().loadCamera(remoteId)
            } catch (e) {
              if (e === 'Not Found') {
                useCameraStore().deleteCamera(remoteId, false)
              }
            }
            break
          }
          case ResourceTypes.Bridge:
            useBridgeStore().loadBridge(remoteId)
            break
          case ResourceTypes.Workspace:
            useWorkspaceStore().getCurrentWorkspace()
            if (currentWorkspace) usePermissionsStore().getUsers(currentWorkspace.id)
            break
          case ResourceTypes.Bookmark:
            PlayerRepository.refreshBookmarksForAllPlayers()
            break
          default:
            break
        }
      })
    },

    updateMaskListener() {
      this.sessionSocket?.on('MaskAltered', async (data: any) => {
        const cameraId = data.metadata.remoteId
        PlayerRepository.refreshMask(cameraId)
        setTimeout(async () => {
          await useCameraStore().cameras.get(cameraId)?.base.reload()
          useCameraStore().cameras.get(cameraId)?.thumbnail.loadThumbnail()
        })
      })
    },

    updateNotificationListener(): void {
      this.sessionSocket?.on('Notification', async () => {
        useNotificationStore().resetAllNotificationsList()
        await Promise.all([
          useNotificationStore().loadNotification(NotificationDisplayTypes.ALL),
          useNotificationStore().loadNotification(NotificationDisplayTypes.CLEARED)
        ])
      })
    },

    updatePaymentListener(): void {
      this.sessionSocket?.on('BillingEvent', async (values) => {
        const method: UpdatePaymentListenerMethods = values.scope as UpdatePaymentListenerMethods
        switch (method) {
          case UpdatePaymentListenerMethods.WS:
            await Promise.all([
              usePaymentStore().loadCurrentStripeWorkspace(true),
              usePaymentStore().getWorkspaceSubscriptions(true)
            ])
            break
          case UpdatePaymentListenerMethods.Method:
            await usePaymentStore().loadPaymentMethods(true)
            break
          case UpdatePaymentListenerMethods.Sub:
            await usePaymentStore().loadActiveSubscription(true)
            await useCameraStoreWrapper().loadCameras()
            break
        }
      })
    },

    updatePermissionsListener(): void {
      this.sessionSocket?.on('PermissionMatrixUpdated', async (data) => {
        await this.reloadPermissions()
        await useTabStore().checkTabsPermission()
      })
    },

    updateWorkspaceUsersListener(): void {
      this.sessionSocket?.on('WorkspaceUserEvent', async (data) => {
        await Promise.all([
          usePermissionsStore().getUsers(data.metadata.workspaceId, true),
          usePermissionsStore().getAllPermissions(true)
        ])
      })
    },

    updateUsersListener(): void {
      this.sessionSocket?.on('UserUpdated', async (data: any) => {
        await usePermissionsStore().getSingleActiveUser(data.metadata.userId)
        await usePermissionsStore().getAllPermissions(true)
      })
      this.sessionSocket?.on('OnlineStatusUpdated', async (data: any) => {
        await usePermissionsStore().getSingleActiveUser(data.metadata.userId)
      })
    },
    ditachUserListener(): void {
      this.sessionSocket?.on('UserDetached', async (data: any) => {
        const wsId = data.metadata.workspaceId
        const userId = data.metadata.userId
        if (
          useWorkspaceStore().currentWorkspace?.id === wsId &&
          this.currentUser?.user?.id === userId
        ) {
          await this.logout()
          window.location.reload()
        } else {
          usePermissionsStore().userDeleteMessage(userId)
        }
      })
    },

    lockedWorkspaceListener(): void {
      this.sessionSocket?.on('WorkspaceLocked', async (data: any) => {
        if (
          useWorkspaceStore().currentWorkspace?.id === data.metadata.workspaceId &&
          this.currentUser?.user?.id != data.metadata.ownerId
        ) {
          this.logout()
        }
      })
    },

    async isSessionActive() {
      const { active } = await useServices().userEventBus.checkUserSessionStatus()
      return active
    },

    closeSessionSocket() {
      this.sessionSocket?.stop()
    },

    async loadPermissions() {
      const userId = this.currentUser?.user?.id
      if (userId) {
        const permissionManager = usePermissionManager()
        await permissionManager.fetchPermissions(userId)
        permissionManager.recheckComponents()
      }
    },

    async reloadPermissions() {
      const userId = this.currentUser?.user?.id
      if (userId) {
        const permissionManager = usePermissionManager()
        await permissionManager.refetchPermissions(userId)
        permissionManager.recheckComponents()
      }
    },

    async reopenSessionSocket() {
      this.closeSessionSocket()
      await this.openSessionSocket()
    },

    async getRules(force: boolean = false) {
      if (this.rules.length === 0 || force) {
        this.rulesLoading = true
        const defaultRules = [
          {
            id: 1,
            type: ChallengeType.password,
            status: false,
            step: 1
          },
          {
            id: 2,
            type: ChallengeType.otp,
            status: false,
            step: 2
          },
          {
            id: 3,
            type: ChallengeType.tOtp,
            status: false,
            step: 3
          }
        ]
        this.rules = await useServices().authenticationManager.challenge.findAll()
        this.disableRules = defaultRules.filter(
          (item) => !this.rules.find((rule) => rule.type === item.type)
        )
        this.rulesLoading = false
      }
      return this.rules
    },
    async activateEasyLogin() {
      await useServices().authenticationManager.challenge.activateEasyLogin()
      await this.getRules(true)
    },
    async deactivateEasyLogin() {
      await useServices().authenticationManager.challenge.inactivateEasyLogin()
      await this.getRules(true)
    },
    toggleIsAdmin() {
      this.isAdmin = !this.isAdmin
    },
    resetAllStore(resetAuth: boolean = true) {
      if (resetAuth) {
        this.closeSessionSocket()
        this.$reset()
      }
      useApplicationStore().isReset = true
      useAlertStore().reset()
      useCameraStore().reset()
      useBridgeStore().reset()
      useCustomSettingStore().$reset()
      useOnboardingStore().$reset()
      useImpexStore().$reset()
      useNotificationStore().$reset()
      usePaymentStore().$reset()
      usePermissionsStore().$reset()
      SecuritySessionsStore().$reset()
      useTabStore().$reset()
      useWorkspaceStore().$reset()
      useThemeStore().$reset()
      useMonitoringStore().$reset()
      useTagStore().reset()
    }
  }
})

import type {
  CurrentUser,
  LoginData,
  RefreshData,
  RegisterData,
  ResponseLogin,
  ResponseRegister
} from '@/lib/api'
import { Service } from '../../service'

export class AuthenticationService extends Service {
  public login(data: LoginData) {
    return this.post<ResponseLogin, LoginData>('/api/u-crm/auth/login', data)
  }

  public register(data: RegisterData) {
    return this.post<ResponseRegister, RegisterData>('/api/u-crm/auth/register', data)
  }

  public refresh(data: RefreshData) {
    return this.post<RefreshData, RefreshData>('/api/u-crm/auth/refresh', data)
  }

  public currentUser() {
    return this.get<CurrentUser>('/api/u-crm/auth/current')
  }

  public eventSocket() {
    return this.post<void>('/api/events/socket/authentication/token')
  }
}

import type { ChangePermission, CreatePermission } from './permission.interfaces'
import type { Permission } from '../team'
import { Service } from '../../service'

export class PermissionService extends Service {
  public async create(data: CreatePermission): Promise<Permission> {
    return this.post(`/api/p-crm/permission`, data)
  }
  public async remove(id: string): Promise<void> {
    return this.delete(`/api/p-crm/permission/${id}`)
  }
  public async update(id: string, data: ChangePermission): Promise<Permission> {
    return this.patch(`/api/p-crm/permission/${id}`, data)
  }
}

import type {
  NotificationService,
  NotificationUserService,
  NotificationUserSettingsResponse,
  Notification
} from '@/lib/api'

export interface NotificationState {
  service: NotificationService
  userService: NotificationUserService
  notifications: {
    [NotificationDisplayTypes.ALL]: Array<Notification>
    [NotificationDisplayTypes.CLEARED]: Array<Notification>
  }
  pageNumbers: {
    [NotificationDisplayTypes.ALL]: number
    [NotificationDisplayTypes.CLEARED]: number
  }
  filters: {
    [NotificationDisplayTypes.ALL]: NotificationGroups
    [NotificationDisplayTypes.CLEARED]: NotificationGroups
  }
  limit: number
  offset: number
  offsetUser: number
  isLastPage: boolean
  isLastPageInUser: boolean
  userSettings: Array<NotifierUserSettingsMapped>
}

export enum NotificationGroups {
  all = 'all',
  cameraHealthAndTampering = 'cameraHealthAndTampering',
  aIAndVideoIntelligenceSuit = 'aIAndVideoIntelligenceSuit',
  billingAndInvoicing = 'billingAndInvoicing',
  securityAndAuthentication = 'securityAndAuthentication'
}

export interface NotifierUserSettingsMapped extends NotificationUserSettingsResponse {
  notificationGroup: NotificationGroups
}

export enum NotificationDisplayTypes {
  ALL = 'all',
  CLEARED = 'cleared'
}

import type { HeatMapOptions, RGBColor } from '@/player/interfaces'
import { HEATMAP_DEFAULT_COLORS } from '@/player/types'
import { HeatmapRender } from '@/player/lib/heatmap/heatmap-render'

export class HeatMapRenderFactory {
  protected options: Partial<HeatMapOptions> = {}

  colors(colors: Array<RGBColor>) {
    this.options.colors = colors
    return this
  }

  opacity(value: number) {
    this.options.opacity = value
    return this
  }

  scaleOption(min: number, max: number) {
    this.options.userMin = min
    this.options.userMax = max
    return this
  }

  create(value: ImageData) {
    const finalOption: HeatMapOptions = {
      actualInput: value,
      colors: this.options.colors || HEATMAP_DEFAULT_COLORS,
      opacity: this.options.opacity || 1,
      userMin: this.options.userMin,
      userMax: this.options.userMax
    }
    return new HeatmapRender(finalOption)
  }

  setRenderCanvas(canvas: HTMLCanvasElement | OffscreenCanvas) {
    // HeatmapRender.renderCanvas = canvas
  }
}

import { CanvasDrawBasedHelper } from '@/player/lib/helpers/canvas-draw-helper'
import type { DrawConfigBadge } from '@/player/interfaces'
import { TimelineHelpers } from '@/player/lib/playback-timeline/timeline-helpers'
import { PeriodType } from '@/player/types'

export class TimelineDrawerHelpers extends CanvasDrawBasedHelper {
  protected drawDateLabelInternal(text: string, x: number, y: number, config: DrawConfigBadge) {
    const width = this.ctx.measureText(text).width
    this.drawLine(x, y + config.paddingY, {
      height: config.font.lineHeight + 2 * config.paddingY,
      width: width + 2 * config.paddingX,
      color: {
        fill: config.color
      },
      borderRadius: config.borderRadius
    })
    this.drawText(x + config.paddingX * 2, y + 1, text, config.font)
  }

  drawDateLabel(
    date: number,
    x: number,
    y: number,
    config: DrawConfigBadge,
    periodType: PeriodType
  ) {
    let needDraw = false
    let text = ''
    if (periodType === PeriodType.daily) {
      const d = new Date(date)
      if (d.getDate() === 1) {
        text = TimelineHelpers.getTextOfDate(date, PeriodType.monthly)
        needDraw = true
      }
    } else if (periodType === PeriodType.monthly) {
      const d = new Date(date)
      if (d.getMonth() === 0) {
        text = d.getFullYear().toString()
        needDraw = true
      }
    } else if (periodType === PeriodType.threeMonth) {
      const d = new Date(date)
      if (d.getMonth() < 3) {
        text = d.getFullYear().toString()
        needDraw = true
      }
    }
    if (needDraw) {
      this.drawDateLabelInternal(text, x, y, config)
    }
  }
}

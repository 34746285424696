import type {
  BridgeProxyAuthorityData,
  ResponseBridgeProxyAuthorityData,
  ResponseBridgeProxyTime
} from './bridge-proxy.interfaces'
import { Service } from '../service'
export class BridgeProxyService extends Service {
  // ***** BRIDGE APIS  *****

  public authorize(data: BridgeProxyAuthorityData) {
    return this.post<ResponseBridgeProxyAuthorityData, BridgeProxyAuthorityData>(
      `/api/bridge-proxy/device`,
      data
    )
  }
  public time() {
    return this.get<ResponseBridgeProxyTime>(`/api/bridge-proxy/device`)
  }
}

import { CanvasDrawBasedHelper } from '@/player/lib/helpers/canvas-draw-helper'
import type {
  DrawConfigAnnotationLabel,
  DrawConfigCircle,
  DrawConfigObject,
  DrawConfigPath,
  DrawConfigRectangle
} from '@/player/interfaces'
import { PathActionType } from '@/player/types'

export class AnnotatorDrawHelpers extends CanvasDrawBasedHelper {
  renderHeight = 0
  renderWidth = 0

  convertX(x: number) {
    return x * this.renderWidth
  }

  convertY(y: number) {
    return y * this.renderHeight
  }

  protected drawOnCanvas(path: Path2D, obj: DrawConfigObject) {
    this.setColor(obj.color)
    this.setShadow(obj.shadow)
    if (obj.lineWidth) {
      this.ctx.lineWidth = obj.lineWidth
    }
    if (obj.color.fill) {
      this.ctx.fill(path)
    } else {
      this.ctx.stroke(path)
    }
  }

  drawRectangle(obj: DrawConfigRectangle) {
    const x1 = this.convertX(obj.x1)
    const x2 = this.convertX(obj.x2)
    const y1 = this.convertY(obj.y1)
    const y2 = this.convertY(obj.y2)
    const path = new Path2D()
    path.roundRect(x1, y1, x2 - x1, y2 - y1, obj.borderRadius || 0)
    this.drawOnCanvas(path, obj)
    return path
  }

  drawCircle(obj: DrawConfigCircle) {
    const x1 = this.convertX(obj.x)
    const y1 = this.convertY(obj.y)
    const radius = this.convertX(obj.radius)
    const path = new Path2D()
    path.arc(x1, y1, radius, 0, 2 * Math.PI)
    this.drawOnCanvas(path, obj)
    return path
  }

  drawPath(obj: DrawConfigPath) {
    const actions = obj.points.map(({ x, y, type }) => ({
      x: this.convertX(x),
      y: this.convertY(y),
      type
    }))
    const path = new Path2D()
    for (const { x, y, type } of actions) {
      switch (type) {
        case PathActionType.line:
          path.lineTo(x, y)
          break
        case PathActionType.move:
          path.moveTo(x, y)
          break
      }
    }
    this.drawOnCanvas(path, obj)
    return path
  }

  protected calculateRectanglePosition(
    config: DrawConfigAnnotationLabel,
    obj: DrawConfigRectangle
  ) {
    let x = 0
    let y = 0
    if (config.position?.top) {
      y = this.convertY(obj.y1)
    } else {
      y = this.convertY(obj.y2)
    }
    if (config.position?.left) {
      x = this.convertX(obj.x1)
    } else {
      x = this.convertX(obj.x2)
    }
    return { x, y }
  }
  drawLabelOnRectangle(config: DrawConfigAnnotationLabel, obj: DrawConfigRectangle) {
    const { x, y } = this.calculateRectanglePosition(config, obj)
    this.setTextStyle(config)
    this.setShadow(config.shadow)
    const measure = this.ctx.measureText(config.content || 'unknown')
    this.ctx.fillStyle = config.backgroundColor || '#000000'
    const path = new Path2D()
    path.roundRect(
      x,
      y,
      measure.width + 2 * config.paddingX,
      config.lineHeight + 2 * config.paddingY,
      2
    )
    this.ctx.fill(path)
    this.ctx.fillStyle = config.color
    this.ctx.fillText(config.content || 'unknown', x + config.paddingX, y + config.paddingY + 2)
  }
}

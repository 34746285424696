export default {
  required: 'This is required.',
  emailNotValid: 'Invalid. Please double check.',
  internetProviderNotValid:
    "Please use only letters, numbers, spaces, and the special characters . - ( ) ' :",
  minChar: 'Must be at least be {min} characters long.',
  maxChar: 'Oops, too long! Max character count is {max}.',
  valueNotMatch: 'Invalid. Please double check.',
  mustNumber: 'Invalid. Please double check.',
  mustString: 'Invalid. Please double check.',
  valueNotLess: 'Invalid. Please double check.',
  valueNotGreat: 'Invalid. Please double check.',
  isString: 'Invalid. Please double check.',
  atLeast10: 'MUST contain at least 10 characters',
  oneUpper: 'MUST contain at least one uppercase letter',
  oneNumber: 'MUST contain at least one number',
  oneSpecial: 'MUST contain at least one special character',
  strong: 'MUST contain Strong enough'
}

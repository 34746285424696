import { defineStore } from 'pinia'
import { useServices } from '@/lib/services'
import { groupBy, orderBy } from 'lodash'
import { NormalizeData } from '@/utils/normalize'
import { updatedItem } from '@/stores/StoreUtils'
import { NotificationGroups, NotificationDisplayTypes } from './userNotification.interface'
import type { NotificationState } from './userNotification.interface'
import { NotificationTypes, NotificationNames } from '@/lib/api'
import type { NotificationUserSettings, NotificationUserSettingsResponse } from '@/lib/api'

const notificationGroups = {
  all: [],

  cameraHealthAndTampering: [
    NotificationNames.alertingHwTampering,
    NotificationNames.alertingSwTampering,
    NotificationNames.cameraHealthHealthy,
    NotificationNames.cameraHealthUnhealthy,
    NotificationNames.cameraCreationFailed,
    NotificationNames.cameraRecordingPaused,
    NotificationNames.cameraRecordingResumed,
    NotificationNames.exportExportFailed,
    NotificationNames.exportExportFinished
  ],

  aIAndVideoIntelligenceSuit: [
    NotificationNames.alertingHwMotion,
    NotificationNames.alertingSwMotion,
    NotificationNames.alertingHwAudio,
    NotificationNames.alertingSwAudio,
    NotificationNames.alertingHwFence,
    NotificationNames.alertingSwFence,
    NotificationNames.alertingHwCrossLine,
    NotificationNames.alertingSwCrossLine,
    NotificationNames.alertingSwCrossLineIn,
    NotificationNames.alertingSwCrossLineOut
  ],

  billingAndInvoicing: [
    NotificationNames.paymentActionRequired,
    NotificationNames.paymentRenewalPaused,
    NotificationNames.paymentTrialEnding,
    NotificationNames.paymentTrialEnded,
    NotificationNames.paymentInvoicePaid,
    NotificationNames.paymentPmExpiring
  ],

  securityAndAuthentication: [
    NotificationNames.workspaceInviteUser,
    NotificationNames.workspaceInviteAccepted,
    NotificationNames.workspaceInviteCancelled,
    NotificationNames.workspaceUserRemoved,
    NotificationNames.workspaceUserSuspended,
    NotificationNames.permissionSuspendTeam,
    NotificationNames.userEventSessionActivated,
    NotificationNames.authenticationUpdatePhone,
    NotificationNames.authenticationChangePhoneAttempt,
    NotificationNames.authenticationUpdateEmail,
    NotificationNames.authenticationPasswordUpdated,
    NotificationNames.authenticationSuspiciousActivity,
    NotificationNames.authenticationSuccessfulRegister,
    NotificationNames.authenticationSuccessfulLogin,
    NotificationNames.authenticationActivateMFA
  ]
}

export const useNotificationStore = defineStore('notificationStore', {
  state: (): NotificationState => ({
    service: useServices().notification,
    userService: useServices().notificationUserService,
    notifications: {
      [NotificationDisplayTypes.ALL]: [],
      [NotificationDisplayTypes.CLEARED]: []
    },
    pageNumbers: {
      [NotificationDisplayTypes.ALL]: 0,
      [NotificationDisplayTypes.CLEARED]: 0
    },
    filters: {
      [NotificationDisplayTypes.ALL]: NotificationGroups.all,
      [NotificationDisplayTypes.CLEARED]: NotificationGroups.all
    },
    limit: 10,
    offset: 0,
    offsetUser: 0,
    isLastPage: false,
    isLastPageInUser: false,
    userSettings: []
  }),

  getters: {
    haveUnreadNotification() {
      return (type: NotificationDisplayTypes) => {
        if (this.notifications[type]) {
          return !!this.notifications[type].find((item) => !item.readAt)
        } else {
          return false
        }
      }
    },

    groupSettings(state) {
      return (group: NotificationGroups) => {
        const filteredNotifications = state.userSettings.filter(
          (notification) => notification.notificationGroup === group
        )

        // Reduce to create the desired object
        const result = filteredNotifications.reduce((acc, notification) => {
          const { notificationType } = notification

          // Count occurrences of each notificationType
          acc[notificationType] = (acc[notificationType] || 0) + 1

          return acc
        }, {} as Record<string, number>)

        // Convert the counts to booleans based on whether the count is equal to 10
        return Object.keys(result).reduce((acc, key) => {
          acc[key] = result[key] === notificationGroups[group].length
          return acc
        }, {} as Record<string, boolean>)
      }
    },

    activeFilter(state) {
      return (type: NotificationDisplayTypes) => state.filters[type]
    },

    filterList() {
      return (type: NotificationDisplayTypes) =>
        notificationGroups[this.filters[type]].length > 0
          ? notificationGroups[this.filters[type]]
          : undefined
    },

    notificationsList() {
      return (type: NotificationDisplayTypes) => {
        return groupBy(
          orderBy(this.notifications[type], 'createdAt', 'desc').map((item) => ({
            ...item,
            createdAtNormalize: String(NormalizeData.standardDate(String(item.createdAt), 'en'))
          })),
          'createdAtNormalize'
        )
      }
    }
  },

  actions: {
    reset() {
      this.resetAllNotificationsList()
      this.limit = 10
      this.offset = 0
      this.offsetUser = 0
      this.isLastPage = false
      this.isLastPageInUser = false
      this.userSettings = []
      // this.currentUserSchedule = []
    },

    resetAllNotificationsList() {
      this.resetNotificationList(NotificationDisplayTypes.ALL)
      this.resetNotificationList(NotificationDisplayTypes.CLEARED)
      this.filters = {
        [NotificationDisplayTypes.ALL]: NotificationGroups.all,
        [NotificationDisplayTypes.CLEARED]: NotificationGroups.all
      }
    },

    changeActiveFilter(filter: NotificationGroups, type: NotificationDisplayTypes, read?: boolean) {
      this.filters[type] = filter
      this.resetNotificationList(type)
      this.loadNotification(type, read)
    },

    resetNotificationList(type: NotificationDisplayTypes) {
      this.notifications[type] = []
      this.pageNumbers[type] = 0
    },

    async loadNextPage(type: NotificationDisplayTypes, read?: boolean) {
      await this.loadNotification(type, read, true)
    },

    async loadNotification(
      type: NotificationDisplayTypes,
      read?: boolean,
      loadMore: boolean = false
    ) {
      if (this.pageNumbers[type] === 0 || loadMore) {
        this.pageNumbers[type] += 1

        const clear = type === NotificationDisplayTypes.CLEARED ? false : undefined
        const fetchedData = await this.service.findAdvance(
          { clear, read, name: this.filterList(type) },
          this.pageNumbers[type]
        )

        this.notifications[type] = [...this.notifications[type], ...fetchedData]
        if (fetchedData.length === 0) this.pageNumbers[type] -= 1
        if (fetchedData.length < 20) this.isLastPage = true
      }
    },

    async markAsSeen(id: string) {
      const data = await this.service.markAsRead(id)
      for (const group in this.notifications) {
        updatedItem(this.notifications[group], data)
      }
    },

    async markAsCleared(id: string) {
      await this.service.markAsCleared(id)
      this.notifications[NotificationDisplayTypes.CLEARED] = this.notifications[
        NotificationDisplayTypes.CLEARED
      ].filter((notif) => {
        return notif.id !== id
      })
    },

    // Notifier settings
    async getUserSettings() {
      const notificationSettings = await useServices().notificationUserService.getCurrent()
      this.userSettings = this.settingsSetializer(notificationSettings)
    },

    settingsSetializer(settings: Array<NotificationUserSettingsResponse>) {
      return settings.map((setting) => {
        let groupName = ''
        for (const group in notificationGroups) {
          const has = notificationGroups[group].includes(setting.notificationName)
          if (has) {
            groupName = group
            break
          }
        }
        return {
          ...setting,
          notificationGroup: NotificationGroups[groupName]
        }
      })
    },

    async turnOffNotifications(group: NotificationGroups, notifier: NotificationTypes) {
      const settings = this.userSettings
      if (settings) {
        try {
          const ids = settings
            .filter(
              (setting) =>
                setting.notificationGroup === group && setting.notificationType === notifier
            )
            .map(({ id }) => id)
          this.userService.remove(ids)
          this.filterUserSettings(group, notifier)
        } catch (e) {
          console.log(e)
        }
      }
    },

    filterUserSettings(group: NotificationGroups, notifier: NotificationTypes) {
      this.userSettings = this.userSettings.filter(
        (setting) => !(setting.notificationGroup == group && setting.notificationType === notifier)
      )
    },

    async turnOnNotifications(group: NotificationGroups, notifier: NotificationTypes) {
      const notificationSetting: NotificationUserSettings = {
        notificationName: notificationGroups[group],
        notificationType: notifier
      }
      const newSettings = await this.userService.create(notificationSetting)
      this.userSettings.push(...this.settingsSetializer(newSettings))
    }
  }
})

import { ref } from 'vue'

export class PlayerUi {
  menuDialog = {
    show: ref(false),
    menuX: ref(0),
    menuY: ref(0)
  }

  timelineSettingDialog = {
    isMenuActive: ref(false),
    menuX: ref(0),
    menuY: ref(0)
  }
}

import { Buffer } from 'buffer'

// Precompute the full conversion table for uLaw bytes to linear samples
const fullConversionTable = new Int16Array(256)
const exp_lut = [0, 132, 396, 924, 1980, 4092, 8316, 16764]
for (let byte = 0; byte < 256; byte++) {
  const uLaw = ~byte
  const segment = (uLaw & 0x70) >> 4
  const mantissa = uLaw & 0x0f
  let sample = exp_lut[segment] + (mantissa << (segment + 3))
  sample = (uLaw & 0x80) !== 0 ? -sample : sample
  fullConversionTable[byte] = sample
}

export function decodePcmULaw(g711UData: Buffer): Float32Array {
  if (!g711UData) {
    throw new Error('Invalid input: Expected a Buffer instance.')
  }

  // Convert uLaw data to linear samples using the precomputed table
  const samples = new Int16Array(g711UData.length)
  for (let i = 0; i < g711UData.length; i++) {
    samples[i] = fullConversionTable[g711UData[i]]
  }

  // Normalize the samples to Float32Array
  const float32Array = new Float32Array(samples.length)
  const normalizationFactor = 1 / 32768
  for (let i = 0; i < samples.length; i++) {
    float32Array[i] = samples[i] * normalizationFactor
  }
  return float32Array
}

export default {
  heatmap: {
    analysisDuration: 'Analysis Duration',
    timeSlice: 'Time Slices',
    twoHours: '2-hour',
    fourHours: '4-hour',
    eightHours: '8-hour',
    twelveHours: '12-hour',
    aDay: '24-hour',
    aWeek: '7-Day',
    filters: {
      fourHours: 'by 4 hours',
      oneDayHours: 'by 24 hours',
      oneWeekHours: 'by a week'
    },
    tooltip: {
      help: 'Time Slice: Imagine zooming in on a particular segment of your entire data collection period. It segments the long analysis process, which could span 4 hours, 24 hours, or a week, into smaller, easier-to-handle chunks. This allows you to closely observe and understand how the patterns in your data shift during these shorter, specific intervals.',
      analysisDuration:
        'Analysis Duration is the total time frame for which heatmap data is collected and analyzed.',
      timeSlice:
        'Analysis Duration is the total time frame for which heatmap data is collected and analyzed.'
    },
    noHeatMapData:
      'Your AI heatmaps for this camera are brewing!  \n' +
      'Pop back in less than 2 hours for a peek.  ',
    shortWait: 'Short Wait '
  }
}

import type { HeatMapRecordInfo } from '@/player/interfaces'
import { ref, watch } from 'vue'
import { getHeatmapCache } from '@/player/lib/heatmap/heatmap-cache'
import { PlayerCore } from '@/player/lib/player/player-core'
import { HeatMapRenderFactory } from '@/player/lib/heatmap/heatmap-render-factory'
import { getHeatMapWorker } from '@/player/lib/heatmap/heatmap-worker'

export class HeatMapDisplay {
  protected height = 0
  protected width = 0
  protected lastImageDate: ImageData | undefined = undefined
  protected lastShowedImage = ref<HeatMapRecordInfo | undefined>()

  get renderHeight() {
    return this.height
  }

  get renderWidth() {
    return this.width
  }

  set renderHeight(value: number) {
    this.setRenderSize(this.width, value)
  }

  set renderWidth(value: number) {
    this.setRenderSize(value, this.height)
  }

  setRenderSize(width: number, height: number) {
    this.width = width
    this.height = height
    this.ctx1.canvas.width = width
    this.ctx2.canvas.width = width
    this.ctx1.canvas.height = height
    this.ctx2.canvas.height = height
    if (this.lastImageDate) {
      const ctx = this.getUpper()
      this.clear(this.getUpper())
      this.drawImage(ctx, this.lastImageDate)
    }
  }

  constructor(
    public readonly core: PlayerCore,
    public readonly ctx1: CanvasRenderingContext2D,
    public readonly ctx2: CanvasRenderingContext2D,
    public readonly cache = getHeatmapCache()
  ) {
    watch(() => this.core.heatmapConfig.activeImage, this.update.bind(this))
  }

  get config() {
    return this.core.heatmapConfig
  }

  flip() {
    this.config.displayFlipper = !this.config.displayFlipper
  }

  getLower() {
    return this.config.displayFlipper ? this.ctx2 : this.ctx1
  }

  getUpper() {
    return this.config.displayFlipper ? this.ctx1 : this.ctx2
  }

  clear(ctx: CanvasRenderingContext2D) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
  }

  protected drawImage(ctx: CanvasRenderingContext2D, image: ImageData) {
    ctx.putImageData(image, 0, 0, 0, 0, this.width, this.height)
  }

  protected async getImage(data: ImageData) {
    const worker = getHeatMapWorker()
    if (worker.factory) {
      try {
        const tmp = await worker.factory.create(data)
        return await tmp.getImage()
      } catch (e) {
        console.error(e)
      }
    }
    console.warn('go local mode')
    return Promise.resolve(new HeatMapRenderFactory().create(data).getImage())
  }

  async render(address: string) {
    const data = await this.cache.request(address)
    if (!data) throw new Error('image cant load')
    this.lastImageDate = await this.getImage(data)
    const ctx = this.getLower()
    setTimeout(() => {
      if (this.lastImageDate) {
        this.drawImage(ctx, this.lastImageDate)
      }
    })
  }

  async show(activeItem: HeatMapRecordInfo) {
    this.clear(this.getLower())
    await this.render(activeItem.image)
    this.flip()
    this.lastShowedImage.value = activeItem
  }

  hide() {
    this.lastShowedImage.value = undefined
    this.clear(this.getLower())
    this.clear(this.getUpper())
  }

  async update() {
    const activeImage = this.core.heatmapConfig.activeImage
    if (activeImage) {
      const lastShowedImageValue = this.lastShowedImage.value
      if (
        lastShowedImageValue &&
        lastShowedImageValue.image &&
        lastShowedImageValue.image === activeImage.image
      ) {
        return
      } else {
        await this.show(activeImage)
      }
    } else {
      this.hide()
    }
  }
}

import type { CameraService, CameraStatusData, CameraStatusObject } from '@/lib/api'
import { CameraCore } from '@/modules/camera-new/CameraCore'
import { SyncAbleObject } from '@/modules/camera-new/SyncAbleObject'
import type { CameraCoreComposite } from '@/modules/camera-new/interface'
import { useServices } from '@/lib/services'
import { CameraStatusBackgroundService } from '@/modules/camera-new/libs/status/CameraStatusBackgroundService'
import type { Ref } from 'vue'
import { watch, type WatchStopHandle, ref } from 'vue'
import { CameraStatusType } from '@/modules/camera-new/types'
import { CameraHealthOverall } from '@/lib/api'
import { PlayerRepository } from '@/player/lib/player/player-repository'

export class CameraStatus
  extends SyncAbleObject<CameraStatusObject>
  implements CameraCoreComposite
{
  public readonly id: string
  protected service: CameraService = useServices().camera
  protected static updater = new CameraStatusBackgroundService()
  public cameraStatus: Ref<CameraStatusType> = ref(CameraStatusType.offline)
  private readonly UpdateWatcher: WatchStopHandle
  private attemptReconnect: number = 0

  constructor(public readonly core: CameraCore) {
    super(CameraStatus.initialCameraStatusData())
    this.id = this.core.id
    this.UpdateWatcher = watch(() => this.cameraStatus.value, this.onchangeStatus.bind(this))
    CameraStatus.updater.register(this)
  }

  protected static initialCameraStatusData(): CameraStatusObject {
    return {
      enabled: false,
      connecting: false,
      connected: false,
      recording: false,
      analysing: false,
      liveHighResStreamError: false,
      liveLowResStreamError: false,
      livePoorStreamError: false,
      recordStreamError: false,
      analyticStreamError: false,
      program: false,
      alarm: false,
      recordFailed: false,
      standBy: false,
      streaming: false,
      overall: CameraHealthOverall.unhealthy
    }
  }

  onchangeStatus() {
    // CameraStatus.updater.restart()
    if (
      this.cameraStatus.value === CameraStatusType.live ||
      this.cameraStatus.value === CameraStatusType.recording
    ) {
      this.core.thumbnail.loadThumbnail()
    }
  }

  async update(advance = false): Promise<void> {
    try {
      this.cameraStatus.value = CameraStatusType.connecting
      const { status } = await this.fetch(advance)
      PlayerRepository.updateStatus(this.id, status) // to update player when other section of app updated
      this.setStatus(status)
    } catch (error) {
      this.cameraStatus.value = CameraStatusType.error
    }
  }

  setStatus(status: CameraStatusObject) {
    Object.assign(this.data, status)
    this.sync()
    this.evaluate()
  }

  protected async fetch(advance: boolean): Promise<CameraStatusData> {
    if (advance || this.isCameraUpdated()) return this.service.statusCameraAdvance(this.id)
    else {
      return this.service.cameraStatus(this.id)
    }
  }

  protected evaluate(): void {
    if (!this.data) {
      this.attemptReconnect = 0
      this.cameraStatus.value = CameraStatusType.troubleshoot
      return
    }

    const { recording, connecting } = this.data
    const isHealthy = this.isCameraHealthy()
    if (connecting && this.attemptReconnect < 5) {
      this.cameraStatus.value = CameraStatusType.connecting
      this.handleConnectingStatus()
    } else if (isHealthy) {
      this.attemptReconnect = 0
      this.cameraStatus.value = recording ? CameraStatusType.recording : CameraStatusType.live
    } else {
      this.attemptReconnect = 0
      this.cameraStatus.value = connecting ? CameraStatusType.connecting : CameraStatusType.error
    }
  }

  protected async handleConnectingStatus(): Promise<void> {
    this.attemptReconnect++
    await this.delay(2_000) // Wait for 5 seconds before attempting an update.
    await this.update(true)
  }

  getOverall() {
    return this.data.overall
  }

  isConnecting() {
    return this.data.connecting
  }

  isCameraHealthy(): boolean {
    return (
      Boolean(this.data) &&
      this.data.enabled &&
      !this.data.connecting &&
      (this.data.connected || this.data.standBy) &&
      !this.data.recordStreamError &&
      !this.data.liveHighResStreamError &&
      !this.data.liveLowResStreamError &&
      !this.data.livePoorStreamError
    )
  }

  protected isCameraUpdated(): boolean {
    const cameraUpdateTime = this.core.base.data.updatedAt.getTime()
    const cameraCreateTime = this.core.base.data.createdAt.getTime()
    const currentDate = new Date().getTime() - 15000 // Consider updated if within the last 15 seconds.
    return cameraUpdateTime >= currentDate || cameraCreateTime >= currentDate
  }

  destroy() {
    CameraStatus.updater.unregister(this)
    super.destroy()
  }

  unregisterFroUpdater() {
    CameraStatus.updater.unregister(this)
  }

  protected delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}

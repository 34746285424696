import { BadRequestException } from '../http-errors'

export class TimeNotSync extends BadRequestException {
  constructor(time: number) {
    super({
      message: 'time not sync',
      time: Date.now(),
      deviceTime: time
    })
  }
}

export enum messageType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info'
}

export enum messageStyle {
  base = 'base',
  outline = 'outline',
  primary = 'primary'
}

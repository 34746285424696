import { Service } from '@/lib/api'
import type {
  ActivationScheduleDto,
  CreateScheduleDto,
  RemoveScheduleDto,
  ScheduleEntity,
  UpdateScheduleDto
} from '@/lib/api/services/ai/alert/alert.interface'

export class AlertService extends Service {
  create(data: CreateScheduleDto): Promise<ScheduleEntity> {
    return this.post<ScheduleEntity>('/api/alert-manager/schedule', data)
  }

  update(scheduleId: string, data: UpdateScheduleDto): Promise<ScheduleEntity> {
    return this.patch<ScheduleEntity>(`/api/alert-manager/schedule/${scheduleId}`, data)
  }

  getCameraSchedules(cameraId: string): Promise<Array<ScheduleEntity>> {
    return this.get<Array<ScheduleEntity>>(`/api/alert-manager/schedule/camera/${cameraId}`)
  }

  getCurrentUserSchedules(): Promise<Array<ScheduleEntity>> {
    return this.get<Array<ScheduleEntity>>(`/api/alert-manager/schedule/user/current`)
  }

  getCurrentWorkspaceSchedules(): Promise<Array<ScheduleEntity>> {
    return this.get<Array<ScheduleEntity>>(`/api/alert-manager/schedule/workspace`)
  }

  getUserSchedules(userId: string): Promise<Array<ScheduleEntity>> {
    return this.get<Array<ScheduleEntity>>(`/api/alert-manager/schedule/user/${userId}`)
  }

  updateUserScheduleStatus(scheduleId: string, data: ActivationScheduleDto) {
    return this.patch<Array<ScheduleEntity>>(
      `/api/alert-manager/schedule/${scheduleId}/user/activation`,
      data
    )
  }

  remove(scheduleId: string, settings: RemoveScheduleDto) {
    return this.deleteData<ScheduleEntity>(`/api/alert-manager/schedule/${scheduleId}`, settings)
  }
}

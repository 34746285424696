export type Tape = [number, number]

class IntervalNode {
  interval: Tape
  maxEnd: number
  left: IntervalNode | null = null
  right: IntervalNode | null = null

  constructor(interval: Tape) {
    this.interval = interval
    this.maxEnd = interval[1]
  }
}

export class IntervalTree {
  root: IntervalNode | null = null

  insert(interval: Tape) {
    this.root = this._insert(this.root, interval)
  }

  private _insert(node: IntervalNode | null, interval: Tape): IntervalNode {
    if (!node) return new IntervalNode(interval)

    // Check and merge if the new interval overlaps with the current node's interval
    if (interval[0] <= node.interval[1] && interval[1] >= node.interval[0]) {
      node.interval = [
        Math.min(node.interval[0], interval[0]),
        Math.max(node.interval[1], interval[1])
      ]
    } else if (interval[0] < node.interval[0]) {
      node.left = this._insert(node.left, interval)
    } else {
      node.right = this._insert(node.right, interval)
    }

    // Check left and right children for further possible merges
    if (node.left && node.left.interval[1] >= node.interval[0]) {
      node.interval = [
        Math.min(node.left.interval[0], node.interval[0]),
        Math.max(node.left.interval[1], node.interval[1])
      ]
      node.left = null // Remove merged left child
    }

    if (node.right && node.interval[1] >= node.right.interval[0]) {
      node.interval = [
        Math.min(node.interval[0], node.right.interval[0]),
        Math.max(node.interval[1], node.right.interval[1])
      ]
      node.right = null // Remove merged right child
    }

    // Update the maxEnd property
    node.maxEnd = Math.max(
      node.interval[1],
      node.left ? node.left.maxEnd : Number.NEGATIVE_INFINITY,
      node.right ? node.right.maxEnd : Number.NEGATIVE_INFINITY
    )

    return node
  }

  queryRange(start: number, end: number): Tape[] {
    const result: Tape[] = []
    this._queryRange(this.root, start, end, result)
    return result
  }

  private _queryRange(node: IntervalNode | null, start: number, end: number, result: Tape[]) {
    if (!node) return

    if (node.interval[0] <= end && node.interval[1] >= start) {
      result.push(node.interval)
    }

    if (node.left && node.left.maxEnd >= start) {
      this._queryRange(node.left, start, end, result)
    }

    if (node.right && node.interval[0] <= end) {
      this._queryRange(node.right, start, end, result)
    }
  }

  // New method to retrieve all intervals in sorted order for testing
  getIntervals(): Tape[] {
    const intervals: Tape[] = []
    this._inOrderTraversal(this.root, intervals)
    return intervals
  }

  private _inOrderTraversal(node: IntervalNode | null, intervals: Tape[]) {
    if (!node) return
    this._inOrderTraversal(node.left, intervals)
    intervals.push(node.interval)
    this._inOrderTraversal(node.right, intervals)
  }
}

import { Buffer } from 'buffer'

export function decodePcmF32LE(pcmF32LEData: Buffer): Float32Array {
  if (!pcmF32LEData || !(pcmF32LEData instanceof Buffer)) {
    throw new Error('Invalid input: Expected a Buffer instance.')
  }

  // Each sample is 4 bytes, so the number of samples is the buffer length divided by 4
  const numSamples = pcmF32LEData.length / 4
  const float32Array = new Float32Array(numSamples)

  for (let i = 0; i < numSamples; i++) {
    // Directly read the 32-bit float value considering little endian format
    float32Array[i] = pcmF32LEData.readFloatLE(i * 4)
  }
  return float32Array
}

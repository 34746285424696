import { useServices } from '@/lib/services'
import { type CreateCustomerSettingResponse, CustomSettingsType } from '@/lib/api'
import { type LayoutBoxConfig } from '@/player/interfaces'

export function useLayoutStoreWrapper() {
  const service = useServices().customSetting
  const keyIdMap: Map<string, string> = new Map()

  function updateKeyIdMap(response: CreateCustomerSettingResponse) {
    keyIdMap.set(response.key, response.id)
    return Array.isArray(response.value) ? response.value : response.value.data
  }

  function createId(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomString = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      randomString += characters[randomIndex]
    }
    return randomString
  }

  function create(id: string, value: LayoutBoxConfig[] = []): Promise<LayoutBoxConfig[]> {
    return service
      .create({
        key: `layout:${id}`,
        type: CustomSettingsType.userWorkspace,
        value: {
          data: value,
          createdAt: Date.now()
        }
      })
      .then(updateKeyIdMap)
  }

  function removeExtraSettings(data: CreateCustomerSettingResponse[]) {
    if (data.length > 1) {
      for (let i = 1; i < data.length; i++) {
        service.remove(data[i].id)
      }
    }
  }

  function load(id: string): Promise<LayoutBoxConfig[]> {
    return service
      .find({
        key: `layout:${id}`,
        type: CustomSettingsType.userWorkspace
      })
      .then((res) => {
        res.sort((a, b) => (a.value?.createdAt - b.value?.createdAt < 0 ? 1 : -1))
        if (res.length > 1) {
          removeExtraSettings(res)
        }
        if (res[0]) {
          return updateKeyIdMap(res[0])
        } else return undefined
      })
  }

  async function update(id: string, value: LayoutBoxConfig[]): Promise<LayoutBoxConfig[]> {
    await remove(id)
    return create(id, value)
  }

  async function remove(id: string) {
    if (!keyIdMap.has(`layout:${id}`)) {
      await load(id)
    }
    const csId = keyIdMap.get(`layout:${id}`) || ''
    await service.remove(csId)
  }

  return {
    createId,
    create,
    load,
    update,
    remove
  }
}

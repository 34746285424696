import type { UserSessionEntity } from '@/lib/api'

export enum SessionMode {
  normal = 'normal',
  safeMode = 'safeMode'
}
export enum OTPMode {
  none,
  totp,
  sms,
  both
}
export interface LoginData {
  username: string
  password: string
  mode: SessionMode
}
export interface ResponseLogin {
  otpType: OTPMode
  accessToken: string
  refreshToken: string
}
export interface RefreshData {
  accessToken?: string
  refreshToken: string
}
export enum CameraCounts {
  none = '0',
  personal = '1-5',
  business = '5-10',
  enterprise = '10+'
}
export enum CameraBrands {
  Other = 0,
  Axis,
  Hanwha,
  Hikvision,
  VivoTek,
  Uniview,
  Onvif,
  Bosch,
  Panasonic,
  Honeywell,
  Arcadian,
  Sunell,
  Dahua
}

export interface RegisterData {
  fullName: string // optional
  internetProvider: string // optional
  phone?: string // optional
  email?: string // optional
  cameraCounts?: CameraCounts // optional
  cameraBrands?: CameraBrands[] // optional
  password: string
  token?: string
}
export interface ResponseRegister {
  fullName?: string
  phone: string
  email: string
  password: string
}
export interface UserData {
  id: string
  email?: string
  phone: string
  isLocked: boolean
  updatedAt: Date
  createdAt: Date
  phoneVerifiedAt: Date
  emailVerifiedAt: Date | null
  fullName: string
  has2fa: boolean
  otpMode: OTPMode
  avatar?: string
  remoteId?: string
}

export interface CurrentUser {
  user: UserData | null
  session: UserSessionEntity | null
  workspaceId?: number
}

import type { BridgeData } from '@/lib/api'
import { type BridgeCamera, BridgeService, type UpdateBridgeData } from '@/lib/api'
import { useServices } from '@/lib/services'
import { reactive, ref, type Ref } from 'vue'
import type { Bridge } from '@/modules/bridge/Bridge'

export class BridgeBaseInfo {
  public data!: BridgeData
  public cameras: Ref<BridgeCamera[]> = ref([])
  public id!: string
  protected service: BridgeService = useServices().bridge

  constructor(readonly core: Bridge, data: BridgeData) {
    this.data = reactive<BridgeData>(data) as BridgeData
    this.cameras.value = data.cameras || []
    this.id = this.core.id
  }

  public async update(data: UpdateBridgeData) {
    const bridgeData = await this.service.update(this.data.id, data)
    this.updateData(bridgeData)
  }

  public async find() {
    const bridgeData = await this.service.find(this.data.id)
    this.updateData(bridgeData)
  }

  public updateData(data: BridgeData) {
    Object.assign(this.data, data)
    this.core.versionManager.version.value = data.version
    if (data.marker) this.core.locationManager.setData(data.marker)
    if (data.cameras) this.cameras.value = data.cameras
  }
}

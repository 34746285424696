import type { CameraTemp, Location } from '@/modules/camera-new/interface'
import { CameraStatusType } from '@/modules/camera-new/types'
import { type TagEachResource } from '@/lib/api'
import { usePaymentStore } from '@/stores/payment/usePaymentStore'
import { useCameraStore } from '@/modules/camera-new/store/cameraStore'
import { useServices } from '@/lib/services'
import { ConvertError } from '@/utils/helpers'
import { useCustomSettingStore } from '@/stores/custom-setting/useCustomSettingStore'
import { storeToRefs } from 'pinia'
import { useOnboardingStore } from '@/stores/onboarding/useOnboardingStore'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { useMonitoringTabsWrapper } from '@/modules/Monitoring/helpers/monitoringTabs'

export const useCreateCameraTempData = (
  id: string,
  name: string,
  groupId: string,
  url: string,
  port: number,
  securePort: number,
  streamPort: number,
  extraPorts: number[],
  username: string,
  password: string,
  channel: number,
  useSSL: boolean,
  manufacturerCode: number,
  timezone: string,
  selectedTag: TagEachResource[],
  avatar: string,
  location: Location,
  bridgeId?: string
): CameraTemp => {
  return {
    cameraData: {
      name,
      groupId,
      bridgeId: bridgeId ? bridgeId : undefined,
      config: {
        url,
        port,
        securePort,
        streamPort,
        extraPorts,
        username,
        password,
        channel,
        useSSL,
        manufacturerCode
      },
      timezone,
      userData: id
    },
    tags: selectedTag,
    avatar,
    status: CameraStatusType.offline,
    location
  }
}

export const createNewCameraHandler = async (newCamera: CameraTemp, toast, t) => {
  const eventBus: any = useEventBus()
  try {
    if (await usePaymentStore().canCreateNewCamera(1)) {
      await createCamera(newCamera)
    }
  } catch (e: any) {
    try {
      if (e.message) {
        if (e.message === 'CanNotAddCamera') {
          const t1 = Date.now()
          toast.add({
            summary: 'Subscription Update',
            closable: false,
            severity: 'info',
            detail:
              "Excellent! Please don't leave this page—we're in the process of connecting your cameras with your upgraded subscription. A minute or two is all we need.",
            life: 7000
          })
          await usePaymentStore().upgradeSubscription(1)
          const t2 = Date.now()
          if (t2 - t1 > 3000) {
            toast.remove({
              summary: 'Subscription Update',
              closable: false,
              severity: 'info',
              detail:
                "Excellent! Please don't leave this page—we're in the process of connecting your cameras with your upgraded subscription. A minute or two is all we need."
            })
          } else {
            setTimeout(() => {
              toast.remove({
                summary: 'Subscription Update',
                closable: false,
                severity: 'info',
                detail:
                  "Excellent! Please don't leave this page—we're in the process of connecting your cameras with your upgraded subscription. A minute or two is all we need."
              })
            }, 3000 - t2 - t1)
          }
          await createCamera(newCamera)
        } else if (e.message === 'NoSubscriptionFound') {
          const { defaultPrice, activePaymentMethod } = storeToRefs(usePaymentStore())
          await useCameraStore().loadCameras()
          await useServices().stripePayment.subscription.createSubscription({
            items: [{ id: defaultPrice.value.id, quantity: useCameraStore().cameras.size + 1 }],
            defaultPaymentMethod: activePaymentMethod.value
          })
          await usePaymentStore().loadActiveSubscription(true)
          await createCamera(newCamera)
        } else {
          toast.add({
            severity: 'error',
            detail: ConvertError(e),
            life: 7000
          })
        }
      } else {
        console.log(e)
        toast.add({
          severity: 'error',
          detail: ConvertError(e),
          life: 7000
        })
      }
    } catch (e) {
      toast.add({
        severity: 'error',
        summary: 'Subscription Error',
        detail: t('errorMessage.SubscriptionProblem'),
        life: 10000
      })
      eventBus.emit(`camera:loading-${newCamera.cameraData.userData}`, false)
    }
  }
}

export const createBulkNewCameraHandler = async (newCameras: CameraTemp[], toast, t) => {
  try {
    if (await usePaymentStore().canCreateNewCamera(newCameras.length)) {
      await createBulkCamera(newCameras, toast, t)
    }
  } catch (e: any) {
    try {
      if (e.message) {
        if (e.message === 'CanNotAddCamera') {
          await usePaymentStore().upgradeSubscription(newCameras.length)
          await createBulkCamera(newCameras, toast, t)
        } else if (e.message === 'NoSubscriptionFound') {
          const { cameras } = useCameraStore()
          const { defaultPrice, activePaymentMethod } = storeToRefs(usePaymentStore())
          await useCameraStore().loadCameras()
          await useServices().stripePayment.subscription.createSubscription({
            items: [
              {
                id: defaultPrice.value.id,
                quantity: cameras.size + newCameras.length
              }
            ],
            defaultPaymentMethod: activePaymentMethod.value
          })
          await usePaymentStore().loadActiveSubscription(true)
          await createBulkCamera(newCameras, toast, t)
        } else {
          toast.add({
            severity: 'error',
            summary: 'Subscription Error',
            detail: t('errorMessage.SubscriptionProblem'),
            life: 10000
          })
        }
      } else {
        toast.add({
          severity: 'error',
          detail: ConvertError(e),
          life: 10000
        })
      }
    } catch (e) {
      toast.add({
        severity: 'error',
        summary: 'Subscription Error',
        detail: t('errorMessage.SubscriptionProblem'),
        life: 10000
      })
    }
  }
}

const createBulkCamera = async (newCameras: CameraTemp[], toast, t) => {
  await useCustomSettingStore().createFirstAddCameraSetting()
  const cameraList = [...newCameras]
  const eventBus: any = useEventBus()
  for (const camera of cameraList) {
    try {
      eventBus.emit(`camera:loading-${camera.cameraData.userData}`, true)
      await useCameraStore().createCamera(camera)
      removeCameraInStore(camera.cameraData.userData)
    } catch (e: any) {
      const message = e.config ? t('errorMessage.cameraDataWrong') : ConvertError(e)
      toast.add({
        severity: 'error',
        detail: message,
        life: 7000
      })
      eventBus.emit(`camera:loading-${camera.cameraData.userData}`, false)
    }
  }
}

const createCamera = async (newCamera: CameraTemp) => {
  await useCustomSettingStore().createFirstAddCameraSetting()
  const camera = await useCameraStore().createCamera(newCamera)
  removeCameraInStore(newCamera.cameraData.userData)
  useMonitoringTabsWrapper().addNewCameraToTabs(camera.id)
}

export function removeCameraInStore(id) {
  const { data } = storeToRefs(useOnboardingStore())
  const index = data.value.camera.cameraList?.findIndex((e) => e.cameraData.userData === id)
  if (index !== -1) {
    data.value.camera.cameraList?.splice(index!, 1)
  }
  removeCameraSelection(id)
}

export function removeCameraSelection(id) {
  const { data } = storeToRefs(useOnboardingStore())
  const index = data.value.camera.selections?.findIndex((e) => e.cameraData.userData === id)
  if (index !== -1) {
    data.value.camera.selections?.splice(index!, 1)
  }
}

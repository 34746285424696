import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import {
  AuthenticationService,
  ChallengeService,
  OtpService,
  SessionService
} from '../../../api/services/authentication-manager'
import { ResetPasswordService } from '../../../../../src/lib/api/services/authentication-manager/resetPassword'
import { TotpService } from '@/lib/api/services/authentication-manager/totp'
import { UserService } from '@/lib/api/services/authentication-manager/user'

export class AuthenticationManagerService extends Service {
  public challenge: ChallengeService
  public authentication: AuthenticationService
  public otp: OtpService
  public resetPassword: ResetPasswordService
  public session: SessionService
  public totp: TotpService
  public user: UserService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.challenge = new ChallengeService(adaptor)
    this.authentication = new AuthenticationService(adaptor)
    this.otp = new OtpService(adaptor)
    this.resetPassword = new ResetPasswordService(adaptor)
    this.session = new SessionService(adaptor)
    this.totp = new TotpService(adaptor)
    this.user = new UserService(adaptor)
  }
}

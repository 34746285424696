import { ApiClient } from '@/lib/api'
import { Authenticator } from '@/lib/api'
import { SessionMode } from '@/lib/api'
import { ChallengeService } from '@/lib/api/services/authentication-manager/challenges'

/**
 * example:
 * ```ts
 * async function example() {
 *   const authenticatorFactory = new AuthenticationHelper(getClientManager());
 *   const authenticator = authenticatorFactory.create('+989302660045');
 *   try {
 *     for await (const state of authenticator.challenge()) {
 *       const data: string = getDataFromUserBasedOnState(state);
 *       authenticator.feed(data);
 *     }
 *   } catch (e) {
 *     reportMessage(e);
 *   }
 *   const { accessToken, refreshToken } = authenticator.result();
 * }
 * ```
 */
export class AuthenticationHelper {
  protected client: ChallengeService

  constructor(protected readonly apiClient: ApiClient) {
    this.client = this.apiClient.authenticationManager.challenge
  }

  create(phone: string, sessionState = SessionMode.safeMode) {
    return new Authenticator(this.client, phone, sessionState)
  }
}

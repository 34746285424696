import { PlayerApis } from '@/player/lib/player/player-apis'

export class HeatmapHelpers {
  static getImage(id: string | number) {
    return PlayerApis.heatmapDownloadImage(id).then(URL.createObjectURL)
  }

  static cleanupImage(imageData: string) {
    URL.revokeObjectURL(imageData)
  }

  static async parseImage(image: string): Promise<ImageData> {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = image
    await img.decode()
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('cant generate 2d context')
    ctx.drawImage(img, 0, 0)
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    img.style.display = 'none'
    return imageData
  }
}

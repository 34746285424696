import { Service } from '../../service'
import type {
  QuotaAdvanceData,
  QuotaAdvanceResponse
} from '@/lib/api/services/stripe-payment/quota/stripe-quota.interfaces'

export class StripeQuotaService extends Service {
  public findAdvanceQuota(data: QuotaAdvanceData): Promise<QuotaAdvanceResponse[]> {
    return this.post(`/api/payment/quota/advance`, data)
  }

  public findQuotaByWorkspace(id: string): Promise<QuotaAdvanceResponse[]> {
    return this.get(`/api/payment/quota/workspace/${id}`)
  }

  public findQuota(id: string): Promise<QuotaAdvanceResponse> {
    return this.get(`/api/payment/quota/${id}`)
  }
}

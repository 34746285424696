import { reactive } from 'vue'
import { uniqueId } from 'lodash'
import type { AuthoritiesFinderOptions } from '@/modules/Permissions'

type mapKey = AuthoritiesFinderOptions[] | AuthoritiesFinderOptions

interface mapValue {
  actions: mapKey
  hasPermissions: boolean
}

export class ComponentPermissionsManager {
  permissionsMap = reactive<Map<string, mapValue>>(new Map())

  constructor() {}

  add(actions: AuthoritiesFinderOptions[] | AuthoritiesFinderOptions, hasPermissions: boolean) {
    const uid = uniqueId()
    this.permissionsMap.set(uid, { actions, hasPermissions })
    return { actions, hasPermissions: this.get(uid) }
  }

  get(uid: string) {
    return this.permissionsMap.get(uid)?.hasPermissions
  }
}

import type {
  CreateEachTagRelation,
  EachTagRelation,
  UpdateEachRElationData
} from './tag-relation.interfaces'

import { Service } from '../../service'

export class TagRelationService extends Service {
  public createRelation(data: CreateEachTagRelation) {
    return this.post<EachTagRelation>(`/api/tag-manager/tag-relation`, data)
  }

  public findRelationByType(type: string) {
    return this.get<EachTagRelation[]>(`/api/tag-manager/tag-relation/find/${type}`)
  }

  public updateRelation(id: string, data: UpdateEachRElationData) {
    return this.patch<EachTagRelation>(`/api/tag-manager/tag-relation/${id}`, data)
  }

  public deleteRelation(id: string) {
    return this.delete(`/api/tag-manager/tag-relation/${id}`)
  }
}

import { defineStore } from 'pinia'
import type {
  CameraStep,
  onboardingCamera,
  onboardingState,
  OnboardingStep,
  OnboardingStepItems
} from '@/components/onboarding-bridge/interfaces'
import type { CameraStatusType } from '@/modules/camera-new/types'

export const useOnboardingStore = defineStore('onboarding', {
  state: (): onboardingState => ({
    steps: [],
    nextStep: 'cameras',
    currentStep: 'workspace',
    data: {
      camera: {
        currentStep: 1,
        cameraList: [],
        selections: [],
        process: [0, 0, 0, 0],
        staticIp: '',
        verifyConfigureIp: false
      },
      payment: {
        fullName: '',
        zipCode: ''
      },
      cameraUsers: []
    }
  }),
  getters: {
    camera(): onboardingCamera {
      return this.data.camera
    },
    stepItems(): OnboardingStep[] {
      return this.steps
    },
    current(): OnboardingStepItems {
      return this.currentStep
    },
    isComplete(): boolean {
      return this.steps.slice(0, this.steps.length - 1).every((item) => item.percent === 100)
    },
    hasPayment(): boolean {
      return !!this.data.payment.fullName
    },
    onboardingDefaultSteps() {
      return [
        {
          title: 'onboarding.steps.workspace',
          key: 'workspace',
          order: 0,
          percent: 0,
          status: true
        },
        {
          title: 'onboarding.steps.pairing',
          key: 'pairing',
          order: 1,
          percent: 0,
          status: false
        },
        {
          title: 'onboarding.steps.cameras',
          key: 'cameras',
          order: 2,
          percent: 0,
          status: false
        },
        {
          title: 'onboarding.steps.pricing',
          key: 'pricing',
          order: 3,
          percent: 0,
          status: false
        },
        {
          title: 'onboarding.steps.liveStream',
          key: 'liveStream',
          order: 5,
          percent: 100,
          status: false
        }
      ] as OnboardingStep[]
    },
    cameraConnectDefaultSteps() {
      return [
        {
          title: 'onboarding.steps.pairing',
          key: 'pairing',
          order: 0,
          percent: 0,
          status: true
        },
        {
          title: 'onboarding.steps.cameras',
          key: 'cameras',
          order: 1,
          percent: 0,
          status: false
        },
        {
          title: 'onboarding.steps.liveStream',
          key: 'liveStream',
          order: 5,
          percent: 100,
          status: false
        }
      ] as OnboardingStep[]
    }
  },
  actions: {
    setSteps(newSteps: OnboardingStep[]) {
      this.steps = newSteps
    },

    initializeSteps(
      savedSteps: OnboardingStep[] = [],
      type: 'onboarding' | 'cameraConnect',
      isDone: boolean = false
    ): OnboardingStep[] {
      let newSteps: OnboardingStep[] = []
      if (type === 'onboarding') {
        if (savedSteps.length !== this.onboardingDefaultSteps.length) {
          newSteps = JSON.parse(JSON.stringify(this.onboardingDefaultSteps))
        } else {
          newSteps = savedSteps
        }
      } else {
        if (savedSteps.length !== this.cameraConnectDefaultSteps.length) {
          newSteps = JSON.parse(JSON.stringify(this.cameraConnectDefaultSteps))
        } else {
          newSteps = savedSteps
        }
      }
      if (isDone) {
        newSteps = newSteps.map((step) => {
          return {
            ...step,
            percent: 100
          }
        })
      }
      this.setSteps(newSteps)

      return newSteps
    },

    setCameraSubmitStatus(unique: string | undefined, status: CameraStatusType) {
      if (typeof unique !== 'undefined') {
        const camera = this.data.camera.cameraList.find((i) => i?.cameraData?.userData === unique)
        if (camera) {
          camera.status = status
        }
      }
    },
    setCurrentStep(step: OnboardingStepItems) {
      this.currentStep = step
    },
    changePageStep(step: OnboardingStepItems) {
      this.currentStep = step
    },
    changeCameraStep(step: CameraStep) {
      this.data.camera.currentStep = step
    }
  }
})

import type { DowntimeMapPLayerEntity } from '@/player/interfaces'
import { BookmarkType } from '@/player/interfaces'
import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'
import { CameraDownReason } from '@/lib/api'
import { TimelineHelpers } from '@/player/lib/playback-timeline/timeline-helpers'
import { InfoMode } from '@/player/types'

export class TimelineNoRecordInfoManager {
  public noRecords: DowntimeMapPLayerEntity[] = []
  public idSet: Set<string> = new Set()
  protected recordPauseImage: HTMLImageElement

  constructor(public readonly timelineCore: TimelineCore) {
    this.recordPauseImage = new Image()
    this.recordPauseImage.src = 'images/recordPause.svg'
  }

  get activeBookmark() {
    return this.timelineCore.core.activeBookmark
  }

  clear() {
    this.noRecords = []
    this.idSet.clear()
  }

  push(value: DowntimeMapPLayerEntity[]) {
    if (value && value.length > 0) {
      for (const noRecord of value) {
        if (!this.idSet.has(noRecord.id)) {
          this.noRecords.push(noRecord)
          this.idSet.add(noRecord.id)
        } else {
          const index = this.noRecords.findIndex((b) => b.id === noRecord.id)
          this.noRecords[index] = noRecord
        }
      }
      this.timelineCore.resetRenderCache()
    }
  }

  getOverlappingPeriods(startTime: number, endTime: number): DowntimeMapPLayerEntity[] {
    return this.noRecords.filter(({ startAt, endAt }) => startAt <= endTime && endAt >= startTime)
  }

  filterForDrawing(points: DowntimeMapPLayerEntity[], minValue: number): DowntimeMapPLayerEntity[] {
    const res = points.filter((point) => point.endAt - point.startAt > 2)
    if (res[0]) {
      res[0].startAt = Math.max(res[0].startAt, this.timelineCore.findRelativeXForDate(minValue))
    }
    return res
  }

  render(records: DowntimeMapPLayerEntity[]) {
    for (const { startAt: start, endAt: end, reason } of records) {
      if (reason === CameraDownReason.status || reason === CameraDownReason.payment) {
        this.timelineCore.drawer.drawLine(
          start,
          this.timelineCore.renderPositionsConstant.horizontalLinePlaceHolder,
          {
            ...this.timelineCore.renderConstant.horizontalLinePlaceHolder,
            width: end - start
          }
        )
      } else {
        this.timelineCore.drawer.drawLine(
          start,
          this.timelineCore.renderPositionsConstant.bookmark,
          {
            ...this.timelineCore.renderConstant.bookmark,
            width: end - start,
            color: {
              fill: TimelineHelpers.formatColor('#C7A82F', 0.3)
            }
          }
        )
        const pattern = this.timelineCore.drawer.ctx.createPattern(this.recordPauseImage, 'repeat')
        this.timelineCore.drawer.drawLine(
          start,
          this.timelineCore.renderPositionsConstant.horizontalLinePlaceHolder,
          {
            ...this.timelineCore.renderConstant.horizontalLinePlaceHolder,
            color: {
              fill: pattern || '#C7A82F'
            },
            height: this.timelineCore.renderConstant.horizontalLinePlaceHolder.height * 2,
            width: end - start
          }
        )
      }
    }
  }

  onHover(x: number, y: number, haveBookmark = false) {
    if (!haveBookmark) {
      const diffStart = (this.timelineCore.view.size * x) / this.timelineCore.renderWidth
      const date = this.timelineCore.view.start + diffStart
      const noRecord = this.noRecords.find(({ startAt, endAt, finished }) => {
        endAt = finished ? endAt : Date.now()
        return startAt <= date && endAt >= date
      })
      if (noRecord) {
        if (noRecord.reason === CameraDownReason.recordMode) {
          this.activeBookmark.enabled = true
          this.activeBookmark.x = this.timelineCore.ctx.canvas.offsetLeft + x
          this.activeBookmark.y = this.timelineCore.ctx.canvas.offsetTop
          this.activeBookmark.bookmark = {
            period: [noRecord.startAt, noRecord.finished ? noRecord.endAt : -1],
            color: '#C7A82F',
            description: 'Cloud archiving was paused.',
            submitBy: noRecord.recordStopper ? 'Loading...' : 'You',
            id: '-1',
            submitById: noRecord.recordStopper || 'systems'
          }
          this.activeBookmark.type = BookmarkType.RecordPaused
        } else if (noRecord.reason === CameraDownReason.payment) {
          this.timelineCore.core.showInfo(
            'Recording Disruption Noted: It appears that a valid payment method was missing.',
            InfoMode.message
          )
        } else {
          this.timelineCore.core.showInfo(
            'Recording Glitch Detected: It appears that your camera briefly entered offline mode.',
            InfoMode.message
          )
        }
      }
    }
  }
}

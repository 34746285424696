import { SizeMode, StreamQuality } from '@/player/types'
import type { PlayerCore } from '@/player/lib/player/player-core'

const WINDOW_MARGIN = 8
export const PLAYER_MIN_HEIGHT = 250
export const PLAYER_SYNC_PLAYBACK_MIN_HEIGHT = 339
export const PLAYER_MIN_WIDTH = 340
export const PLAYER_SYNC_PLAYBACK_MIN_WIDTH = 452

export const SizeConfigs = {
  minHeight: PLAYER_MIN_HEIGHT,
  minWidth: PLAYER_MIN_WIDTH
}

export const PLAYER_MED_WIDTH = 720

export class SizeHelper {
  public lastVisitedFrameSize = {
    width: 0,
    height: 0
  }

  constructor(public readonly core: PlayerCore) {}

  public static heightMinMaxCheck(value: number) {
    return Math.max(SizeConfigs.minHeight, value)
  }

  public static widthMinMaxCheck(value: number, noMarginAtAll = false) {
    const noMargin = noMarginAtAll || document.fullscreenElement !== null
    const margins = noMargin ? 0 : WINDOW_MARGIN * 2
    const screenWidth = noMargin ? window.innerWidth : window.outerWidth
    return Math.min(screenWidth - margins, Math.max(SizeConfigs.minWidth, value))
  }

  public static getRenderSize(width?: number, height?: number, noMarginAtAll = false) {
    if (!width && !height) {
      return {
        renderHeight: SizeConfigs.minHeight,
        renderWidth: SizeConfigs.minWidth
      }
    } else if (height && !width) {
      return {
        renderHeight: SizeHelper.heightMinMaxCheck(height),
        renderWidth: SizeHelper.widthMinMaxCheck(height * 1.7777, noMarginAtAll)
      }
    } else if (!height && width) {
      return {
        renderHeight: SizeHelper.heightMinMaxCheck(width * 0.5625),
        renderWidth: SizeHelper.widthMinMaxCheck(width, noMarginAtAll)
      }
    } else if (height && width) {
      return {
        renderHeight: SizeHelper.heightMinMaxCheck(height),
        renderWidth: SizeHelper.widthMinMaxCheck(width, noMarginAtAll)
      }
    }
    return {
      renderHeight: 0,
      renderWidth: 0
    }
  }

  public setAspectRatio(imageWidth: number, imageHeight: number) {
    if (
      this.lastVisitedFrameSize.height !== imageHeight ||
      this.lastVisitedFrameSize.width !== imageWidth
    ) {
      this.lastVisitedFrameSize = {
        height: imageHeight,
        width: imageWidth
      }
      this.calculateSize()
      return true
    }
    return false
  }

  public setRenderSizes() {
    this.calculateSize()
    if (this.core.display) {
      this.core.display.setRenderSize(this.core.size.renderWidth, this.core.size.renderHeight)
    }
    if (this.core.snapshotPainter) {
      this.core.snapshotPainter.setRenderSize(
        this.core.size.renderWidth,
        this.core.size.renderHeight
      )
    }
    if (this.core.timeline) {
      this.core.timeline.setCanvasRenderSize(
        SizeHelper.widthMinMaxCheck(this.core.size.wrapperWidth, this.core.noLayout.value)
      )
    }
    if (this.core.heatMapDisplay) {
      this.core.heatMapDisplay.setRenderSize(
        this.core.size.renderWidth,
        this.core.size.renderHeight
      )
    }
    if (this.core.annotator) {
      this.core.annotator.setRenderSize(this.core.size.renderWidth, this.core.size.renderHeight)
    }
    this.setStreamIndex()
    this.core.emitFunction('sizeChanged', this.core.size)
  }

  protected setStreamIndex() {
    if (this.core.playback.isLive && this.core.streams.isAuto && this.core.capture) {
      if (this.core.size.renderWidth < PLAYER_MED_WIDTH) {
        this.core.capture.live(this.core.information.streamInformation.lowResIndex || 0)
        this.core.streams.quality = StreamQuality.low
      } else {
        this.core.capture.live(this.core.information.streamInformation.highResIndex || 0)
        this.core.streams.quality = StreamQuality.highDefinition
      }
    }
  }

  protected setWrapperSize() {
    if (this.core.size.mode === SizeMode.contain) {
      this.core.size.wrapperWidth = this.core.size.desiredWidth || 0
      this.core.size.wrapperHeight = this.core.size.desiredHeight || 0
    } else {
      this.core.size.wrapperWidth = this.core.size.renderWidth
      this.core.size.wrapperHeight = this.core.size.renderHeight
    }
  }

  protected calculateSizeWithLastFrameDesiredWidth(
    imageWidth: number,
    imageHeight: number,
    desiredWidth: number,
    desiredHeight: number
  ) {
    this.core.size.renderHeight = SizeHelper.heightMinMaxCheck(
      (desiredWidth * imageHeight) / imageWidth
    )
    this.core.size.renderWidth = desiredWidth
  }

  protected calculateSizeWithLastFrameDesiredHeight(
    imageWidth: number,
    imageHeight: number,
    desiredWidth: number,
    desiredHeight: number
  ) {
    const width = (desiredHeight * imageWidth) / imageHeight
    const doableWidth = SizeHelper.widthMinMaxCheck(
      (desiredHeight * imageWidth) / imageHeight,
      this.core.noLayout.value
    )

    this.core.size.renderWidth = doableWidth
    if (doableWidth < width) {
      this.core.size.renderHeight = SizeHelper.heightMinMaxCheck(
        (doableWidth * imageHeight) / imageWidth
      )
    } else {
      this.core.size.renderHeight = desiredHeight
    }
  }

  protected calculateSizeWithLastFrameContain(
    imageWidth: number,
    imageHeight: number,
    desiredWidth: number,
    desiredHeight: number
  ) {
    const tmpHeight = (desiredWidth * imageHeight) / imageWidth
    const tmpWidth = (desiredHeight * imageWidth) / imageHeight
    if (desiredHeight >= tmpHeight) {
      this.core.size.renderHeight = tmpHeight
      this.core.size.renderWidth = desiredWidth
    } else {
      this.core.size.renderHeight = desiredHeight
      this.core.size.renderWidth = tmpWidth
    }
  }

  protected calculateSizeWithLastFrameScale(
    imageWidth: number,
    imageHeight: number,
    desiredWidth: number,
    desiredHeight: number
  ) {
    this.core.size.renderWidth = desiredWidth
    this.core.size.renderHeight = desiredHeight
  }

  protected calculateSizeWithLastFrame() {
    const imageWidth = this.lastVisitedFrameSize.width
    const imageHeight = this.lastVisitedFrameSize.height
    const desiredWidth = this.core.size.desiredWidth || 0
    const desiredHeight = this.core.size.desiredHeight || 0
    if (this.core.size.mode === SizeMode.desiredWidth) {
      this.calculateSizeWithLastFrameDesiredWidth(
        imageWidth,
        imageHeight,
        desiredWidth,
        desiredHeight
      )
    } else if (this.core.size.mode === SizeMode.desiredHeight) {
      this.calculateSizeWithLastFrameDesiredHeight(
        imageWidth,
        imageHeight,
        desiredWidth,
        desiredHeight
      )
    } else if (this.core.size.mode === SizeMode.contain) {
      this.calculateSizeWithLastFrameContain(imageWidth, imageHeight, desiredWidth, desiredHeight)
    } else if (this.core.size.mode === SizeMode.scale) {
      this.calculateSizeWithLastFrameScale(imageWidth, imageHeight, desiredWidth, desiredHeight)
    }
  }

  protected calculateSizeNoLastFrame() {
    this.core.size.renderWidth = this.core.size.desiredWidth || 0
    this.core.size.renderHeight = this.core.size.desiredHeight || 0
  }

  protected calculateSize() {
    if (this.lastVisitedFrameSize.height > 0 || this.lastVisitedFrameSize.width > 0) {
      this.calculateSizeWithLastFrame()
    } else {
      this.calculateSizeNoLastFrame()
    }
    this.setWrapperSize()
  }

  getAvailableWidth() {
    return (
      this.core.size.renderWidth ||
      this.core.size.desiredWidth ||
      this.core.size.wrapperWidth ||
      PLAYER_MIN_WIDTH
    )
  }
}

export function setDefaultSizeToNormal() {
  SizeConfigs.minWidth = PLAYER_MIN_WIDTH
  SizeConfigs.minHeight = PLAYER_MIN_HEIGHT
}

export function setDefaultSizeToSyncPlayback() {
  SizeConfigs.minWidth = PLAYER_SYNC_PLAYBACK_MIN_WIDTH
  SizeConfigs.minHeight = PLAYER_SYNC_PLAYBACK_MIN_HEIGHT
}

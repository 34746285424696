import type { Marker, CreateMarkerData, UpdateMarkerData } from './marker.interfaces'
import { Service } from '../../service'
export class MarkerService extends Service {
  public create(data: CreateMarkerData) {
    return this.post<Marker, CreateMarkerData>(`/api/map-manager/marker`, data)
  }
  public find(mapId: string) {
    return this.get<Marker[]>(`/api/map-manager/marker/${mapId}`)
  }
  public update(mapId: string, data: UpdateMarkerData) {
    return this.patch<Marker, UpdateMarkerData>(`/api/map-manager/marker/${mapId}`, data)
  }
  public remove(mapId: string) {
    return this.delete<void>(`/api/map-manager/marker/${mapId}`)
  }

  public attachLayer(markerId: string, layerData: any) {
    return this.post<void>(`/api/map-manager/marker/${markerId}/attach`, layerData)
  }
}

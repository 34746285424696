export default {
  ai: {
    moreThan50: 'More than 50% match',
    moreThan70: 'More than 70% match',
    moreThan90: 'More than 90% match',
    showEveryThing: 'Show everything',
    placeHolderAISearch: 'What are you looking for today?',
    search: 'Search',
    back: 'Back'
  }
}

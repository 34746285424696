import { Service } from '../../service'
import type {
  NotificationUserSettings,
  NotificationUserSettingsResponse
} from './notifier-user-settings.interfaces'

export class NotificationUserService extends Service {
  public getCurrent() {
    return this.get<Array<NotificationUserSettingsResponse>>(`/api/notifier/settings`)
  }

  public create(data: NotificationUserSettings) {
    return this.post<Array<NotificationUserSettingsResponse>>(`/api/notifier/settings`, data)
  }

  public update(data: NotificationUserSettingsResponse) {
    return this.patch<Array<NotificationUserSettingsResponse>>(`/api/notifier/settings`, data)
  }

  public remove(ids: Array<string>) {
    return this.deleteData(`/api/notifier/settings`, { ids })
  }
}

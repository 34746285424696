//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
import DateTimeFormat = Intl.DateTimeFormat
import type { Options } from '@/utils/normalize/date/interface'

const defaultLocale = 'en-US'
const defaultOptions: Options = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false
}
export default class NormalizeDate {
  static format(
    date: Date = new Date(),
    locale = defaultLocale,
    options = defaultOptions
  ): string | number {
    return DateTimeFormat(locale, options).format(date)
  }
}

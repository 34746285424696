export default {
  isAlphanumeric: 'Do not include numbers or special character such as "?-/" ',
  matches: 'hey, please avoid special characters.',
  maxLength: 'hey, please use fewer characters.',
  Invalid: 'Invalid code.',
  Unauthorized:
    'Sorry! You took too long. For security purposes, start over by entering your phone number.',
  tooMany: 'Exceeded log in attempts. Retry in a few minutes.',
  InvitationFailed: 'invite user failed',
  email: 'Email',
  isString: 'must be string ',
  expired: 'Expired',
  otpError: 'Invalid code.',
  enableMultiFactor: 'Enable your' + 'Multi-Factor Authentication.',
  enable: 'Enabled.',
  isRemoved: 'Removed!',
  verified: 'Verified!',
  validCode: 'Code required.',
  passUpdate: 'Updated!',
  dataNotFound: 'No match found.',
  page: 'Page',
  of: 'of',
  all: 'All',
  InvalidPhone: 'Invalid phone number.',
  currentPasswordIsNotCorrect: 'Invalid.',
  forbidenResource: 'Access denied.',
  addCamera: 'Connected.',
  chooseSite: 'Missing required fields.',
  fillOrRemove: 'Missing required fields.',
  addsite: 'Saved!',
  resolveNotification: 'Resolved!',
  created: 'Created!',
  requestStreamingInfo: 'Connecting . . .',
  editCamera: 'Saved!',
  logoutDevice: 'Ciao.',
  submit: 'Submitted!',

  removeOTP: 'Disabled.',
  updatePassword: 'Saved!',
  editUser: 'Saved!',

  deleteCameras: 'Disconnected.',
  login: 'Hello!',
  signup: 'Please log in.',
  siteAdded: 'Saved!',
  resetOTP: 'Saved!',
  validPhoneNumber: 'Phone number is required.',
  addSite: 'Missing required fields.',
  enabled: 'Enabled!',
  disabled: 'Disabled.',
  successfullyEdited: 'Saved!',
  UniqueViolation: 'Name must be unique.',
  CameraNotAccessible: '{0} is out of reach.',
  CameraNotConfigured: 'Configuration error detected for {0}.',
  ExportNotReady: 'Prepping your file. Stay tuned.',

  NoSuitableMediaServer: 'Unable to detect {0}.',

  isDefined: '{0} is required.',
  isNumber: '{0} must be an integer.',
  isPhoneNumber: 'A valid phone number is required.',
  manyEmpty: 'Missing required fields.',
  successFully: 'Mission accomplished!',
  verifyPhoneSuccess: 'Verified!',

  ResetPasswordTokenRevoked: 'Invalid code.',
  ResetPasswordTokenUsed: 'Expired code.',
  ResetPasswordTokenExpired: 'Expired code.',
  ResetPasswordInvalidToken: 'Invalid code.',
  usedVoucherCode: 'Invalid code.',
  invalidVoucherCode: 'Invalid code.',
  PasswordNotStrongEnough: 'Try a stronger combination.',

  SubscriptionProblem: 'Payment error!',

  passwordisDefined: 'Missing required fields.',
  UsernameOrPasswordNotMatch: 'Wrong combination.',
  UniquenessViolation: 'exists. Try something else.',

  usernameisDefined: 'Missing required fields.',
  nameisString: 'Missing required fields.',
  resourceIdmin: 'Access Denied. This section may be off-limits, or the link might be incorrect.',
  Internalservererror: 'Try refreshing your screen or reach out to us',
  'Bad Gateway': 'Try refreshing your screen or reach out to us',
  cameraInfoNotFound: 'Device out of reach.',
  '400': 'Error 400: Bad Request',
  '401': 'Error 401: Unauthorized Access',
  '403': '403: Access Denied. This section may be off-limits, or the link might be incorrect.',
  '404': 'Error 404: Pardon The Interruption',
  '422': 'Error 422: Forbidden',
  '429': 'Error 429: Hot Server! Try again in few minutes.',
  '500':
    'Server Outage. Please reload your screen. If the problem remains, contact our support team.',
  oop: 'Oops! Something went wrong.',
  emailVerification: 'Unable to verify your email address.',
  challengeRetryFailed: 'Invalid. Try again.',
  validationFailed: 'Invalid.',
  challengeProcessFailed: 'Invalid. Try again.',
  challengeCreationFailed: 'Failed. Try again.',
  challengeRemovalFailed: 'Failed. Try again.',
  userNotfound: 'User not found.',
  challengeNotfound: 'Not found.',
  invalidSession: 'Unusual activity detected. Log back in again.',
  TooManyLoginAttempts: 'Too many attempts. Try again in few minutes.',
  sendOtpFailed: 'Attempts exceeded. Contact support.',
  expiredValue: 'Expired code.',
  invalidRequest: 'Invalid.',
  TokenSessionNotFound: 'Security Notice: Odd activity detected. Log back in for safety.',
  invalidValue: 'Invalid.',
  sessionRevokedBefore: 'Security Notice: Odd activity detected. Log back in for safety.',
  wrongToken: 'Invalid.',
  OtpMissConfig: 'OTP missing. Please enter your one-time password.',
  CameraNotRegistered:
    "Looks like your camera's on a stealth mission! Double-check those ports to bring it back from invisibility mode.",
  usersNotfound: 'No results found.',
  cameraNotAccessible: "Camera seems to be taking a nap! Ensure it's online and within range.",
  ExportIsNotReady: 'Prepping your file. Few more minutes...',
  SnapshotNotAvailable: 'Failed to download.',
  emptyUserAgent: 'Security Notice: Odd activity detected. Log back in for safety.',
  NotValidJSON: "We've noticed unusual behavior. Please re-login.",
  resourceNotfound: 'Not found.',
  resourceAccessDenied:
    'Access Denied. This section may be off-limits, or the link might be incorrect.',
  authenticateFailed: 'Security Notice: Your session has expired. Log back in for safety.',
  customerCreationFailed:
    '500: Server Outage. Please reload your screen. If the problem remains, contact our support team immediately.',
  customerUpdateFailed: 'Something went wrong. Try again.',
  hasActiveSubscription: 'Bravo! Your active subscription is the symphony of security.',
  noActiveSubscription:
    "Woah! Your account's floating in space with no active subscription in its orbit. Head to Workspace Billing to get it sorted out.",
  misMatchSubscription: 'Mismatched subscription.',
  forbiddenResource:
    'Access Denied. This section may be off-limits, or the link might be incorrect.',
  teamNotFound: 'Access Denied. This section may be off-limits, or the link might be incorrect.',
  authorityNotFound:
    'Access Denied. This section may be off-limits, or the link might be incorrect.',
  RequiredFieldNotProvided: 'Missing required information.',
  avatarNotfound: 'Something went wrong. Try again.',
  teamNotfound: 'No results found.',
  invalidPhone: 'Invalid phone number.',
  userNotInvited: "Member doesn't exist.",
  inappropriateType: 'Try a different combination.',
  MaximumCameraLicenceLimitReached:
    '500: Server Outage. Please reload your screen. If the problem remains, contact our support team immediately.',
  MaximumNumberOfSimultaneousStreamsReached:
    '500: Server Outage. Please reload your screen. If the problem remains, contact our support team immediately.',
  ScheduleIdDoesNotExist: 'Schedule Not Found',
  CameraIdDoesNotExists: 'Camera Does Not Exist',
  StreamIdNotFound: 'Stream Not Found',
  InvalidOrCorruptParameters: 'Invalid entry.',
  CheckTheParameters: 'Please Check Input Parameters',
  PasswordsDoNotDiffer:
    'Your new password must not match the old one. Try a new combination please.',
  Forbidden: "Couldn't save. Please try again.",
  InvalidPromotionCode: 'Sorry! Invalid code.',
  AvatarUploadFailed: "Couldn't save your file. Please try again.",
  minLength: 'not strong enough',
  Not2FAVerified: 'Security Notice: Your session has expired. Log back in for safety.',
  StripeInvalidRequestError:
    "Problem encountered. Please email support{'@'}arcadian.ai for assistance.",
  StripeRandomError:
    'Your workspace is on hold due to billing discrepancies. Contact us through live chat at https://www.arcadian.ai for immediate resolution.'
}

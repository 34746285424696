import { PeriodType } from '@/player/types'

export class TimelineHelpers {
  static findNextRoundDate(inputDate: number, type: PeriodType) {
    const date = new Date(inputDate)

    switch (type) {
      case PeriodType.singleMinute:
      case PeriodType.minimal:
      case PeriodType.quarter:
      case PeriodType.hourly: {
        const milliseconds = date.getTime()
        date.setTime(Math.ceil((milliseconds + 1) / type) * type)
        break
      }
      case PeriodType.daily:
        date.setDate(date.getDate() + 1)
        date.setHours(0, 0, 0, 0)
        break
      case PeriodType.monthly:
        date.setMonth(date.getMonth() + 1)
        date.setDate(1)
        date.setHours(0, 0, 0, 0)
        break
      case PeriodType.threeMonth: {
        const currentMonth = date.getMonth()
        const nextStartingMonth = currentMonth - (currentMonth % 3) + 3
        date.setMonth(nextStartingMonth)
        date.setDate(1)
        date.setHours(0, 0, 0, 0)
        break
      }
    }

    return date.getTime()
  }

  static findPreviousRoundDate(inputDate: number, type: PeriodType) {
    const date = new Date(inputDate)

    switch (type) {
      case PeriodType.singleMinute:
      case PeriodType.minimal:
      case PeriodType.quarter:
      case PeriodType.hourly: {
        const milliseconds = date.getTime()
        date.setTime(Math.floor(milliseconds / type) * type)
        break
      }
      case PeriodType.daily:
        date.setHours(0, 0, 0, 0)
        break
      case PeriodType.monthly:
        date.setDate(1)
        date.setHours(0, 0, 0, 0)
        break
      case PeriodType.threeMonth: {
        const currentMonth = date.getMonth()
        const startingMonth = currentMonth - (currentMonth % 3)
        date.setMonth(startingMonth)
        date.setDate(1)
        date.setHours(0, 0, 0, 0)
        break
      }
    }

    return date.getTime()
  }

  static formatColor(value: string, opacity: number): string {
    if (value.startsWith('#')) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value)
      if (result) {
        return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
          result[3],
          16
        )},${opacity})`
      } else throw new Error('unknown color format ' + value)
    }
    if (value.startsWith('rgba(')) {
      const parts = value.split(',')
      parts[3] = opacity + ')'
      return parts.join(',')
    }
    if (value.startsWith('rgb(')) {
      const parts = value.split(',')
      parts[3] = opacity + ')'
      parts[0] = 'rgba' + parts[0].slice(2)
      return parts.join(',')
    }
    throw new Error('unknown color format ' + value)
  }

  static getTextOfDate(value: number, type: PeriodType) {
    const date = new Date(value)
    switch (type) {
      case PeriodType.singleMinute:
      case PeriodType.minimal:
      case PeriodType.quarter:
      case PeriodType.hourly:
        return date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        })
      case PeriodType.daily:
        return date.toLocaleDateString('en-US', {
          day: '2-digit'
        })
      case PeriodType.threeMonth:
      case PeriodType.monthly:
        return date.toLocaleDateString('en-US', {
          month: 'short'
        })
    }
  }
}

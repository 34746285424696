import type {
  activeData,
  BridgeData,
  BridgeStatusData,
  bridgeUpdateCheckData,
  CameraHandlerData,
  DiscoverCamerasData,
  DiscoveredCamera,
  FindBridgeData,
  UpdateBridgeData
} from './bridge.interfaces'
import { Service } from '../service'
import { BridgeStatus } from './bridge.interfaces'

export class BridgeService extends Service {
  // ***** BRIDGE APIS  *****

  public active(bridgeData: activeData) {
    return this.post<BridgeData, activeData>(`/api/bridge-manager/bridge`, bridgeData)
  }

  public list() {
    return this.get<BridgeData[]>(`/api/bridge-manager/bridge`)
  }

  public update(id: string, bridgeData: UpdateBridgeData) {
    return this.patch<BridgeData, UpdateBridgeData>(`/api/bridge-manager/bridge/${id}`, bridgeData)
  }

  public find(id: string) {
    return this.get<BridgeData>(`/api/bridge-manager/bridge/${id}/details`)
  }

  public advanceFind(SearchBridgeData: FindBridgeData) {
    return this.post<BridgeData | BridgeData[], FindBridgeData>(
      `/api/bridge-manager/bridge/find`,
      SearchBridgeData
    )
  }

  public findByLabel(label: string) {
    return this.get<BridgeData>(`/api/bridge-manager/bridge/label?label=${label}`)
  }

  public fastDiscovery(id: string) {
    return this.post<{ key: string }>(`/api/bridge-manager/bridge/${id}/fast-discover`)
  }

  public fullDiscovery(id: string, data?: DiscoverCamerasData) {
    return this.post<{ key: string }, DiscoverCamerasData>(
      `/api/bridge-manager/bridge/${id}/discover`,
      data
    )
  }

  public discoveryResponse(key: string) {
    return this.get<DiscoveredCamera[]>(`/api/bridge-manager/bridge/discover/response?key=${key}`)
  }

  public bridgeHeartbeat(id: string) {
    return this.get<{ time: number }>(`/api/bridge-manager/bridge/${id}/heartbeat`)
  }

  public refresh() {
    return this.get<boolean>(`/api/bridge-manager/bridge/cache/refresh`)
  }

  public availableHandlers(id: string) {
    return this.get<CameraHandlerData[]>(`/api/bridge-manager/bridge/${id}/handlers`)
  }

  public softReset(id: string) {
    return this.post(`/api/bridge-manager/bridge/${id}/soft-reset`)
  }

  public hardReset(id: string) {
    return this.delete(`/api/bridge-manager/bridge/${id}/hard-reset`)
  }

  public forceReset(id: string) {
    return this.delete(`/api/bridge-manager/bridge/${id}/force-hard-reset`)
  }

  public updateCheck(id: string) {
    return this.get<bridgeUpdateCheckData>(`/api/bridge-manager/bridge/${id}/update-check`)
  }
  public softwareUpdate(id: string) {
    return this.patch(`/api/bridge-manager/bridge/${id}/software`)
  }

  public checkStatus(id: string) {
    return this.get<BridgeStatusData>(`/api/bridge-manager/bridge/${id}/status`)
  }
  public allStatus() {
    return this.get<BridgeStatusData[]>(`/api/bridge-manager/bridge/status`)
  }
}

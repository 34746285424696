import { HeatMapPeriodType } from '@/player/types'

export class HeatmapTimelineHelpers {
  static findPreviousRoundDate(inputDate: number, type: HeatMapPeriodType) {
    const date = new Date(inputDate)

    switch (type) {
      case HeatMapPeriodType.short: {
        const milliseconds = date.getTime()
        date.setTime(Math.floor(milliseconds / type) * type)
        break
      }
      case HeatMapPeriodType.medium:
        date.setHours(0, 0, 0, 0)
        break
      case HeatMapPeriodType.long: {
        date.setDate(1)
        date.setHours(0, 0, 0, 0)
        break
      }
    }

    return date.getTime()
  }

  static findNextRoundDate(inputDate: number, type: HeatMapPeriodType) {
    const date = new Date(inputDate)

    switch (type) {
      case HeatMapPeriodType.short: {
        const milliseconds = date.getTime()
        date.setTime(Math.ceil((milliseconds + 1) / type) * type)
        break
      }
      case HeatMapPeriodType.medium: {
        date.setDate(date.getDate() + 1)
        date.setHours(0, 0, 0, 0)
        break
      }
      case HeatMapPeriodType.long: {
        date.setMonth(date.getMonth() + 1)
        date.setDate(1)
        date.setHours(0, 0, 0, 0)
        break
      }
    }

    return date.getTime()
  }

  static getTextOfDate(value: number, type: HeatMapPeriodType) {
    const date = new Date(value)
    switch (type) {
      default:
      case HeatMapPeriodType.short: {
        const mini = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        })
        const large = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })
        return {
          mini,
          large
        }
      }
      case HeatMapPeriodType.medium:
      case HeatMapPeriodType.long: {
        const mini = date.toLocaleTimeString('en-US', {
          day: 'numeric'
        })
        const large = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'short'
        })
        return {
          mini,
          large
        }
      }
    }
  }
}

import type { App } from 'vue'
import { UserPermissions } from './UserPermissions'
import type { MatrixResource } from '@/lib/api'
import { useServices } from '@/lib/services'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { AuthoritiesFinderOptions, MatrixAuthorities } from '../interface'
import { MatrixDifferChecker } from '..'
import { useComponentPermissionsManager } from './ComponentPermissionsManager'

declare module 'vue' {
  interface ComponentCustomProperties {
    $cp: (action: AuthoritiesFinderOptions | AuthoritiesFinderOptions[]) => boolean
  }
}

export class PermissionManager {
  userPermissions: UserPermissions = new UserPermissions()
  private componentPermissionsManager = useComponentPermissionsManager()
  private matrixDifferChecker = new MatrixDifferChecker()

  async fetchPermissions(userId: string): Promise<MatrixAuthorities[]> {
    const permissionsMatrix: MatrixResource[] =
      await useServices().permissionManager.user.permissionMatrix(userId)
    this.userPermissions.transformPermissions(permissionsMatrix)
    return this.userPermissions.permissions
  }

  async refetchPermissions(userId: string): Promise<void> {
    this.matrixDifferChecker.setOldMatrix(this.userPermissions.permissions)
    const newMatrix = await this.fetchPermissions(userId)
    this.matrixDifferChecker.setNewMatrix(newMatrix)
    await this.matrixDifferChecker.checkMatrixes()
  }

  private async checkMatrixDifferences(): Promise<void> {
    await this.matrixDifferChecker.checkMatrixes()
  }

  permissionsMiddleware(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    const requiredActions = to.meta.actions as AuthoritiesFinderOptions[] | AuthoritiesFinderOptions
    if (requiredActions && this.userPermissions.permissions.length > 0) {
      if (!this.hasPermission(requiredActions)) {
        return next({ name: 'forbidden' })
      }
    }
  }

  private setupComponentPermissionChecker(app: App): void {
    app.config.globalProperties.$cp = (
      action: AuthoritiesFinderOptions | AuthoritiesFinderOptions[]
    ): boolean => {
      const { hasPermissions } = this.componentPermissionsManager.add(
        action,
        this.hasPermission(action)
      )
      return hasPermissions || false
    }
  }

  private setupPermissionChecker(app: App): void {
    app.config.globalProperties.$p = (
      actions: AuthoritiesFinderOptions | AuthoritiesFinderOptions[]
    ): boolean => {
      return this.hasPermission(actions)
    }
  }

  hasPermission(action: AuthoritiesFinderOptions | AuthoritiesFinderOptions[]): boolean {
    if (Array.isArray(action)) {
      return this.userPermissions.checkAllPermissions(action)
    }
    return this.userPermissions.checkPermission(action)
  }

  recheckComponents(): void {
    const permissionMap = this.componentPermissionsManager.permissionsMap
    for (const permission of permissionMap.values()) {
      permission.hasPermissions = this.hasPermission(permission.actions)
    }
  }

  setup(app: App): void {
    this.setupComponentPermissionChecker(app)
    this.setupPermissionChecker(app)
  }

  clearPermissions(): void {
    this.matrixDifferChecker = new MatrixDifferChecker()
    this.userPermissions.clear()
  }
}

import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import { PermissionAuthorityService } from '@/lib/api/services/permission/authority'
import { PermissionService } from '@/lib/api/services/permission/permission'
import { PermissionResourceService } from '@/lib/api/services/permission/resource'
import { TeamService } from '@/lib/api/services/permission/team'
import { PermissionUserService, UserService } from '@/lib/api'
export class permissionManagerService extends Service {
  public authority: PermissionAuthorityService
  public permission: PermissionService
  public resource: PermissionResourceService
  public team: TeamService
  public user: PermissionUserService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.authority = new PermissionAuthorityService(adaptor)
    this.permission = new PermissionService(adaptor)
    this.resource = new PermissionResourceService(adaptor)
    this.team = new TeamService(adaptor)
    this.user = new PermissionUserService(adaptor)
  }
}

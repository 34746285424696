import { IntervalTree, type Tape } from './interval-tree'

export class CoverageChecker {
  private tree = new IntervalTree()

  mark(newTaps: Tape[]) {
    for (const interval of newTaps) {
      this.tree.insert(interval)
    }
  }

  check(start: number, end: number): Tape[] {
    const uncovered: Tape[] = []
    const coveredIntervals = this.tree.queryRange(start, end)

    let currentStart = start

    for (const [tapStart, tapEnd] of coveredIntervals) {
      if (tapStart > currentStart && tapStart <= end) {
        uncovered.push([currentStart, Math.min(tapStart, end)])
      }
      currentStart = Math.max(currentStart, tapEnd)

      if (currentStart >= end) break
    }

    if (currentStart < end) {
      uncovered.push([currentStart, end])
    }

    return uncovered
  }

  getTapes(): Tape[] {
    return this.tree.getIntervals()
  }
}

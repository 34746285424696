export enum AnalyticEventTypes {
  None = 'none', // 0
  HwSensor = 'hw-sensor', // 1
  HwTampering = 'hw-tampering', // 2
  HwAudio = 'hw-audio', // 3
  HwMotion = 'hw-motion', // 4
  HwBorderCrossing = 'hw-border-crossing', // 5
  HwAppearance = 'hw-appearance', // 6
  HwDisappearance = 'hw-disappearance', // 7
  HwCapacity = 'hw-capacity', // 8
  SwMotion = 'sw-motion', // 9
  SwFence = 'sw-fence', // 10
  SwLeftMissed = 'sw-left_missed' // 11
}

export function convertEventType(type: number): AnalyticEventTypes {
  switch (type) {
    case 0:
      return AnalyticEventTypes.None
    case 1:
      return AnalyticEventTypes.HwSensor
    case 2:
      return AnalyticEventTypes.HwTampering
    case 3:
      return AnalyticEventTypes.HwAudio
    case 4:
      return AnalyticEventTypes.HwMotion
    case 5:
      return AnalyticEventTypes.HwBorderCrossing
    case 6:
      return AnalyticEventTypes.HwAppearance
    case 7:
      return AnalyticEventTypes.HwDisappearance
    case 8:
      return AnalyticEventTypes.HwCapacity
    case 9:
      return AnalyticEventTypes.SwMotion
    case 10:
      return AnalyticEventTypes.SwFence
    case 11:
      return AnalyticEventTypes.SwLeftMissed
    default:
      return AnalyticEventTypes.None
  }
}

export interface FindEventDto {
  cameraId: string

  type: AnalyticEventTypes

  index?: string

  startTime: string

  endTime: string
}

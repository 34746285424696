export const ALLOCATION_RATE = 8

export class AudioPlayerAdvance {
  protected readonly context: AudioContext
  protected readonly buffer: AudioBuffer
  protected readonly gain: GainNode
  protected readonly source: AudioBufferSourceNode
  protected readonly channelIndex: Map<number, number> = new Map()
  protected isStarted = false

  constructor(
    public readonly frameSize: number,
    public readonly sampleRate: number,
    public readonly numberOfChannels = 1,
    protected readonly zeroLatency = false
  ) {
    try {
      this.context = new AudioContext({
        sampleRate: this.sampleRate
      })
    } catch (e) {
      console.error('Failed to create AudioContext:', e)
      throw e
    }

    this.gain = this.context.createGain()

    this.buffer = this.context.createBuffer(
      numberOfChannels,
      ALLOCATION_RATE * frameSize,
      sampleRate
    )

    this.source = this.context.createBufferSource()
    this.source.buffer = this.buffer
    this.source.loop = true

    this.gain.connect(this.context.destination)
    this.source.connect(this.gain)

    for (let i = 0; i < numberOfChannels; i++) {
      this.channelIndex.set(i, 0)
    }
  }

  push(audioData: Float32Array, channelNumber = 0) {
    const index = this.channelIndex.get(channelNumber) || 0
    this.buffer.copyToChannel(audioData, channelNumber, index)
    const newLength = (index + audioData.length) % this.buffer.length
    this.channelIndex.set(channelNumber, newLength)
    if (!this.isStarted && (this.zeroLatency || newLength === 0)) {
      this.source.start()
      this.isStarted = true
    }
  }

  setVolume(value: number) {
    this.gain.gain.value = value / 100
  }

  destroy() {
    this.context.close()
    this.isStarted = false
  }
}

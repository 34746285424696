import type { responseOtpData, VerifyOtpData, VerifyOtpResponse } from '@/lib/api'
import { Service } from '../../service'

export class OtpService extends Service {
  // ***** OTP APIS  *****

  public setupOtp() {
    return this.post<void>('/api/u-crm/otp/sms/setup')
  }
  public removeOtp() {
    return this.delete<void>('/api/u-crm/otp/sms/remove')
  }
  public requestOtp() {
    return this.post<responseOtpData>('/api/u-crm/otp/sms/request')
  }
  public verifyOtp(data: VerifyOtpData) {
    return this.post<VerifyOtpResponse | undefined, VerifyOtpData>(
      '/api/u-crm/otp/sms/verify',
      data
    )
  }
}

import type { Bookmark, BookmarkWithX } from '@/player/interfaces'
import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'
import { TimelineHelpers } from '@/player/lib/playback-timeline/timeline-helpers'
import { BookmarkType } from '@/player/interfaces'

export class TimelineBookmarkManager {
  public information: Bookmark[] = []
  public idSet: Set<string> = new Set()
  public paths: Array<{
    path: Path2D
    bookmark: Bookmark
  }> = []

  constructor(public readonly timelineCore: TimelineCore) {}

  get activeBookmark() {
    return this.timelineCore.core.activeBookmark
  }

  clear() {
    this.information = []
    this.idSet.clear()
  }

  push(value: Bookmark[]) {
    if (value && value.length > 0) {
      for (const bookmark of value) {
        if (!this.idSet.has(bookmark.id)) {
          this.information.push(bookmark)
          this.idSet.add(bookmark.id)
        } else {
          const index = this.information.findIndex((b) => b.id === bookmark.id)
          this.information[index] = bookmark
        }
      }
      this.information.sort((a, b) => a.period[0] - b.period[0])
    }
    this.timelineCore.resetRenderCache()
  }

  remove(id: string) {
    const index = this.information.findIndex((b) => b.id === id)
    if (index >= 0) {
      this.information.splice(index, 1)
      this.idSet.delete(id)
    }
  }

  getOverlappingPeriods(startTime: number, endTime: number): Bookmark[] {
    return this.information.filter(({ period }) => period[0] <= endTime && period[1] >= startTime)
  }

  filterForDrawing(bookmarks: BookmarkWithX[], minValue: number): BookmarkWithX[] {
    const res = bookmarks.filter((bookmark) => bookmark.periodX[1] - bookmark.periodX[0] > 2)
    if (res[0]) {
      res[0].periodX[0] = Math.max(
        res[0].periodX[0],
        this.timelineCore.findRelativeXForDate(minValue)
      )
    }
    return res
  }

  setUsername(id: string, name: string) {
    const index = this.information.findIndex((b) => b.id === id)
    if (index >= 0) {
      this.information[index].submitBy = name
    }
  }

  onHover(x: number, y: number) {
    for (const { path, bookmark } of this.paths) {
      if (this.timelineCore.ctx.isPointInPath(path, x, y)) {
        this.activeBookmark.enabled = true
        this.activeBookmark.x = this.timelineCore.ctx.canvas.offsetLeft + x
        this.activeBookmark.y = this.timelineCore.ctx.canvas.offsetTop
        this.activeBookmark.bookmark = bookmark
        this.activeBookmark.type = BookmarkType.userMade
        return true
      }
    }
    this.activeBookmark.enabled = false
    return this.activeBookmark.enabled
  }

  render(bookmarks: BookmarkWithX[]) {
    this.paths = []
    for (const bookmark of bookmarks) {
      const [start, end] = bookmark.periodX
      const path = this.timelineCore.drawer.drawLine(
        start,
        this.timelineCore.renderPositionsConstant.bookmark,
        {
          ...this.timelineCore.renderConstant.bookmark,
          width: end - start,
          color: {
            fill: TimelineHelpers.formatColor(bookmark.color, 0.3)
          }
        }
      )
      this.paths.push({
        path,
        bookmark
      })
    }
  }
}

import type { PlayerCore } from '@/player/lib/player/player-core'
import type { AudioPlayerCallback, CaptureObject, PlayerAudioSetting } from '@/player/interfaces'
import { Codec, FrameTypes } from '@/player/types'
import { watch } from 'vue'
import { Buffer } from 'buffer'
import * as Comlink from 'comlink'
import { decodePcmULaw } from '@/player/lib/audio/codecs/u-law'
import { decodePcmALaw } from '@/player/lib/audio/codecs/a-law'
import { decodePcmU8 } from '@/player/lib/audio/codecs/u8'
import { decodePcmS16LE } from '@/player/lib/audio/codecs/s16-le'
import { decodePcmS24LE } from '@/player/lib/audio/codecs/s24-le'
import { decodePcmS32LE } from '@/player/lib/audio/codecs/s32-le'
import { decodePcmF32LE } from '@/player/lib/audio/codecs/f32-le'
import { decodeAdPcmG726_16 } from '@/player/lib/audio/codecs/g726-16'
import { decodeAdPcmG726_24 } from '@/player/lib/audio/codecs/g726-24'
import { decodeAdPcmG726_32 } from '@/player/lib/audio/codecs/g726-32'
import { decodeAdPcmG726_40 } from '@/player/lib/audio/codecs/g726-40'
import { ALLOCATION_RATE, AudioPlayerAdvance } from '@/player/lib/audio/audio-player-advance'
import { PlayerRepository } from '@/player/lib/player/player-repository'

const SAMPLE_RATE = 8000
export class AudioPlayer {
  private audioContext!: AudioPlayerAdvance
  private stopHandler?: ReturnType<typeof setTimeout>
  constructor(public readonly core: PlayerCore) {
    this.audioContext = new AudioPlayerAdvance(512, SAMPLE_RATE)
  }

  get config(): PlayerAudioSetting {
    return this.core.audioSetting
  }

  protected setNewSampleRate() {}

  protected setStopHandler() {
    clearTimeout(this.stopHandler)
    this.stopHandler = setTimeout(() => {
      const frameSize = this.audioContext.frameSize
      const sampleRate = this.audioContext.sampleRate
      this.audioContext.destroy()
      this.audioContext = new AudioPlayerAdvance(frameSize, sampleRate)
      this.audioContext.setVolume(this.config.volume)
    }, (1000 * this.audioContext.frameSize * ALLOCATION_RATE) / 2 / this.audioContext.sampleRate)
  }

  protected decodeAudio(buf: Buffer) {
    let data
    switch (this.config.lastCodec) {
      case Codec.pcmMULAW:
        data = decodePcmULaw(buf)
        break
      case Codec.pcmALAW:
        data = decodePcmALaw(buf)
        break
      case Codec.pcmU8:
        data = decodePcmU8(buf)
        break
      case Codec.pcmS16LE:
        data = decodePcmS16LE(buf)
        break
      case Codec.pcmS24LE:
        data = decodePcmS24LE(buf)
        break
      case Codec.pcmS32LE:
        data = decodePcmS32LE(buf)
        break
      case Codec.pcmF32LE:
        data = decodePcmF32LE(buf)
        break
      case Codec.adPcmG726_16:
        data = decodeAdPcmG726_16(buf)
        break
      case Codec.adPcmG726_24:
        data = decodeAdPcmG726_24(buf)
        break
      case Codec.adPcmG726_32:
        data = decodeAdPcmG726_32(buf)
        break
      case Codec.adPcmG726_40:
        data = decodeAdPcmG726_40(buf)
        break
      case Codec.g729:
        // todo
        break
      case Codec.aac:
        // todo
        break
    }
    return data
  }

  play(frame: CaptureObject) {
    if (frame.type === FrameTypes.AudioHeader && this.config.lastCodec !== frame.streamCodec) {
      this.config.lastCodec = frame.streamCodec
      this.config.enabled = true
      this.setNewSampleRate()
    }
    if (this.config.enabled && frame.data && frame.data.length > 0) {
      const data = this.decodeAudio(frame.data)
      if (data) {
        if (data.length !== this.audioContext.frameSize) {
          this.audioContext.destroy()
          this.audioContext = new AudioPlayerAdvance(frame.data.length, SAMPLE_RATE)
          this.audioContext.setVolume(this.config.volume)
        }
        this.setStopHandler()
        this.audioContext.push(data)
      }
    }
  }

  async enable() {
    if (PlayerRepository.activeAudioPlayer) {
      if (PlayerRepository.activeAudioPlayer.id !== this.core.id) {
        PlayerRepository.activeAudioPlayer.audioSetting.volume = 0
      }
    }
    PlayerRepository.activeAudioPlayer = this.core
    await this.core.worker.enableAudioCallback()
  }

  async disable() {
    await this.core.worker.disableAudioCallback()
  }

  async init() {
    await this.core.worker.setAudioCallback(
      Comlink.proxy<AudioPlayerCallback>({
        onAudio: this.play.bind(this)
      })
    )
    watch(
      () => this.config.enabled,
      async (value) => {
        if (value && this.config.volume > 0) {
          await this.enable()
        } else {
          await this.disable()
        }
      }
    )
    watch(
      () => this.config.volume,
      async (value, oldValue) => {
        this.audioContext?.setVolume(value)
        if (value === 0) {
          await this.disable()
        }
        if (oldValue === 0 && this.config.enabled) {
          await this.enable()
        }
      },
      { immediate: true }
    )
  }

  close() {
    this.audioContext?.destroy()
  }
}

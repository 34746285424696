import { AbstractHelper } from './abstract-helper'
import type { inviteUserWorkspace, ChallengeRegisterResponse } from '@/lib/api'
import {
  ApiClient,
  ChallengeService,
  Languages,
  WorkspaceService,
  WorkspaceUserService,
  StripeCustomerService
} from '@/lib/api'

export class UserHelper extends AbstractHelper {
  protected workspaceService: WorkspaceService
  protected challengeService: ChallengeService
  protected userService: WorkspaceUserService
  protected customerService: StripeCustomerService

  constructor(apiClient: ApiClient) {
    super(apiClient)
    this.workspaceService = apiClient.workspaceManager.workspace
    this.challengeService = apiClient.authenticationManager.challenge
    this.userService = apiClient.workspaceManager.user
    this.customerService = apiClient.stripePayment.customer
  }

  public async userInviteBulk(allUser: inviteUserWorkspace[], id) {
    try {
      await Promise.all(
        allUser.map((user) => {
          return this.inviteUser(id, user) // make sure to return the promise here
        })
      )
    } catch (error) {
      // This will catch any rejection from `inviteUser`
      console.error('Error in bulk invite:', error)
      throw error // Re-throw the error so it can be caught by the outer try/catch
    }
  }

  public async inviteUser(id, dataCode: inviteUserWorkspace) {
    return await this.workspaceService.invite(id.toString(), dataCode)
  }

  public async CreateUser(createUserData, token): Promise<ChallengeRegisterResponse> {
    const currentUserData = await this.challengeService.registerChallenge({
      fullName: createUserData.fullName,
      internetProvider: createUserData.internetProvider,
      email: createUserData.email,
      phone: createUserData.phone,
      password: createUserData.password,
      cameraBrands: createUserData.cameraBrands,
      cameraCounts: createUserData.cameraCounts,
      token: token
    })
    await this.apiClient.setHeader('Authorization', `Bearer ${currentUserData.accessToken}`)
    await this.customerService.createCustomer({})
    return currentUserData
  }
}

import type { Options } from '@/utils/normalize/date/interface'
import NormalizeNumber from '@/utils/normalize/numbers'
import LocalesConfig from '@/utils/normalize/configData/i18nConfigs'
import NormalizeDate from '@/utils/normalize/date'
import dayjs from 'dayjs'
export { NormalizePhoneNumber } from '@/utils/normalize/phoneNumber'
export class NormalizeData {
  static currency(value: string, locale: any, option: Options | null = null) {
    try {
      const options = !option ? LocalesConfig[locale].currency : option
      return NormalizeNumber.format(parseFloat(value), LocalesConfig[locale].locale, options)
    } catch (e) {
      return value
    }
  }

  static standardDate(value: string, locale: any, option: Options | null = null) {
    try {
      const config = !option ? LocalesConfig[locale].date : option
      return NormalizeDate.format(new Date(value), LocalesConfig[locale].locale, config)
    } catch (e) {
      return value
    }
  }

  static displayDateByFormat(dataStr: string | number | Date, pattern = 'YYYY-MM-DD') {
    if (dataStr) {
      if (/^\d+$/.test(dataStr as string)) {
        return dayjs((dataStr as number) * 1000).format(pattern)
      } else {
        return dayjs(dataStr).format(pattern)
      }
    } else {
      return dataStr
    }
  }

  static shortenText(text: string | undefined, maxLength: number): string {
    if (typeof text === 'undefined') {
      return ''
    } else if (text.length <= maxLength || maxLength === 0) {
      return text
    } else {
      return text.substring(0, maxLength) + (maxLength > 3 ? '...' : '')
    }
  }
}

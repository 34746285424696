export function decodeAdPcmG726_16(adpcmData) {
  // Initialize the state of the decoder
  let index = 0 // Index in the step size table
  let predictedSample = 0 // Predicted PCM value
  const stepSizes: number[] = [
    /* Array of step sizes for G.726 */
  ]
  const indexAdjust: number[] = [
    /* Index adjustments based on ADPCM nibbles */
  ]

  // Placeholder arrays for demonstration. Replace with actual G.726 values.
  stepSizes[0] = 7 // Example initialization, replace with correct G.726 values
  for (let i = 1; i < 49; i++) {
    stepSizes[i] = stepSizes[i - 1] + 8 // Example calculation
  }
  indexAdjust[0] = -1 // Example initialization, replace with correct G.726 values
  indexAdjust[1] = 2
  // Continue filling indexAdjust based on G.726 specifications

  const pcmSamples: number[] = []

  // Process each byte of ADPCM data (assuming 2 bits per sample for 16 kbps)
  for (let i = 0; i < adpcmData.length; i++) {
    const byte = adpcmData[i]
    for (let nibblePos = 0; nibblePos < 4; nibblePos++) {
      const adpcmNibble = (byte >> (nibblePos * 2)) & 0x03

      // Decode ADPCM nibble to PCM sample
      const step = stepSizes[index]
      let difference = 0

      // Determine the difference using the lower bits of the nibble
      if (adpcmNibble & 2) difference += step
      if (adpcmNibble & 1) difference += step >> 1
      difference += step >> 2

      // Adjust the sample based on the sign bit
      if (adpcmNibble & 2) {
        predictedSample -= difference
      } else {
        predictedSample += difference
      }

      // Clamp the predicted sample within 16-bit PCM range
      if (predictedSample > 32767) predictedSample = 32767
      else if (predictedSample < -32768) predictedSample = -32768

      // Adjust the index for the next step size
      index += indexAdjust[adpcmNibble]
      if (index < 0) index = 0
      else if (index > stepSizes.length - 1) index = stepSizes.length - 1

      // Save the decoded sample
      pcmSamples.push(predictedSample)
    }
  }

  // Convert to Float32 for audio processing
  const float32Samples = new Float32Array(pcmSamples.length)
  for (let i = 0; i < pcmSamples.length; i++) {
    float32Samples[i] = pcmSamples[i] / 32768
  }
  return float32Samples
}

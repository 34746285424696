import { Service } from '@/lib/api'
import type {
  ChatEntity,
  UserColorDto,
  UserDateDto,
  UserFunctionCallResultDto,
  UserMessageDto,
  UserObjectDto
} from '@/lib/api/services/ai/chat/chat.interface'

export class ChatService extends Service {
  public create(cameraId: string) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat', {
      cameraId
    })
  }

  public createUserMessage(body: UserMessageDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat/message/text', body)
  }

  public createManualActionDate(body: UserDateDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat/message/date', body)
  }

  public createManualActionColor(body: UserColorDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat/message/color', body)
  }

  public createManualActionObject(body: UserObjectDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat/message/object', body)
  }

  public submitFunctionData(body: UserFunctionCallResultDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat/message/function-call', body)
  }

  public findOne(id: string) {
    return this.get<ChatEntity>(`/api/event-storage-ai/chat/${id}`)
  }
}

import { EventTypes } from '@/player/types'
import { EventCache } from '@/player/lib/analytic/event-cache'

export class FrameFollower {
  protected lastDate: number | undefined = undefined
  protected occurredInLastRequest = false

  constructor(public readonly targetEvent: EventTypes, protected readonly cache: EventCache) {}

  process(events: Set<EventTypes>, date: number) {
    const occurred = events.has(this.targetEvent)
    if (this.lastDate !== undefined) {
      if (this.occurredInLastRequest) {
        this.cache.addManually(this.lastDate, date)
      } else {
        this.cache.markManually(this.lastDate, date)
      }
    }
    this.lastDate = date
    this.occurredInLastRequest = occurred
  }
}

import { Service } from '../../service'

export class StripeInvoiceService extends Service {
  public findAllInvoice(): Promise<any> {
    return this.get(`/api/payment/invoice`)
  }

  public filterInvoice(data: { startRange: number | Date; endRange: number | Date }): Promise<any> {
    return this.post(`/api/payment/invoice`, data)
  }

  public invoiceUpcoming(): Promise<any> {
    return this.get(`/api/payment/invoice/upcoming`)
  }
}

import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import { WorkspaceUserService } from './user'
import { WorkspaceService } from './workspace'

export class WorkspaceManagerService extends Service {
  public user: WorkspaceUserService
  public workspace: WorkspaceService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.user = new WorkspaceUserService(adaptor)
    this.workspace = new WorkspaceService(adaptor)
  }
}

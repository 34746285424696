import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import { MapService } from '@/lib/api/services/map-manager/map'
import { MarkerService } from '@/lib/api/services/map-manager/marker'
import { MapResourceService } from '@/lib/api/services/map-manager/resource'
export class MapManagerService extends Service {
  public map: MapService
  public marker: MarkerService
  public resource: MapResourceService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.map = new MapService(adaptor)
    this.marker = new MarkerService(adaptor)
    this.resource = new MapResourceService(adaptor)
  }
}

import type { IndirectPermission, Resource } from './permission-resource.interfaces'
import type { Permission } from '../team'
import { Service } from '../../service'
export class PermissionResourceService extends Service {
  public findResource(id: string, type: string): Promise<Resource> {
    return this.get(`/api/p-crm/resource/find?id=${id}&type=${type}`)
  }
  public findAllResource(): Promise<Resource[]> {
    return this.get(`/api/p-crm/resource`)
  }
  public findPermissions(id: string): Promise<Permission[]> {
    return this.get(`/api/p-crm/resource/${id}/permission`)
  }

  public findPermissionAdvance(id: string): Promise<IndirectPermission[]> {
    return this.get(`/api/p-crm/resource/${id}/permission//advance`)
  }
}

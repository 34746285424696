import { Service } from '../../service'
import type {
  DryRunData,
  FreezeData
} from '@/lib/api/services/stripe-payment/subscription/stripe-subscription.interfaces'

export class StripeSubscriptionService extends Service {
  public createSubscription(data: DryRunData): Promise<any> {
    return this.post(`/api/payment/subscription`, data)
  }

  public findSubscription(id: string): Promise<any> {
    return this.get(`/api/payment/subscription/${id}`)
  }

  public updateSubscription(id: string, data: any): Promise<any> {
    return this.patch(`/api/payment/subscription/${id}`, data)
  }

  public findSubscriptionByWorkspace(id: string): Promise<any> {
    return this.get(`/api/payment/subscription/workspace/${id}`)
  }
  public findSubscriptionByWorkspaceDetail(id: string): Promise<any> {
    return this.get(`/api/payment/subscription/workspace/${id}/detailed`)
  }

  public findActiveSubscriptionByWorkspace(id: string): Promise<any> {
    return this.get(`/api/payment/subscription/workspace/${id}/active`)
  }
  public findActiveSubscriptionByWorkspaceDetailed(id: string): Promise<any> {
    return this.get(`/api/payment/subscription/workspace/${id}/active/detailed`)
  }

  public calculateSubscriptionDetail(data: DryRunData): Promise<any> {
    return this.post(`/api/payment/subscription/dry-run`, data)
  }

  public dryRunSubscription(id: string, data: DryRunData): Promise<any> {
    return this.patch(`/api/payment/subscription/${id}/dry-run`, data)
  }

  public freezeSubscription(id: string, data?: FreezeData): Promise<any> {
    return this.patch(`/api/payment/subscription/${id}/freeze`, data)
  }
  public updateRenewalSubscription(id: string, data: { enabled: boolean }): Promise<any> {
    return this.patch(`/api/payment/subscription/${id}/renewal`, data)
  }
  public reactivateSubscription(): Promise<any> {
    return this.patch(`/api/payment/subscription/reactivate`)
  }
}

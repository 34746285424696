export default {
  camera: {
    live: 'Live',
    connecting: ' Connecting. . .',
    requesting: ' Requesting. . .',
    offline: 'Offline',
    error: 'Error',
    again: 'Try Again',
    errorTryAgain: 'ERROR: CONNECTION TIMEOUT',
    record: 'REC',
    StepByStep: 'Step-by-Step Setup Guide',
    RiskLossData: 'Risk of Data Loss',
    RiskLossDataDescription:
      "Your decision to pause cloud-based archiving puts your sites at an increased risk of security incidents. Without the cloud storage, you no longer have access to your historical footage, AI detection, and analysis. To mitigate this risk, click 'Resume Recording' to ensure continuous monitoring.",
    cameraProfile: 'Camera Profile',
    cameraProfileNcrDvr: 'NVR/DVR Profile',
    connection: 'Connection',
    securityTip: 'Security Tip',
    securityTipContent:
      "If you can't remember the username and password, we'll try to connect using your brand's default manufacturer setup. However, make sure to set up proper security credentials for your devices later to ensure bulletproof safety. 🔐",
    deleteCameraText:
      "By selecting 'Delete Camera', I acknowledge that I am initiating an irreversible action. This will permanently disconnect Arcadian Orbit Inc.'s services for my device, leading to the immediate and irretrievable loss of all recorded footage and all associated camera data.\n",
    deleteCameraTitle:
      'Ready to wipe out {data} and its entire cloud collection (recordings, clips, and analytics)? Type in your name to make it official.',
    cameraNotFound: 'camera not found',
    errorFailed: 'ERROR: FAILING TO STREAM',
    actions: {
      edit: 'Edit',
      view: 'View',
      clone: 'Duplicate',
      live: 'Live',
      forgetDevice: 'Forget Device',
      setup: 'Setup Profile',
      editProfile: 'Edit Setup',
      profile: 'Camera Profile',
      duplicate: 'Duplicate',
      connect: 'Connect',
      troubleshot: 'Troubleshoot',
      stopRecording: 'Stop Recording',
      resumeRecording: 'Resume Recording'
    },
    form: {
      name: 'Device Name',
      address: 'Device Location (Physical Address)',
      cameraUsername: 'Camera Username',
      cameraPassword: 'Camera Password',
      nvrdvrUsername: 'NVR/DVR Username',
      nvrdvrPassword: 'NVR/DVR Password',
      tag: 'Tags',
      IP: 'URL',
      port: 'Web (HTTP) Port No. *',
      streamPort: 'Stream (RTSP) Port No. *',
      securePort: 'Secure Communications (HTTPS) Port No.',
      extraPort: 'Port No.',
      audioPort: 'Audio Port No.',
      serialPort: 'Serial Port No. (i.e. PTZ Controllers)',
      additionalCustomPorts: 'Additional Custom Ports',
      channel: 'NVR/DVR Channel Numbers *',
      useSSL: 'Secure Sockets Layer (SSL)',
      enable: 'Enable',
      disable: 'Disable',
      reset: 'Reset Configurations',
      advanceSetup: 'Advance Connection Setup',
      personalizedSetup: 'Personalized Setup Assistance',
      howToConnect: 'Lens to Live Stream: How to Connect',
      cloneCamera: 'Duplicate Camera',
      editCamera: 'Edit',
      save: 'Save',
      urlTip: 'Tip: Router’s Static IP'
    },
    tooltip: {
      cameraUsernameHeader: "Finding Your Camera's Credentials 🛠",
      cameraUrl:
        'Tags are keywords or hashtags for devices, aiding in categorization and quick searches. Use them for efficient organization and streamlined search.',
      cameraUsernameDesc:
        "If your camera is on factory settings or has been reset, you won't need to worry about usernames or passwords; we'll retrieve the defaults from your manufacturer configs.",
      cameraURl:
        "Accepted Inputs: You can use a router's static IP address (e.g., 192.168.1.100), a domain name like mycamera.mydomain.com, a Dynamic DNS address (e.g., mycamera.ddns.net), or any internet URL for cameras using RTSP",

      WebHTTPPortDesc:
        "Web (HTTP) Port is designated for accessing the device's web interface, commonly used with IP cameras. The default configuration typically assigns it to port 80.",
      StreamRTSPPortDesc:
        'Dedicated to the Real-Time Streaming Protocol (RTSP), this port is used to stream video content from IP cameras and typically defaults to 554.',
      SecurePortDesc:
        'Data transmitted over a secure port is encrypted, offering protection against eavesdropping or data tampering. 443 is a common secure port number. ',
      ChannelPortDesc:
        "Sequential video input channels for connecting and recording video from surveillance cameras. For example, an 8-channel NVR allows up to 8 camera connections. Check your NVR or DVR's interface for details.",
      AudioPortDesc:
        'These ports, like 8000 and 9000, are commonly used for two-way audio in network cameras',
      SerialPortDesc:
        'In case your camera has serial ports for connecting to external devices or control systems, such as pan-tilt-zoom (PTZ) controllers.',
      ExtraPortsDesc:
        'can refer to any supplementary ports a device or service might use beyond the main or standard ports. These ports might be reserved for specific functions not covered by the primary ports or for handling additional data flow',
      SecureSocketLayerSSL: 'Secure Socket Layer (SSL)',
      tagTooltip:
        'Tags are keywords or hashtags for devices, aiding in categorization and quick searches. Use them for efficient organization and streamlined search.',
      SecureSocketLayerSSLDesc:
        "is a protocol used for securing data transmission over the internet. When enabled, it encrypts the data being sent so that it can't be easily intercepted or tampered with.",
      tryAgainMain:
        "Camera playing hide and seek? Let's find it ! \n 1) Make sure it's powered up and near by. \n  2) Is it on good terms with your router? Check their connection. \n 3) Clear the path – turn off firewalls or VPNs that might be in the way. \n  4) Play detective – enter its IP address in a browser to see its status.\n ",
      tryAgainNeedHelp: 'Still elusive?',
      tryAgainReachOut: 'Knowledge Center',
      tryAgainBridge: ' is ready to crack the case!',
      offlineMain:
        "Port Connection Issue Detected: Your camera's on, but the streaming ports are playing hard to get. Time to revisit your port forwarding setup. ",
      offlineNeedHelp: 'Need help?',
      offlineReachOut: 'Reach out',
      offlineBridge: " and we'll bridge the gap in no time! "
    }
  },
  cameraConnect: {
    workspaceHeader:
      'The journey to a safer tomorrow is yours to lead. First, a workspace tune-up. 🔌',
    workspaceTooltip:
      "Imagine this - workspaces are your distinct personal 'game zones', each with unique plans, devices, and teammates chosen by you. Feel like building a cozy space at home and inviting your family to join? Or perhaps diving into a workspace at your job, taking on the role of the Chief of Security for Business? Thrilling, isn't it?\"",
    nextSetup: 'Next, Setup Cameras',
    nextPortForward: 'Next, Port Forwarding',
    stepsGuide: 'Step-by-Step Setup Guide',
    advanceSetup: 'Advance Connection Setup',
    affordableVigilance: 'Making the Jump: How to Seamlessly Onboard onto Cloud Video Security ☁️',
    harnessPowerAi: 'Opt for 30, 60, and 90 Days of cloud safety!',
    seamlessIntegration: 'Seamless Integrations',
    flexibleArchiving: 'Precision Detection: Next-Gen AI \n' + '& Video Intelligence',
    getAssistance: 'Get Setup Assistance Here 🚀',
    chatGptGuid: 'ChatGPT Guide ',
    rapidAIAnalysis:
      'Harness the power of AI to detect people, objects and events, swiftly sifting through hours of video in mere minutes',
    enhancedMonitoringLayout:
      'Optimize your monitoring coverage by creating nested maps & floor plans',
    unsureIp: 'Unsure about your IP?',
    ipAddressMarketHeader: 'Onboard with a Smile! We Cover Installation. Call for Help! 🤙🏼 ',
    ipAddressMarketSubHeader:
      "Reach out, and we'll bridge the gap in no time! Our savvy team will swing by your location and get everything up and running smoothly.",
    explorationAwaits: 'Peek at the resources below to discover yours!',
    likePro: 'Feeling like a pro?',
    skipAhead: "If this isn't your first rodeo, skip right to the final step.",
    cameraNotFound: "Can't spot your camera?",
    quickAssistance: "Reach out, and we'll bridge the gap in no time!",
    tailoredSetup: 'Personalized Setup Assistance',
    seamlessCompatibility: 'Curious to know how we flawlessly sync with any camera you own?',
    ONVIFExplained: 'ONVIF Explained',
    frequentlyQuestion: 'FAQ: Data Privacy 🛡️',
    accessFootage: 'Who has access to my video footage?',
    monitoringHeader: 'Seamless Onboarding, Free of Charge',
    monitoringDesc:
      "Reach out, and we'll bridge the gap in no time! Our savvy team will swing by your location and get everything up and running smoothly.",
    Q: 'Q',
    A: 'A',
    hintPortForward: 'Hint: We asked ChatGPT to explain how to port forward cameras to a router',
    readAnswerPortForward: 'Read the answer.',
    security:
      'Unequivocally, no one. Your footage is fortified with {app} and is securely anchored on the Amazon AWS cloud, leveraging the pinnacle of encryption technologies. Your privacy is paramount to us.',
    endEncryption: 'end-to-end encryption',
    guideSecurity: 'Guide to Security',
    pricing: 'Pricing',
    DigitalSafety: 'Digital Safety ',
    SecureAWS:
      "Stored on the trusted Amazon AWS cloud, we use a zero-trust model, which, in essence, means it's built to be impenetrable. It's not just hard to crack; it's designed to be impossible.",
    CameraPortingSimplified:
      "Intro to Camera Ports: Setting up your camera? Remember, you'll need to port forward two key ports!",
    WebPortex: 'Web Access (HTTP) Port: ',
    VideoStreamex: 'Video Stream (RTSP) Port: ',
    WebGateway:
      "{WebPortex}: Imagine this as your camera's digital doorway, leading you straight into its web interface. Commonly used in IP cameras, right from the start, many cameras have this set to the familiar port 80.",
    StreamPort:
      "{VideoStreamex}t: All about delivering those live video feeds! It uses the Real-Time Streaming Protocol to make it happen. Most times, you'll find it's already set to port 554 when you start!",
    FreeOnboarding: 'Seamless Onboarding, Free of Charge',
    OnsiteSetup:
      "Reach out, and we'll bridge the gap in no time! Our savvy team will swing by your location and get everything up and running smoothly.",
    labelSelectManufacture: 'Search by Brand, Part Number or Model *',
    hintCameraProfile:
      'Pro Tip: Rock your {nvrSetup} Port forward it on your router just once, then easily add multiple cameras using their sequential channel numbers.',
    nvrSetup: 'NVR/DVR setup!',
    dropDownPlaceHolder: 'Click here..',
    staticIp: {
      GetStaticIP: 'Network Setup 🌐 Steady internet plus static IP equals camera ready!',
      ConfigurePort: 'Router Setup🔧 Enable Port-forwarding on your router for camera feeds.',
      portForwardSkip: 'Good to go. Both ports are port-forwarded on my router.',
      nextStep: 'Next Step ',
      guidToVideo: 'I’m Puzzled. \n Guide me through it. ',
      watchNow: 'Watch Now',
      hintSearchPort: "Hint: Search 'how to port forward on my {{Your Router Name}}' on Google.",
      ipAddress: 'My Router’s Static IP',
      hintIp: 'Hint: This field could also be a Domain Name, Dynamic DNS (DDNS), or a URL',
      ipTooltip:
        "To seamlessly integrate your cameras with our platform, we need to locate their digital address on your network. It's essential for your router to have a static IP address. This is like assigning a permanent digital address to your home or business on the internet",
      skip: 'skip',
      jumpToPricing: 'Jump to Pricing',
      paymentMethod: 'Next, Payment Method',
      masterImmediately: 'Master the Basics in 3 Minutes',
      step1VerifyIp: '(1/3) Verify IP Address',
      portForwarding: '(2/3) Port Forwarding',
      cameraProfile: '(3/3) Camera Profile',
      verifyConfigureIp:
        "I confirm that I've set up port forwarding on my router and jotted down all the port numbers. Onward to the next step! 🚀",
      powerDevices:
        'Camera Check 🎥 Power on and keep your devices nearby for a seamless installation.',
      hintConfigureIp:
        "Hint: Open a web browser → Input your router's IP address → Log in using your router’s username \n" +
        'and password→ Navigate to "Port Forwarding", "Virtual Server", or "Applications" \n' +
        '(terminology varies by router brand).',
      NextCameraProfile: 'Next, Camera Profile',
      SearchCriteria: 'Search by Brand, Part Number or Model',
      maps: 'Maps',
      connect: 'Connect',
      clone: 'Duplicate',
      forget: 'Forget',
      forgetDevice: 'Forget Device'
    },
    goToStreaming: 'Monitoring Dashboard'
  }
}

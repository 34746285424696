import { Service } from '@/lib/api'
import type {
  Analytic,
  BadAnalytic,
  CreateAnalyticDto
} from '@/lib/api/services/ai/analytics/analytic.interface'
import { CameraUpdateState } from '@/lib/api/services/ai/analytics/analytic.interface'

export class AnalyticService extends Service {
  public findAll(cameraId: string) {
    return this.get<Analytic[]>(`/api/analytic/camera/${cameraId}`)
  }

  public getUpdateSignal(cameraId: string) {
    return this.get<CameraUpdateState | undefined>(`/api/analytic/camera/${cameraId}/state`)
  }

  public create(cameraId: string, request: CreateAnalyticDto) {
    return this.post<{
      status: CameraUpdateState
      item: Analytic
    }>(`/api/analytic/camera/${cameraId}`, request)
  }

  public update(cameraId: string, analyticId: string, request: CreateAnalyticDto) {
    return this.patch<{
      status: CameraUpdateState
      item: Analytic
    }>(`/api/analytic/camera/${cameraId}/${analyticId}`, request)
  }

  public remove(cameraId: string, analyticId: unknown) {
    return this.delete<unknown>(`/api/analytic/camera/${cameraId}/${analyticId}`)
  }
}

import { PlayerCore } from '@/player/lib/player/player-core'
import { ImagePreview } from '@/player/lib/preview/image-preview'

export interface ExportEntity {
  diff: number
  startDate: Date
  name: string
  type: string
}
export class ExportHelper {
  constructor(protected readonly core: PlayerCore) {}

  async download(config: ExportEntity) {
    const exportEntity = await this.core.apis.createExport({
      duration: Math.round(config.diff),
      startAt: config.startDate.toISOString(),
      name: config.name,
      format: config.type
    })
    const preview = new ImagePreview(this.core.cameraId)
    const image = await preview.archive(config.startDate, 'image/jpeg')
    const snapshotEntity = await this.core.apis.createSnapshot({
      file: image,
      time: config.startDate,
      exportId: exportEntity.id
    })
    return { snapshotEntity, exportEntity }
  }
}

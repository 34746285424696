import { ref } from 'vue'
import type { ImageObject, ImageQueueCallbacks } from '@/player/interfaces'
import type { PlayerCore } from '@/player/lib/player/player-core'
import * as Comlink from 'comlink'

export class DecoderImageQueue<T = ImageObject> {
  protected loading = ref(true)
  public firstFrameShown = ref(false)

  constructor(protected readonly core: PlayerCore) {}

  init() {
    return this.core.worker.setQueueCallbacks(
      Comlink.proxy<ImageQueueCallbacks>({
        onLoadingChanged: this.onLoadingChange.bind(this),
        onFirstImageShownChanged: this.onFirstImageShownChanged.bind(this)
      })
    )
  }

  enable() {
    return this.core.worker.enableBuffering()
  }

  disable() {
    return this.core.worker.disableBuffering()
  }

  isBuffering() {
    return this.core.worker.isBufferingEnabled()
  }

  get isLoading() {
    return this.loading
  }

  clean() {
    // todo
  }

  protected onLoadingChange(value: boolean) {
    this.loading.value = value
  }

  protected onFirstImageShownChanged(value: boolean) {
    this.firstFrameShown.value = value
  }
}

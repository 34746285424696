import { AbstractHelper } from '@/lib/api/helpers/abstract-helper'
import {
  ApiClient,
  PermissionService,
  PermissionUserService,
  WorkspaceService,
  WorkspaceUserService
} from '@/lib/api'
import type { WorkSpaceUser, CreatePermission } from '@/lib/api'

export class permissionHelper extends AbstractHelper {
  protected workspaceService: WorkspaceService
  protected userService: WorkspaceUserService
  protected PermissionService: PermissionService
  protected PermissionUserService: PermissionUserService

  constructor(apiClient: ApiClient) {
    super(apiClient)
    this.workspaceService = apiClient.workspaceManager.workspace
    this.userService = apiClient.workspaceManager.user
    this.PermissionService = apiClient.permissionManager.permission
    this.PermissionUserService = apiClient.permissionManager.user
  }

  public async addPeopleToTeam(users: WorkSpaceUser[], teamId, workSpaceID) {
    await this.removePeopleFromOtherTeam(users)
    await Promise.all(users.map((user) => this.createPermission(user, teamId, workSpaceID)))
  }

  public async removePeopleFromTeam(users: WorkSpaceUser[], teamId) {
    const reqs = new Array<Promise<any>>()
    for (const user of users) {
      reqs.push(
        this.PermissionUserService.permissions(String(user?.id)).then((permissions) => {
          permissions.map((p) => (p.teamId === teamId ? this.removePermission(p.id) : null))
        })
      )
    }
    await Promise.all(reqs)
  }

  public async removePeopleFromOtherTeam(users: WorkSpaceUser[]) {
    const reqs = new Array<Promise<any>>()
    for (const user of users) {
      reqs.push(
        this.PermissionUserService.permissions(String(user?.id)).then((permissions) => {
          permissions.map((p) => this.removePermission(p.id))
        })
      )
    }
    await Promise.all(reqs)
  }

  public async createPermission(user: WorkSpaceUser, teamId, workSpaceID) {
    return await this.PermissionService.create({
      userId: user.remoteId,
      teamId: teamId,
      resourceId: workSpaceID
    } as CreatePermission)
  }

  public async removePermission(permissionId) {
    return await this.PermissionService.remove(permissionId)
  }
}

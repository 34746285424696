import { defineStore } from 'pinia'

export const useThemeStore = defineStore('ThemeSetting', {
  state: () => ({
    theme: localStorage.getItem('theme') || 'dark'
  }),
  actions: {
    changeTheme(theme: string) {
      const darkTheme = document.getElementById('dark-theme')
      const lightTheme = document.getElementById('light-theme')
      switch (theme) {
        case 'dark':
          this.changeThemeToDark(darkTheme, lightTheme)
          break
        case 'light':
          this.changeThemeToLight(darkTheme, lightTheme)
          break
        default:
          this.changeThemeToDark(darkTheme, lightTheme)
      }
    },

    changeThemeToDark(darkTheme: HTMLElement | null, lightTheme: HTMLElement | null) {
      this.theme = 'dark'
      if (lightTheme) lightTheme.remove()
      if (!darkTheme) {
        const link = document.createElement('link')
        link.id = 'dark-theme'
        link.href = '/themes/dark.css'
        link.rel = 'stylesheet'
        document.head.appendChild(link)
        const root = document.getElementsByTagName('html')[0]
        root.className = 'dark theme-dark'
        localStorage.setItem('theme', 'dark')
      }
    },
    changeThemeToLight(darkTheme: HTMLElement | null, lightTheme: HTMLElement | null) {
      this.theme = 'light'
      if (darkTheme) darkTheme.remove()
      if (!lightTheme) {
        const link = document.createElement('link')
        link.id = 'light-theme'
        link.href = '/themes/light.css'
        link.rel = 'stylesheet'
        document.head.appendChild(link)
        const root = document.getElementsByTagName('html')[0]
        root.className = 'light theme-light'
        localStorage.setItem('theme', 'light')
      }
    }
  }
})

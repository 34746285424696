import { Buffer } from 'buffer'

export function decodePcmS16LE(pcmS16LEData: Buffer): Float32Array {
  if (!pcmS16LEData || !(pcmS16LEData instanceof Buffer)) {
    throw new Error('Invalid input: Expected a Buffer instance.')
  }

  // Since each sample is 2 bytes, the number of samples is half the buffer length
  const numSamples = pcmS16LEData.length / 2
  const float32Array = new Float32Array(numSamples)
  const normalizationFactor = 1 / 32768 // To normalize to the range [-1, 1]

  for (let i = 0; i < numSamples; i++) {
    // Read two bytes, considering little endian format
    const sample = pcmS16LEData.readInt16LE(i * 2)
    float32Array[i] = sample * normalizationFactor
  }
  return float32Array
}

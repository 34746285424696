import { useI18n } from 'vue-i18n'
import { PeopleStatus } from '@/modules/Permissions/components/interfaces'
import { WorkspaceUsersStatus } from '@/lib/api'

export function translateTxt(text: string) {
  const { t } = useI18n()
  return t(text)
}

export function generateUniqueId(): string {
  const time = new Date().getTime()
  const random = Math.floor(Math.random() * 1000000)
  return `${time}-${random}`
}

export function getStatusTranslation(status: WorkspaceUsersStatus): string {
  switch (status) {
    case WorkspaceUsersStatus.joined:
      return translateTxt('permissions.table.statuses.joined')
    case WorkspaceUsersStatus.pending:
      return translateTxt('permissions.table.statuses.pending')
    case WorkspaceUsersStatus.notFound:
      return translateTxt('permissions.table.statuses.notFound')
    case WorkspaceUsersStatus.termsRequired:
      return translateTxt('permissions.table.statuses.termsRequired')
    case WorkspaceUsersStatus.withdrawn:
      return translateTxt('permissions.table.statuses.withdrawn')
    default:
      return translateTxt('permissions.table.statuses.joined')
  }
}
export function getAvatarName(name: string) {
  const names = name.split(' ')
  if (names.length > 1) return names[0].substring(0, 1) + names[1].substring(0, 1)
  else return name.substring(0, 1)
}

export async function convertFromBlob(initialFile) {
  let blob

  if (initialFile.startsWith('blob:')) {
    // Convert blob URL back to blob
    blob = await fetch(initialFile).then((res) => res.blob())
  } else {
    // Fetch the image as blob from a regular URL
    const response = await fetch(initialFile)
    blob = await response.blob()
  }

  return new File([blob], 'image.png', { type: 'image/png' }) // You can adjust the type if it's not a PNG
}
export function countKeyOccurrences(data, authKey) {
  let count = 0

  for (let i = 0; i < data.length; i++) {
    if (data[i] === authKey) {
      count++
    }
  }
  return count
}

export function getRandomIntInclusive(min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

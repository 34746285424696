import { Service } from '../service'
import type { AxiosInstance } from 'axios'
import { AnalyticEventService, RecordService } from '@/lib/api'
export class EventStorageService extends Service {
  public event: AnalyticEventService
  public record: RecordService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.event = new AnalyticEventService(adaptor)
    this.record = new RecordService(adaptor)
  }
}
